import typeToReducer from 'type-to-reducer';
import * as actionType from '../actions/actionType';

const initialState = {
  misDefault: [],
};

const bookingSettingsReducer = typeToReducer(
  {
    [actionType.ATMED_GET_ALL_BOOKING_SETTINGS]: {
      FULFILLED: (state, action) => ({
        ...state,
        misDefault: action.payload.data.data,
      }),
    },
    [actionType.ATMED_UPDATE_BOOKING_SETTINGS]: {
      FULFILLED: (state, action) => ({
        ...state,
        misDefaultUpdate: true,
      }),
    },
  },
  initialState,
);

export default bookingSettingsReducer;
