import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Typography, Tag, Row, Col, Modal, Tooltip
} from 'antd';
import {
  InfoCircleOutlined
} from '@ant-design/icons';
import moment from 'moment';

const { Title, Link } = Typography;

function LogManagement(props) {
  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const [stateColumnData, setStateColumnData] = useState({});
  const [currentPage,setCurrentPage]=useState(1);

  const renderData = useCallback((currentPage) => {
    props.getAtmedNotification(undefined,20,currentPage);
  }, [props.getAtmedNotification]);

  useEffect(() => {
    renderData(currentPage);
  }, [renderData,currentPage]);

  const showNotificationsModal = (data) => {
    setNotificationsVisible(true);
    setStateColumnData(data);
  };

  const handleOk = () => {
    setNotificationsVisible(false);
    setStateColumnData({});
  };

  const handleNotificationsCancel = () => {
    setNotificationsVisible(false);
    setStateColumnData({});
  };

  const columns = [
    {
      title: 'Title',
      key: 'title',
      width: 250,
      render: (_, columnData) => (
        <>
          <div>{columnData.title}</div>
        </>
      )
    },
    {
      title: 'Description',
      key: 'description',
      render: (_, columnData) => (
        <>
          <div>{columnData.description}</div>
        </>
      )
    },
    {
      title: 'CREATED',
      key: 'status',
      dataIndex: 'status',
      className: 'date-time',
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format('MM/DD/YYYY')}</p>
          <p>{moment(columnData.created_at).format('hh:mm A')}</p>
        </>
      )
    },
  ];

  return (
    <div>
      <Modal className="logs-modal" title="Notifications" footer={false} visible={notificationsVisible} onOk={handleOk} onCancel={handleNotificationsCancel}>
        <p><span>Title : </span>{stateColumnData.title}</p>
        <p><span>Description : </span>{stateColumnData.desc}</p>
      </Modal>
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Notifications
          </Title>
        </Col>
        <Col className="gutter-row" span={9} offset={0}>
        </Col>
      </Row>
     <Table 
        className="list_table" 
        dataSource={props.allAtmedNotification?props.allAtmedNotification.data:null} 
        pagination={{ pageSize: 20, total: props.allAtmedNotification?.total_count, showSizeChanger:false }} 
        columns={columns} 
        scroll={{ x: 800 }} 
        rowKey="id" 
        onChange={(e)=>{
          setCurrentPage(e.current);
        }} />
    </div>
  );
}

export default LogManagement;
