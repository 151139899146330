import React, { useState } from 'react';
import { Modal, Button, Form, Input, Select,Col } from 'antd';
import GooglePlacesAutocomplete from "react-google-autocomplete";
import GoogleMapComponent from "./googlemap";
const { Option } = Select;

const NewAddressForm = ({ visible, onCancel, onSave }) => {
    const [addressType, setAddressType] = useState("");
    const [houseNumber, setHouseNumber] = useState("");
    const [landmark, setLandmark] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [otherAddressType, setOtherAddressType] = useState("");
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [longitude, setLong] = useState(null);
    const [latitude, setLat] = useState(null);

  const handleAddressTypeChange = (value) => {
    setAddressType(value);
  };
  const handleCancel = () => {
    // setAddressType("");
    // setHouseNumber("");
    // setLandmark("");
    // setMobileNumber("");
    // setOtherAddressType("");
    // setSelectedPlace(null); // Clear the selected place
    // setLong(null);
    // setLat(null);
    onCancel();
  };
  const handleSaveAddress = () => {
    let city = "";
    let country = "";
    let state = "";
    let locality = "";
    let zip ="";
    if (selectedPlace && selectedPlace?.address_components) {
      const addressComponents = selectedPlace?.address_components;
      addressComponents.forEach(component => {
        if (component.types.includes("locality")) {
          city = component.long_name;
        }
        if (component.types.includes("sublocality")) {
            locality = component.long_name;
          }
        if (component.types.includes("administrative_area_level_1")) {
          state = component.long_name;
        }
        if (component.types.includes("country")) {
          country = component.long_name;
        }
        if (component.types.includes("postal_town")) {
          city = component.long_name;
        }
        if (component.types.includes("postal_code")) {
            zip = component.long_name;
          }
      });
      const address = {
        category: addressType,
        location: selectedPlace?.formatted_address || "",
        house_no: houseNumber,
        locality: locality,
        city:city,
        state: state,
        zip: zip,
        landmark: landmark,
        country: country,
        latitude: latitude,
        longitude: longitude,
        phone_code: "+91",
        phone: mobileNumber,
        otherAddressType: addressType === "3" ? otherAddressType : "",
    };
    console.log("addressnewform", address)
    onSave(address); // Call onSave and pass the address data
    }
    onCancel();
  };
  return (
    <Form layout="vertical"onClick={handleSaveAddress} >
    <Form.Item label="New Address">
              {" "}
              <div style={{ width: "100%", height: "40px" }}>
                {" "}
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyAxjwom-B5LHIh_8MYyq61_lyCqCBohz6Y"
                  onPlaceSelected={(place) => {
                    console.log("Selected Place:", place);
                    if (place && place.geometry && place.geometry.location) {
                      const lat = place.geometry.location.lat();
                      const lng = place.geometry.location.lng();
                      setLat(lat);
                      setLong(lng);
                      console.log("Latitude:", lat, "Longitude:", lng);
                    } else {
                      console.error("Invalid place object:", place);
                    }
                    setSelectedPlace(place);
                  }}
                  options={{
                    types: ["establishment"],
                    componentRestrictions: { country: "IN" },
                  }}
                  style={{ width: "100%", height: "40px" }}
                />
              </div>
              {selectedPlace && (
                <div
                  style={{
                    width: "100%",
                    height: "300px",
                    marginTop: "30px",
                  }}
                >
                  {" "}
                  <GoogleMapComponent
                    location={{ lat: latitude, lng: longitude }}
                  />
                </div>
              )}
            </Form.Item>
      <Form.Item label="House No/Flat/Building No">
        <Input
          autoComplete="off"
          placeholder="Enter House No/Flat/Building No"
          size="large"
          value={houseNumber}
          onChange={(e) => setHouseNumber(e.target.value)}
        />
      </Form.Item>
      <Form.Item label="Landmark">
        <Input
          autoComplete="off"
          placeholder="Enter Landmark"
          size="large"
          value={landmark}
          onChange={(e) => setLandmark(e.target.value)}
        />
      </Form.Item>
      <Form.Item label="Mobile Number">
        <Input
          autoComplete="off"
          placeholder="Enter Mobile Number"
          size="large"
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
        />
      </Form.Item>
      <Form.Item label="Address Type">
        <Select
          placeholder="Select Address Type"
          size="large"
          onChange={handleAddressTypeChange}
          value={addressType}
        >
          <Option value="1">Home</Option>
          <Option value="2">Work</Option>
          <Option value="3">Others</Option>
        </Select>
      </Form.Item>
      {addressType === "3" && (
        <Form.Item label="Please specify">
          <Input placeholder="Specify Address Type" size="large" value={otherAddressType} onChange={(e) => setOtherAddressType(e.target.value)} />
        </Form.Item>
      )}
    </Form>

  );
  
};

export default NewAddressForm;
