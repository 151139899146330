import React, { useState } from "react";
import { Modal, Dropdown, Menu, Form, Typography } from "antd";
import { get } from "lodash";
import { MoreOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import moment from "moment";
import VendorEquipmentFormModal from "./VendorEquipmentFormModal";
import OwnerFormModal from "./OwnerFormModal";
import ViewDocuments from "./ViewDocuments";
import UploadComponent from "./UploadComponent";
import { formatPayload } from "../../../../common/PhoneInput";

const { Link } = Typography;
const formatUserProfile = (profileInfo) => {
  if (!profileInfo) return undefined;
  return {
    ...profileInfo,
    established_year:
      profileInfo && profileInfo.established_year === ""
        ? undefined
        : moment(profileInfo.established_year),
  };
};
const EditVendorEquipmentButton = ({
  columnData,
  onUpdateVendorEquipment,
  getSingleEquipmentUser,
  singleVendorEquipment,
  addVendorEquipmentOwner,
  allCountry,
  countryCode,
  updateVendorEquipmentOwner,
  props,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [ownerModalVisible, setOwnerModalVisible] = useState(false);
  const [documentModalVisible, showDocumentModalVisible] = useState(false);
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  const [form] = Form.useForm();

  const owner = get(columnData, "owner");
  const id = get(columnData, "id");

  const showModal = async () => {
    if (columnData.id) {
      await getSingleEquipmentUser(`id=${columnData.id}`);
    }
    setModalVisible(true);
  };
  const showDocumentModal = async () => {
    if (columnData.id) {
      await getSingleEquipmentUser(`id=${columnData.id}`);
    }
    showDocumentModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
    setOwnerModalVisible(false);
    form.resetFields();
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { equip_vendor: data },
    };
    await onUpdateVendorEquipment(payloadObj);
    form.resetFields();
    hideModal();
  };
  const onOwnerSubmit = async (data) => {
    // console.log("data", data)
    if (owner) {
      const userId = get(owner, "id");
      await updateVendorEquipmentOwner({ id, payload: { user: data }, userId });
      hideModal();
    }
  };

  return (
    <>
      {documentModalVisible ? (
        <Modal
          title={`Documents - ${columnData.name}`}
          closable
          onCancel={() => showDocumentModalVisible(false)}
          visible={documentModalVisible}
          footer={null}
          destroyOnClose
          width={1000}
        >
          <ViewDocuments
            id={columnData.id}
            onClose={() => showDocumentModalVisible(false)}
          />
        </Modal>
      ) : null}

      {profileModalVisible ? (
        <Modal
          title={`Update Vendor Photo - ${columnData.name}`}
          closable
          onCancel={() => setProfileModalVisible(false)}
          visible={profileModalVisible}
          footer={null}
          destroyOnClose
          width={500}
          className="upload-image-modal"
        >
          <UploadComponent
            props={props}
            id={columnData.id}
            onClose={() => setProfileModalVisible(false)}
            columnData={columnData}
          />
        </Modal>
      ) : null}

      <VendorEquipmentFormModal
        data={singleVendorEquipment}
        onSubmit={onSubmit}
        onCancel={hideModal}
        countryCode={countryCode}
        allCountry={allCountry}
        visible={modalVisible}
        form={form}
        title={`Edit Vendor - ${columnData.name}`}
        submitText="Update and Save"
      />
      <OwnerFormModal
        onSubmit={onOwnerSubmit}
        onCancel={() => setOwnerModalVisible(false)}
        visible={ownerModalVisible}
        countryCode={countryCode}
        title={`Edit ${owner && owner.name}'s Details`}
        submitText={"Update Owner"}
        data={owner}
      />
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="0" onClick={showModal}>
              View orders
            </Menu.Item>
          </Menu>
        }
        trigger={["click"]}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};

const mapStateToProps = ({ vendorEquipmentReducer }) => ({
  singleVendorEquipment: formatUserProfile(
    vendorEquipmentReducer.singleVendorEquipment
  ),
});
export default connect(mapStateToProps)(EditVendorEquipmentButton);
