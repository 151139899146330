
import React, { useState, useEffect } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const MyMapComponent = ({ location }) => {
  const [zoom, setZoom] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      setZoom((currentZoom) => {
        if (currentZoom < 20) {
          return currentZoom + 1;
        }
        clearInterval(interval);
        return currentZoom;
      });
    }, 200);

    return () => clearInterval(interval);
  }, []);

  return (
    <GoogleMap mapContainerStyle={containerStyle} center={location} zoom={zoom}>
      {location && <Marker position={location} />}
    </GoogleMap>
  );
};

export default MyMapComponent;
