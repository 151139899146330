import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Typography,
  Select,
  DatePicker,
  Popover,
  Button,
  Row,
  Col,
  Divider,
  Tooltip,
  message,
  Modal,
  Card,
  Image,
} from "antd";
import {
  InfoCircleOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { unset, get, set } from "lodash";
import AdvancedSearchForm from "../../../common/SearchForm";
import ProductDetailMenuDrawerModal from "./ProductDetailMenuDrawerModal";
import PaymentDetails from "./PaymentDetails";
import styles from "../../../common/Layout.module.scss";
import documentsIcon from "../../../assets/document.png";

// import { ORDER_STATUS_COLORS } from '../Orders/vendorEquipmentOrders.constants';

const { Title, Link } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ProofComponent = (value) => {
  return (
    <Row className="document-section">
      <Col span={12} justify="start" style={{ padding: 10 }}>
        <Card>
          {value.values.settlement_proof_url.match(/\.(pdf|doc|xls|xml)$/) !=
          null ? (
            <Link href={value.values.settlement_proof_url} target="_blank">
              <Image
                preview={false}
                src={documentsIcon}
                style={{ marginRight: 6, width: "86px", height: "86px" }}
                alt="Route"
              />
            </Link>
          ) : (
            <Image
              style={{ width: "86px", height: "86px" }}
              alt="Proof files"
              src={value.values.settlement_proof_url}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
};

function Payments(props) {
  // const sessionsMessage = useSelector(state => state.homeHealthcareReducer.allHomePaymentsRefresh);

  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState([]);
  const [searchOption, setSearchOption] = useState(false);
  const [currentPaymentInfoColumn, setPaymentInfoColumn] = useState({});
  const [paymentDetails, setPaymentDetails] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [proofVisible, setProofVisible] = useState(false);
  const [stateColumnData, setStateColumnData] = useState({});
  const allHomePaymentsRefresh = useSelector(
    (state) => state.vendorEquipmenReducer.allHomePaymentsRefresh
  );

  const renderData = useCallback(
    (currentPage) => {
      props.getHomePayments(undefined, 20, currentPage);
    },
    [props.getHomePayments]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  const showPaymentDetails = (data) => {
    setPaymentDetails(true);
    setPaymentInfoColumn(data);
  };

  const showProofModal = (data) => {
    setProofVisible(true);
    setStateColumnData(data);
  };

  const handleProofOk = () => {
    setProofVisible(false);
    setStateColumnData({});
  };

  const handleProofCancel = () => {
    setProofVisible(false);
    setStateColumnData({});
  };

  const columns = [
    {
      title: "Booking ID",
      key: "bid",
      width: 230,
      render: (_, columnData) => (
        <>
          <p>{`${columnData.module}`}</p>
          <p className="orderId">Booking ID: {columnData.bid}</p>
          <p className="orderId">Date: {columnData.booking_date}</p>
          <Link>
            <div
              onClick={() => {
                setDescriptionModal(true);
                setCurrentColumn(columnData);
              }}
            >
              <div className="view-details">View Details</div>
            </div>
          </Link>
        </>
      ),
    },
    {
      title: "Patient Details",
      key: "patient_name",
      width: 130,
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>{columnData.patient_name} </Col>
          </Row>
          <Row>
            <Col span={24} className="lightFont">
              {columnData.patient_age} / {columnData.patient_gender}
            </Col>
          </Row>
          <div className="view-details">
            <Link onClick={() => showPaymentDetails(columnData)}>
              PAYMENT INFO
            </Link>
          </div>
        </>
      ),
    },
    {
      title: "Program Details",
      key: "program_name",
      width: 200,
      render: (_, columnData) => (
        <>
          <div>{`${columnData.program_name}`}</div>
          <div className="orderId">{`${columnData.total_sessions} Sessions`}</div>
        </>
      ),
    },
    {
      title: "Payment",
      key: "settlement_amount",
      width: 230,
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={12}>Total Payable</Col>
            <Col span={8} offset={1} className="fw500">
              {columnData.currency}
              {columnData.total_payable}
            </Col>
            {/* <Col span={12}>Online Payment</Col>
            <Col span={8} offset={1} className="fw500">{columnData.currency}{columnData.total_online}</Col>
            <Col span={12}>Cash Payment</Col>
            <Col span={8} offset={1} className="fw500">{columnData.currency}{columnData.total_cash}</Col> */}
          </Row>
        </>
      ),
    },
    {
      title: "Receivable",
      key: "settlement_amount",
      width: 100,
      render: (_, columnData) => (
        <>
          <span className="fw500">{`${columnData.currency}${columnData.settlement_amount}`}</span>
          <Popover
            content={
              <>
                <Row>
                  <Col span={12}>Atmed percentage</Col>
                  <Col span={4} offset={8} className="fw500">
                    {columnData.atmed_fee_percent}%
                  </Col>
                  <Col span={12}>Atmed Price</Col>
                  <Col span={4} offset={8} className="fw500">
                    {columnData.currency}
                    {columnData.atmed_price}
                  </Col>
                </Row>
                <Divider style={{ border: 0, margin: "8px 0" }} />
                <Row>
                  <Col span={12}>Company percentage</Col>
                  <Col span={4} offset={8} className="fw500">
                    {100 - columnData.atmed_fee_percent}%
                  </Col>
                  <Col span={12}>Company Price</Col>
                  <Col span={4} offset={8} className="fw500">
                    {columnData.currency}
                    {columnData.vendor_price}
                  </Col>
                </Row>
              </>
            }
            title="Receivable Amount "
          >
            <Button type="text" icon={<InfoCircleOutlined />} />
          </Popover>
        </>
      ),
    },
    {
      title: "Status",
      key: "settlement_status",
      width: 120,
      render: (_, columnData) => (
        <>
          {columnData.settlement_status ? (
            <span className="activate">Completed</span>
          ) : (
            <span className="inActivateNotverified">Pending</span>
          )}
          {columnData.notes !== null ? (
            <div>
              <Tooltip placement="bottom" title={columnData.notes}>
                <InfoCircleOutlined />
              </Tooltip>
            </div>
          ) : (
            ""
          )}
          {columnData.settlement_proof_url !== null && (
            <div className="view-details">
              <Link onClick={() => showProofModal(columnData)}>
                VIEW PROOFS
              </Link>
            </div>
          )}
        </>
      ),
    },
  ];
  const handleRefresh = () => {
    props.getHomePayments(undefined, 20, currentPage);
    if (allHomePaymentsRefresh && allHomePaymentsRefresh) {
      message.success(allHomePaymentsRefresh.message);
    }
  };
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    if (newValues["q[date_from_to]"]) {
      const [from, to] = get(newValues, "q[date_from_to]");
      unset(newValues, "q[date_from_to]");
      set(newValues, ["q[created_at_gteq]"], moment(from).format("DD/MM/YYYY"));
      set(newValues, ["q[created_at_lteq]"], moment(to).format("DD/MM/YYYY"));
    }
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getHomePayments(queryString, 20, currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <div>
      {proofVisible && (
        <Modal
          className="Proof-modal"
          title="Proof"
          footer={false}
          visible={proofVisible}
          onOk={handleProofOk}
          onCancel={handleProofCancel}
          width={300}
        >
          <ProofComponent
            className={styles.detValue}
            values={stateColumnData}
          />
        </Modal>
      )}
      <PaymentDetails
        visible={paymentDetails}
        data={currentPaymentInfoColumn && currentPaymentInfoColumn}
        onCancel={() => setPaymentDetails(false)}
        width={1000}
        title={`Payment Details - Booking ID: ${
          currentPaymentInfoColumn && currentPaymentInfoColumn.bid
        } (${
          currentPaymentInfoColumn && currentPaymentInfoColumn.patient_name
        })`}
      />
      <ProductDetailMenuDrawerModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Settlements
        <Button shape="circle" icon={<SyncOutlined />} style={{ marginRight: 8 }} onClick={() => handleRefresh()} />
      </Title> */}
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Settlements
          </Title>
        </Col>
        <Col className="gutter-row section-heading" span={3} offset={0}>
          <Title
              level={4}
              className="d-flex title"
              style={{ marginTop: '10px' }}
            >
              Total: {props.allHomePayments?.total_count}
            </Title>
        </Col>
        <Col className="gutter-row" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row" span={2} offset={0}>
          <Button
            shape="circle"
            icon={<SyncOutlined />}
            style={{ marginRight: 8, marginLeft: 10 }}
            onClick={() => handleRefresh()}
          />
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Settlements"
          formItems={[
            {
              key: "q[module_eq]",
              label: "Service Name",
            },
            {
              key: "q[bid_eq]",
              label: "Booking ID",
            },
            {
              key: "q[patient_name_cont]",
              label: "Patient Name",
            },
            {
              key: "q[settlement_status_eq]",
              label: "Settlement Status",
              Component: (
                <Select placeholder="Settled / Pending" size="large">
                  <Option value>Completed</Option>
                  <Option value={false}>Pending</Option>
                </Select>
              ),
            },
            {
              key: "q[date_from_to]",
              label: "Date",
              Component: (
                <RangePicker
                  format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                  placeholder={["Start Date", "End Date"]}
                  size="large"
                />
              ),
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={props.allHomePayments ? props.allHomePayments.data : null}
        pagination={{
          pageSize: 20,
          total: props.allHomePayments?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="bid"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default Payments;
