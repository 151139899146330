import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Button,
  Typography,
  Select,
  Modal,
  Card,
  Image,
  DatePicker,
  Row,
  Col,
  message,
} from "antd";
import { SearchOutlined, SyncOutlined } from "@ant-design/icons";
import AdvancedSearchForm from "../../../common/SearchForm";
import documentsIcon from "../../../assets/document.png";
import DetailDrawer from "./components/detailDrawer";
import PaymentDetails from "./components/PaymentDetails";

const { Title, Link } = Typography;
const { Option } = Select;

const DocumentsComponent = (value) => {
  return (
    <Row className="case-file-section">
      {value.values.map((val) => (
        <Col
          span={4}
          justify="start"
          style={{ padding: "10", margin: "0 10px" }}
        >
          <Card>
            {/* <Image style={{ width: '110px', height: '86px' }} alt="Case Files" src={val} /> */}
            {val.match(/\.(pdf|doc|xls|xml)$/) != null ? (
              <Link href={val}>
                <Image
                  preview={false}
                  src={documentsIcon}
                  style={{ marginRight: 6, width: "110px", height: "86px" }}
                  alt="Route"
                />
              </Link>
            ) : (
              <Image
                style={{ width: "86px", height: "86px" }}
                alt="Proof files"
                src={val}
              />
            )}
          </Card>
        </Col>
      ))}
    </Row>
  );
};

function AppointmentClinic(props) {
  const vendorEquipmentRefresh = useSelector(
    (state) => state.vendorEquipmenReducer.allBookingsRefresh
  );

  //const [modalVisible, setModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [logsVisible, setLogsVisible] = useState(false);
  const [caseVisible, setCaseVisible] = useState(false);
  const [searchOption, setSearchOption] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  // const showModal = () => {
  //   setModalVisible(true);
  // };
  // const hideModal = () => {
  //   setModalVisible(false);
  // };

  const showLogsModal = (data) => {
    setLogsVisible(true);
    setCurrentColumn(data);
  };
  const showCaseModal = (data) => {
    setCaseVisible(true);
    setCurrentColumn(data);
  };
  const showPaymentDetails = (data) => {
    setPaymentDetails(true);
    setCurrentColumn(data);
  };

  const handleOk = () => {
    setLogsVisible(false);
    setCaseVisible(false);
    setCurrentColumn({});
  };

  const handleLogsCancel = () => {
    setLogsVisible(false);
    setCaseVisible(false);
    setCurrentColumn({});
  };

  const renderData = useCallback(
    (currentPage) => {
      props.getAllAtmedBookingHomeHealthcare(undefined, 20, currentPage);
      props.getAllAtmedBookingHomeHealthcareServices.call();
    },
    [
      props.getAllAtmedBookingHomeHealthcare,
      props.getAllAtmedBookingHomeHealthcareServices,
    ]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  const handleRefresh = () => {
    props.getAllAtmedBookingHomeHealthcare(undefined, 20, currentPage);
    if (vendorEquipmentRefresh && vendorEquipmentRefresh) {
      message.success(vendorEquipmentRefresh.message);
    }
  };

  const columns = [
    {
      title: "Service",
      key: "bid",
      dataIndex: "bid",
      width: "35%",
      render: (_, columnData) => (
        <Row className="name-details-row">
          <Col span={14} offset={1}>
            <div className="table-fc-name">{columnData.module}</div>
            <div className="table-fc-email">Booking ID: {columnData.bid}</div>
            <div className="table-fc-email">
              Date: {columnData.booking_date}
            </div>
            <div className="view-details">
              <Link
                onClick={() => {
                  setDescriptionModal(true);
                  setCurrentColumn(columnData);
                }}
              >
                VIEW DETAILS
              </Link>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Patient Details",
      key: "patient_name",
      width: "20%",
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>{columnData.patient_name} </Col>
            <Col span={24} className="lightFont">
              {columnData.patient_age} / {columnData.patient_gender}
            </Col>
            <Col span={24} className="lightFont">
              {columnData.patient_phone}
            </Col>
          </Row>
          {/* <div className="view-details">
            <Link onClick={() => showCaseModal(columnData)}>
              CASE FILES
            </Link>
          </div>
          <div className="view-details">
            <Link onClick={() => showLogsModal(columnData)}>
              CASE SHEET
            </Link>
          </div> */}
          <div className="view-details">
            <Link onClick={() => showPaymentDetails(columnData)}>
              PAYMENT INFO
            </Link>
          </div>
        </>
      ),
    },
    {
      title: "Package Details",
      key: "program_name",
      width: "25%",
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>{columnData.program_name} </Col>
          </Row>
          <Row>
            <Col span={24} className="lightFont">
              {columnData.program_duration}
            </Col>
          </Row>
          <Row>
            <Col span={24} className="lightFont">
              {columnData.total_sessions} Sessions
            </Col>
          </Row>
          <Row>
            <Col span={24} className="lightFont">
              {columnData.schedule}
            </Col>
          </Row>
          <Row>
            <Col span={24} className="lightFont">
              ({columnData.preferred_time})
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Status",
      key: "status_code",
      dataIndex: "status_code",
      width: "25%",
      render: (_, columnData) => (
        <Row>
          <Col span={24}>
            {columnData.status_code === 1 ? (
              <span className="booking-placed">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 2 ? (
              <span className="booking-confirmed">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 3 ? (
              <span className="booking-inprogress">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 4 ? (
              <span className="booking-completed">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 5 ? (
              <span className="booking-cancelled">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 6 ? (
              <span className="booking-cancelled">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 7 ? (
              <span className="booking-placed">{columnData.status}</span>
            ) : null}
          </Col>
          <Col span={24} className="lightFont">
            {columnData.booking_date}
          </Col>
        </Row>
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    console.log("newValues string", newValues);
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    console.log("query string", queryString);
    props.getAllAtmedBookingHomeHealthcare(queryString, 20, currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <div>
      <Modal
        className="logs-modal"
        title="Prescription"
        footer={false}
        visible={logsVisible}
        onOk={handleOk}
        onCancel={handleLogsCancel}
      >
        <p>
          <span>Prescription : </span>
          {currentColumn.prescription && currentColumn.prescription.html}
        </p>
      </Modal>
      <PaymentDetails
        visible={paymentDetails}
        data={currentColumn && currentColumn}
        onCancel={() => setPaymentDetails(false)}
        width={1000}
        title={`Payment Details - Booking ID: ${
          currentColumn && currentColumn.bid
        } (${currentColumn && currentColumn.vendor_name})`}
      />
      <Modal
        className="logs-modal"
        title="Case Files"
        footer={false}
        visible={caseVisible}
        onOk={handleOk}
        onCancel={handleLogsCancel}
        width={900}
      >
        {caseVisible && currentColumn.case_files.length > 0 ? (
          <DocumentsComponent values={currentColumn.case_files} />
        ) : (
          <p style={{ textAlign: "center" }}>No Case files</p>
        )}
      </Modal>
      <DetailDrawer
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        All Appointments - Surgery Consultation
      </Title> */}
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Home Healthcare
          </Title>
        </Col>
        <Col className="gutter-row section-heading" span={3} offset={0}>
          <Title
              level={4}
              className="d-flex title"
              style={{ marginTop: '10px' }}
            >
              Total: {props.results?.total_count}
            </Title>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={2} offset={0}>
          <Button
            shape="circle"
            icon={<SyncOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => handleRefresh()}
          />
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Surgery Consultation"
          formItems={[
            {
              key: "q[bid_cont]",
              label: "Booking ID",
            },
            {
              key: "q[patient_name_cont]",
              label: "Patient name",
            },
            {
              key: "q[address_cont]",
              label: "Patient Address",
            },
            {
              key: "q[module_eq]",
              label: "Service name",
              Component: (
                <Select placeholder="Select Service name" size="large">
                  {props.services &&
                    props.services.length > 0 &&
                    props.services.map((parentd, index) => (
                      <Option key={parentd} value={parentd}>
                        {parentd}
                      </Option>
                    ))}
                </Select>
              ),
            },
            {
              key: "q[program_name_cont]",
              label: "Program name",
            },
            {
              key: "q[status_code_eq]",
              label: "Status",
              Component: (
                <Select placeholder="Select Booking status" size="large">
                  <Option value="1">Booking Placed</Option>
                  <Option value="2">Booking Confirmed</Option>
                  <Option value="3">In Progress</Option>
                  <Option value="4">Booking Completed</Option>
                  <Option value="5">Booking Cancelled</Option>
                </Select>
              ),
            },
            {
              key: "q[created_at_gteq]",
              label: "From Date",
              Component: <DatePicker size="large" />,
            },
            {
              key: "q[created_at_lteq]",
              label: "To Date",
              Component: <DatePicker size="large" />,
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={props.results ? props.results.data : null}
        pagination={{
          pageSize: 20,
          total: props.results?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default AppointmentClinic;
