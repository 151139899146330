/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import {
  Table,
  Typography,
  Tooltip,
  Card,
  Col,
  Row,
  DatePicker,
  Button,
  Select,
  Rate,
  message,
  Spin,
} from "antd";
import { useLocation } from "react-router-dom";
import { SearchOutlined, SyncOutlined } from "@ant-design/icons";
import moment from "moment";
import { unset, get, set } from "lodash";
import AdvancedSearchForm from "../../../common/SearchForm";
import ChangeCaregiverform from "./ChangeCaregiverform";
import ViewDetailsDrawer from "./ViewDetailsDrawer";
import DetailsDrawer from "./CaregiverDetailsDrawer";
import ConfirmCaseSheetButton from "./ConfirmCaseSheetButton";
import TrackPartner from "./TrackPartner";

const { Title, Link } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

function UserManagement(props) {
  const location = useLocation();
  const searchQuery = location.search.substring(1);

  const [descriptionModal, setShowDetailsDrawer] = useState(false);
  const [showSessionDrawer, setShowSessionDrawer] = useState(false);
  const [searchOption, setSearchOption] = useState(false);
  const [trackDeliveryModal, setTrackDeliveryModal] = useState(false);

  const [selectedColumn, setSelectedColumn] = useState(null);

  const toggleTrackDeliveryModal = () => {
    setTrackDeliveryModal(!trackDeliveryModal);
  };

  useEffect(() => {
    props.getBookingHomeHealthcareSessions(searchQuery);
  }, []);
  const confirmHomeHealthcareBooking = async (params) => {
    await props.changeHomeHealthcareBookingCareGiver(params);
    await props.getBookingHomeHealthcareSessions(searchQuery);
  };
  const BookingRedirectcolumns = [
    {
      title: "Session",
      key: "id",
      width: "25%",
      render: (_, columnData) => (
        <>
          {/* <Link onClick={() => toggleActivateModalVisibility(columnData)}>
            {columnData.sid}
          </Link>
          <div>{columnData.session_date}</div> */}
          <Row className="name-details-row">
            <Col span={24} offset={1}>
              <div className="table-fc-name">
                {props.bookingSession.details.module}
              </div>
              <div className="table-fc-email">Session ID: {columnData.sid}</div>
              <div className="table-fc-email">
                Date: {columnData.session_date}
              </div>
              <div className="view-details">
                <Link
                  onClick={() => {
                    setShowDetailsDrawer(true);
                    setSelectedColumn(columnData);
                  }}
                >
                  VIEW DETAILS
                </Link>
              </div>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Caregiver",
      key: "service_details",
      render: (_, columnData) => (
        <>
          <div className="table-fc-name">{columnData.caregiver_name}</div>
          <div className="table-fc-name">{columnData.caregiver_phone}</div>
          <div className="view-details">
            <Link onClick={() => toggleActivateModalVisibility(columnData)}>
              VIEW DETAILS
            </Link>
          </div>
        </>
      ),
    },

    {
      title: "Date",
      key: "payment_details",
      render: (_, columnData) => (
        <>
          <div>Start Date :{columnData.started_at}</div>
          <div>End Date :{columnData.ended_at}</div>
          <div>Payment Details : {columnData.payment_status}</div>
        </>
      ),
    },
    // {
    //   title: 'Progress Sheet',
    //   key: 'progress_button',
    //   dataIndex: 'progress_button',
    //   render: (_, columnData) => (
    //     <>
    //       <div>
    //         {columnData.progress_button ? (
    //           <ConfirmCaseSheetButton
    //             columnData={columnData}
    //             confirmHomeHealthcareBooking={confirmHomeHealthcareBooking}
    //           />
    //         ) : null}
    //       </div>
    //     </>
    //   ),
    // },
    {
      title: "Status",
      key: "payment_details",
      dataIndex: "status",
      render: (_, columnData) => (
        <>
          {columnData.status === "Completed" ? (
            <span className="activate">Completed</span>
          ) : (
            ""
          )}
          {columnData.status === "Yet to Start" ? (
            <span className="yet-to-start">Yet to Start</span>
          ) : (
            ""
          )}
          {columnData.status === "Booking Cancelled" ? (
            <span className="booking-cancelled">Booking Cancelled</span>
          ) : (
            ""
          )}
          {columnData.status === "Session Cancelled" ? (
            <span className="booking-cancelled">Session Cancelled</span>
          ) : (
            ""
          )}
          {columnData.status === "Reschedule" ? (
            <span className="yet-to-start">Reschedule</span>
          ) : (
            ""
          )}
          {columnData.status === "On the Way" ? (
            <span className="emergency-book-confirm">On the Way</span>
          ) : (
            ""
          )}
          {columnData.status === "Reached location" ? (
            <span className="reached-location">Reached location</span>
          ) : (
            ""
          )}
          {columnData.status === "In Progress" ? (
            <span className="booking-inprogress">In Progress</span>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "Action",
      key: "payment_details",
      render: (_, columnData) => (
        <>
          {(columnData.track_button && (
            <Button
              onClick={() => {
                toggleTrackDeliveryModal(columnData);
                setSelectedColumn(columnData);
              }}
              style={{ borderColor: "green", color: "green" }}
            >
              Track Now
            </Button>
          )) ||
            null}
          {(columnData.caregiver_button && (
            <ChangeCaregiverform
              columnData={columnData}
              getAllHomeHealthcareHelpOption={
                props.getAllHomeHealthcareHelpOption
              }
              helpOption={props.helpOption}
              module={props.bookingSession.details.module}
              confirmHomeHealthcareBooking={confirmHomeHealthcareBooking}
            />
          )) ||
            null}
          {columnData.progress_button ? (
            <ConfirmCaseSheetButton
              columnData={columnData}
              confirmHomeHealthcareBooking={confirmHomeHealthcareBooking}
            />
          ) : null}
          {columnData.status === "Completed" ? (
            <Rate disabled value={columnData.rating} />
          ) : null}
        </>
      ),
    },
  ];
  const columns = [
    {
      title: "Session ID",
      key: "id",
      render: (_, columnData) => (
        <>
          <div>{columnData.sid}</div>
          <div>{columnData.session_date}</div>
        </>
      ),
    },
    {
      title: "Patient Details",
      key: "patient_details",
      render: (_, columnData) => (
        <Tooltip placement="left" title={columnData.patient_address}>
          <div>{columnData.patient_name}</div>
          <div>{`${columnData.patient_age}/${columnData.patient_gender}`}</div>
        </Tooltip>
      ),
    },
    {
      title: "Caregiver",
      key: "caregiver",
      render: (_, columnData) => (
        <>
          <div>{columnData.caregiver_name}</div>
          <div>{columnData.module}</div>
        </>
      ),
    },
    {
      title: "Session Date",
      key: "session_date",
      render: (_, columnData) => (
        <>
          <div>{columnData.started_at}</div>
          <div>{columnData.ended_at}</div>
        </>
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    if (newValues["q[date_from_to]"]) {
      const [from, to] = get(newValues, "q[date_from_to]");
      unset(newValues, "q[date_from_to]");
      set(newValues, ["q[created_at_gteq]"], moment(from).format("DD/MM/YYYY"));
      set(newValues, ["q[created_at_lteq]"], moment(to).format("DD/MM/YYYY"));
    }
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getBookingHomeHealthcareSessions(`${queryString}&${searchQuery}`);
  };
  const toggleActivateModalVisibility = (columnData) => {
    setShowSessionDrawer(!showSessionDrawer);
    setSelectedColumn(columnData);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  const drawerConfig = [
    {
      displayName: "Payment Status",
      key: "payment_status",
    },
    {
      displayName: "Program Name",
      key: "program_name",
    },
    {
      displayName: "Session Status",
      key: "status",
    },
    {
      displayName: "Patient Name",
      key: "patient_name",
    },
    {
      displayName: "Patient Age",
      key: "patient_age",
    },
    {
      displayName: "Patient Gender",
      key: "patient_gender",
    },
    {
      displayName: "Patient Address",
      key: "patient_address",
    },
  ];
  const drawerConfigCareGiver = [
    {
      displayName: "Agency / Individual",
      key: "mode",
    },
    {
      displayName: "Program Name",
      key: "module",
    },
    {
      displayName: "Summary",
      key: "summary",
    },
    {
      displayName: "Vendor Name",
      key: "vendor_name",
    },
  ];
  const handleRefresh = () => {
    props.getBookingHomeHealthcareSessions(searchQuery);
    if (props.bookingSessionMessage && props.bookingSessionMessage) {
      message.success(props.bookingSessionMessage.message);
    }
  };
  return (
    <Spin spinning={props.Loading === true}>
      <ViewDetailsDrawer
        getData={() =>
          props.getSingleHomeHealthcareBookingSession(
            `id=${selectedColumn.id}&booking_id=${selectedColumn.booking_id}`
          )
        }
        visible={descriptionModal}
        data={props.homeHealthCareBookingSessionSingle}
        setVisible={setShowDetailsDrawer}
        config={drawerConfig}
        title={`Details for Session ID: ${get(
          props.homeHealthCareBookingSessionSingle,
          "module"
        )}`}
      />
      <DetailsDrawer
        getData={() =>
          props.getSessionCareGiverProfile(
            `id=${selectedColumn.id}&booking_id=${selectedColumn.booking_id}&user_id=${selectedColumn.caregiver_id}`
          )
        }
        visible={showSessionDrawer}
        data={props.sessionCareGiverProfile}
        setVisible={setShowSessionDrawer}
        config={drawerConfigCareGiver}
        title={`Details of Caregiver: ${get(
          props.sessionCareGiverProfile,
          "caregiver_name"
        )}`}
      />
      {trackDeliveryModal && (
        <TrackPartner
          columnData={selectedColumn}
          onCancel={toggleTrackDeliveryModal}
          visible={trackDeliveryModal}
          // getBookingHomeHealthcareSessions={props.getBookingHomeHealthcareSessions(searchQuery)}
        />
      )}
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Booking ID -
        {' '}
        {props.bookingSession && props.bookingSession.details.bid}
        <Button shape="circle" icon={<SyncOutlined />} style={{ marginRight: 8 }} onClick={() => props.getBookingHomeHealthcareSessions(searchQuery)} />
      </Title> */}
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            {props.bookingSession &&
              props.bookingSession.details &&
              props.bookingSession.details.program_name}
          </Title>
        </Col>
        <Col className="gutter-row" span={8} offset={0}></Col>
        <Col className="gutter-row" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row" span={1} offset={0}>
          <Button
            shape="circle"
            icon={<SyncOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => handleRefresh()}
          />
        </Col>
      </Row>
      <div className="booking-session-top">
        {/* <Row gutter={16}>
          <Col span={6}> */}
        <Card bordered={false}>
          <Row gutter={24}>
            <Col span={8}>
              <p className="title">Booking Details</p>
              <Row gutter={24}>
                <Col span={12}>
                  <p className="content-title">Booking Id</p>
                  <p className="darkFont">
                    {props.bookingSession &&
                      props.bookingSession.details &&
                      props.bookingSession.details.bid}
                  </p>
                </Col>
                <Col span={12}>
                  <p className="content-title">Booking Date</p>
                  <p className="darkFont">
                    {props.bookingSession &&
                      props.bookingSession.details &&
                      props.bookingSession.details.booking_date.substring(
                        0,
                        11
                      )}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <p className="title">Patient Details</p>
              <Row gutter={24}>
                <Col span={12}>
                  <p className="content-title">Patient Name</p>
                  <p className="darkFont">
                    {props.bookingSession &&
                      props.bookingSession.details &&
                      props.bookingSession.details.patient_name}
                  </p>
                </Col>
                <Col span={12}>
                  <p className="content-title">Age/Gender</p>
                  <p className="darkFont">
                    {props.bookingSession &&
                      props.bookingSession.details &&
                      props.bookingSession.details.patient_age}
                    /
                    {props.bookingSession &&
                      props.bookingSession.details &&
                      props.bookingSession.details.patient_gender}{" "}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <p className="title">Service Duration</p>
              <Row gutter={24}>
                <Col span={12}>
                  <p className="content-title">Session</p>
                  <p className="darkFont">
                    {props.bookingSession &&
                      props.bookingSession.details &&
                      props.bookingSession.details.total_sessions}{" "}
                    Sessions
                  </p>
                </Col>
                <Col span={12}>
                  <p className="content-title">Duration</p>
                  <p className="darkFont">
                    {props.bookingSession &&
                      props.bookingSession.details &&
                      props.bookingSession.details.program_duration}{" "}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        {/* </Col>
        </Row> */}
      </div>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Sessions"
          formItems={[
            {
              key: "q[sid_cont]",
              label: "Session ID",
            },
            {
              key: "q[status_eq]",
              label: "Session status",
              Component: (
                <Select placeholder="Select Session status" size="large">
                  <Option value="Yet to Start">Yet to Start</Option>
                  <Option value="In Progress">In Progress</Option>
                  <Option value="Completed">Completed</Option>
                  {/* <Option value="4">Booking Completed</Option> */}
                  <Option value="Cancelled">Cancelled</Option>
                </Select>
              ),
            },

            {
              key: "q[date_from_to]",
              label: "Date",
              Component: (
                <RangePicker
                  format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                  size="large"
                />
              ),
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={
          (props.bookingSession &&
            props.bookingSession.sessions &&
            props.bookingSession.sessions) ||
          []
        }
        columns={searchQuery ? BookingRedirectcolumns : columns}
        scroll={{ x: 800 }}
      />
    </Spin>
  );
}

export default UserManagement;
