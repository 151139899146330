import React, { useState, useEffect } from "react";
import { Select, Upload, Input, Modal, Form, Button, message } from "antd";
import { noop, isEmpty } from "lodash";
import TextArea from "antd/lib/input/TextArea";
import { getBucketUploadPayload } from "../../../../store/api/vendorHomeApi";
import { ATMED_VENDOR_EQUIPMENT_SETTLEMENTS } from "../../../../common/awsBucket";
import { PlusOutlined } from "@ant-design/icons";
import instance from "../../../../store/api/instance";


const UpdateMedicalEquipmentFormModal = ({
  data = {},
  visible,
  columnData,
  onCancel,
  onUpdateSettlement,
  getAtmedPayments,
  hideModal,
  title,
  submitText,
  ...props
}) => {

  
  const [imageUrl, setImageUrl] = useState("");
  const [imageUploadUrl, setImageUploadUrl] = useState("");


  const [form] = Form.useForm();
  const { Option } = Select;

 
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      message.error("File upload must be less than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  // get the S3 upload dpayload information
  const getUploadUrlPayload = async () => {
    const { data } = await getBucketUploadPayload(
      ATMED_VENDOR_EQUIPMENT_SETTLEMENTS
    );
    return data;
  };

  // returns the FormData for the upload request
  const getFormData = (file, payload) => {
    let filename = file.name.replace(/\s/g, "-").toLowerCase();
    const formData = new FormData();
    Object.keys(payload.fields).forEach((key) =>
      formData.append(key, payload.fields[key])
    );
    formData.append("file", file, filename);
    return formData;
  };

  // uploads the document to the S3 bucket
  const uploadDocument = async (url, formData, requestOptions) => {
    const { onSuccess, onError, onProgress } = requestOptions;

    // use onProgress to update Upload progress
    const config = {
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    try {
      const res = await instance.post(url, formData, config);
      const uploadUrl = new DOMParser()
        .parseFromString(res.data, "application/xml")
        .getElementsByTagName("Location")[0].textContent;

      // use onSuccess to return the uploadUrl to the form value
      onSuccess(uploadUrl);
      setImageUrl(uploadUrl);
      // console.log("uploadUrl", uploadUrl)
      setImageUploadUrl(decodeURIComponent(uploadUrl));
    } catch (err) {
      onError({ err });
    }
  };

  const customRequest = async (requestOptions) => {
    const { file } = requestOptions;

    const uploadPayload = await getUploadUrlPayload(file);

    const { url } = uploadPayload;

    const formData = getFormData(file, uploadPayload);
    uploadDocument(url, formData, requestOptions);
  };

  const onOk = async (data) => {
    const settlementstatus = form.getFieldValue("status");
    const settlementnotes = form.getFieldValue("notes");
    console.log('settlementstatus', typeof settlementstatus);
    console.log('settlementnotes', typeof settlementnotes);
    console.log('imageUploadUrl', typeof imageUploadUrl);
    if (typeof settlementstatus != 'boolean' || settlementnotes == '' || imageUploadUrl == '') {
      message.error("Please enter all required values and try it out!");
      return false;
    }
    else {
      const orderData = getAtmedPayments.map((a) => a.id);

      const payloadObj = {
        id: orderData,
        payload: {
          equip_order: {
            settlement_status: settlementstatus,
            settlement_proof_url: imageUploadUrl,
            notes: settlementnotes,
          },
        },
      };
      hideModal();
      await onUpdateSettlement(payloadObj);
      form.resetFields();
      setImageUrl("");
    }
  };



  const onModalCancel = () => {
    form.resetFields();
    setImageUrl("");
    onCancel();
  };
  const modalFooter = (
    <Button onClick={onOk} type="primary">
      {submitText}
    </Button>
  );

  useEffect(() => {
    form.resetFields();
    setImageUrl("");
  }, []);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  
  const handleChange = (info) => {
    // Get this url from response in real world.
    getBase64(info.file.originFileObj, (imageUrl) => setImageUrl(imageUrl));
  };

  return (
    <Modal
      className="Proof-modal" 
      visible={visible}
      onCancel={onModalCancel}
      onOk={onOk}
      title={title}
      okText={submitText}
      destroyOnClose
      footer={modalFooter}
      {...props}
    >
      <Form 
        form={form}
        layout="vertical"
        initialValues={columnData}
        onFinish={onOk}
      >
       
        <Form.Item
          label="Status"
          name="status"
          rules={[{ required: true, message: "Please select Status!" }]}
        >
          <Select size="large" placeholder="Status - Pending / Completed">
            <Option value={false}>Pending</Option>
            <Option value={true}>Completed</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Notes"
          name="notes"
          rules={[
            { required: true, message: "Please input name!" },
            { max: 25, message: "Name must be maximum 25 characters." },
          ]}
        >
        <TextArea rows={4} placeholder="Enter Notes" />
        </Form.Item>

        <Form.Item label="Image">
          <Upload
            listType="picture-card"
            value={imageUploadUrl}
            accept=".png,.jpeg,.jpg"
            showUploadList={false}
            customRequest={customRequest}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

      </Form>
      
    </Modal>
  );
};
export default UpdateMedicalEquipmentFormModal;
