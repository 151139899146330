import React, { useState, useEffect, useCallback } from "react";
import { Table, Button, Typography, Select, Row, Col, Image } from "antd";
import { isEqual } from "lodash";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import VendorEquipmentFormModal from "./components/VendorEquipmentFormModal";
import ActionsColumn from "./components/ActionsColumn";
import OwnerColumn from "./components/OwnerColumn";
import AdvancedSearchForm from "../../../common/SearchForm";
import VendorDetailDrawerModal from "./components/VendorDetailDrawerModal";
import { DEFAULT_AGENCY_IMAGE } from "../../../common/awsBucket";
import { Link } from 'react-router-dom';

const { Title } = Typography;
const { Option } = Select;

// function getBase64(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });
// }

function VendorEquipment(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [fileList, setFileList] = useState([]);
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onAddVendorEquipment = async (payload) => {
    const payloadObj = { equip_vendor: payload };
    await props.addVendorEquipment(payloadObj);
    await props.getAllVendorEquipment(undefined, 20, currentPage);
    hideModal();
  };
  const onUpdateVendorEquipment = async (payloadObj) => {
    await props.updateVendorEquipment(payloadObj);
    await props.getAllVendorEquipment(undefined, 20, currentPage);
  };
  const onAddVendorEquipmentOwner = async (payload) => {
    await props.addVendorEquipmentOwner(payload);
    await props.getAllVendorEquipment(undefined, 20, currentPage);
    // hideModal();
  };
  // const onDeleteProfile = async (payloadObj) => {
  //   // console.log("on delete console", payloadObj);
  //   await props.updateVendorEquipment(payloadObj)
  //   await props.getAllVendorEquipment();
  //   // message.success('Deleted Profile Photo');
  // };
  const onUpdateVendorEquipmentOwner = async (payloadObj) => {
    await props.updateVendorEquipmentOwner(payloadObj);
    await props.getAllVendorEquipment(undefined, 20, currentPage);
  };

  // const onChange = ({ fileList: newFileList }) => {
  //   setFileList(newFileList);
  // };

  // const handlePreview = async (file) => {
  //   if (!file.url && !file.preview) {
  //     file.preview = await getBase64(file.originFileObj);
  //   }
  //   setpreviewImage(file.url || file.preview);
  //   setpreviewVisible(true);
  //   setpreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  // };

  const renderData = useCallback(
    (currentPage) => {
      props.getAllVendorEquipment(undefined, 20, currentPage);
      props.getAllCountry.call();
      props.getAllCountryCodes.call();
    },
    [props.getAllVendorEquipment]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);


  useEffect(() => {
    const newFileList =
      props.vendorEquipment ||
      [].map((assetObj, index) => ({
        uid: assetObj.id,
        // name: `image${index}.png`,
        //  status: 'done',
        url: assetObj.pic_url,
      }));
    console.log("newFileList:: vendor", newFileList);
    if (!isEqual(newFileList, fileList)) {
      setFileList(newFileList);
    }
  }, [props.vendorEquipment]);

  const columns = [
    {
      title: "Vendor",
      key: "pic_url",
      dataIndex: "pic_url",
      render: (_, columnData) => (
        <Row className="name-details-row">
          <Col span={4} className="table-fc-image">
            <Image
              src={
                columnData.pic_url ? columnData.pic_url : DEFAULT_AGENCY_IMAGE
              }
              // preview={false}
            />
          </Col>
          <Col offset={2}>
            <div className="table-fc-name">{columnData.name}</div>
            <div className="table-fc-status">
              {columnData.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </div>
            <div className="view-details">
              <Link
                onClick={() => {
                  setDescriptionModal(true);
                  setCurrentColumn(columnData);
                }}
              >
                VIEW DETAILS
              </Link>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Owner",
      key: "owner",
      render: (_, columnData) => (
        <OwnerColumn
          columnData={columnData}
          addVendorEquipmentOwner={onAddVendorEquipmentOwner}
          updateVendorEquipmentOwner={onUpdateVendorEquipmentOwner}
          countryCode={props.countryCode}
        />
      ),
    },
    {
      title: "View Orders",
      key: "actions",
      render: (_, columnData) => (
        <>
          <Link to={`/atmed/settlement/orders/medical?equip_vendor_id=${columnData.id}`}>
            LINK
          </Link>
        </>
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    //props.getAllVendorEquipment(queryString);
    props.getAllVendorEquipment(queryString, 20, currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <div>
      <VendorDetailDrawerModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <VendorEquipmentFormModal
        onSubmit={onAddVendorEquipment}
        onCancel={hideModal}
        visible={modalVisible}
        allCountry={props.allCountry}
        countryCode={props.countryCode}
        title="Add a New Vendor"
        submitText="Add Vendor"
      />
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Health Products
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          <span style={{ fontWeight: '600' }}>
            Add Vendor
          </span>
        </Button>
      </Title> */}
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Medical Equipment
          </Title>
        </Col>
        <Col className="gutter-row section-heading" span={3} offset={0}>
          <Title
              level={4}
              className="d-flex title"
              style={{ marginTop: '10px' }}
            >
              Total: {props.vendorEquipment?.total_count}
            </Title>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        {/* <Col className="gutter-row header-buttons" span={4} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span>ADD VENDOR</span>
          </Button>
        </Col> */}
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Health Products"
          formItems={[
            {
              key: "q[name_cont]",
              label: "Company Name",
            },
            {
              key: "q[status_eq]",
              label: "Vendor Status",
              Component: (
                <Select placeholder="Vendor Status" size="large">
                  <Option value={true}>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={props.vendorEquipment ? props.vendorEquipment.data : null}
        pagination={{
          pageSize: 20,
          total: props.vendorEquipment?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default VendorEquipment;
