import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Typography,
  Button,
  DatePicker,
  Select,
  Popover,
  Row,
  Col,
  Divider,
  Tooltip,
  message,
  Modal,
  Card,
  Image,
} from "antd";
import { useLocation } from "react-router-dom";
import moment from "moment";
import {
  InfoCircleOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { unset, get, set } from "lodash";
import OrderDetailDrawerModal from "./components/OrderDetailDrawerModal";
import ActionsColumn from "./components/ActionsColumn";
import documentsIcon from "../../../assets/document.png";
import styles from "../../../common/Layout.module.scss";
import AdvancedSearchForm from "../../../common/SearchForm";
import PaymentDetails from "./components/PaymentDetails";
import UpdateStatusProofModal from "./components/UpdateStatusProof";

const { RangePicker } = DatePicker;
const { Option } = Select;

const { Title, Link } = Typography;

const ProofComponent = (value) => {
  return (
    <Row className="document-section">
      <Col span={12} justify="start" style={{ padding: 10 }}>
        <Card>
          {value.values.settlement_proof_url.match(/\.(pdf|doc|xls|xml)$/) !=
          null ? (
            <Link href={value.values.settlement_proof_url} target="_blank">
              <Image
                preview={false}
                src={documentsIcon}
                style={{ marginRight: 6, width: "86px", height: "86px" }}
                alt="Route"
              />
            </Link>
          ) : (
            <Image
              style={{ width: "86px", height: "86px" }}
              alt="Proof files"
              src={value.values.settlement_proof_url}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
};

function FAQS(props) {
  const location = useLocation();
  let searchQuery = 'q[settlement_status_eq]=false';
  searchQuery = searchQuery +'&'+location.search.substring(1);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState([]);
  const [searchOption, setSearchOption] = useState(false);
  const [proofVisible, setProofVisible] = useState(false);
  const [stateColumnData, setStateColumnData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPaymentInfoColumn, setPaymentInfoColumn] = useState({});
  const [paymentDetails, setPaymentDetails] = useState(false);
  const [settlementVisible, setSettlementVisible] = useState(false);

  const showModal = () => {
    setSettlementVisible(true);
  };

  const hideModal = () => {
    setSettlementVisible(false);
  };

  const atmedPaymentsHomeRefresh = useSelector(
    (state) => state.vendorEquipmenReducer.atmedPaymentsHomeRefresh
  );
  const updatePaymentStatusHome = async (payloadObj) => {
    await props.updatePaymentStatusHomeBulk(payloadObj);
    await props.getAtmedPaymentsHome(searchQuery, 20, currentPage);
  };


  useEffect(() => {
    props.getAtmedPaymentsHome(searchQuery, 20, currentPage);
  }, []);

  const renderData = useCallback(
    (currentPage) => {
      props.getAtmedPaymentsHome(searchQuery, 20, currentPage);
    },
    [props.getAtmedPaymentsHome, props.atmedPaymentsHomeUpdate]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  const showPaymentDetails = (data) => {
    setPaymentDetails(true);
    setPaymentInfoColumn(data);
  };

  const showProofModal = (data) => {
    setProofVisible(true);
    setStateColumnData(data);
  };

  const handleProofOk = () => {
    setProofVisible(false);
    setStateColumnData({});
  };

  const handleProofCancel = () => {
    setProofVisible(false);
    setStateColumnData({});
  };

  const columns = [
    {
      title: "Booking Id",
      key: "bid",
      // width: 240,
      render: (_, columnData) => (
        <Row>
          <Col>
            <div className="table-fc-name">{columnData.module}</div>
            <div className="table-fc-email">Order ID: {columnData.bid}</div>
            <div className="table-fc-email">
              {columnData.payment_method} : {columnData.payment_id}
            </div>
            <div>{`${columnData.program_name}`}</div>
            <div>{`${columnData.total_sessions} sessions`}</div>
            <div className="view-details">
              <Link
                onClick={() => {
                  setDescriptionModal(true);
                  setCurrentColumn(columnData);
                }}
              >
                VIEW DETAILS
              </Link>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Patient Name",
      key: "patient_name",
      width: 150,
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>{columnData.patient_name} </Col>
            <Col span={24} className="lightFont">
              {columnData.patient_phone}
            </Col>
            <Col span={24}>{columnData.module}</Col>
          </Row>
          <div className="view-details">
            <Link onClick={() => showPaymentDetails(columnData)}>
              PAYMENT INFO
            </Link>
          </div>
        </>
      ),
    },
    {
      title: "Payment",
      key: "settlement_amount",
      // width: 220,
      render: (_, columnData) => (
        <Row className="fs13">
          <Col span={12}>Total Payable</Col>
          <Col span={8} className="fw500">
            {columnData.currency}
            {columnData.total_payable}
          </Col>
          {/* <Col span={12}>Online Payment</Col>
          <Col span={8} className="fw500">
            {columnData.currency}
            {columnData.total_online}
          </Col>
          <Col span={12}>Cash Payment</Col>
          <Col span={8} className="fw500">
            {columnData.currency}
            {columnData.total_cash}
          </Col> */}
        </Row>
      ),
    },
    {
      title: "Receivable",
      key: "settlement_amount",
      // width: 100,
      render: (_, columnData) => (
        <>
          <span className="fw500">{`${columnData.currency}${columnData.vendor_price}`}</span>
          <Popover
            content={
              <>
                <Row>
                  <Col span={12}>Atmed percentage</Col>
                  <Col span={4} offset={8} className="fw500">
                    {columnData.atmed_fee_percent}%
                  </Col>
                  <Col span={12}>Atmed Price</Col>
                  <Col span={4} offset={8} className="fw500">
                    {columnData.currency}
                    {columnData.atmed_price}
                  </Col>
                </Row>
                <Divider style={{ border: 0, margin: "8px 0" }} />
                <Row>
                  <Col span={12}>Company percentage</Col>
                  <Col span={4} offset={8} className="fw500">
                    {100 - columnData.atmed_fee_percent}%
                  </Col>
                  <Col span={12}>Company Price</Col>
                  <Col span={4} offset={8} className="fw500">
                    {columnData.currency}
                    {columnData.vendor_price}
                  </Col>
                </Row>
              </>
            }
            title="Receivable Amount"
          >
            <Button type="text" icon={<InfoCircleOutlined />} />
          </Popover>
        </>
      ),
    },
    {
      title: "Status",
      key: "settlement_status",
      //width: 120,
      render: (_, columnData) => (
        <Row>
          <Col>
            {columnData.settlement_status ? (
              <span className="activate">Completed</span>
            ) : (
              <span className="inActivateNotverified">Pending</span>
            )}
            {columnData.notes !== null && columnData.notes !== "" ? (
              <div>
                <Tooltip placement="bottom" title={columnData.notes}>
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            ) : (
              ""
            )}
            {columnData.settlement_proof_url !== null && (
              <div className="view-details">
                <Link onClick={() => showProofModal(columnData)}>
                  VIEW PROOF
                </Link>
              </div>
            )}
          </Col>
        </Row>
      ),
    },
  ];
  const handleRefresh = () => {
    props.getAtmedPaymentsHome(searchQuery, 20, currentPage);
    if (atmedPaymentsHomeRefresh && atmedPaymentsHomeRefresh) {
      message.success(atmedPaymentsHomeRefresh.message);
    }
  };

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    if (newValues["q[date_from_to]"]) {
      const [from, to] = get(newValues, "q[date_from_to]");
      unset(newValues, "q[date_from_to]");
      set(newValues, ["q[created_at_gteq]"], moment(from));
      set(newValues, ["q[created_at_lteq]"], moment(to));
    }
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAtmedPaymentsHome(`${queryString}&${searchQuery}`, 20, currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <div>
      {proofVisible && (
        <Modal
          className="Proof-modal"
          title="Proof"
          footer={false}
          visible={proofVisible}
          onOk={handleProofOk}
          onCancel={handleProofCancel}
          width={300}
        >
          <ProofComponent
            className={styles.detValue}
            values={stateColumnData}
          />
        </Modal>
      )}
      <OrderDetailDrawerModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      
      <UpdateStatusProofModal
        visible={settlementVisible}
        columnData={stateColumnData}
        updatePaymentStatusHome={updatePaymentStatusHome}
        getAtmedPaymentsHome={props.atmedPaymentsHome ? props.atmedPaymentsHome.data : null}
        hideModal={hideModal}
        title="Settlement"
        submitText="Submit"
        onCancel={hideModal}
      />

      <PaymentDetails
        visible={paymentDetails}
        data={currentPaymentInfoColumn && currentPaymentInfoColumn}
        onCancel={() => setPaymentDetails(false)}
        width={1000}
        title={`Payment Details - Booking ID: ${
          currentPaymentInfoColumn && currentPaymentInfoColumn.bid
        } (${
          currentPaymentInfoColumn && currentPaymentInfoColumn.patient_name
        })`}
      />
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Home Healthcare - Settlements
        <Button shape="circle" icon={<SyncOutlined />} style={{ marginRight: 8 }} onClick={() => handleRefresh()} />
      </Title> */}
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Settlements - Home Healthcare
          </Title>
        </Col>
        <Col className="gutter-row section-heading" span={3} offset={0}>
          <Title
              level={4}
              className="d-flex title"
              style={{ marginTop: '10px' }}
            >
              Total: {props.atmedPaymentsHome?.total_count}
            </Title>
        </Col>
        <Col className="gutter-row" span={3} offset={0}>
          <Button onClick={showModal} type="primary">
            <span style={{ fontWeight: '600' }}>
              Settlement
            </span>
         </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={2} offset={0}>
          <Button
            shape="circle"
            icon={<SyncOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => handleRefresh()}
          />
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Home HealthCare"
          formItems={[
            {
              key: "q[bid_cont]",
              label: "Booking ID",
            },
            {
              key: "q[patient_name_cont]",
              label: "Patient Name",
            },
            {
              key: "q[module_eq]",
              label: "Service Name",
            },
            {
              key: "q[settlement_status_eq]",
              label: "Settlement Status",
              Component: (
                <Select placeholder="Completed / Pending" size="large">
                  <Option value={true}>Completed</Option>
                  <Option value={false}>Pending</Option>
                </Select>
              ),
            },
            {
              key: "q[date_from_to]",
              label: "Date",
              Component: <RangePicker size="large" />,
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={
          props.atmedPaymentsHome ? props.atmedPaymentsHome.data : null
        }
        pagination={{
          pageSize: 20,
          total: props.atmedPaymentsHome?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default FAQS;
