const ROUTES = {
  ADMINISTRATION: {
    id: 1,
    name: 'Administration',
    path: '/admin/atmed',
  },
  APP_IDS: {
    id: 2,
    name: 'App Ids',
    path: '/admin/atmed/app-ids',
  },
  APP_VERSIONS: {
    id: 3,
    name: 'App versions',
    path: '/admin/atmed/app-versions',
  },
  EQUIPMENT_CATEGORIES: {
    id: 4,
    name: 'Medical Equipment',
    path: '/admin/atmed/equipment-categories',
  },
  LOCATIONS: {
    id: 5,
    name: 'Locations',
    path: '/admin/atmed/location',
  },
  ORDER_PAYMENT: {
    id: 6,
    name: 'Medical Equipment',
    path: '/admin/atmed/order-payment',
  },
  PRODUCT_APPROVAL: {
    id: 7,
    name: 'Product approvals',
    path: '/admin/atmed/product-approvals-management',
  },
  HOME_SERVICES: {
    id: 8,
    name: 'Home Healthcare',
    path: '/admin/atmed/home-services',
  },
  ASSET_APPROVAL: {
    id: 34,
    name: 'Medical Equipment',
    path: '/admin/atmed/asset-approval',
  },
  USERS: {
    id: 9,
    name: 'Atmed Staff',
    path: '/admin/atmed/users',
  },
  VENDORS: {
    id: 10,
    name: 'Vendors / Partners',
    path: '/vendor',
  },
  ALLIED_HEALTH: {
    id: 11,
    name: 'Allied Health',
    path: '/vendor/allied-health',
  },
  DIAGNOSTIC_CENTRE: {
    id: 12,
    name: 'Diagnostic Centre',
    path: '/vendor/diagnostic-centre',
  },
  DIETICIAN: {
    id: 13,
    name: 'Dietician',
    path: '/vendor/dietician',
  },
  DOCTORS: {
    id: 24,
    name: 'Doctors',
    path: '/vendor/doctors',
  },
  EMERGENCY_CENTRE: {
    id: 14,
    name: 'Emergency centre',
    path: '/vendor/EmergencyCentre',
  },
  HOME_HEALTH: {
    id: 15,
    name: 'Home Healthcare',
    path: '/atmed/vendors/home-health',
  },
  HOSPITAL_CLINICS: {
    id: 16,
    name: 'Hospitals/Clinics',
    path: '/vendor/hospitals-clinic',
  },
  INSURANCE: {
    id: 17,
    name: 'Insurance',
    path: '/vendor/insurance',
  },
  LAB_CENTRE: {
    id: 18,
    name: 'Lab Center',
    path: '/vendor/lab-centre',
  },
  EQUIPMENT: {
    id: 19,
    name: 'Medical Equipment',
    path: '/atmed/vendors/equipment',
  },
  PHARMACY: {
    id: 20,
    name: 'Pharmacy',
    path: '/vendor/pharmacy',
  },
  STEMCELL_PROVIDER: {
    id: 21,
    name: 'Stem Cell Provider',
    path: '/vendor/stemcell-provider',
  },
  SURGERIES: {
    id: 22,
    name: 'Surgeries',
    path: '/vendor/surgeries',
  },
  DASHBOARD: {
    id: 23,
    name: 'Dashboard',
    path: '/home',
  },
  VENDOR_EQUIPMENT_USERS: {
    id: 24,
    name: 'Company Staff',
    path: '/vendor/equipment/users',
  },
  VENDOR_EQUIPMENT_LOCATION: {
    id: 31,
    name: 'Delivery Locations',
    path: '/vendor/equipment/locations',
  },
  VENDOR_EQUIPMENT_PAYMENT: {
    id: 32,
    name: 'Settlements',
    path: '/vendor/equipment/payments',
  },
  VENDOR_EQUIPMENT_FAQS: {
    id: 33,
    name: 'Product Queries',
    path: '/vendor/equipment/faqs',
  },

  VENDOR_BOOKING_MEDICAL_EQUIPMENT: {
    id: 273,
    name: 'Medical Equipment Booking',
    path: '/vendor/equipment/booking',
  },
  VENDOR_EQUIPMENT_PRODUCTS: {
    id: 25,
    name: 'Product Catalog',
    path: '/vendor/equipment/products',
  },
  VENDOR_EQUIPMENT_ORDERS: {
    id: 26,
    name: 'All Orders',
    path: '/vendor/equipment/orders',
  },

  PROFILE: {
    id: 27,
    name: 'Profile',
    path: '/profile',
  },
  VENDOR_HOME_USERS: {
    id: 28,
    name: 'Users',
    path: '/vendor/home/users',
  },
  VENDOR_HOME_LOCATIONS: {
    id: 29,
    name: 'Location Support',
    path: '/vendor/home/locations',
  },
  VENDOR_HOME_SERVICES: {
    id: 30,
    name: 'Services',
    path: '/vendor/home/services',
  },
  VENDOR_HOME_PROGRAMS: {
    id: 31,
    name: 'Programs',
    path: '/vendor/home/programs',
  },
  HOME_COMPANY_STAFF: {
    id: 32,
    name: 'Company Staff',
    path: '/home/admin/users',
  },
  HOME_CAREGIVER_STAFF: {
    id: 33,
    name: 'Caregiver Staff',
    path: '/home/admin/caregivers',
  },
  HOME_LOCATIONS_SUPPORT: {
    id: 34,
    name: 'Location Support',
    path: '/home/admin/locations',
  },
  HOME_BOOKINGS: {
    id: 35,
    name: 'Bookings',
    path: '/home/admin/bookings',
  },
  HOME_ALL_BOOKINGS: {
    id: 35,
    name: 'All Bookings',
    path: '/home/admin/bookings',
  },
  HOME_ALL_APPOINTMENTS: {
    id: 36,
    name: 'Appointment Booking',
    path: '/home/admin/booking',
  },
  HOME_ALL_SESSIONS: {
    id: 37,
    name: 'All Sessions',
    path: '/home/admin/sessions',
  },
  HOME_HEALTHCARE_SERVICES: {
    id: 38,
    name: 'Services / Programs',
    path: '/home/admin/services',
  },
  HOME_HEALTHCARE_PROGRAMS: {
    id: 38,
    name: 'Home Healthcare Programs',
    path: '/home/admin/programs',
  },
  HOME_HEALTHCARE_FEEDBACK: {
    id: 40,
    name: 'Feedback / Ratings',
    path: '/home/admin/feedback',
  },
  HOME_HEALTHCARE_SETTLEMENT: {
    id: 41,
    name: 'Settlements',
    path: '/home/admin/settlements',
  },
  SETTLEMENT: {
    id: 100,
    name: 'Settlements',
    path: '/home/admin/settlememnts',
  },
  ATMED_CATEGORIES: {
    id: 101,
    name: 'Categories',
    path: '/home/admin/categories',
  },
  MEDIA_APPROVALS: {
    id: 102,
    name: 'Media Approvals',
    path: '/home/admin/media-approvals',
  },
  FEEDBACK_RATINGS: {
    id: 103,
    name: 'Feedback / Ratings',
    path: '/home/admin/feedback-ratings',
  },
  EQUIPMENT_FEEDBACK: {
    id: 104,
    name: 'Medical Equipment',
    path: '/home/admin/feedback-healthproducts',
  },
  ASSET_APPROVAL_HOME_SERVICE: {
    id: 105,
    name: 'Home Healthcare',
    path: '/home/media',
  },
  HOME_FEEDBACK: {
    id: 106,
    name: 'Home Healthcare',
    path: '/atmed/home/feedback',
  },
  ORDER_PAYMENT_HOME: {
    id: 107,
    name: 'Home Healthcare',
    path: '/atmed/home/settlements',
  },
  COMPANY_PROFILE: {
    id: 108,
    name: 'Company Profile',
    path: '/atmed/home/company-details',
  },
  HOME_SESSIONS_BOOKINGS: {
    id: 109,
    name: 'Home Session Bookings',
    path: '/home/admin/booking/sessions',
  },
  ATMED_LABS_PARTNERS: {
    id: 110,
    name: 'Labs & Diagnostics',
    path: '/atmed/labs/partners',
  },
  ATMED_LABS_BRANCHES: {
    id: 111,
    name: 'Labs & Diagnostics',
    path: '/atmed/labs/branches',
  },
  ATMED_LABS_CATEGORIES: {
    id: 112,
    name: 'Lab Packages',
    path: '/atmed/labs/categories',
  },
  ATMED_CATALOG: {
    id: 113,
    name: 'Catalog',
    path: '/catalog',
  },
  ATMED_CATALOG_LAB: {
    id: 114,
    name: 'Lab Tests',
    path: '/atmed/labs/catalog/lab-tests',
  },
  ATMED_CATALOG_DIAGNOSTICS: {
    id: 115,
    name: 'Diagnostic Tests',
    path: '/atmed/labs/catalog/diagnostics',
  },
  ATMED_CATALOG_PHARMACY: {
    id: 116,
    name: 'Medicines',
    path: '/atmed/pharmacy/catalog/medicines',
  },
  ATMED_SETTLEMENTS_PHARMACY: {
    id: 117,
    name: 'Pharmacy',
    path: '/atmed/pharmacy/settlements',
  },
  CATALOG: {
    id: 118,
    name: 'Catalog',
    path: '/labs/admin/catalog-tests',
  },
  LAB_CATALOG: {
    id: 119,
    name: 'Lab Tests',
    path: '/labs/admin/catalog-tests',
  },
  LAB_PACKAGES: {
    id: 120,
    name: 'Lab Packages',
    path: '/labs/admin/catalog-packages',
  },
  LAB_DIAGNOSTICS: {
    id: 121,
    name: 'Diagnostic Tests',
    path: '/labs/admin/catalog-diagnostics',
  },
  ATMED_SETTLEMENTS_LAB_DIAGNOSTICS: {
    id: 122,
    name: 'Labs & Diagnostics',
    path: '/atmed/labs/settlements',
  },
  ATMED_FEEDBACK_LAB_DIAGNOSTICS: {
    id: 123,
    name: 'Labs & Diagnostics',
    path: '/atmed/labs/feedback',
  },
  ATMED_ASSET_APPROVAL_PHARMACY: {
    id: 124,
    name: 'Pharmacy',
    path: '/atmed/pharmacy/media',
  },
  ATMED_FEEDBACK_PHARMACY: {
    id: 125,
    name: 'Pharmacy',
    path: '/atmed/pharmacy/feedback',
  },
  LAB_ORDERS: {
    id: 126,
    name: 'All Orders',
    path: '/labs/admin/orders',
  },
  LAB_SETTLEMENTS: {
    id: 127,
    name: 'Settlements',
    path: '/labs/admin/settlements',
  },
  LAB_FEEDBACK: {
    id: 128,
    name: 'Feedback / Ratings',
    path: '/labs/admin/feedback',
  },
  LAB_COMPANY_STAFF: {
    id: 129,
    name: 'Company Staff',
    path: '/labs/admin/users',
  },
  LAB_COMPANY_BRANCHES: {
    id: 130,
    name: 'Company Branches',
    path: '/labs/admin/branches',
  },
  ATMED_CATEGORIES_PHARMACY: {
    id: 131,
    name: 'Pharmacy',
    path: '/atmed/pharmacy/categories',
  },
  VENDOR_PHARMACY_FEEDBACK: {
    id: 132,
    name: 'Feedback / Ratings',
    path: '/pharmacy/admin/feedback',
  },
  VENDOR_PHARMACY_SETTLEMENTS: {
    id: 133,
    name: 'Settlements',
    path: '/pharmacy/admin/settlements',
  },
  VENDOR_PHARMACY_CATALOG_HEALTH_PRODUCTS: {
    id: 134,
    name: 'Health Products',
    path: '/pharmacy/admin/catalog-health-products',
  },
  VENDOR_PHARMACY_CATALOG_MEDICINE_PRODUCTS: {
    id: 135,
    name: 'Medicine Products',
    path: '/pharmacy/admin/catalog-medicines',
  },
  VENDOR_PHARMACY_COMPANY_STAFF: {
    id: 136,
    name: 'Company Staff',
    path: '/pharmacy/admin/users',
  },
  VENDOR_PHARMACY_COMPANY_BRANCHES: {
    id: 137,
    name: 'Pharmacy Branches',
    path: '/pharmacy/admin/branches',
  },
  VENDOR_PHARMACY_ORDERS: {
    id: 138,
    name: 'All Orders',
    path: '/pharmacy/admin/orders',
  },
  VENDOR_EQUIPMENT_FEEDBACK: {
    id: 139,
    name: 'Feedback / Ratings',
    path: '/vendor/equipment/feedback',
  },
  ATMED_FEEDBACK_MEDICAL_DOCTOR_CONSULTATION: {
    id: 140,
    name: 'Doctor consultation',
    path: '/atmed/medical/consultation/feedback',
  },
  ATMED_FEEDBACK_MEDICAL_SURGERY_CONSULTATION: {
    id: 141,
    name: 'Surgeries',
    path: '/atmed/medical/surgery/feedback',
  },
  ATMED_FEEDBACK_MEDICAL_ONLINE_CONSULTATION: {
    id: 142,
    name: 'Online Consultation',
    path: '/atmed/medical/online/feedback',
  },
  ATMED_ASSET_APPROVAL_MEDIC: {
    id: 143,
    name: 'Medic Stories',
    path: '/atmed/medical/medic/media',
  },
  ATMED_ASSET_APPROVAL_CENTRE: {
    id: 144,
    name: 'Hospital Stories',
    path: '/atmed/medical/centre/media',
  },
  ATMED_CATEGORIES_MEDICAL: {
    id: 145,
    name: 'Medical Specialties',
    path: '/atmed/medical/medical/categories',
  },
  ATMED_CATEGORIES_SURGERY: {
    id: 146,
    name: 'Surgical Specialties',
    path: '/atmed/medical/surgery/categories',
  },
  ATMED_CATALOG_SURGERY: {
    id: 147,
    name: 'Surgery Procedures',
    path: '/atmed/medical/catalog/surgeries',
  },
  ATMED_CATALOG_SURGERY_STORIES: {
    id: 148,
    name: 'Surgery Stories',
    path: '/atmed/medical/catalog/surgeries/stories',
  },
  ATMED_SETTLEMENT_DOCTOR_CONSULTATION: {
    id: 149,
    name: 'Doctor Consultation',
    path: '/atmed/medical/consultation/settlements',
  },
  ATMED_SETTLEMENT_SURGERY_CONSULTATION: {
    id: 150,
    name: 'Surgeries',
    path: '/atmed/medical/surgery/settlements',
  },
  ATMED_SETTLEMENT_ONLINE_CONSULTATION: {
    id: 151,
    name: 'Online Consultation',
    path: '/atmed/medical/online/settlements',
  },
  ATMED_VENDOR_MEDIC: {
    id: 152,
    name: 'Doctors',
    path: '/atmed/medical/medics',
  },
  ATMED_VENDOR_MEDICAL_HOSPITAL: {
    id: 153,
    name: 'Hospital Vendor',
    path: '/atmed/medical/partners',
  },
  ATMED_VENDOR_MEDICAL_HOSPITAL_BRANCHES: {
    id: 154,
    name: 'Health Center',
    path: '/atmed/medical/branches',
  },
  ATMED_VENDOR_PHARMACY: {
    id: 155,
    name: 'Pharmacy',
    path: '/atmed/pharmacy/partners',
  },
  ATMED_VENDOR_PHARMACY_BRANCHES: {
    id: 156,
    name: 'Pharmacy',
    path: '/atmed/pharmacy/branches',
  },
  VENDOR_MEDICAL_FEEDBACK_CLINIC: {
    id: 157,
    name: 'Clinic consultation',
    path: '/medical/admin/consultation/feedback',
  },
  VENDOR_MEDICAL_FEEDBACK_SURGERY: {
    id: 158,
    name: 'Surgery consultation',
    path: '/medical/admin/surgery/feedback',
  },
  VENDOR_MEDICAL_ADM_HOSPITAL_STUFF: {
    id: 159,
    name: 'Hospital Staff',
    path: '/medical/admin/users',
  },
  VENDOR_MEDICAL_ADM_HEALTH_CENTRES: {
    id: 160,
    name: 'Health Centres',
    path: '/medical/admin/centres',
  },
  VENDOR_MEDICAL_APPOINTMENTS: {
    id: 161,
    name: 'Appointments',
    path: '/medical/admin/consultation/appointments',
  },
  VENDOR_MEDICAL_APPOINTMENT_CLINIC: {
    id: 162,
    name: 'Clinic consultation',
    path: '/medical/admin/consultation/appointments',
  },
  VENDOR_MEDICAL_APPOINTMENT_SURGERY: {
    id: 163,
    name: 'Surgery consultation',
    path: '/medical/admin/surgery/appointments',
  },
  VENDOR_MEDICAL_SURGERY_PACKAGE: {
    id: 164,
    name: 'Packages',
    path: '/medical/admin/surgeries',
  },
  ATMED_CATALOG_INSURANCE: {
    id: 165,
    name: 'Surgery Insurance',
    path: '/atmed/medical/catalog/insurance',
  },
  ATMED_CATALOG_SPOT_FINANCE: {
    id: 166,
    name: 'Surgery Spot Finance',
    path: '/atmed/medical/catalog/spot-finance',
  },
  VENDOR_MEDICAL_SURGERY_SPOT_FINANCE: {
    id: 167,
    name: 'Spot Finance',
    path: '/medical/admin/spot-finance',
  },
  VENDOR_MEDICAL_SURGERY_INSURANCE: {
    id: 168,
    name: 'Insurance',
    path: '/medical/admin/insurance',
  },
  ATMED_MISCELLANEOUS: {
    id: 169,
    name: 'Miscellaneous',
  },
  VENDOR_MEDICAL_CONSULTANT_DOCTORS: {
    id: 170,
    name: 'Consultant Doctors',
    path: '/medical/admin/medics',
  },
  ATMED_REGISTRATION: {
    id: 171,
    name: 'Registration'
  },
  ATMED_REGISTRATION_MEDICS: {
    id: 172,
    name: 'Medic Registration',
    path: '/atmed/registration/medics',
  },
  ATMED_REGISTRATION_VENDORS: {
    id: 173,
    name: 'Vendor Registration',
    path: '/atmed/registration/vendors',
  },
  VENDOR_HOME_COMPANY_PROFILE: {
    id: 174,
    name: 'Company Profile',
    path: '/home/company/profile',
  },
  VENDOR_LAB_COMPANY_PROFILE: {
    id: 175,
    name: 'Company Profile',
    path: '/lab/company/profile',
  },
  VENDOR_MEDICAL_COMPANY_PROFILE: {
    id: 176,
    name: 'Company Profile',
    path: '/medical/company/profile',
  },
  VENDOR_EQUIPMENT_LOG: {
    id: 177,
    name: 'Log Management',
    path: '/vendor/equipment/log',
  },
  VENDOR_MEDICAL_HELP_SUPPORT: {
    id: 178,
    name: 'Help and Support',
    path: '/medical/help',
  },
  VENDOR_MEDICAL_FEEDBACK_TOURISM: {
    id: 179,
    name: 'Medical Tourism',
    path: '/medical/admin/abroad/feedback',
  },
  VENDOR_MEDICAL_FEEDBACK_EMERGENCY: {
    id: 180,
    name: 'Emergency',
    path: '/medical/admin/emergency/feedback',
  },
  VENDOR_MEDICAL_LOG: {
    id: 181,
    name: 'Log Management',
    path: '/medical/admin/log',
  },
  VENDOR_LAB_LOG: {
    id: 182,
    name: 'Log Management',
    path: '/labs/admin/log',
  },
  HOME_HEALTHCARE_LOG: {
    id: 183,
    name: 'Log Management',
    path: '/home/admin/log',
  },
  VENDOR_LAB_HELP_SUPPORT: {
    id: 184,
    name: 'Help and Support',
    path: '/lab/admin/help',
  },
  HOME_HEALTHCARE_HELP_SUPPORT: {
    id: 185,
    name: 'Help and Support',
    path: '/home/admin/help',
  },
  VENDOR_MEDICAL_APPOINTMENT_TOURISM: {
    id: 186,
    name: 'Medical Tourism',
    path: '/medical/admin/appointments/tourism',
  },
  VENDOR_MEDICAL_APPOINTMENT_EMERGENCY: {
    id: 187,
    name: 'Emergency',
    path: '/medical/admin/appointments/emergency',
  },
  VENDOR_MEDICAL_SETTLEMENT: {
    id: 188,
    name: 'Settlement'
  },
  VENDOR_MEDICAL_SETTLEMENT_SURGERY: {
    id: 189,
    name: 'Surgery Consultation',
    path: '/medical/settlement/surgery',
  },
  VENDOR_MEDICAL_SETTLEMENT_TOURISM: {
    id: 190,
    name: 'Medical Tourism',
    path: '/medical/settlement/tourism',
  },
  VENDOR_MEDICAL_SURGERY: {
    id: 191,
    name: 'Surgery'
  },
  VENDOR_MEDICAL_ABROAD: {
    id: 192,
    name: 'Medical Tourism'
  },
  VENDOR_MEDICAL_ABROAD_PACKAGES: {
    id: 193,
    name: 'Packages',
    path: '/medical/abroad/packages',
  },
  VENDOR_MEDICAL_ABROAD_INSURANCE: {
    id: 194,
    name: 'Insurance',
    path: '/medical/abroad/insurance',
  },
  VENDOR_EQUIPMENT_HELP_SUPPORT: {
    id: 195,
    name: 'Help and Support',
    path: '/vendor/equipment/help',
  },
  ATMED_HELP_SUPPORT: {
    id: 196,
    name: 'Help and Support',
  },
  ATMED_HELP_SUPPORT_MEDICAL_EQUIPMENT: {
    id: 197,
    name: 'Medical Equipment',
    path: '/admin/atmed/help/equipment',
  },
  ATMED_HELP_SUPPORT_HOME_HEALTHCARE: {
    id: 198,
    name: 'Home Healthcare',
    path: '/admin/atmed/help/home',
  },
  ATMED_HELP_SUPPORT_LAB: {
    id: 199,
    name: 'Lab Tests',
    path: '/admin/atmed/help/lab',
  },
  ATMED_HELP_SUPPORT_EMERGENCY: {
    id: 200,
    name: 'Emergency',
    path: '/admin/atmed/help/emergency',
  },
  ATMED_HELP_SUPPORT_DOCTOR_CONSULTATION: {
    id: 201,
    name: 'Doctor Consultation',
    path: '/admin/atmed/help/doctor',
  },
  ATMED_HELP_SUPPORT_SURGERY_CONSULTATION: {
    id: 202,
    name: 'Surgery Consultation',
    path: '/admin/atmed/help/surgery',
  },
  ATMED_HELP_SUPPORT_ONLINE_CONSULTATION: {
    id: 203,
    name: 'Online Consultation',
    path: '/admin/atmed/help/online',
  },
  ATMED_HELP_SUPPORT_MEDICAL_TOURISM: {
    id: 204,
    name: 'Medical Tourism',
    path: '/admin/atmed/help/abroad',
  },
 
  ATMED_BOOKING: {
    id: 205,
    name: 'All Bookings'
  },
  ATMED_BOOKING_MEDICAL_EQUIPMENT: {
    id: 206,
    name: 'Medical Equipment',
    path: '/admin/atmed/booking/equipment',
  },

  ATMED_BOOKING_HOME_HEALTHCARE: {
    id: 207,
    name: 'Home Healthcare',
    path: '/admin/atmed/booking/home',
  },
  ATMED_BOOKING_LAB: {
    id: 208,
    name: 'Lab Tests',
    path: '/admin/atmed/booking/lab',
  },
  ATMED_BOOKING_DOCTOR_CONSULTATION: {
    id: 209,
    name: 'Doctor Consultation',
    path: '/admin/atmed/booking/doctor',
  },
  ATMED_BOOKING_SURGERY_CONSULTATION: {
    id: 210,
    name: 'Surgery Consultation',
    path: '/admin/atmed/booking/surgery',
  },
  ATMED_BOOKING_ONLINE_CONSULTATION: {
    id: 211,
    name: 'Online Consultation',
    path: '/admin/atmed/booking/online',
  },
  ATMED_BOOKING_EMERGENCY: {
    id: 212,
    name: 'Emergency',
    path: '/admin/atmed/booking/emergency',
  },
  ATMED_BOOKING_MEDICAL_TOURISM: {
    id: 213,
    name: 'Medical Tourism',
    path: '/admin/atmed/booking/abroad',
  },
  ATMED_SETTLEMENT_MEDICAL_TOURISM: {
    id: 214,
    name: 'Medical Tourism',
    path: '/atmed/medical/abroad/settlements',
  },
  ATMED_CATEGORIES_ABROAD_COUNTRIES: {
    id: 215,
    name: 'Abroad Countries',
    path: '/atmed/categories/abroad/countries',
  },
  ATMED_CATEGORIES_HELP_FAQ: {
    id: 216,
    name: 'Help FAQ',
    path: '/atmed/categories/help/faq',
  },
  ATMED_CATEGORIES_ABROAD_SPECIALTIES: {
    id: 217,
    name: 'Abroad Specialties',
    path: '/atmed/categories/abroad/specialties',
  },
  ATMED_CATEGORIES_TOUR: {
    id: 218,
    name: 'Tour Categories',
    path: '/atmed/categories/tour',
  },
  ATMED_CATEGORIES_ABROAD_PROCESS_FLOW: {
    id: 219,
    name: 'Abroad Process flow',
    path: '/atmed/categories/abroad/process',
  },
  ATMED_CATEGORIES_HELP_OPTION: {
    id: 220,
    name: 'Help Option',
    path: '/atmed/categories/help/option',
  },
  ATMED_CATEGORIES_HELP_VENDOR_OPTION: {
    id: 221,
    name: 'Help Vendor Option',
    path: '/atmed/categories/help/vendor/option',
  },
  ATMED_MISCELLANEOUS_BANNER: {
    id: 222,
    name: 'Banners',
    path: '/atmed/banners',
  },
  ATMED_CATALOG_ABROAD_PROCEDURES: {
    id: 223,
    name: 'Abroad Procedures',
    path: '/atmed/catalog/abroad/procedures',
  },
  ATMED_CATALOG_ABROAD_PROCEDURE_STORIES: {
    id: 224,
    name: 'Abroad Procedure Stories',
    path: '/atmed/catalog/abroad/procedure/stories',
  },
  ATMED_CATALOG_ABROAD_STORIES: {
    id: 225,
    name: 'Abroad Home Stories',
    path: '/atmed/catalog/abroad/stories',
  },
  ATMED_CATALOG_ABROAD_INSURANCE: {
    id: 226,
    name: 'Abroad Insurance',
    path: '/atmed/catalog/abroad/insurance',
  },
  ATMED_CATALOG_ABROAD_LOCATIONS: {
    id: 227,
    name: 'Abroad Locations',
    path: '/atmed/catalog/abroad/locations',
  },
  ATMED_CATALOG_TOURISM_INFORMATION: {
    id: 228,
    name: 'Tourism Information',
    path: '/atmed/catalog/tourism/info',
  },
  ATMED_CATALOG_TOURISM_ESTABLISHMENT: {
    id: 229,
    name: 'Tourism Etablishment',
    path: '/atmed/catalog/tourism/establishment',
  },
  ATMED_CATALOG_TOURISM_STORIES: {
    id: 230,
    name: 'Tourism Stories',
    path: '/atmed/catalog/tourism/stories',
  },
  ATMED_CATALOG_TOURISM_TRANSPORT_SERVICE: {
    id: 231,
    name: 'Tourism Transport Service',
    path: '/atmed/catalog/tourism/transport',
  },
  ATMED_MISCELLANEOUS_DEFAULT: {
    id: 232,
    name: 'Default',
    path: '/atmed/miscellaneous/default',
  },
  ATMED_PROMOTED_FEATURED: {
    id: 233,
    name: 'Promoted/Featured',
    path: '/atmed/promoted/featured',
  },
  ATMED_PROMOTED_FEATURED_EQUIP_DEAL: {
    id: 234,
    name: 'Equipment - Deals of the Day',
    path: '/atmed/promoted/equip/deal',
  },
  ATMED_PROMOTED_EQUIP_POPULAR: {
    id: 235,
    name: 'Equipment - Popular Products',
    path: '/atmed/promoted/equip/popular',
  },
  ATMED_PROMOTED_HOME_HEALTH: {
    id: 236,
    name: 'Home Health - Featured',
    path: '/atmed/promoted/home',
  },
  ATMED_PROMOTED_LABS: {
    id: 237,
    name: 'Labs - Featured Labs',
    path: '/atmed/promoted/labs',
  },
  ATMED_PROMOTED_LAB_PACKAGES: {
    id: 238,
    name: 'Labs - Featured Packages',
    path: '/atmed/promoted/labs/packages',
  },
  ATMED_PROMOTED_CLINIC_HOSPITALS: {
    id: 239,
    name: 'Clinic - Featured Hospitals',
    path: '/atmed/promoted/clinic/hospitals',
  },
  ATMED_PROMOTED_CLINIC_DOCTORS: {
    id: 240,
    name: 'Clinic - Featured Doctors',
    path: '/atmed/promoted/clinic/doctors',
  },
  ATMED_PROMOTED_SURGERY_HOSPITALS: {
    id: 241,
    name: 'Surgery - Featured Hospitals',
    path: '/atmed/promoted/surgery/hospitals',
  },
  ATMED_PROMOTED_SURGERY_DOCTORS: {
    id: 242,
    name: 'Surgery - Featured Doctors',
    path: '/atmed/promoted/surgery/doctors',
  },
  ATMED_PROMOTED_ONLINE: {
    id: 243,
    name: 'Online - Featured Doctors',
    path: '/atmed/promoted/Online',
  },
  ATMED_PROMOTED_ABROAD_HOSPITALS: {
    id: 244,
    name: 'Abroad - Featured Hospitals',
    path: '/atmed/promoted/abroad/hospitals',
  },
  ATMED_PROMOTED_ABROAD_DOCTORS: {
    id: 245,
    name: 'Abroad - Featured Doctors',
    path: '/atmed/promoted/abroad/doctors',
  },
  ATMED_FEEDBACK_MEDICAL_TOURISM: {
    id: 246,
    name: 'Medical Tourism',
    path: '/atmed/medical/abroad/feedback',
  },
  ATMED_FEEDBACK_EMERGENCY: {
    id: 247,
    name: 'Emergency',
    path: '/atmed/medical/emergency/feedback',
  },
  ATMED_LOG_MANAGEMENT: {
    id: 248,
    name: 'Log Management',
    path: '/atmed/logs',
  },
  ATMED_MISCELLANEOUS_COUNTRIES: {
    id: 249,
    name: 'Countries',
    path: '/atmed/countries',
  },
  ATMED_CATALOG_TOURISM_DESTINATION: {
    id: 250,
    name: 'Tourism Destinations',
    path: '/atmed/catalog/destination',
  },

  VENDOR_SETTLEMENT: {
    id: 251,
    name: 'Vendor Settlements',
    path: '/atmed/settlement/vendors',
  },

  ATMED_MEDICAL_SETTLEMENT_VENDOR: {
    id: 252,
    name: 'Medical Equipment',
    path: '/atmed/settlement/vendors/medical',
  },

  ATMED_HOMEHEALTHCARE_SETTLEMENT_VENDOR: {
    id: 253,
    name: 'Home Healthcare',
    path: '/atmed/settlement/vendors/home-healthcare',
  },

  ATMED_LABS_SETTLEMENT_VENDOR: {
    id: 254,
    name: 'Labs & Diagnostics',
    path: '/atmed/settlement/vendors/labs-diagnostics',
  },

  // ATMED_PHARMACY_SETTLEMENT_VENDOR: {
  //   id: 255,
  //   name: 'Pharmacy',
  //   path: '/atmed/settlement/vendors/pharmacy',
  // },

  ATMED_HOSPITAL_SETTLEMENT_VENDOR: {
    id: 256,
    name: 'Hospital Vendor',
    path: '/atmed/settlement/vendors/hospital-vendor',
  },

  ATMED_DOCTOR_SETTLEMENT_VENDOR: {
    id: 257,
    name: 'Doctors',
    path: '/atmed/settlement/vendors/doctors',
  },


  ATMED_MEDICAL_SETTLEMENT_VENDOR_ORDER: {
    id: 259,
    name: 'Medical Equipment',
    path: '/atmed/settlement/orders/medical',
  },

  ATMED_HOMEHEALTHCARE_SETTLEMENT_VENDOR_ORDER: {
    id: 260,
    name: 'Home Healthcare',
    path: '/atmed/settlement/orders/home-healthcare',
  },

  ATMED_LABS_SETTLEMENT_VENDOR_ORDER: {
    id: 261,
    name: 'Labs & Diagnostics',
    path: '/atmed/settlement/orders/labs-diagnostics',
  },

  // ATMED_PHARMACY_SETTLEMENT_VENDOR_ORDER: {
  //   id: 262,
  //   name: 'Pharmacy',
  //   path: '/atmed/settlement/orders/doctor-consulation',
  // },

  ATMED_HOSPITAL_SETTLEMENT_VENDOR_ORDER: {
    id: 263,
    name: 'Hospital Vendor',
    path: '/atmed/settlement/orders/surgeries',
  },

  ATMED_DOCTOR_SETTLEMENT_VENDOR_ORDER: {
    id: 264,
    name: 'Doctors',
    path: '/atmed/settlement/orders/online-consultation',
  },

  ATMED_BOOKING_SETTINGS: {
    id: 265,
    name: 'Booking Settings',
    path: '/atmed/booking-settings',
  },

  ABOUTUS_CMS: {
    id: 266,
    name: 'About Us',
    path: '/about-us',
  },

  PRIVACYPOLICY_CMS: {
    id: 267,
    name: 'Privacy Policy',
    path: '/privacy-policy',
  },

  TERMSCONDITIONS_CMS: {
    id: 268,
    name: 'Terms and Conditions',
    path: '/terms-conditions',
  },

  CANCELLATIONPOLICY_CMS: {
    id: 269,
    name: 'Cancellation and Refund Policy',
    path: '/cancellation-refund-policy',
  },

  ATMED_NOTIFICATION_MANAGEMENT: {
    id: 270,
    name: 'Notification',
    path: '/atmed/notifications',
  },
  ATMED_BOOKING_LAB_VENDOR: {
    id: 271,
    name: 'Lab Booking',
    path: '/labs/admin/booking',
  },
  VENDOR_MEDICAL_APPOINTMENT_BOOKING: {
    id: 272,
    name: 'Hospital Booking',
    path: '/medical/admin/appointments/booking',
  },

};

export default ROUTES;
export const routesToId = Object.values(ROUTES).reduce((acc, route) => {
  acc[route.path] = route.id;
  return acc;
}, {});
