import { protectedServiceInstance } from "./instance";
import { message } from "antd";
//import { getAllHealthcareVendor } from './vendorEquipmentsApi';

const apiKey = "7A3MXb5pTzE7zMuBRspN";

export const getAllVendorEquipment = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `atmed/vendors/equipment/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const addVendorEquipment = (payload) =>
  protectedServiceInstance
    .post("atmed/vendors/equipment/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateVendorEquipment = ({ payload, id }) =>
  protectedServiceInstance
    .post("atmed/vendors/equipment/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const addVendorEquipmentOwner = ({ id, payload }) =>
  protectedServiceInstance
    .post("atmed/vendors/equipment/user/create", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const updateVendorEquipmentOwner = ({ payload, id, userId }) =>
  protectedServiceInstance
    .post("atmed/vendors/equipment/user/update", payload, {
      // params: { api_key: apiKey, id, user_id: userId },
      params:
        payload.user.password === undefined
          ? { id, user_id: userId }
          : { api_key: apiKey, id, user_id: userId },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

    
export const getSingleEquipmentUser = (queryString) =>
  protectedServiceInstance.get(`atmed/vendors/equipment/show?${queryString}`, {
    params: { api_key: apiKey },
  });
