import * as actionType from './actionType';
import * as vendorEquipmentsApi from '../api/vendorEquipmentsApi';
import * as vendorEquipmentApi from '../api/vendorEquipmentApi';

export const getAllCountryCodes = (params) => ({
  type: actionType.GET_ALL_COUNTRY_CODES,
  payload: vendorEquipmentsApi.getAllCountryCodes(params),
});

export const getAllEquipmentUsers = (params) => ({
  type: actionType.GET_ALL_EQUIPMENT_USERS,
  payload: vendorEquipmentsApi.getAllEquipmentUsers(params),
});

export const getSingleEquipmentUser = (params) => ({
  type: actionType.GET_SINGLE_EQUIPMENT_USER,
  payload: vendorEquipmentApi.getSingleEquipmentUser(params),
});

export const addEquipmentUser = (params) => ({
  type: actionType.ADD_EQUIPMENT_USER,
  payload: vendorEquipmentsApi.addEquipmentUser(params),
});
export const updateEquipmentUser = (params) => ({
  type: actionType.UPDATE_EQUIPMENT_USER,
  payload: vendorEquipmentsApi.updateEquipmentUser(params),
});
export const updateEquipmentUserPermission = (params) => ({
  type: actionType.UPDATE_EQUIPMENT_USER_PERMISSION,
  payload: vendorEquipmentsApi.updateEquipmentUserPermission(params),
});

export const activateEquipmentUser = (params) => ({
  type: actionType.VENDOR_EQUIPMENT_ACTIVATE_COMPANY_USER,
  payload: vendorEquipmentsApi.activateEquipmentUser(params),
});

export const resendEquipmentActivateUserOTP = (params) => ({
  type: actionType.VENDOR_EQUIPMENT_RESENDOTP_COMPANY_USER,
  payload: vendorEquipmentsApi.resendEquipmentActivateUserOTP(params),
});

export const getAllVendorEquipment = (queryString,pageSize,currentPage) => ({
  type: actionType.GET_ALL_VENDOR_EQUIPMENT,
  payload: vendorEquipmentApi.getAllVendorEquipment(queryString,pageSize,currentPage),
});
export const addVendorEquipment = (params) => ({
  type: actionType.ADD_VENDOR_EQUIPMENT,
  payload: vendorEquipmentApi.addVendorEquipment(params),
});
export const updateVendorEquipment = (params) => ({
  type: actionType.UPDATE_VENDOR_EQUIPMENT,
  payload: vendorEquipmentApi.updateVendorEquipment(params),
});
export const addVendorEquipmentOwner = (params) => ({
  type: actionType.ADD_VENDOR_EQUIPMENT_OWNER,
  payload: vendorEquipmentApi.addVendorEquipmentOwner(params),
});
export const updateVendorEquipmentOwner = (params) => ({
  type: actionType.UPDATE_VENDOR_EQUIPMENT_OWNER,
  payload: vendorEquipmentApi.updateVendorEquipmentOwner(params),
});

export const getAllEquipmentProducts = (queryString,pageSize,currentPage) => ({
  type: actionType.GET_ALL_EQUIPMENT_PRODUCTS,
  payload: vendorEquipmentsApi.getAllEquipmentProducts(queryString,pageSize,currentPage),
});


export const getSearchEquipmentProducts = (queryString,pageSize,currentPage) => ({
  type: actionType.GET_ALL_SEARCH_EQUIPMENT_PRODUCTS,
  payload: vendorEquipmentsApi.getSearchEquipmentProducts(queryString,pageSize,currentPage),
});
export const getSingleEquipmentProduct = (params) => ({
  type: actionType.GET_SINGLE_EQUIPMENT_PRODUCT,
  payload: vendorEquipmentsApi.getSingleEquipmentProduct(params),
});

export const addEquipmentProduct = (params) => ({
  type: actionType.ADD_EQUIPMENT_PRODUCT,
  payload: vendorEquipmentsApi.addEquipmentProduct(params),
});
export const updateEquipmentProduct = (params) => ({
  type: actionType.UPDATE_EQUIPMENT_PRODUCT,
  payload: vendorEquipmentsApi.updateEquipmentProduct(params),
});
export const addEquipmentProductAsset = (params) => ({
  type: actionType.ADD_EQUIPMENT_PRODUCT_ASSET,
  payload: vendorEquipmentsApi.addEquipmentProductAsset(params),
});
export const addcasefilesAsset = (params) => ({
  type: actionType.ADD_CASE_FILES_ASSET,
  payload: vendorEquipmentsApi.addcasefilesAsset(params),
});
export const addcasefilesurgeryAsset = (params) => ({
  type: actionType.ADD_CASE_FILES_ASSET_SURGERY,
  payload: vendorEquipmentsApi.addcasefilesurgeryAsset(params),
});
export const deleteProductAsset = (params) => ({
  type: actionType.DELETE_PRODUCT_ASSET,
  payload: vendorEquipmentsApi.deleteProductAsset(params),
});

export const getAllVendorEquipmentOrders = (queryString,pageSize,currentPage) => ({
  type: actionType.GET_ALL_VENDOR_EQUIPMENT_ORDERS,
  payload: vendorEquipmentsApi.getAllVendorEquipmentOrders(queryString,pageSize,currentPage),
});
export const getSingleVendorEquipmentOrder = (params) => ({
  type: actionType.GET_SINGLE_VENDOR_EQUIPMENT_ORDER,
  payload: vendorEquipmentsApi.getSingleVendorEquipmentOrder(params),
});
export const getAllVendorEquipmentOrderDeliveryExecutive = (params) => ({
  type: actionType.GET_ALL_VENDOR_EQUIPMENT_DELIVERY_EXECUTIVE,
  payload: vendorEquipmentsApi.getAllVendorEquipmentOrderDeliveryExecutive(params),
});
export const confirmVendorEquipmentOrder = (params) => ({
  type: actionType.CONFIRM_VENDOR_EQUIPMENT_ORDER,
  payload: vendorEquipmentsApi.confirmVendorEquipmentOrder(params),
});
export const changeModeEquipmentOrder = (params) => ({
  type: actionType.CHANGE_MODE_EQUIPMENT_ORDER,
  payload: vendorEquipmentsApi.changeModeEquipmentOrder(params),
});
export const changeExecutiveEquipmentOrder = (params) => ({
  type: actionType.CHANGE_EXECUTIVE_EQUIPMENT_ORDER,
  payload: vendorEquipmentsApi.changeExecutiveEquipmentOrder(params),
});
export const cancelVendorEquipmentOrder = (params) => ({
  type: actionType.CANCEL_VENDOR_EQUIPMENT_ORDER,
  payload: vendorEquipmentsApi.cancelVendorEquipmentOrder(params),
});
export const helpOptionVendorEquipmentOrder = (params) => ({
  type: actionType.HELP_OPTION_VENDOR_EQUIPMENT_ORDER,
  payload: vendorEquipmentsApi.helpOptionVendorEquipmentOrder(params),
});
export const assignVendorEquipmentOrderDeliveryExecutive = (params) => ({
  type: actionType.ASSIGN_VENDOR_EQUIPMENT_ORDER_DELIVERY_EXECUTIVE,
  payload: vendorEquipmentsApi.assignVendorEquipmentOrderDeliveryExecutive(params),
});
export const vendorEquipmentOrderCourierDispatch = (params) => ({
  type: actionType.VENDOR_EQUIPMENT_ORDER_COURIER_DISPATCH,
  payload: vendorEquipmentsApi.vendorEquipmentOrderCourierDispatch(params),
});
export const vendorEquipmentOrderCompleteOrder = (params) => ({
  type: actionType.VENDOR_EQUIPMENT_ORDER_COMPLETE_ORDER,
  payload: vendorEquipmentsApi.vendorEquipmentOrderCompleteOrder(params),
});

export const getAllHealthcareVendor = (queryString,pageSize,currentPage) => ({
  type: actionType.GET_ALL_HEALTHCARE_VENDOR,
  payload: vendorEquipmentsApi.getAllHealthcareVendor(queryString,pageSize,currentPage),
});
export const getSingleHealthcareVendor = (params) => ({
  type: actionType.GET_SINGLE_HEALTHCARE_VENDOR,
  payload: vendorEquipmentsApi.getSingleHealthcareVendor(params),
});
export const addHealthcareVendor = (params) => ({
  type: actionType.ADD_HEALTHCARE_VENDOR,
  payload: vendorEquipmentsApi.addHealthcareVendor(params),
});
export const updateHealthcareVendor = (params) => ({
  type: actionType.UPDATE_HEALTHCARE_VENDOR,
  payload: vendorEquipmentsApi.updateHealthcareVendor(params),
});
export const getAllHomeServices = (params) => ({
  type: actionType.GET_ALL_HOME_SERVICES,
  payload: vendorEquipmentsApi.getAllHomeServices(params),
});
export const associateServiceToVendor = (params) => ({
  type: actionType.ASSOCIATE_SERVICE_TO_VENDOR,
  payload: vendorEquipmentsApi.associateServiceToVendor(params),
});
export const addHomeHealthcareOwner = (params) => ({
  type: actionType.ADD_HOME_HEALTHCARE_OWNER,
  payload: vendorEquipmentsApi.addHomeHealthcareOwner(params),
});
export const updateHomeHealthcareOwner = (params) => ({
  type: actionType.UPDATE_HOME_HEALTHCARE_OWNER,
  payload: vendorEquipmentsApi.updateHomeHealthcareOwner(params),
});

export const getAllEquipmentSupportedLocations = (params) => ({
  type: actionType.GET_ALL_EQUIPMENT_SUPPORTED_LOCATIONS,
  payload: vendorEquipmentsApi.getAllEquipmentSupportedLocations(params),
});

export const getAllEquipmentCities = (params) => ({
  type: actionType.GET_ALL_EQUIPMENT_CITIES,
  payload: vendorEquipmentsApi.getAllEquipmentCities(params),
});

export const updateAllEquipmentSupportedLocations = (params) => ({
  type: actionType.UPDATE_EQUIPMENT_SUPPORTED_LOCATIONS,
  payload: vendorEquipmentsApi.updateAllEquipmentSupportedLocations(params),
});

export const getAllHomeSupportedLocations = (params) => ({
  type: actionType.GET_ALL_HOME_SUPPORTED_LOCATIONS,
  payload: vendorEquipmentsApi.getAllHomeSupportedLocations(params),
});

export const getAllHomeCities = (params) => ({
  type: actionType.GET_ALL_HOME_CITIES,
  payload: vendorEquipmentsApi.getAllHomeCities(params),
});

export const getAllHomeRegions = (params) => ({
  type: actionType.GET_ALL_HOME_REGIONS,
  payload: vendorEquipmentsApi.getAllHomeRegions(params),
});
export const updateAllHomeSupportedCities = (params) => ({
  type: actionType.UPDATE_HOME_SUPPORTED_CITIES,
  payload: vendorEquipmentsApi.updateAllHomeSupportedCities(params),
});
export const updateAllHomeSupportedRegions = (params) => ({
  type: actionType.UPDATE_HOME_SUPPORTED_CITIES,
  payload: vendorEquipmentsApi.updateAllHomeSupportedRegions(params),
});

export const getPatientProductFAQs = (params) => ({
  type: actionType.GET_PATIENT_PRODUCT_FAQS,
  payload: vendorEquipmentsApi.getPatientProductFAQs(params),
});

export const updatePatientProductFAQ = (params) => ({
  type: actionType.UPDATE_PATIENT_PRODUCT_FAQ,
  payload: vendorEquipmentsApi.updatePatientProductFAQ(params),
});

export const deletePatientProductFAQ = (params) => ({
  type: actionType.DELETE_PATIENT_PRODUCT_FAQ,
  payload: vendorEquipmentsApi.deletePatientProductFAQ(params),
});

export const getAllEquipmentCategories = (params) => ({
  type: actionType.GET_ALL_EQUIPMENT_CATEGORIES,
  payload: vendorEquipmentsApi.getAllEquipmentCategories(params),
});
export const getAllCitie = (params) =>({
  type: actionType.GET_ALL_EQUIPMENT_CITIE,
  payload: vendorEquipmentsApi.getAllCitie(params),
 })
export const getEquipmentFeeback = (queryString,pageSize,currentPage) => ({
  type: actionType.GET_ALL_EQUIPMENT_FEEBACK,
  payload: vendorEquipmentsApi.getEquipmentFeeback(queryString,pageSize,currentPage),
});

export const getEquipmentFeebackInfo = (params) => ({
  type: actionType.GET_EQUIPMENT_FEEBACK_INFO,
  payload: vendorEquipmentsApi.getEquipmentFeebackInfo(params),
});

export const getEquipmentPayments = (queryString,pageSize,currentPage) => ({
  type: actionType.GET_ALL_EQUIPMENT_PAYMENTS,
  payload: vendorEquipmentsApi.getEquipmentPayments(queryString,pageSize,currentPage),
});

export const getEquipmentPaymentInfo = (params) => ({
  type: actionType.GET_EQUIPMENT_PAYMENT_INFO,
  payload: vendorEquipmentsApi.getEquipmentPaymentInfo(params),
});

export const getHomePayments = (queryString,pageSize,currentPage) => ({
  type: actionType.GET_ALL_HOME_PAYMENTS,
  payload: vendorEquipmentsApi.getHomePayments(queryString,pageSize,currentPage),
});

export const getHomePaymentDetails = (params) => ({
  type: actionType.GET_ALL_HOME_PAYMENT_DETAILS,
  payload: vendorEquipmentsApi.getHomePaymentDetails(params),
});

export const getHomeCompanyProfile = (params) => ({
  type: actionType.GET_HOME_COMPANY_PROFILE,
  payload: vendorEquipmentsApi.getHomeCompanyProfile(params),
});
export const getEquipmentCompanyProfile = (params) => ({
  type: actionType.GET_EQUIPMENT_COMPANY_PROFILE,
  payload: vendorEquipmentsApi.getEquipmentCompanyProfile(params),
});

export const getAtmedPayments = (queryString,pageSize,currentPage) => ({
  type: actionType.GET_ATMED_PAYMENTS,
  payload: vendorEquipmentsApi.getAtmedPayments(queryString,pageSize,currentPage),
});

export const updatePaymentStatus = (params) => ({
  type: actionType.UPDATE_ATMED_PAYMENTS,
  payload: vendorEquipmentsApi.updatePaymentStatus(params),
});

export const updatePaymentStatusBulk = (params) => ({
  type: actionType.UPDATE_ATMED_PAYMENTS,
  payload: vendorEquipmentsApi.updatePaymentStatusBulk(params),
});

export const getAtmedHealthProductSettlementInfo = (params) => ({
  type: actionType.GET_ATMED_PAYMENTS_INFO,
  payload: vendorEquipmentsApi.getAtmedHealthProductSettlementInfo(params),
});

export const getAtmedPaymentsHome = (queryString,pageSize,currentPage) => ({
  type: actionType.GET_ATMED_PAYMENTS_HOME,
  payload: vendorEquipmentsApi.getAtmedPaymentsHome(queryString,pageSize,currentPage),
});

export const getAtmedHomeHealthcareSettlement = (params) => ({
  type: actionType.GET_ATMED_PAYMENTS_HOME_INFO,
  payload: vendorEquipmentsApi.getAtmedHomeHealthcareSettlement(params),
});



export const updatePaymentStatusHome = (params) => ({
  type: actionType.UPDATE_ATMED_PAYMENTS_HOME,
  payload: vendorEquipmentsApi.updatePaymentStatusHome(params),
});


export const updatePaymentStatusHomeBulk = (params) => ({
  type: actionType.UPDATE_ATMED_PAYMENTS_HOME,
  payload: vendorEquipmentsApi.updatePaymentStatusHomeBulk(params),
});
export const getAtmedAssets = (queryString,pageSize,currentPage) => ({
  type: actionType.GET_ATMED_ASSETS,
  payload: vendorEquipmentsApi.getAtmedAssets(queryString,pageSize,currentPage),
});

export const updateAssetStatus = (params) => ({
  type: actionType.UPDATE_ATMED_ASSETS,
  payload: vendorEquipmentsApi.updateAssetStatus(params),
});

export const deleteAssets = (params) => ({
  type: actionType.DELETE_ATMED_ASSETS,
  payload: vendorEquipmentsApi.deleteAssets(params),
});



export const activateUser = (params) => ({
  type: actionType.ACTIVATE_ATMED_USER,
  payload: vendorEquipmentsApi.activateUser(params),
});

export const resendActivateUserOTPHome = (params) => ({
  type: actionType.RESENDOTP_ACTIVATE_ATMED_USER,
  payload: vendorEquipmentsApi.resendActivateUserOTP(params),
});

export const getAtmedAssetsHome = (queryString,pageSize,currentPage) => ({
  type: actionType.GET_ATMED_ASSETS_HOME,
  payload: vendorEquipmentsApi.getAtmedAssetsHome(queryString,pageSize,currentPage),
});

export const updateAssetStatusHome = (params) => ({
  type: actionType.UPDATE_ATMED_ASSETS_HOME,
  payload: vendorEquipmentsApi.updateAssetStatusHome(params),
});

export const deleteAssetsHome = (params) => ({
  type: actionType.DELETE_ATMED_ASSETS_HOME,
  payload: vendorEquipmentsApi.deleteAssetsHome(params),
});

export const getAtmedEquipmentFeedBack = (queryString,pageSize,currentPage) => ({
  type: actionType.GET_EQUIPMENTS_FEEDBACK,
  payload: vendorEquipmentsApi.getAtmedEquipmentFeedBack(queryString,pageSize,currentPage),
});

export const getAtmedEquipmentFeedBackSingleInfo = (params) => ({
  type: actionType.GET_EQUIPMENTS_FEEDBACK_SINGLE_INFO,
  payload: vendorEquipmentsApi.getAtmedEquipmentFeedBackSingleInfo(params),
});

export const getAllAtmedSettlementsLabDiagnostics = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_SETTLEMENTS_LAB_DIAGNOSTICS_GET_ALL,
  payload: vendorEquipmentsApi.getAllAtmedSettlementsLabDiagnostics(queryString,pageSize,currentPage),
});

export const getAtmedSettlementsLabDiagnostics = (params) => ({
  type: actionType.ATMED_SETTLEMENTS_LAB_DIAGNOSTICS_GET,
  payload: vendorEquipmentsApi.getAtmedSettlementsLabDiagnostics(params),
});


export const updateAtmedSettlementsLabDiagnostics = (params) => ({
  type: actionType.ATMED_SETTLEMENTS_LAB_DIAGNOSTICS_UPDATE,
  payload: vendorEquipmentsApi.updateAtmedSettlementsLabDiagnostics(params),
});

export const updateAtmedSettlementsLabDiagnosticsBulk = (params) => ({
  type: actionType.ATMED_SETTLEMENTS_LAB_DIAGNOSTICS_UPDATE,
  payload: vendorEquipmentsApi.updateAtmedSettlementsLabDiagnosticsBulk(params),
});

export const getAllAtmedSettlementsPharmacy = (params) => ({
  type: actionType.ATMED_GET_ALL_SETTLEMENTS_PHARMACY,
  payload: vendorEquipmentsApi.getAllAtmedSettlementsPharmacy(params),
});

export const updateAtmedSettlementsPharmacy = (params) => ({
  type: actionType.ATMED_UPDATE_SETTLEMENTS_PHARMACY,
  payload: vendorEquipmentsApi.updateAtmedSettlementsPharmacy(params),
});

export const getAtmedSettlementsPharmacy = (params) => ({
  type: actionType.ATMED_GET_SETTLEMENTS_PHARMACY,
  payload: vendorEquipmentsApi.getAtmedSettlementsPharmacy(params),
});

export const getAllAtmedSettlementsDoctorConsultation = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_SETTLEMENTS_GET_ALL_MEDICAL_DOCTOR,
  payload: vendorEquipmentsApi.getAllAtmedSettlementsDoctorConsultation(queryString,pageSize,currentPage),
});

export const getAtmedSettlementsDoctorConsultationInfo = (params) => ({
  type: actionType.ATMED_SETTLEMENTS_GET_MEDICAL_DOCTOR_INFO,
  payload: vendorEquipmentsApi.getAtmedSettlementsDoctorConsultationInfo(params),
});

export const updateAtmedSettlementsDoctorConsultation = (params) => ({
  type: actionType.ATMED_SETTLEMENTS_UPDATE_MEDICAL_DOCTOR,
  payload: vendorEquipmentsApi.updateAtmedSettlementsDoctorConsultation(params),
});

export const getAllAtmedSettlementsSurgeryConsultation = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_SETTLEMENTS_GET_ALL_MEDICAL_SURGERY,
  payload: vendorEquipmentsApi.getAllAtmedSettlementsSurgeryConsultation(queryString,pageSize,currentPage),
});

export const getAtmedSurgerySettlementInfo = (params) => ({
  type: actionType.ATMED_SETTLEMENTS_GET_MEDICAL_SURGERY_INFO,
  payload: vendorEquipmentsApi.getAtmedSurgerySettlementInfo(params),
});

export const updateAtmedSettlementsSurgeryConsultation = (params) => ({
  type: actionType.ATMED_SETTLEMENTS_UPDATE_MEDICAL_SURGERY,
  payload: vendorEquipmentsApi.updateAtmedSettlementsSurgeryConsultation(params),
});

export const updateAtmedSettlementsSurgeryConsultationBulk = (params) => ({
  type: actionType.ATMED_SETTLEMENTS_UPDATE_MEDICAL_SURGERY,
  payload: vendorEquipmentsApi.updateAtmedSettlementsSurgeryConsultationBulk(params),
});

export const getAllAtmedSettlementsOnlineConsultation = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_SETTLEMENTS_GET_ALL_MEDICAL_ONLINE,
  payload: vendorEquipmentsApi.getAllAtmedSettlementsOnlineConsultation(queryString,pageSize,currentPage),
});
export const getAtmedOnlineSettlementInfo = (params) => ({
  type: actionType.ATMED_SETTLEMENTS_GET_MEDICAL_ONLINE_INFO,
  payload: vendorEquipmentsApi.getAtmedOnlineSettlementInfo(params),
});

export const updateAtmedSettlementsOnlineConsultation = (params) => ({
  type: actionType.ATMED_SETTLEMENTS_UPDATE_MEDICAL_ONLINE,
  payload: vendorEquipmentsApi.updateAtmedSettlementsOnlineConsultation(params),
});

export const getAllAtmedSettlementsAbroad = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_SETTLEMENTS_GET_ALL_ABROAD,
  payload: vendorEquipmentsApi.getAllAtmedSettlementsAbroad(queryString,pageSize,currentPage),
});

export const getAtmedAbroadSettlementInfo = (params) => ({
  type: actionType.ATMED_SETTLEMENTS_GET_ABROAD_INFO,
  payload: vendorEquipmentsApi.getAtmedAbroadSettlementInfo(params),
});

export const updateAtmedSettlementsAbroad = (params) => ({
  type: actionType.ATMED_SETTLEMENTS_UPDATE_ABROAD,
  payload: vendorEquipmentsApi.updateAtmedSettlementsAbroad(params),
});

// Feedback - Health Products
export const getAtmedHealthFeedBack = (queryString,pageSize,currentPage) => ({
  type: actionType.GET_HOME_FEEDBACK,
  payload: vendorEquipmentsApi.getAtmedHealthFeedBack(queryString,pageSize,currentPage),
});

export const getAtmedHomeFeedBack = (queryString,pageSize,currentPage) => ({
  type: actionType.GET_HOMEHEALTH_FEEDBACK,
  payload: vendorEquipmentsApi.getAtmedHomeFeedBack(queryString,pageSize,currentPage),
});

export const getHomeFeedBackInfo = (params) => ({
  type: actionType.HOME_FEEDBACK_INFO,
  payload: vendorEquipmentsApi.getHomeFeedBackInfo(params),
});


export const getAtmedHomeFeedBackInfo = (params) => ({
  type: actionType.ATMED_HOME_FEEDBACK_SINGLE_INFO,
  payload: vendorEquipmentsApi.getAtmedHomeFeedBackInfo(params),
});

// Feedback - Lab & Diagnostics
export const getAllAtmedFeedBackLabDiagnostics = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_FEEDBACK_LAB_DIAGNOSTICS_GET_ALL,
  payload: vendorEquipmentsApi.getAllAtmedFeedBackLabDiagnostics(queryString,pageSize,currentPage),
});

export const getAtmedFeedBackLabDiagnostics = (params) => ({
  type: actionType.ATMED_FEEDBACK_LAB_DIAGNOSTICS_GET,
  payload: vendorEquipmentsApi.getAtmedFeedBackLabDiagnostics(params),
});

//Feedback - Pharmacy
export const getAllAtmedFeedBackPharmacy = (params) => ({
  type: actionType.ATMED_GET_ALL_FEEDBACK_PHARMACY,
  payload: vendorEquipmentsApi.getAllAtmedFeedBackPharmacy(params),
});

export const getAtmedFeedBackPharmacy = (params) => ({
  type: actionType.ATMED_GET_FEEDBACK_PHARMACY,
  payload: vendorEquipmentsApi.getAtmedFeedBackPharmacy(params),
});

export const getAllAtmedFeedBackMedicalDoctorConsultation = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_GET_ALL_FEEDBACK_MEDICAL_DOCTOR_CONSULTATION,
  payload: vendorEquipmentsApi.getAllAtmedFeedBackMedicalDoctorConsultation(queryString,pageSize,currentPage),
});

export const getAllAtmedFeedBackMedicalSurgeryConsultation = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_GET_ALL_FEEDBACK_MEDICAL_SURGERY_CONSULTATION,
  payload: vendorEquipmentsApi.getAllAtmedFeedBackMedicalSurgeryConsultation(queryString,pageSize,currentPage),
});

export const getAllAtmedFeedBackMedicalOnlineConsultation = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_GET_ALL_FEEDBACK_MEDICAL_ONLINE_CONSULTATION,
  payload: vendorEquipmentsApi.getAllAtmedFeedBackMedicalOnlineConsultation(queryString,pageSize,currentPage),
});

export const getAllAtmedFeedBackMedicalTourism = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_GET_ALL_FEEDBACK_MEDICAL_TOURISM,
  payload: vendorEquipmentsApi.getAllAtmedFeedBackMedicalTourism(queryString,pageSize,currentPage),
});

export const getAllAtmedFeedBackMedicalEmergency = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_GET_ALL_FEEDBACK_MEDICAL_EMERGENCY,
  payload: vendorEquipmentsApi.getAllAtmedFeedBackMedicalEmergency(queryString,pageSize,currentPage),
});


export const getAllVendorLabsDiagnostics = (queryString,pageSize,currentPage) => ({
  type: actionType.GET_ALL_VENDOR_LABS_DIAGNOSTICS,
  payload: vendorEquipmentsApi.getAllVendorLabsDiagnostics(queryString,pageSize,currentPage),
});

export const getAtmedVendorLabsDiagnosticsInfo = (params) => ({
  type: actionType.VENDOR_LABS_DIAGNOSTICS_GET_SINGLE_INFO,
  payload: vendorEquipmentsApi.getAtmedVendorLabsDiagnosticsInfo(params),
});

export const addAtmedVenderLabsDiagnostics = (params) => ({
  type: actionType.ATMED_VENDOR_LABS_DIAGNOSTICS_ADD,
  payload: vendorEquipmentsApi.addAtmedVenderLabsDiagnostics(params),
});

export const updateAtmedVendorLabsDiagnostics = (params) => ({
  type: actionType.ATMED_VENDOR_UPDATE_LABS_DIAGNOSTICS,
  payload: vendorEquipmentsApi.updateAtmedVendorLabsDiagnostics(params),
});

export const addAtmedVendorLabsDiagnosticsOwner = (params) => ({
  type: actionType.ATMED_VENDOR_ADD_LABS_DIAGNOSTICS_OWNER,
  payload: vendorEquipmentsApi.addAtmedVendorLabsDiagnosticsOwner(params),
});
export const updateAtmedVendorLabsDiagnosticsOwner = (params) => ({
  type: actionType.ATMED_VENDOR_UPDATE_LABS_DIAGNOSTICS_OWNER,
  payload: vendorEquipmentsApi.updateAtmedVendorLabsDiagnosticsOwner(params),
});

export const getAllVendorLabsDiagnosticsBranches = (params) => ({
  type: actionType.ATMED_VENDOR_LABS_DIAGNOSTICS_BRANCHES_GET_ALL,
  payload: vendorEquipmentsApi.getAllVendorLabsDiagnosticsBranches(params),
});

export const getAtmedVendorLabsDiagnosticsBranchInfo = (params) => ({
  type: actionType.ATMED_VENDOR_LABS_DIAGNOSTICS_BRANCH_GET_INFO,
  payload: vendorEquipmentsApi.getAtmedVendorLabsDiagnosticsBranchInfo(params),
});

export const addAtmedVenderLabsDiagnosticsBranch = (params) => ({
  type: actionType.ATMED_VENDOR_LABS_DIAGNOSTICS_BRANCH_ADD,
  payload: vendorEquipmentsApi.addAtmedVenderLabsDiagnosticsBranch(params),
});

export const updateAtmedVenderLabsDiagnosticsBranch = (params) => ({
  type: actionType.ATMED_UPDATE_VENDOR_LABS_DIAGNOSTICS_BRANCH,
  payload: vendorEquipmentsApi.updateAtmedVenderLabsDiagnosticsBranch(params),
});



// Media Pharmacy
export const getAtmedAssetsPharmacy = (params) => ({
  type: actionType.ATMED_GET_ALL_ASSET_APPROVAL_PHARMACY,
  payload: vendorEquipmentsApi.getAtmedAssetsPharmacy(params),
});

export const updateAssetStatusPharmacy = (params) => ({
  type: actionType.ATMED_UPDATE_ASSET_APPROVAL_PHARMACY,
  payload: vendorEquipmentsApi.updateAssetStatusPharmacy(params),
});

export const deleteAssetsPharmacy = (params) => ({
  type: actionType.ATMED_DELETE_ASSET_APPROVAL_PHARMACY,
  payload: vendorEquipmentsApi.deleteAssetsPharmacy(params),
});

// Media Medic
export const getAtmedAssetsMedic = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_GET_ALL_ASSET_APPROVAL_MEDIC,
  payload: vendorEquipmentsApi.getAtmedAssetsMedic(queryString,pageSize,currentPage),
});

export const updateAssetStatusMedic = (params) => ({
  type: actionType.ATMED_UPDATE_ASSET_APPROVAL_MEDIC,
  payload: vendorEquipmentsApi.updateAssetStatusMedic(params),
});

export const deleteAssetsMedic = (params) => ({
  type: actionType.ATMED_DELETE_ASSET_APPROVAL_MEDIC,
  payload: vendorEquipmentsApi.deleteAssetsMedic(params),
});

// Media Hospital
export const getAtmedAssetsHospital = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_GET_ALL_ASSET_APPROVAL_HOSPITAL,
  payload: vendorEquipmentsApi.getAtmedAssetsHospital(queryString,pageSize,currentPage),
});

export const updateAssetStatusHospital = (params) => ({
  type: actionType.ATMED_UPDATE_ASSET_APPROVAL_HOSPITAL,
  payload: vendorEquipmentsApi.updateAssetStatusHospital(params),
});

export const deleteAssetsHospital = (params) => ({
  type: actionType.ATMED_DELETE_ASSET_APPROVAL_HOSPITAL,
  payload: vendorEquipmentsApi.deleteAssetsHospital(params),
});

//Atmed - Vendor - Pharmacy
export const getAllAtmedVendorPharmacy = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_VENDOR_GET_ALL_PHARMACY,
  payload: vendorEquipmentsApi.getAllAtmedVendorPharmacy(queryString,pageSize,currentPage),
});

export const getAtmedVendorPharmacyInfo = (params) => ({
  type: actionType.ATMED_VENDOR_GET_SINGLE_PHARMACY_INFO,
  payload: vendorEquipmentsApi.getAtmedVendorPharmacyInfo(params),
});

export const addAtmedVenderPharmacy = (params) => ({
  type: actionType.ATMED_VENDOR_ADD_PHARMACY,
  payload: vendorEquipmentsApi.addAtmedVenderPharmacy(params),
});

export const updateAtmedVendorPharmacy = (params) => ({
  type: actionType.ATMED_VENDOR_UPDATE_PHARMACY,
  payload: vendorEquipmentsApi.updateAtmedVendorPharmacy(params),
});

export const addAtmedVendorPharmacyOwner = (params) => ({
  type: actionType.ATMED_VENDOR_ADD_PHARMACY_OWNER,
  payload: vendorEquipmentsApi.addAtmedVendorPharmacyOwner(params),
});
export const updateAtmedVendorPharmacyOwner = (params) => ({
  type: actionType.ATMED_VENDOR_UPDATE_PHARMACY_OWNER,
  payload: vendorEquipmentsApi.updateAtmedVendorPharmacyOwner(params),
});

export const getAllAtmedVendorPharmacyBranches = (params) => ({
  type: actionType.ATMED_VENDOR_GET_ALL_PHARMACY_BRANCHES,
  payload: vendorEquipmentsApi.getAllAtmedVendorPharmacyBranches(params),
});

export const getAtmedVendorPharmacyBranchInfo = (params) => ({
  type: actionType.ATMED_VENDOR_GET_INFO_PHARMACY_BRANCH,
  payload: vendorEquipmentsApi.getAtmedVendorPharmacyBranchInfo(params),
});

export const addAtmedVenderPharmacyBranch = (params) => ({
  type: actionType.ATMED_VENDOR_ADD_PHARMACY_BRANCH,
  payload: vendorEquipmentsApi.addAtmedVenderPharmacyBranch(params),
});

export const updateAtmedVenderPharmacyBranch = (params) => ({
  type: actionType.ATMED_VENDOR_UPDATE_PHARMACY_BRANCH,
  payload: vendorEquipmentsApi.updateAtmedVenderPharmacyBranch(params),
});

//Atmed - Vendor - Health Center
export const getAllVendorMedicalHospital = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_VENDOR_GET_ALL_MEDICAL_HOSPITAL,
  payload: vendorEquipmentsApi.getAllVendorMedicalHospital(queryString,pageSize,currentPage),
});

export const getAtmedVendorMedicalHospitalInfo = (params) => ({
  type: actionType.ATMED_VENDOR_GET_SINGLE_MEDICAL_HOSPITAL_INFO,
  payload: vendorEquipmentsApi.getAtmedVendorMedicalHospitalInfo(params),
});

export const addAtmedVenderMedicalHospital = (params) => ({
  type: actionType.ATMED_VENDOR_ADD_MEDICAL_HOSPITAL,
  payload: vendorEquipmentsApi.addAtmedVenderMedicalHospital(params),
});

export const updateAtmedVendorMedicalHospital = (params) => ({
  type: actionType.ATMED_VENDOR_UPDATE_MEDICAL_HOSPITAL,
  payload: vendorEquipmentsApi.updateAtmedVendorMedicalHospital(params),
});

export const addAtmedVendorHospitalOwner = (params) => ({
  type: actionType.ATMED_VENDOR_ADD_HOSPITAL_OWNER,
  payload: vendorEquipmentsApi.addAtmedVendorHospitalOwner(params),
});
export const updateAtmedVendorHospitalOwner = (params) => ({
  type: actionType.ATMED_VENDOR_UPDATE_HOSPITAL_OWNER,
  payload: vendorEquipmentsApi.updateAtmedVendorHospitalOwner(params),
});

export const getAllAtmedVendorHospitalBranches = (params) => ({
  type: actionType.ATMED_VENDOR_GET_ALL_HOSPITAL_BRANCHES,
  payload: vendorEquipmentsApi.getAllAtmedVendorHospitalBranches(params),
});

export const getAtmedVendorHospitalBranchInfo = (params) => ({
  type: actionType.ATMED_VENDOR_GET_INFO_HOSPITAL_BRANCH,
  payload: vendorEquipmentsApi.getAtmedVendorHospitalBranchInfo(params),
});

export const addAtmedVenderHospitalBranch = (params) => ({
  type: actionType.ATMED_VENDOR_ADD_HOSPITAL_BRANCH,
  payload: vendorEquipmentsApi.addAtmedVenderHospitalBranch(params),
});

export const updateAtmedVenderHospitalBranch = (params) => ({
  type: actionType.ATMED_VENDOR_UPDATE_HOSPITAL_BRANCH,
  payload: vendorEquipmentsApi.updateAtmedVenderHospitalBranch(params),
});

//Atmed - Vendor - Medic
export const getAllAtmedVendorMedic = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_VENDOR_GET_ALL_MEDIC,
  payload: vendorEquipmentsApi.getAllAtmedVendorMedic(queryString,pageSize,currentPage),
});

export const getAllAtmedVendorMedicCategory = (params) => ({
  type: actionType.ATMED_VENDOR_GET_ALL_MEDIC_CATEGORY,
  payload: vendorEquipmentsApi.getAllAtmedVendorMedicCategory(params),
});

export const getAllAtmedVendorMedicCategories = (params) => ({
  type: actionType.ATMED_VENDOR_GET_ALL_MEDIC_CATEGORIES,
  payload: vendorEquipmentsApi.getAllAtmedVendorMedicCategories(params),
});

export const getAtmedVendorMedicInfo = (params) => ({
  type: actionType.ATMED_VENDOR_GET_SINGLE_MEDIC_INFO,
  payload: vendorEquipmentsApi.getAtmedVendorMedicInfo(params),
});

// export const getAllCatalogSurgeryCategories = (params) => ({
//   type: actionType.ATMED_CATALOG_GET_ALL_SURGERY_CATEGORIES,
//   payload: vendorEquipmentsApi.getAllCatalogSurgeryCategories(params),
// });

export const addAtmedVendorMedicUser = (params) => ({
  type: actionType.ATMED_VENDOR_ADD_MEDIC_USER,
  payload: vendorEquipmentsApi.addAtmedVendorMedicUser(params),
});

export const updateAtmedVendorMedicUser = (params) => ({
  type: actionType.ATMED_VENDOR_UPDATE_MEDIC_USER,
  payload: vendorEquipmentsApi.updateAtmedVendorMedicUser(params),
});

export const addAtmedVendorMedic = (params) => ({
  type: actionType.ATMED_VENDOR_ADD_MEDIC,
  payload: vendorEquipmentsApi.addAtmedVendorMedic(params),
});

export const updateAtmedVendorMedic = (params) => ({
  type: actionType.ATMED_VENDOR_UPDATE_MEDIC,
  payload: vendorEquipmentsApi.updateAtmedVendorMedic(params),
});

export const updateimageAtmedVendorMedic = (params) => ({
  type: actionType.ATMED_VENDOR_UPDATE_MEDIC,
  payload: vendorEquipmentsApi.updateimageAtmedVendorMedic(params),
});

//Registration - Medic
export const getAllAtmedRegMedics = (params) => ({
  type: actionType.ATMED_REG_GET_ALL_MEDICS,
  payload: vendorEquipmentsApi.getAllAtmedRegMedics(params),
});

export const getAtmedRegMedic = (params) => ({
  type: actionType.ATMED_REG_GET_MEDIC,
  payload: vendorEquipmentsApi.getAtmedRegMedic(params),
});

export const updateAtmedRegMedic = (params) => ({
  type: actionType.ATMED_UPDATE_REG_MEDIC,
  payload: vendorEquipmentsApi.updateAtmedRegMedic(params),
});

//Registration - Vendors
export const getAllAtmedRegVendors = (params) => ({
  type: actionType.ATMED_REG_GET_ALL_VENDORS,
  payload: vendorEquipmentsApi.getAllAtmedRegVendors(params),
});

export const getAtmedRegVendor = (params) => ({
  type: actionType.ATMED_REG_GET_VENDOR,
  payload: vendorEquipmentsApi.getAtmedRegVendor(params),
});

export const updateAtmedRegVendor = (params) => ({
  type: actionType.ATMED_UPDATE_REG_VENDOR,
  payload: vendorEquipmentsApi.updateAtmedRegVendor(params),
});

//Log Management
export const getEquipmentLog = (queryString,pageSize,currentPage) => ({
  type: actionType.GET_ALL_EQUIPMENT_LOG,
  payload: vendorEquipmentsApi.getEquipmentLog(queryString,pageSize,currentPage),
});

export const getHomeHealthcareLog = (queryString,pageSize,currentPage) => ({
  type: actionType.HOME_HEALTHCARE_LOG,
  payload: vendorEquipmentsApi.getHomeHealthcareLog(queryString,pageSize,currentPage),
});

//Vendor Equipment - Help and Support
export const getAllVendorEquipmentHelpSupport = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_EQUIPMENT_GET_ALL_HELP_SUPPORT,
  payload: vendorEquipmentsApi.getAllVendorEquipmentHelpSupport(queryString,pageSize,currentPage),
});

export const updateVendorEquipmentHelpSupport = (params) => ({
  type: actionType.VENDOR_EQUIPMENT_UPDATE_HELP_SUPPORT,
  payload: vendorEquipmentsApi.updateVendorEquipmentHelpSupport(params),
});

//Home Healthcare - Help and Support
export const getAllHomeHealthcareHelpSupport = (queryString,pageSize,currentPage) => ({
  type: actionType.HOME_HEALTHCARE_GET_ALL_HELP_SUPPORT,
  payload: vendorEquipmentsApi.getAllHomeHealthcareHelpSupport(queryString,pageSize,currentPage),
});

export const getAllHomeHealthcareHelpOption = (params) => ({
  type: actionType.HOME_HEALTHCARE_GET_ALL_HELP_OPTION,
  payload: vendorEquipmentsApi.getAllHomeHealthcareHelpOption(params),
});

export const updateHomeHealthcareHelpSupport = (params) => ({
  type: actionType.HOME_HEALTHCARE_UPDATE_HELP_SUPPORT,
  payload: vendorEquipmentsApi.updateHomeHealthcareHelpSupport(params),
});

// export const getEquipmentLogInfo = (params) => ({
//   type: actionType.GET_EQUIPMENT_LOG_INFO,
//   payload: vendorEquipmentsApi.getEquipmentLogInfo(params),
// });

//Atmed - All Bookings
export const getAllAtmedBookingClinic = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_BOOKING_CLINIC_GET_ALL,
  payload: vendorEquipmentsApi.getAllAtmedBookingClinic(queryString,pageSize,currentPage),
});
export const getAtmedBookingClinic = (params) => ({
  type: actionType.ATMED_BOOKING_CLINIC_INFO,
  payload: vendorEquipmentsApi.getAtmedBookingClinic(params),
});

export const getAllAtmedBookingSurgery = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_BOOKING_SURGERY_GET_ALL,
  payload: vendorEquipmentsApi.getAllAtmedBookingSurgery(queryString,pageSize,currentPage),
});
export const getAtmedBookingSurgery = (params) => ({
  type: actionType.ATMED_BOOKING_SURGERY_INFO,
  payload: vendorEquipmentsApi.getAtmedBookingSurgery(params),
});

export const getAllAtmedBookingAbroad = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_BOOKING_ABROAD_GET_ALL,
  payload: vendorEquipmentsApi.getAllAtmedBookingAbroad(queryString,pageSize,currentPage),
});
export const getAtmedBookingAbroad = (params) => ({
  type: actionType.ATMED_BOOKING_ABROAD_INFO,
  payload: vendorEquipmentsApi.getAtmedBookingAbroad(params),
});

export const getAllAtmedBookingEmergency = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_BOOKING_EMERGENCY_GET_ALL,
  payload: vendorEquipmentsApi.getAllAtmedBookingEmergency(queryString,pageSize,currentPage),
});
export const getAtmedBookingEmergency = (params) => ({
  type: actionType.ATMED_BOOKING_EMERGENCY_INFO,
  payload: vendorEquipmentsApi.getAtmedBookingEmergency(params),
});

export const getAllAtmedBookingOnline = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_BOOKING_ONLINE_GET_ALL,
  payload: vendorEquipmentsApi.getAllAtmedBookingOnline(queryString,pageSize,currentPage),
});
export const getAtmedBookingOnline = (params) => ({
  type: actionType.ATMED_BOOKING_ONLINE_INFO,
  payload: vendorEquipmentsApi.getAtmedBookingOnline(params),
});

export const getAllAtmedBookingLab = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_BOOKING_LAB_GET_ALL,
  payload: vendorEquipmentsApi.getAllAtmedBookingLab(queryString,pageSize,currentPage),
});
export const getAtmedBookingLab = (params) => ({
  type: actionType.ATMED_BOOKING_LAB_INFO,
  payload: vendorEquipmentsApi.getAtmedBookingLab(params),
});

export const getAllAtmedBookingHomeHealthcare = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_BOOKING_HOME_HEALTHCARE_GET_ALL,
  payload: vendorEquipmentsApi.getAllAtmedBookingHomeHealthcare(queryString,pageSize,currentPage),
});

export const getAllAtmedBookingHomeHealthcareServices = (params) => ({
  type: actionType.ATMED_BOOKING_HOME_HEALTHCARE_SERVICES_GET_ALL,
  payload: vendorEquipmentsApi.getAllAtmedBookingHomeHealthcareServices(params),
});

export const getAtmedBookingHomeHealthcare = (params) => ({
  type: actionType.ATMED_BOOKING_HOME_HEALTHCARE_INFO,
  payload: vendorEquipmentsApi.getAtmedBookingHomeHealthcare(params),
});

export const getAllAtmedBookingMedicalEquipment = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_BOOKING_MEDICAL_EQUIPMENT_GET_ALL,
  payload: vendorEquipmentsApi.getAllAtmedBookingMedicalEquipment(queryString,pageSize,currentPage),
});
export const getAtmedBookingMedicalEquipment = (params) => ({
  type: actionType.ATMED_BOOKING_MEDICAL_EQUIPMENT_INFO,
  payload: vendorEquipmentsApi.getAtmedBookingMedicalEquipment(params),
});




//Atmed - Help and Support - Medical Equipment
export const getAllAtmedHelpSupportMedicalEquip = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_HELP_SUPPORT_MEDICAL_EQUIPMENT,
  payload: vendorEquipmentsApi.getAllAtmedHelpSupportMedicalEquip(queryString,pageSize,currentPage),
});

export const updateAtmedHelpSupport = (params) => ({
  type: actionType.UPDATE_ATMED_HELP_SUPPORT,
  payload: vendorEquipmentsApi.updateAtmedHelpSupport(params),
});

export const getAllAtmedHelpSupportHomeHealthcare = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_HELP_SUPPORT_HOME_HEALTHCARE,
  payload: vendorEquipmentsApi.getAllAtmedHelpSupportHomeHealthcare(queryString,pageSize,currentPage),
});
export const updateAtmedHelpSupportHomeHealthcare = (params) => ({
  type: actionType.UPDATE_ATMED_HELP_SUPPORT,
  payload: vendorEquipmentsApi.updateAtmedHelpSupportHomeHealthcare(params),
});


export const getAllAtmedHelpSupportLab = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_HELP_SUPPORT_LAB,
  payload: vendorEquipmentsApi.getAllAtmedHelpSupportLab(queryString,pageSize,currentPage),
});
export const updateAtmedHelpSupportLab = (params) => ({
  type: actionType.UPDATE_ATMED_HELP_SUPPORT,
  payload: vendorEquipmentsApi.updateAtmedHelpSupportLab(params),
});


export const getAllAtmedHelpSupportEmergency = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_HELP_SUPPORT_EMERGENCY,
  payload: vendorEquipmentsApi.getAllAtmedHelpSupportEmergency(queryString,pageSize,currentPage),
});
export const updateAtmedHelpSupportEmergency = (params) => ({
  type: actionType.UPDATE_ATMED_HELP_SUPPORT,
  payload: vendorEquipmentsApi.updateAtmedHelpSupportEmergency(params),
});


export const getAllAtmedHelpSupportClinicConsultation = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_HELP_SUPPORT_CLINIC_CONSULTATION,
  payload: vendorEquipmentsApi.getAllAtmedHelpSupportClinicConsultation(queryString,pageSize,currentPage),
});
export const updateAtmedHelpSupportClinicConsultation = (params) => ({
  type: actionType.UPDATE_ATMED_HELP_SUPPORT,
  payload: vendorEquipmentsApi.updateAtmedHelpSupportClinicConsultation(params),
});


export const getAllAtmedHelpSupportSurgeryConsultation = (params) => ({
  type: actionType.ATMED_HELP_SUPPORT_SURGERY_CONSULTATION,
  payload: vendorEquipmentsApi.getAllAtmedHelpSupportSurgeryConsultation(params),
});
export const updateAtmedHelpSupportSurgeryConsultation = (params) => ({
  type: actionType.UPDATE_ATMED_HELP_SUPPORT,
  payload: vendorEquipmentsApi.updateAtmedHelpSupportSurgeryConsultation(params),
});


export const getAllAtmedHelpSupportOnlineConsultation = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_HELP_SUPPORT_ONLINE_CONSULTATION,
  payload: vendorEquipmentsApi.getAllAtmedHelpSupportOnlineConsultation(queryString,pageSize,currentPage),
});
export const updateAtmedHelpSupportOnlineConsultation = (params) => ({
  type: actionType.UPDATE_ATMED_HELP_SUPPORT,
  payload: vendorEquipmentsApi.updateAtmedHelpSupportOnlineConsultation(params),
});


export const getAllAtmedHelpSupportAbroadConsultation = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_HELP_SUPPORT_ABROAD_CONSULTATION,
  payload: vendorEquipmentsApi.getAllAtmedHelpSupportAbroadConsultation(queryString,pageSize,currentPage),
});
export const updateAtmedHelpSupportAbroadConsultation = (params) => ({
  type: actionType.UPDATE_ATMED_HELP_SUPPORT,
  payload: vendorEquipmentsApi.updateAtmedHelpSupportAbroadConsultation(params),
});


export const updateEquipmentUserProfile = (params) => ({
  type: actionType.VENDOR_EQUIPMENT_USER_PROFILE_UPDATE,
  payload: vendorEquipmentsApi.updateEquipmentUserProfile(params),
});

//Atmed - Administration - log
export const getAtmedLog = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_LOG_MANAGEMENT,
  payload: vendorEquipmentsApi.getAtmedLog(queryString,pageSize,currentPage),
});

export const getAtmedNotification = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_NOTIFICATION_MANAGEMENT,
  payload: vendorEquipmentsApi.getAtmedNotification(queryString,pageSize,currentPage),
});

// vendor -medical equipmet
// lab/bookings#booking_create 
export const saveMedicalData = (params) => ({
  type:actionType.VENDOR_MEDICAL_BOOKING,
  payload: vendorEquipmentsApi.saveMedicalData(params),
});


export const getconsultcategory = (queryString) => ({
  type: actionType.VENDOR_GET_CATEGORY,
  payload: vendorEquipmentsApi.getconsultcategory(queryString)
});

export const getsurgerycategory = (queryString) => ({
  type: actionType.VENDOR_SURGER_CATEGORY,
  payload: vendorEquipmentsApi.getsurgerycategory(queryString)
});

export const getabroadcategory = (queryString) => ({
  type: actionType.VENDOR_ABROAD_CATEGORY,
  payload : vendorEquipmentsApi.getabroadcategory(queryString)
});

export const getdoctorlist = (queryString) => ({
  type: actionType.VENDOR_DOCTOR_LIST,
  payload: vendorEquipmentsApi.getdoctorlist(queryString)
});
export const getdoctorvalidation =(queryString) =>({
  type: actionType.VENDOR_DOCTOR_VALIDATION,
  payload: vendorEquipmentsApi.getdoctorvalidation(queryString)
})

export const gettimeslot=(queryString) =>({
  type: actionType.VENDOR_TIME_SLOT,
  payload: vendorEquipmentsApi.gettimeslot(queryString)
})
export const gettimevalidate=(queryString) =>({
  type: actionType.VENDOR_TIME_VALIDATE_SLOT,
  payload: vendorEquipmentsApi.gettimevalidate(queryString)
})
export const createbooking = (params) => ({
  type:actionType.VENDOR_MEDICAL_BOOKING_CREATE,
  payload: vendorEquipmentsApi.createbooking(params),
});

export const searchcategory =(queryString) =>({
  type: actionType.VENDOR_SURGERY_CATEGORY,
  payload: vendorEquipmentsApi.searchcategory(queryString),
})
export const searchsurgery =(queryString) =>({
  type: actionType.VENDOR_searchsurgery_category,
  payload: vendorEquipmentsApi.searchsurgery(queryString)
});
export const doctordetails =(queryString) =>({
  type: actionType.VENDOR_DOCTOR_SURGERY,
  payload: vendorEquipmentsApi.doctordetails(queryString)
});

export const createbookingsurgery = (params) => ({
  type:actionType.VENDOR_SURGEY_BOOKING_CREATE,
  payload: vendorEquipmentsApi.createbookingsurgery(params),
});

export const createbookingtourism = (params) => ({
  type:actionType.VENDOR_TOURISM_BOOKING_CREATE,
  payload: vendorEquipmentsApi.createbookingtourism(params),
});
export const createbookingEmegrency = (params) => ({
  type:actionType.VENDOR_EMERGENCY_BOOKING_CREATE,
  payload: vendorEquipmentsApi.createbookingEmegrency(params),
});

export const searchtourismcategory =(queryString) =>({
  type: actionType.VENDOR_TOURISM_CATEGORY,
  payload: vendorEquipmentsApi.searchtourismcategory(queryString),
})
export const searchtourismsurgery =(queryString) =>({
  type: actionType.VENDOR_TOURISM_SURGEY,
  payload: vendorEquipmentsApi.searchtourismsurgery(queryString),
})

export const doctorTourismdetails =(queryString) =>({
  type: actionType.VENDOR_TOURISM_DOCTOR,
  payload: vendorEquipmentsApi.doctorTourismdetails(queryString),
})

// medical tourism
export const validationTourismdetails =(queryString) =>({
  type: actionType.VENDOR_TOURISM_TIME,
  payload: vendorEquipmentsApi.validationTourismdetails(queryString),
})
