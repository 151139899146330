import React, { useEffect, useCallback } from 'react';
import {
  Typography, Form, Row, Col, Button, Input
} from 'antd';
import { noop, round } from 'lodash';
import { parsePhoneNumber } from 'react-phone-number-input';
import styles from '../../../common/Layout.module.scss';

const { Title } = Typography;

function Feedback(props) {

  const [form] = Form.useForm();

 
  const formItems = [
    {
      key: 'cancellation_free',
      label: 'Cancellation Free Hours (Customer can cancel the session before the hours and its free)',
      placeholder: 'Cancellation Free Hours',
      width: 12,
      rules: [{ required: true, message: 'Please input cancellation free hours!' }],
    },
    {
      key: 'cancellation_hours',
      label: 'Cancellation Hours (Customer can cancel the session before the hours but the cancellation charge will be deducted)',
      placeholder: 'Cancellation Hours',
      width: 12,
      rules: [{ required: true, message: 'Please input cancellation hours!' }],
    },
    {
      key: 'cancellation_charge',
      label: 'Cancellation Charge in (%) (For Cancellation hours)',
      placeholder: 'Cancellation Charge',
      width: 12,
      rules: [{ required: true, message: 'Please input cancellation charge!' }],
    },
    {
      key: 'reschedule_hours',
      label: 'Reschedule Hours',
      placeholder: 'Reschedule Hours',
      width: 12,
      rules: [{ required: true, message: 'Please input reschedule hours!' }],
    }
  ]

  const setPrice = (e) => {
    if (e.target.type === 'tel') {
      const numberPattern = /\d+/g;
      const phoneObj = parsePhoneNumber(`+${e.target.value.match(numberPattern) ? e.target.value.match(numberPattern).join('') : ''}`);
      // debugger;
      if (phoneObj && phoneObj.countryCallingCode) {
        form.setFieldsValue({
          phone_code: `+${phoneObj.countryCallingCode}`,
          phone: phoneObj.nationalNumber,
        });
      }
    }
    const discount = form.getFieldValue('discount');
    const mrp = form.getFieldValue('mrp');
    if (discount > 0) form.setFieldsValue({ price: round(mrp * (1 - discount / 100)) });
    else form.setFieldsValue({ price: mrp });
  };

  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        console.log("values", values)
        let payloadObj = {
          id: props.misDefault && props.misDefault.defaults.id,
          payload: { default: values }
        };
        await props.updateBookingSettings(payloadObj);
        await props.getAllBookingSettings();
      })
      .catch(noop);
  };

  const renderData = useCallback(() => {
    props.getAllBookingSettings.call();
  }, [props.getAllBookingSettings])

  useEffect(() => {
    renderData();
  }, [renderData]);

  return props.misDefault && props.misDefault.defaults ? (
    <div>
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={22} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'right' }}
          >
            Booking Settings
          </Title>
        </Col>
        <Col className="gutter-row header-buttons" justify="end" span={2} offset={0}>
          <Button onClick={() => onOk()} type="primary">
            <span style={{ fontWeight: '600' }}>
              SAVE
            </span>
          </Button>
        </Col>
      </Row>
      <Form
        form={form}
        initialValues={props.misDefault.defaults}
        onChange={(e) => setPrice(e)}
        layout="vertical"
        className={styles.formModalRequiredAsterisk}
      >
        <Row gutter={24}>
          {formItems.map(({ Component, ...formItem }) => (
            <Col
              xs={24}
              md={formItem.width ? formItem.width : 24}
              lg={formItem.width ? formItem.width : 24}
              key={formItem.key}
            >
              <Form.Item
                name={formItem.key}
                {...formItem}
              >
                {Component || <Input autoComplete='off' size='large' placeholder={`Enter ${formItem.placeholder === undefined ? formItem.label : formItem.placeholder}`} />}
              </Form.Item>
            </Col>

          ))}
        </Row>
      </Form>
    </div>
  ) : '';
}

export default Feedback;
