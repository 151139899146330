import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedSettlementsSurgeryConsultation, updateAtmedSettlementsSurgeryConsultation, updateAtmedSettlementsSurgeryConsultationBulk,
} from '../../../store/actions';
import Settlements from './settlements';

const mapStateToProps = ({ vendorEquipmentReducer }) => ({
  vendorEquipment: vendorEquipmentReducer.vendorEquipment,
  updateSurgerySettlements: vendorEquipmentReducer.updateSurgerySettlements
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedSettlementsSurgeryConsultation,
    updateAtmedSettlementsSurgeryConsultation,
    updateAtmedSettlementsSurgeryConsultationBulk,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Settlements);
