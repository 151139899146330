import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import * as serviceWorker from './serviceWorker';
import Routes from './common/routes';
import { store } from './store';
import './index.css';
import './assets/css/custom.css';

//Dev
// const pubnub = new PubNub({
//   publishKey: 'pub-c-d94d1fef-b761-49d1-b730-f107fb24acef',
//   subscribeKey: 'sub-c-838fe694-d659-11ea-9485-d6b39d8ad52f',
// });

//Production
const pubnub = new PubNub({
  publishKey: 'pub-c-11a0aee8-cb6a-430d-af5e-c71bbcb81a36',
  subscribeKey: 'sub-c-5f9c793f-765c-4af4-b01d-ad60df5c3a20',
});

ReactDOM.render(
  <React.StrictMode>
    <PubNubProvider client={pubnub}>
      <Provider store={store}>
        <Router>
          <Routes />
        </Router>
      </Provider>
    </PubNubProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
