import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllHomeHealthcareBookings,
  getSingleHomeHealthcareBooking,
  confirmHomeHealthcareBooking,
  cancelHomeHealthcareBooking,
  getAllUser,
  saveAddress,
  saveData,
} from '../../../store/actions/homeHealthcareVendor/booking';
import {
  getSingleHomeHealthcareProgram,
  getProgramCategories,
  getFilterCategory
} from '../../../store/actions/homeHealthcareVendor/programsServices';
import {
  getAllHomeHealthcareHelpOption
} from '../../../store/actions';
import HomeBookings from './homeBookings';
import { getAllHomeHealthcareServicesList, getAllHomeHealthcareServices } from "../../../store/actions/homeHealthcareVendor/programsServices";
import {
  getAllHomeHealthcareCaregiverStaff } from "../../../store/actions/homeHealthcareVendor/caregiverStaff";
const mapStateToProps = ({ homeHealthcareReducer, vendorEquipmenReducer }) => ({
  bookings: homeHealthcareReducer.bookings,
  singleBooking: homeHealthcareReducer.singleBooking,
  helpOption: vendorEquipmenReducer.helpOption,
  cancelBooking: homeHealthcareReducer.cancelBooking,
  Loading: homeHealthcareReducer.Loading,
  homeHealthCareServiceCategories: homeHealthcareReducer.homeHealthCareServiceCategories,

});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllHomeHealthcareBookings,
    getSingleHomeHealthcareBooking,
    confirmHomeHealthcareBooking,
    getAllHomeHealthcareHelpOption,
    cancelHomeHealthcareBooking,
    getAllHomeHealthcareServicesList,
    getAllHomeHealthcareCaregiverStaff,
    getAllHomeHealthcareServices,
    getSingleHomeHealthcareProgram,
    getFilterCategory,
    getAllUser,
    getProgramCategories,
    saveAddress,
    saveAddress,
    saveData
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(HomeBookings);
