import React, { useState, useEffect, Component, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Typography,
  Form,
  Input,
  Select,
  Button,
  message,
  Row,
  Col,
  Spin,
  Modal,
  Divider,
  Space,
  AutoComplete,
} from "antd";
import moment from "moment";
import ListForm from "./listform";
import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import GoogleMapComponent from "../../../common/googlemap";
import GooglePlacesAutocomplete from "react-google-autocomplete";
import AvatarUploader from "../../../common/AvatarUploader";
import { VENDOR_MEDICAL_CASEFILES } from "../../../common/awsBucket";
const { Title } = Typography;
const { Option } = Select;

const today = new Date();
// const maxDate = new Date(today);
// maxDate.setDate(today.getDate() + 15);

const maxDate = new Date(today.getFullYear(), today.getMonth() + 2, 0);

console.log("Today:", today);
console.log("Max Date:", maxDate);

const maxDate1 = new Date(
  today.getFullYear() - 15,
  today.getMonth(),
  today.getDate()
);
function BookForm(props) {
  console.log("getAllMedicalAdmHealthCentres", props);
  const [newNumber, setNewNumber] = useState("");
  // State to hold user information
  const [code, setCountryCode] = useState([]);
  const [phoneCode, setPhoneCode] = useState("+91");
  const [phoneNumberList, setPhoneNumberList] = useState([]);
  const [searchPhoneNumber, setSearchPhoneNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [addressOption, setAddressOption] = useState("");
  const [dob, setDob] = useState("");
  console.log("dob", dob);
  const [name1, setName] = useState("");
  console.log("name1", name1);
  const inputRef = useRef();
  const [User, setUser] = useState("");
  const [branches, setBranches] = useState([]);
  const [selectedbranch, setSelectedBranch] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [consultcategory, setConsultCategory] = useState([]);
  console.log("selectedbranch", selectedbranch);
  const [subcatgeory, setSubCategory] = useState([]);
  const [allcategory, setAllCategories] = useState("");
  const [categoryname, setCategoryName] = useState();
  const [doctorlist, setDoctorList] = useState([]);
  console.log("🚀 ~ BookForm ~ doctorlist:", doctorlist);
  const [selecteddoctor, setSelecteddoctor] = useState("");
  const [timelist, setTimeList] = useState("");
  const [alldata, setALLData] = useState("");
  const [onClearCount, setOnClearCount] = useState(0);
  console.log("selecteddata", alldata);
  console.log("selecteddoctor", selecteddoctor);
  const [searchcategory, setsearchcategory] = useState([]);
  console.log("searchcategory:", searchcategory);
  const [selectedcategory, setSelectedcategory] = useState("");
  console.log("selectedcategory:", selectedcategory);

  useEffect(() => {
    const id = User?.id; // Retrieve the id from formValues

    // Call props.saveAddress() with the id parameter
    props
      .saveAddress(id)
      // Inside your useEffect hook where you're setting addressOption
      .then((response) => {
        // Filter the received data based on the category property
        const filteredAddress = response?.value?.data?.data;
        setAddressOption(filteredAddress);
      })

      .catch((error) => {});
  }, [User.id]); // Make sure useEffect is triggered when formValues.id changes
  // New Address

  const [houseNumber, setHouseNumber] = useState("");
  const [landmark, setLandmark] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [otherAddressType, setOtherAddressType] = useState("");
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [longitude, setLong] = useState(null);
  const [latitude, setLat] = useState(null);
  const [isAddAddress, setIsAddAddress] = useState(false);
  const [addressType, setAddressType] = useState("");
  const [addresssaved, setAddresssaved] = useState("");
  const [newaddress, setNewAddress] = useState("");

  const handleAddressTypeChange = (value) => {
    setAddressType(value);
  };

  const handleSaveAddress = (values) => {
    let city = "";
    let country = "";
    let state = "";
    let locality = "";
    let zip = "";
    if (selectedPlace && selectedPlace?.address_components) {
      const addressComponents = selectedPlace?.address_components;
      addressComponents.forEach((component) => {
        if (component.types.includes("locality")) {
          city = component.long_name;
        }
        if (component.types.includes("sublocality")) {
          locality = component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          state = component.long_name;
        }
        if (component.types.includes("country")) {
          country = component.long_name;
        }
        if (component.types.includes("postal_town")) {
          city = component.long_name;
        }
        if (component.types.includes("postal_code")) {
          zip = component.long_name;
        }
      });
      const Newaddress = {
        category: addressType,
        location: selectedPlace?.formatted_address || "",
        house_no: houseNumber,
        locality: locality,
        city: city,
        state: state,
        zip: zip,
        landmark: landmark,
        country: country,
        latitude: latitude,
        longitude: longitude,
        phone_code: phoneCode,
        phone: mobileNumber || `${phoneCode} ${values?.phone}`,
        otherAddressType: otherAddressType,
      };
      return Newaddress;
    }
  };

  const handleAddressSelect = (e) => {
    setAddresssaved(e);
    setIsAddAddress(false);
    setAddressType("");
    setMobileNumber("");
    setLandmark("");
    setHouseNumber("");
    setSelectedPlace("");
    setUser(User);
  };
  const handleAddressSaved = (address) => {
    onSubmit(address); // Call onSubmit and pass both form values and address
  };

  // file upload

  const [profile, setProfile] = useState(null);
  const [uploadImage, setuploadImage] = useState(null);
  const [picUrl, setPicUrl] = useState([]);
  console.log("upload", picUrl);
  const [documentStatus, setDocumentStatus] = useState();
  const getValueFromEvent = (e) => {
    const { file, target } = e;
    const { response } = file || {};
    const { value } = target || {};
    console.log("getssssss", file, target);
    return response || value;
  };
  // const docUploadSuccess = (uploadUrl, key) => {
  //   console.log("UPload",uploadUrl)
  //   setPicUrl(uploadUrl);
  //   setuploadImage(uploadUrl);
  // };

  const docUploadSuccess = async (uploadUrl, key) => {
    console.log("Upload", uploadUrl);
    setPicUrl(uploadUrl);
    setuploadImage(uploadUrl);

    // // Prepare payload
    // const payload = [{ url: uploadUrl }];

    // // Call the API
    // console.log("Upload", payload);
    // try {
    //   props.addcasefilesAsset(payload);
    //   console.log("API call successful");
    // } catch (error) {
    //   console.error("API call failed", error);
    // }
  };

  // USERS AND BRANCHES

  useEffect(() => {
    //   const user = response?.value?.data?.data;
    //   setUser(user);
    // });
    props.getAllMedicalAdmHealthCentres().then((response) => {
      const branching = response.value.data.data.filter(
        (item) => item.status === true
      );
      setBranches(branching);
    });
    props.getAllCountryCodes().then((response) => {
      setCountryCode(response?.value?.data?.data);
    });
  }, []);

  // patient details

  const handleOnSearch = (searchValue) => {
    setUser({});
    props.getAllUser(searchValue).then((response) => {
      console.log("API Response", response);
      const phoneNumbers = response?.value?.data?.data.map((user) => ({
        phone: user.phone,
      }));
      if (phoneNumbers?.length == 0) {
        setSearchPhoneNumber(searchValue);
      } else {
        setSearchPhoneNumber("");
      }
      setPhoneNumberList(phoneNumbers);
    });
  };
  const handleOnSelect = (data) => {
    props.getAllUser(data).then((response) => {
      const findUser = response.value.data.data?.find(
        (each) => each.phone == data
      );
      console.log("findusse", findUser);
      setPhoneNumber(findUser?.phone);

      // Assuming the API response contains user data
      setUser(findUser);
      setName(findUser?.name);

      // setName(response?.value?.data?.data[0].name);
    });

    // setPhoneNumber
  };

  const userInfo = JSON.parse(localStorage.getItem("userObj"));
  console.log("🚀 ~ BookForm ~ userInfo:", userInfo);

  // selected dates and prefered time

  const [selectedDates, setSelectedDates] = useState(moment());
  console.log("sssss", selectedDates);

  const handledates = (dates) => {
    setSelectedDates(dates);
    setTimeList("");
  };
  const [timeListOptions, setTimeListOptions] = useState([]);
  console.log("timeListOptions:", timeListOptions);

  const todayDateFormatted = moment().format("DD/MM/YYYY");
  console.log("🚀 ~ BookForm ~ todayDateFormatted:", todayDateFormatted);

  const formattedDates = selectedDates?.format("ddd DD MMMM");
  console.log("Formatted Dates:", formattedDates);

  const formattedDates1 = selectedDates?.format("DD/MM/YYYY");
  console.log("Formatted Dates1", formattedDates1);
  const [matchedHospital, setMatchedHospital] = useState(null);

  // Update selected service
  const handleServiceChange = (value) => {
    setSelectedService(value);
    setCategoryName("");
    setTimeListOptions([]);
    setSelecteddoctor("");
    setConsultfee("");
    setALLData("");
    setMatchedHospital(null);
    setSelectedcategory("");
    setSelecteddoctor("");
    setAllCategories("");
  };

  // TIME LIST

  const timeOptions = [
    "09:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "01:00 PM",
    "02:00 PM",
    "03:00 PM",
    "04:00 PM",
    "05:00 PM",
    "06:00 PM",
    "07:00 PM",
    "08:00 PM",
    "09:00 PM",
  ];
  const [timelistm, setTimeListOptionsm] = useState([]);
  console.log(" timelistm:", timelistm);

  const handlePreferredTimeList = () => {
    const isToday = formattedDates1?.includes(todayDateFormatted);
    if (isToday) {
      const currentHour = parseInt(moment().format("HH"), 10); // Using 24-hour format for easier comparison
      const currentMinute = moment().format("mm");

      const newArray = timeOptions.filter((each) => {
        const [time, period] = each.split(" ");
        let [hour, minute] = time.split(":").map(Number);

        if (period === "PM" && hour !== 12) hour += 12;
        if (period === "AM" && hour === 12) hour = 0;

        // Check if the time is after the current time
        if (
          hour > currentHour ||
          (hour === currentHour && minute > currentMinute)
        ) {
          return true;
        }

        return false;
      });
      console.log("Time", newArray);
      setTimeListOptionsm(newArray);
    } else {
      setTimeListOptionsm(timeOptions);
    }
  };

  useEffect(() => {
    handlePreferredTimeList();
  }, [selectedDates]);

  // TIME VALIDATION FOR CLINIC

  useEffect(() => {
    if (
      selectedService === "medical" &&
      selectedbranch &&
      selecteddoctor &&
      selectedDates
    ) {
      const params = new URLSearchParams({
        id: selecteddoctor?.id,
        health_centre_id: selecteddoctor?.health_centre_id,
        current_lat: selectedbranch?.latitude,
        current_long: selectedbranch?.longitude,
        current_locality: selectedbranch?.locality,
        current_city: selectedbranch?.city,
        // module: "medical",
        current_country: "India",
      }).toString();

      props
        .gettimeslot(params)
        .then((response) => {
          // Check if the response has expected structure
          const timings = response?.value?.data?.data?.timings || [];
          const data = response?.value?.data?.data;
          setALLData(data);
          setDiscount(0);
          // Filter the timings by the selected date
          const filteredTimings = timings.filter(
            (timing) => timing.date === formattedDates1
          );
          console.log("filteredTimings:", timings);

          // Merge and filter time slots
          let allTimeSlots = [];
          if (filteredTimings.length > 0) {
            const { morning, afternoon, evening } = filteredTimings[0];
            // Combine all time slots
            allTimeSlots = [].concat(morning, afternoon, evening);
          }
          const selectedDateFormatted = selectedDates.format("DD/MM/YYYY");
          console.log("selectedDateFormatted:", selectedDateFormatted);
          const isToday = selectedDateFormatted === todayDateFormatted;

          if (isToday) {
            const currentHour = moment().hour();
            const currentMinute = moment().minute();

            const newArray = allTimeSlots.filter((each) => {
              const [startTime] = each.split(" - ");
              const startMoment = moment(startTime, "hh:mm A");
              return (
                startMoment.hour() > currentHour ||
                (startMoment.hour() === currentHour &&
                  startMoment.minute() > currentMinute)
              );
            });

            setTimeListOptions(newArray);
          } else {
            setTimeListOptions(allTimeSlots);
          }
          // Set the filtered time list
          // setNewTimeList(allTimeSlots);

          // Debug: Log the merged and filtered time slots
          console.log("All Time Slots:", allTimeSlots);
        })
        .catch((error) => {
          console.error("Error fetching doctor list:", error);
        });
    }
  }, [selectedbranch, selecteddoctor, selectedDates, selectedService]);

  const formattedDate = dob ? dob.format("DD/MM/YYYY") : ""; // Format the single date
  console.log("Selected Date:", formattedDate);

  const calculateAge = (dob) => {
    const now = moment();
    const age = now.diff(dob, "years");
    return age;
  };

  const handlecategorychange = (value) => {
    const selected = consultcategory.find((branch) => branch?.id === value);
    console.log("selected", selected);
    setAllCategories(selected);
    setCategoryName(selected?.name);
    setSubCategory(selected?.sub_categories);
    setSelectedcategory("");
    setSelecteddoctor("");
    // sub_categories[0].name
  };

  // SERVICE API

  useEffect(() => {
    if (selectedService === "medical") {
      props.getconsultcategory().then((response) => {
        const category = response.value.data.data.filter(
          (item) => item.status === true
        );
        console.log("Clinic category", category);
        setConsultCategory(category);
      });
    } else if (selectedService === "Surgery Consultation") {
      props.getsurgerycategory().then((response) => {
        const category = response.value.data.data.filter(
          (item) => item.status === true
        );
        console.log("Surgery category", category);
        // setSurgeryCategory(category);
        setConsultCategory(category);
      });
    } else if (selectedService === "Medical Tourism") {
      props.getabroadcategory().then((response) => {
        const category = response.value.data.data.filter(
          (item) => item.status === true
        );
        console.log("Tourism category", category);
        // setTourismCategory(category);
        setConsultCategory(category);
      });
    }
    // You can add more conditions if needed for other services
  }, [selectedService]);
  const [services, setServices] = useState([]);

  const handleBranchChange = (value) => {
    const selected = branches.find((branch) => branch?.id === value);
    setSelectedBranch(selected);

    const newServices = [];

    newServices.push({ value: "medical", label: "Clinic Consultation" });
    if (selected?.surgery_service) {
      newServices.push({
        value: "Surgery Consultation",
        label: "Surgery Consultation",
      });
    }
    if (selected?.abroad_service) {
      newServices.push({ value: "Medical Tourism", label: "Medical Tourism" });
    }
    if (selected?.emergency_service) {
      newServices.push({ value: "Emergency", label: "Emergency" });
    }

    setServices(newServices);
  };

  const handleNameChange = (e) => {
    const { value } = e.target;
    setUser((prevValues) => ({
      ...prevValues,
      name: value,
    }));
  };
  const handleEmailChange = (e) => {
    const { value } = e.target;
    setUser((prevValues) => ({
      ...prevValues,
      email: value,
    }));
  };

  const handleDobChange = (date) => {
    // Handle date change and update the state
    setUser((prevValues) => ({
      ...prevValues,
      dob: date,
    }));
    setDob(date); // Update the dob state directly with the selected date
  };

  const handleGenderChange = (value) => {
    // Handle gender change and update the state
    setUser((prevValues) => ({
      ...prevValues,
      gender: value,
    }));
  };

  const handleTime = (value) => {
    setTimeList(value);
  };

  // Doctor details for CLINIC

  useEffect(() => {
    if (selectedService === "medical" && selectedbranch && categoryname) {
      const params = new URLSearchParams({
        current_lat: selectedbranch.latitude,
        current_long: selectedbranch.longitude,
        category: categoryname,
        current_locality: selectedbranch.locality,
        sort: "",
        offset: "0",
        current_city: selectedbranch.city,
        module: "medical",
        limit: "20",
        current_country: "India",
      }).toString();

      props
        .getdoctorlist(params)
        .then((response) => {
          console.log("response", response);
          const doctorlists = response?.value?.data?.data || [];
          const filteredDoctorList = doctorlists.filter(
            (doctor) =>
              doctor?.health_centre_id === selectedbranch?.health_centre_id
          );
          console.log("Filtered Doctor List:", filteredDoctorList);
          setDoctorList(filteredDoctorList);
        })
        .catch((error) => {
          console.error("Error fetching doctor list:", error);
        });
    }
  }, [selectedbranch, categoryname, selectedService]);

  useEffect(() => {
    if (
      selectedService === "medical" &&
      selectedbranch &&
      selecteddoctor &&
      timelist &&
      formattedDates
    ) {
      const params = new URLSearchParams({
        id: selecteddoctor?.id,
        health_centre_id: selecteddoctor?.health_centre_id
          ? selecteddoctor.health_centre_id
          : "",
        selected_time: timelist,
        selected_date: formattedDates,
        current_city: selectedbranch.city,
        current_lat: selectedbranch.latitude,
        current_long: selectedbranch.longitude,
        current_locality: selectedbranch.locality,
        current_city: selectedbranch.city,
        module: "medical",
        current_country: "India",
      }).toString();
      props
        .getdoctorvalidation(params)
        .then((response) => {
          console.log("response1", response);
          const validate = response?.value?.data?.message;
          if (response?.value?.data?.code === "422") {
            message.error(validate);
          }
        })
        .catch((error) => {
          console.error("Error fetching doctor list:", error);
        });
    }
  }, [
    selectedbranch,
    selecteddoctor,
    timelist,
    formattedDates,
    selectedService,
  ]);

  const handledoctor = (value) => {
    const selected = doctorlist?.find((branch) => branch?.id === value);
    setSelecteddoctor(selected);
    setSelectedDates();
  };

  // surgery --------------------------

  const [consult, setConsultfee] = useState("");
  const [surgerydetail, setSurgerydetail] = useState("");
  console.log("surgerydetail:", surgerydetail);
  const [validatesurgery, setValidateSurgery] = useState([]);
  console.log("🚀 ~ validatesurgery:", validatesurgery);
  // Category and progarm

  useEffect(() => {
    if (
      selectedService === "Surgery Consultation" &&
      selectedbranch &&
      categoryname
    ) {
      const params = new URLSearchParams({
        // current_long:"13.0067315",
        // current_lat:"80.2417759",
        current_lat: selectedbranch.latitude,
        current_long: selectedbranch.longitude,
        category: categoryname,
        current_locality: selectedbranch.locality,
        sort: "",
        offset: "0",
        current_city: selectedbranch.city,
        limit: "20",
        current_country: "India",
      }).toString();

      props
        .searchcategory(params)
        .then((response) => {
          // Check if the response has expected structure
          const timings = response?.value?.data?.data;
          setsearchcategory(timings);
        })
        .catch((error) => {
          console.error("Error fetching doctor list:", error);
        });
    }
  }, [selectedbranch, categoryname, selectedService]);

  // search
  useEffect(() => {
    if (
      selectedService === "Surgery Consultation" &&
      selectedbranch &&
      selectedcategory
    ) {
      const params = new URLSearchParams({
        current_lat: selectedbranch.latitude,
        current_long: selectedbranch.longitude,
        current_locality: selectedbranch.locality,
        current_city: selectedbranch.city,
        current_country: "India",
        id: selectedcategory?.surgery_id,
        sort: "",
        offset: "0",
        limit: "20",
      }).toString();

      props
        .searchsurgery(params)
        .then((response) => {
          console.log("respond", response);
          const surgeries = response?.value?.data?.data;
          setValidateSurgery(surgeries);
        })
        .catch((error) => {
          console.error("Error fetching doctor list:", error);
        });
    }
  }, [selectedbranch, selectedService, selectedcategory]);

  useEffect(() => {
    if (validatesurgery && selectedbranch) {
      const foundHospital = validatesurgery.find(
        (hospital) => hospital?.hospital_id === selectedbranch?.health_centre_id
      );
      setMatchedHospital(foundHospital || null);
      console.log("matchedHospital", foundHospital);
    }
  }, [validatesurgery, selectedbranch]);

  //  Doctor details FOR SURGERY

  useEffect(() => {
    if (
      selectedService === "Surgery Consultation" &&
      selectedbranch &&
      matchedHospital
    ) {
      const params = new URLSearchParams({
        current_lat: selectedbranch.latitude,
        current_long: selectedbranch.longitude,
        current_locality: selectedbranch.locality,
        current_city: selectedbranch.city,
        current_country: "India",
        // id: selectedcategory?.surgery_id ,
        id: matchedHospital?.id,
        hospital_id: matchedHospital?.hospital_id,
        // id:selectedcentre?.id,
        // hospital_id:selectedcentre?.hospital_id
      }).toString();

      props
        .doctordetails(params)
        .then((response) => {
          console.log("responseqqqqqq", response);
          const centerdetails = response?.value?.data?.data?.specialists;
          const consultfee = response?.value?.data?.data?.cost_estimation;
          const surgery = response?.value?.data?.data?.surgery_details;
          setDoctorList(centerdetails);
          setConsultfee(consultfee);
          setSurgerydetail(surgery);
        })
        .catch((error) => {
          console.error("Error fetching doctor list:", error);
        });
    }
  }, [selectedbranch, selectedService, matchedHospital]);
  // Doctot time validation FOR SURGERY

  useEffect(() => {
    if (
      selectedService === "Surgery Consultation" &&
      selectedbranch &&
      selecteddoctor &&
      surgerydetail &&
      selectedDates
    ) {
      const params = new URLSearchParams({
        id: selecteddoctor?.id,
        health_centre_id: selectedbranch?.health_centre_id,
        current_lat: selectedbranch?.latitude,
        current_long: selectedbranch?.longitude,
        current_locality: selectedbranch?.locality,
        current_city: selectedbranch?.city,
        surgery_id: surgerydetail?.id,
        current_country: "India",
      }).toString();

      props
        .gettimevalidate(params)
        .then((response) => {
          // Check if the response has expected structure
          const timings = response?.value?.data?.data?.timings || [];
          const data = response?.value?.data?.data;
          setALLData(data);
          setDiscount(0);
          // Filter the timings by the selected date
          const filteredTimings = timings.filter(
            (timing) => timing.date === formattedDates1
          );
          console.log("filteredTimings:", timings);

          // Merge and filter time slots
          let allTimeSlots = [];
          if (filteredTimings.length > 0) {
            const { morning, afternoon, evening } = filteredTimings[0];
            // Combine all time slots
            allTimeSlots = [].concat(morning, afternoon, evening);
          }
          const selectedDateFormatted = selectedDates.format("DD/MM/YYYY");
          console.log("selectedDateFormatted:", selectedDateFormatted);
          const isToday = selectedDateFormatted === todayDateFormatted;

          if (isToday) {
            const currentHour = moment().hour();
            const currentMinute = moment().minute();

            const newArray = allTimeSlots.filter((each) => {
              const [startTime] = each.split(" - ");
              const startMoment = moment(startTime, "hh:mm A");
              return (
                startMoment.hour() > currentHour ||
                (startMoment.hour() === currentHour &&
                  startMoment.minute() > currentMinute)
              );
            });

            setTimeListOptions(newArray);
          } else {
            setTimeListOptions(allTimeSlots);
          }
          // Set the filtered time list
          // setNewTimeList(allTimeSlots);

          // Debug: Log the merged and filtered time slots
          console.log("All Time Slots:", allTimeSlots);
        })
        .catch((error) => {
          console.error("Error fetching doctor list:", error);
        });
    }
  }, [
    selectedbranch,
    selecteddoctor,
    selectedDates,
    surgerydetail,
    selectedService,
  ]);

  // validate time
  useEffect(() => {
    if (
      selectedService === "Surgery Consultation" &&
      selectedbranch &&
      selecteddoctor &&
      surgerydetail &&
      timelist &&
      formattedDates
    ) {
      const params = new URLSearchParams({
        id: selecteddoctor?.id,
        health_centre_id: selectedbranch?.health_centre_id,
        selected_time: timelist,
        selected_date: formattedDates,
        current_city: selectedbranch.city,
        current_lat: selectedbranch.latitude,
        current_long: selectedbranch.longitude,
        current_locality: selectedbranch.locality,
        current_city: selectedbranch.city,
        current_country: "India",
        surgery_id: surgerydetail?.id,
      }).toString();
      props
        .getdoctorvalidation(params)
        .then((response) => {
          console.log("response1", response);
          const validate = response?.value?.data?.message;
          if (response?.value?.data?.code === "422") {
            message.error(validate);
          }
        })
        .catch((error) => {
          console.error("Error fetching doctor list:", error);
        });
    }
  }, [
    selectedbranch,
    selecteddoctor,
    timelist,
    formattedDates,
    selectedService,
    surgerydetail,
  ]);
  // onchange
  const handlesearchcategory = (value) => {
    console.log("surya", value);
    const selected = searchcategory.find((branch) => branch?.name === value);
    console.log("surya2", selected);
    setSelectedcategory(selected);
  };

  // CONSULT FEES
  const collectedfee =
    alldata?.consult_fee ?? alldata?.package_details?.consult_fee;
  const collectedfeesurgery = consult?.consult_fee;
  console.log("collectedfeesurgery", collectedfee);
  const [discount, setDiscount] = useState("");
  const medicaldiscount = alldata?.package_details?.discount;
  const totalTourism = alldata?.package_details?.total_payable;

  // medical torurism

  const [currentPage, setCurrentPage] = useState(1);
  const [programtourism, setProgramTourism] = useState([]);
  const [surgerytourism, setSurgeryTourism] = useState([]);
  const [desc, setDesc] = useState("");

  useEffect(() => {
    props.getAllMedicalAbroadPackages(undefined, 20, currentPage);
  }, []);

  useEffect(() => {
    props.getAllMedicalCatalogConsultantDoctors().then((response) => {
      console.log("meenatchim", response);
      // const surgeries = response?.value?.data?.data;
      // setValidateSurgery(surgeries);
    });
  }, []);

  useEffect(() => {
    if (selectedService === "Medical Tourism" && allcategory) {
      const params = new URLSearchParams({
        cid: 6,
        offset: 0,
        medical_category_id: allcategory?.id,
        limit: 5,
      }).toString();

      props
        .searchtourismcategory(params)
        .then((response) => {
          console.log("responseqqqqqq", response);
          const tourismcat = response?.value?.data?.data;
          setsearchcategory(tourismcat);
        })
        .catch((error) => {
          console.error("Error fetching doctor list:", error);
        });
    }
  }, [selectedService, allcategory]);
  useEffect(() => {
    if (
      selectedService === "Medical Tourism" &&
      allcategory &&
      selectedcategory
    ) {
      const params = new URLSearchParams({
        cid: 6,
        offset: 0,
        medical_category_id: allcategory?.id,
        limit: 5,
        surgery_id: selectedcategory?.surgery_id,
        sort: "",
      }).toString();

      props
        .searchtourismsurgery(params)
        .then((response) => {
          console.log("meena", response);
          const surgeries = response?.value?.data?.data;
          setValidateSurgery(surgeries);
        })
        .catch((error) => {
          console.error("Error fetching doctor list:", error);
        });
    }
  }, [selectedService, allcategory, selectedcategory]);

  useEffect(() => {
    if (selectedService === "Medical Tourism" && matchedHospital) {
      const params = new URLSearchParams({
        package_id: matchedHospital?.package_id,
        hospital_id: matchedHospital?.hospital_id,
      }).toString();

      props
        .doctorTourismdetails(params)
        .then((response) => {
          console.log("meenatchi", response);
          const centerdetails = response?.value?.data?.data?.specialists;
          // const consultfee = response?.value?.data?.data?.cost_estimation;
          // const surgery = response?.value?.data?.data?.surgery_details;
          setDoctorList(centerdetails);
        })
        .catch((error) => {
          console.error("Error fetching doctor list:", error);
        });
    }
  }, [selectedService, matchedHospital]);

  useEffect(() => {
    if (
      selectedService === "Medical Tourism" &&
      selecteddoctor &&
      matchedHospital
    ) {
      const params = new URLSearchParams({
        package_id: matchedHospital?.package_id,
        hospital_id: matchedHospital?.hospital_id,
        medic_id: selecteddoctor?.id,
      }).toString();

      props
        .validationTourismdetails(params)
        .then((response) => {
          // Check if the response has expected structure
          const timings = response?.value?.data?.data?.timings || [];
          const data = response?.value?.data?.data;
          setALLData(data);
          setDiscount(0);
          // Filter the timings by the selected date
          const filteredTimings = timings.filter(
            (timing) => timing.date === formattedDates1
          );
          console.log("filteredTimings:", timings);

          // Merge and filter time slots
          let allTimeSlots = [];
          if (filteredTimings.length > 0) {
            const { morning, afternoon, evening } = filteredTimings[0];
            // Combine all time slots
            allTimeSlots = [].concat(morning, afternoon, evening);
          }
          const selectedDateFormatted = selectedDates?.format("DD/MM/YYYY");
          console.log("selectedDateFormatted:", selectedDateFormatted);
          const isToday = selectedDateFormatted === todayDateFormatted;

          if (isToday) {
            const currentHour = moment().hour();
            const currentMinute = moment().minute();

            const newArray = allTimeSlots.filter((each) => {
              const [startTime] = each.split(" - ");
              const startMoment = moment(startTime, "hh:mm A");
              return (
                startMoment.hour() > currentHour ||
                (startMoment.hour() === currentHour &&
                  startMoment.minute() > currentMinute)
              );
            });

            setTimeListOptions(newArray);
          } else {
            setTimeListOptions(allTimeSlots);
          }
          // Set the filtered time list
          // setNewTimeList(allTimeSlots);

          // Debug: Log the merged and filtered time slots
          console.log("All Time Slots:", allTimeSlots);
        })
        .catch((error) => {
          console.error("Error fetching doctor list:", error);
        });
    }
  }, [selectedService, selecteddoctor]);

  // EMERGENCY
  const [emertype, setEmertype] = useState("");
  console.log("🚀 ~ emertype:", emertype);
  const [notes, setNotes] = useState("");
  console.log("notes", notes);

  const Emergency = [
    "Road Accident",
    "Bleeding",
    "Breathing Problem",
    "Heart Attack",
    "stroke",
    "Eye Trauma",
  ];
  const handleemergency = (value) => {
    setEmertype(value);
  };

  const handleEmeNotes = (e) => {
    const { value } = e.target;
    setNotes(value);
  };
  const handleMedNotes = (e) => {
    const { value } = e.target;
    setDesc(value);
  };
  // ONSUBMIT
  const onSubmit = (values) => {
    console.log("values", values);
    const phoneNumber = `${phoneCode} ${values?.phone}`;

    const age = calculateAge(formattedDate || values?.dob);

    console.log("age:", age);
    if (values?.service === "medical") {
      const payload = {
        consultation_booking: {
          centre_latitude: selectedbranch.latitude,
          centre_longitude: selectedbranch.longitude,
          centre_phone: alldata?.centre_phone,
          centre_address: selectedbranch?.address,
          centre_name: selectedbranch?.name,
          vendor_country: "India",
          health_centre_id: selecteddoctor?.health_centre_id,
          health_vendor_id: selectedbranch?.health_vendor_id,
          patient_id: User?.id ? User.id : 0,
          patient_name: values?.name,
          patient_age: age,
          patient_gender: values?.gender,
          patient_phone: phoneNumber,
          reg_phone: phoneNumber,
          contact_phone: phoneNumber,
          // address: "",
          // latitude: "",
          // longitude: "",
          medic_id: alldata?.medic_id,
          mode: "medical",
          total_mrp: selecteddoctor?.consult_fee,
          schedule_time: timelist,
          schedule_date: formattedDates1,
          wait_time: alldata?.wait_time,
          total_savings: 0,
          total_payable: selecteddoctor?.consult_fee,
          total_discount: 0,
          additional_discount: 0,
          applied_coupon: "",
        },
        consultation_payment: {
          mode: "COD",
          mode_type: "Pay at clinic",
          total_amount: selecteddoctor?.consult_fee,
        },
      };

      const existingUser = User;

      if (!existingUser?.phone) {
        payload.patient = {
          phone_code: phoneCode,
          phone: values.phone,
          name: values.name,
          email: values.email,
          gender: values.gender,
          dob: formattedDate,
        };
      }
      console.log("onSubmitpayload", payload);
      props.createbooking(payload);
      setUser("");
      setDiscount("");
      setOnClearCount((prev) => prev + 1);
    } else if (values?.service === "Surgery Consultation") {
      const payload =
        // {
        //   surgery_booking: {
        //     health_vendor_id: 2,
        //     centre_address: "39, 7th Cross St, Rajalakshmi Nagar, Velachery, Chennai, Tamil Nadu 600042",
        //     medic_id: 2,
        //     centre_name: "Test Hospital, Velachery",
        //     centre_phone: "8088321887",
        //     centre_latitude: "12.9791133",
        //     centre_longitude: "80.2022623",
        //     patient_id:12,
        //     patient_name: "Suresh",
        //     patient_age: "24",
        //     patient_phone: "+91-9080928402",
        //     patient_gender: "Male",

        //     surgery_id: 2,
        //     schedule_date: "27/06/2024",
        //     total_mrp: 1,
        //     schedule_time: "11:00 AM",
        //     mode: "medical",
        //     wait_time: "20 mins",
        //     total_payable: 1,
        //     vendor_country: "India",
        //     health_centre_id: 2,
        //     total_savings: 0,
        //     applied_coupon: "",
        //     additional_discount: 0,
        //     total_discount: 0
        //   },
        //   surgery_payment: {
        //     total_amount: 1,
        //     mode: "COD",
        //     mode_type: "Pay at the time of Visit"
        //   }
        // }
        {
          surgery_booking: {
            health_vendor_id: alldata?.health_vendor_id,
            health_centre_id: alldata?.health_centre_id,
            medic_id: alldata?.medic_id,
            surgery_id: matchedHospital?.id,
            mode: "medical",
            centre_address: selectedbranch?.address,
            centre_name: selectedbranch?.name,
            centre_phone: alldata?.centre_phone,
            centre_latitude: selectedbranch?.latitude,
            centre_longitude: selectedbranch?.longitude,
            patient_id: User?.id ? User.id : "",
            patient_name: values?.name,
            patient_age: age ? age : "",
            patient_phone: phoneNumber,
            patient_gender: values?.gender,
            reg_phone: phoneNumber,
            contact_phone: phoneNumber,
            // address: "",
            // latitude: "",
            // longitude: "",
            vendor_country: "India",

            schedule_date: formattedDates1,
            schedule_time: timelist,
            wait_time: alldata?.wait_time,

            total_mrp: collectedfeesurgery,
            total_savings: 0,
            total_payable: collectedfeesurgery,
            applied_coupon: "",
            additional_discount: 0,
            total_discount: 0,
          },
          surgery_payment: {
            total_amount: collectedfeesurgery,
            mode: "COD",
            mode_type: "Pay at the time of Visit",
          },
        };
      const existingUser = User;

      if (!existingUser?.phone) {
        payload.patient = {
          phone_code: phoneCode,
          phone: values?.phone,
          name: values?.name,
          email: values?.email,
          gender: values?.gender,
          dob: formattedDate,
        };
      }
      console.log("onSubmitpayload2", payload);
      props
        .createbookingsurgery(payload)
        .then((data) => {
          console.log("onSubmitpayload", data);
          const bookingId = data?.value?.data?.id;
          console.log("Booking ID:", bookingId);

         
        // Create a new payload for the addcasefiles API call
        const caseFilesPayload = {
          assets: [picUrl]
        };
        console.log("AddcaseFilesPayload:", caseFilesPayload);
    
        // Call the addcasefiles API with the bookingId if caseFilesPayload.assets is not empty
        if (caseFilesPayload.assets.length > 0 && caseFilesPayload.assets[0].length > 0) {
          return props.addcasefilesAsset({ payload: caseFilesPayload, id: bookingId });
        } else {
          throw new Error("caseFilesPayload.assets is empty");
        }
      })
      .then((response) => {
        console.log("Case files added successfully:", response);
        // Handle the success of addcasefiles API
      })
      .catch((error) => {
        console.error("Error creating booking or adding case files:", error);
      });
      setUser("");
      setOnClearCount((prev) => prev + 1);
      setALLData("");
      setDiscount("");
    } else if (values?.service === "Medical Tourism") {
      const payload = {
        abroad_booking: {
          surgery_id: matchedHospital?.package_id,
          medic_id: selecteddoctor?.id,
          health_centre_id: alldata?.centre_details?.hospital_id,
          health_vendor_id: alldata?.centre_details?.health_vendor_id,
          schedule_date: formattedDates1,
          schedule_time: timelist,
          centre_name: selectedbranch?.name,
          centre_phone: alldata?.centre_details?.centre_phone,
          centre_address: selectedbranch?.address,
          centre_latitude: selectedbranch?.latitude,
          centre_longitude: selectedbranch?.longitude,
          vendor_country: "India",
          patient_name: values?.name,
          patient_id: User?.id ? User.id : "",
          patient_age: age ? age : "",
          patient_gender: values?.gender,
          patient_phone: phoneNumber,
          reg_phone: phoneNumber,
          contact_phone: phoneNumber,
          // address: "",
          // latitude: "",
          // longitude: "",
          ailment_desc: desc,
          total_payable: alldata?.package_details?.total_payable,
          total_savings: alldata?.package_details?.total_savings,
          total_mrp: alldata?.package_details?.consult_fee,
          total_discount: alldata?.package_details?.discount,
          additional_discount: 0,
          case_files: picUrl,
        },
        abroad_payment: {
          total_amount: alldata?.package_details?.total_payable,
          mode: "COD",
          mode_type: "Pay at the time of Visit",
          // payment_method: "Pay at the time of Visit",
          // payment_mode: "cod",
          // // payment_id: "pay_OO6vNcVxv5brqK",
          // payment_id: "pay_OO6vNcVxv5brqQ",
        },
      };
      const existingUser = User;

      if (!existingUser?.phone) {
        payload.patient = {
          phone_code: phoneCode,
          phone: values.phone,
          name: values.name,
          email: values.email,
          gender: values.gender,
          dob: formattedDate,
        };
      }
      console.log("onSubmitpayload3", payload);

      props.createbookingtourism(payload)
      .then((data) => {
        console.log("onSubmitpayload", data);
        const bookingId = data?.value?.data?.id;
        console.log("Booking ID:", bookingId);
    
        // Create a new payload for the addcasefiles API call
        const caseFilesPayload = {
          assets: [picUrl]
        };
        console.log("AddcaseFilesPayload:", caseFilesPayload);
    
        // Call the addcasefiles API with the bookingId if caseFilesPayload.assets is not empty
        if (caseFilesPayload.assets.length > 0 && caseFilesPayload.assets[0].length > 0) {
          return props.addcasefilesAsset({ payload: caseFilesPayload, id: bookingId });
        } else {
          throw new Error("caseFilesPayload.assets is empty");
        }
      })
      .then((response) => {
        console.log("Case files added successfully:", response);
        // Handle the success of addcasefiles API
      })
      .catch((error) => {
        console.error("Error creating booking or adding case files:", error);
      });
    
      setUser("");
      setOnClearCount((prev) => prev + 1);
      setALLData("");
      setDiscount("");
    } else {
      const address = handleSaveAddress(values);
      console.log("address", address);
      const isAddress =
        typeof address?.location === "string" && address?.location?.length;
      const isNewAddress =
        typeof values.address === "string" && values.address?.length;
      console.log("addressdest", isAddress);

      if (isAddress || isNewAddress) {
        const addressObj = addressOption?.find(
          (each) => each.location === values?.address
        );

        // Regular expression to detect addresses inside India
        const indianAddressRegex =
          /India|IN|Pin\s*Code\s*\d{6}|Tamil Nadu|Karnataka|Maharashtra|Kerala|Gujarat|Delhi|Uttar Pradesh|Bihar|Rajasthan|Madhya Pradesh|Punjab|Haryana|West Bengal|Andhra Pradesh|Telangana|Odisha|Assam|Jharkhand|Chhattisgarh|Uttarakhand|Himachal Pradesh|Tripura|Meghalaya|Manipur|Nagaland|Goa|Arunachal Pradesh|Mizoram|Sikkim|Dadra and Nagar Haveli and Daman and Diu|Lakshadweep|Puducherry|Chandigarh|Andaman and Nicobar Islands|Jammu and Kashmir|Ladakh/;

        // Validate if address is inside India
        const isAddressInIndia = indianAddressRegex.test(
          address?.location || values?.address
        );

        if (address?.location || values?.address) {
          if (isAddressInIndia) {
            const payload = {
              emergency_booking: {
                health_centre_id: selectedbranch?.id,
                emergency_type: emertype,
                emergency_notes: notes,
                patient_name: values?.name,
                patient_age: age ? age : "",
                patient_id: User?.id,
                patient_gender: values?.gender,
                reg_phone: phoneNumber,
                contact_phone: phoneNumber,
                address: address?.location
                  ? address?.location
                  : values?.address, // Include address if provided
                latitude: addressObj?.latitude
                  ? addressObj.latitude
                  : address.latitude,
                longitude: addressObj?.longitude
                  ? addressObj.longitude
                  : address.longitude,
                centre_address: selectedbranch?.address,
                centre_name: selectedbranch?.name,
                centre_phone: selectedbranch?.phone
                  ? selectedbranch?.phone
                  : "",
                centre_latitude: selectedbranch?.latitude,
                centre_longitude: selectedbranch?.longitude,
              },
              address,
            };
            const existingUser = User;

            if (!existingUser?.phone) {
              payload.patient = {
                phone_code: phoneCode,
                phone: values.phone,
                name: values.name,
                email: values.email,
                gender: values.gender,
                dob: formattedDate,
              };
            }
            console.log("onSubmitpayloadww", payload);
            props.createbookingEmegrency(payload);
            setUser("");
            setOnClearCount((prev) => prev + 1);
            setAddresssaved("");
            setSelectedPlace(null);
          } else {
            message.error("Service not available for this address.");
          }
        } else {
          message.error("Address cannot be empty");
        }
      }
    }

    // setSelectedDates([]);
  };

  const otherFieldInput =
    addressType === "Others"
      ? {
          key: "otherInput",
          name: "otherInput",
          label: "Others specify",
          width: 12,
          Component: (
            <Input
              placeholder="Specify Address Type"
              size="large"
              value={otherAddressType}
              onChange={(e) => setOtherAddressType(e.target.value)}
            />
          ),
        }
      : {};

  const NewAddressFields = isAddAddress
    ? [
        {
          key: "newAddress",
          name: "newAddress",
          width: 24, // Adjust the width according to your layout
          Component: (
            <Form.Item
              label="New Address"
              name="newAddress"
              rules={[{ required: true, message: "New address is required!" }]}
            >
              <div style={{ width: "100%", height: "40px" }}>
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyAxjwom-B5LHIh_8MYyq61_lyCqCBohz6Y"
                  onPlaceSelected={(place) => {
                    console.log("Selected Place:", place);
                    if (place && place.geometry && place.geometry.location) {
                      const lat = place.geometry.location.lat();
                      const lng = place.geometry.location.lng();
                      setLat(lat);
                      setLong(lng);
                      console.log("Latitude:", lat, "Longitude:", lng);
                    } else {
                      console.error("Invalid place object:", place);
                    }
                    setSelectedPlace(place);
                  }}
                  options={{
                    types: ["establishment", "geocode"], // Removing componentRestrictions
                  }}
                  style={{ width: "100%", height: "40px" }}
                />
              </div>
            </Form.Item>
          ),
        },
        {
          key: "houseNumber",
          name: "houseNumber",
          label: "House No/Flat/Building No",
          width: 12,
          rules: [{ required: true, message: "House No is required!" }],
          Component: (
            <Input
              autoComplete="off"
              placeholder="Enter House No/Flat/Building No"
              size="large"
              value={houseNumber}
              onChange={(e) => setHouseNumber(e.target.value)}
            />
          ),
        },
        {
          key: selectedPlace ? "map" : null,
          name: "map",
          Component: selectedPlace && (
            <div
              style={{
                width: "100%",
                height: "300px",
                marginBottom: "30px",
              }}
            >
              <GoogleMapComponent
                location={{ lat: latitude, lng: longitude }}
              />
            </div>
          ),
        },
        {
          key: "landmark",
          name: "landmark",
          label: "Landmark",
          width: 12,
          rules: [{ required: true, message: "Landmark is required!" }],

          Component: (
            <Input
              autoComplete="off"
              placeholder="Enter Landmark"
              size="large"
              value={landmark}
              onChange={(e) => setLandmark(e.target.value)}
            />
          ),
        },
        {
          key: "mobileNumber",
          name: "mobileNumber",
          label: "Mobile Number",
          width: 12,
          rules: [
            { required: true, message: "Phone number is required!" },
            {
              pattern: /^[0-9]{10}$/,
              message: "Phone number must be 10 digits!",
            },
          ],
          Component: (
            <Input
              autoComplete="off"
              placeholder="Enter Mobile Number"
              size="large"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          ),
        },

        {
          key: "addressType",
          name: "addressType",
          label: "Address Type",
          width: 12,
          rules: [{ required: true, message: "AddressType is required!" }],
          Component: (
            // <>
            <Select
              placeholder="Select Address Type"
              size="large"
              onChange={handleAddressTypeChange}
              value={addressType} // Ensure addressType is initialized correctly
            >
              {addressOption?.find(
                (each) => each.category === "Home"
              ) ? null : (
                <Option value="Home">Home</Option>
              )}
              <Option value="Work">Work</Option>
              <Option value="Others">Others</Option>
            </Select>
            // {/* Additional field for specifying address type if "Others" is selected */}
            // {addressType === "Others" && (
            // <Form.Item label="Please specify">
            // <Input placeholder="Specify Address Type" size="large" value={otherAddressType} onChange={(e) => setOtherAddressType(e.target.value)} />
            // </Form.Item>
            // )}
            // </>
          ),
        },
        otherFieldInput,
      ]
    : [];

  return (
    <Spin spinning={props.Loading === true}>
      {/* Patient Details Section */}
      <Row gutter={[0, 16]}></Row>
      <ListForm
        addressOption={addressOption}
        addressType={addressType}
        allcategory={allcategory}
        onSubmit={onSubmit}
        selectedService={selectedService}
        User={User}
        totalTourism={totalTourism}
        medicaldiscount={medicaldiscount}
        discount={discount}
        collectedfee={collectedfee}
        collectedfeesurgery={collectedfeesurgery}
        selectedDates={selectedDates}
        selectedbranch={selectedbranch}
        formItems={[
          {
            heading: "Branch Details",
          },
          {
            key: "branch_id",
            label: "Select branch",
            width: 12,
            rules: [{ required: true, message: " Branch is required!" }],
            Component: (
              <Select
                size="large"
                placeholder="Select Branch"
                className="ant-input ant-input-lg"
                onChange={handleBranchChange}
              >
                {branches?.map((option) => (
                  <Option key={option?.id} value={option?.id}>
                    {option?.name}
                  </Option>
                ))}
              </Select>
            ),
          },
          {
            heading: "Patient Details",
          },
          {
            key: "contact_phone",
            label: "Customer Phone Number",
            name: "phone",
            width: 12,
            rules: [{ required: true, message: "Phone number is required!" }],
            Component: (
              <Input.Group
                compact
                validateTrigger={["onBlur", "onChange"]}
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.phone !== currentValues.phone
                }
                style={{ width: "100%" }}
              >
                <Select
                  value={phoneCode}
                  size="large"
                  onSelect={(value) => setPhoneCode(value)}
                  style={{ width: "25%" }}
                >
                  {code?.map((program) => (
                    <Option key={program?.id} value={program?.id}>
                      {program?.phone_code}
                    </Option>
                  ))}
                  {/* Add more options as needed */}
                </Select>
                <Form.Item
                  name="phone"
                  rules={[
                    // { required: true, message: "Phone number is required!" },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Phone number must be 10 digits!",
                    },
                  ]}
                  noStyle
                >
                  <AutoComplete
                    className="autoCompletewidth"
                    size="large"
                    options={phoneNumberList?.map((phoneObj, index) => ({
                      value: phoneObj.phone,
                      label: phoneObj.phone,
                      key: index.toString(),
                    }))}
                    onSelect={handleOnSelect}
                    onSearch={handleOnSearch}
                    placeholder="Enter phone number"
                    style={{ width: "75%" }}
                  />
                </Form.Item>
              </Input.Group>
            ),
          },
          {
            key: "name",
            name: "name",
            label: "Name",
            width: 12,
            rules: [{ required: true, message: "User's name is required!" }],
            Component: (
              <Input
                size="large"
                value={name1}
                placeholder="Enter name"
                onChange={handleNameChange}
              />
            ),
          },
          {
            label: "Date of Birth",
            key: "dob",
            rules: [
              { required: true, message: " Select Date of Birth is required!" },
            ],
            Component: (
              <DatePicker
                placeholder="Enter Date of birth"
                value={dob}
                onChange={handleDobChange}
                maxDate={maxDate1}
                currentDate={new DateObject().set({
                  year: today.getFullYear() - 15,
                  format: "DD/MM/YYYY",
                })}
                className="ant-input ant-input-lg"
                style={{ width: "100%" }}
              />
            ),
          },
          {
            key: "gender",
            label: "Patient Gender",
            name: "gender",
            width: 12,
            rules: [{ required: true, message: "User's gender is required!" }],
            Component: (
              <Select
                style={{ width: "100%", color: "#5a5959" }} // Custom style for the Select component
                size="large"
                name="gender"
                onChange={(e) => {
                  handleGenderChange(e);
                }}
              >
                <Option value="" disabled>
                  Select gender
                </Option>
                <Option value={"Male"}>Male</Option>
                <Option value={"Female"}>Female</Option>
              </Select>
            ),
          },
          {
            key: "email",
            label: "Email ID",
            name: "email",
            width: 12,
            // rules: [{ required: true, message: "  User's email !" }],
            Component: (
              <Input
                size="large"
                onChange={handleEmailChange}
                placeholder="Enter email"
              />
            ),
          },
          {
            heading: "Booking Details",
          },
          {
            key: "service",
            label: "Service Type",
            width: 12,
            rules: [{ required: true, message: "User's service is required!" }],
            Component: (
              <Select
                showSearch
                placeholder="Select service"
                size="large"
                onChange={handleServiceChange}
              >
                {services.map((service) => (
                  <Option key={service.value} value={service.value}>
                    {service.label}
                  </Option>
                ))}
              </Select>
            ),
          },
          {
            key: "category",
            label: "Category",
            width: 12,
            rules: [
              { required: true, message: "User's category is required! " },
            ],
            Component: (
              <Select
                showSearch
                placeholder="Select category"
                size="large"
                onChange={handlecategorychange}
              >
                {consultcategory.map((option) => (
                  <Option key={option.name} value={option.id}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            ),
          },
          {
            key: "subcatgeory",
            label: "Sub Catgeory ",
            width: 12,
            Component: (
              <Select
                showSearch
                placeholder="Select subcatgeory"
                size="large"
                // onChange={handlesubcategory}
              >
                {subcatgeory?.map((product) => (
                  <Option key={product?.id} value={product?.name}>
                    {product?.name}
                  </Option>
                ))}
              </Select>
            ),
          },
          {
            key: "progarm",
            label: "Program",
            width: 12,
            rules: [
              { required: true, message: "User's program is required! " },
            ],
            Component: (
              <Select
                showSearch
                placeholder="Select progarm"
                size="large"
                onChange={handlesearchcategory}
              >
                {searchcategory?.map((product) => (
                  <Option key={product?.name} value={product?.name}>
                    {product?.name}
                  </Option>
                ))}
              </Select>
            ),
          },
          {
            key: "Doctor",
            label: "Doctor",
            width: 12,
            rules: [{ required: true, message: "User's doctor is required! " }],
            Component: (
              <Select
                showSearch
                placeholder="Select Doctor"
                size="large"
                onChange={handledoctor}
              >
                {doctorlist?.map((product) => (
                  <Option key={product?.name} value={product?.id}>
                    {product?.name}
                  </Option>
                ))}
              </Select>
            ),
          },
          {
            key: "Booking_date",
            label: "Select Date",
            width: 12,
            rules: [{ required: true, message: "Select Date's is required!" }],
            Component: (
              <DatePicker
                className="ant-input ant-input-lg"
                style={{ width: "100%" }} // Adjust the width value as needed
                onChange={handledates}
                minDate={today}
                maxDate={maxDate}
                format="DD/MM/YYYY"
                // plugins={[<DatePanel />]}
              />
            ),
          },
          {
            key: "PreferedTime",
            label: "Prefered Time",
            width: 12,
            rules: [{ required: true, message: " Time is required!" }],
            Component: (
              <Select
                size="large"
                placeholder="Select time"
                className="ant-input ant-input-lg"
                onChange={handleTime}
              >
                {selectedService === "medical" ||
                selectedService === "Surgery Consultation"
                  ? timeListOptions.map((option) => (
                      <Option key={option} value={option}>
                        {option}
                      </Option>
                    ))
                  : selectedService === "Medical Tourism"
                  ? timelistm?.map((option) => (
                      <Option key={option} value={option}>
                        {option}
                      </Option>
                    ))
                  : null}
              </Select>
            ),
          },
          // {
          //   placeholder: "ID proof",
          //   key: "id_proof_url",
          //   label: "ID proof",
          //   getValueFromEvent,
          //   editComponent: (
          //     <AvatarUploader
          //       uploadBucketName={VENDOR_MEDICAL_CASEFILES}
          //       uploadSuccess={(e) => docUploadSuccess(e, "id_proof_url")}
          //     />
          //   ),
          // },

          // {
          //   key: "payment",
          //   label: "payment Mode",
          //   width: 12,
          //   rules: [
          //     { required: true, message: "Select payment's is required!" },
          //   ],
          //   Component: (
          //     <Select showSearch size="large">
          //       <Option value="COD">Pay at the time of Visit</Option>
          //     </Select>
          //   ),
          // },
          {
            key: "Description",
            label: "Description",
            name: "Description",
            width: 12,
            Component: (
              <Input
                size="large"
                onChange={handleMedNotes}
                placeholder="Enter notes"
                maxLength={250} // Restrict to 250 characters
              />
            ),
          },
          {
            key: "casefile_url",
            label: "CASE FILE",
            width: 12,
            getValueFromEvent,
            Component: (
              <AvatarUploader
                uploadBucketName={VENDOR_MEDICAL_CASEFILES}
                uploadSuccess={(e) => docUploadSuccess(e, "casefile_url")}
              />
            ),
          },

          // {
          //   key: "paymenttype",
          //   label: "Payment Mode",
          //   width: 12,
          //   rules: [
          //     { required: true, message: "Select payment's is required!" },
          //   ],
          //   Component: (
          //     <Select showSearch size="large">
          //       <Option value="COD">Cash on delivery</Option>
          //     </Select>
          //   ),
          // },
          {
            key: "Emergencytype",
            label: "Emergency Type",
            width: 12,
            rules: [
              {
                required: true,
                message: "User's Emergency Type is required! ",
              },
            ],
            Component: (
              <Select
                showSearch
                placeholder="Select Emergency Type"
                size="large"
                onChange={handleemergency}
              >
                {Emergency?.map((product) => (
                  <Option key={product} value={product}>
                    {product}
                  </Option>
                ))}
              </Select>
            ),
          },
          {
            key: "Notes",
            label: "Emergency Notes",
            name: "Notes",
            width: 12,
            Component: (
              <Input
                size="large"
                onChange={handleEmeNotes}
                placeholder="Enter notes"
                maxLength={250} // Restrict to 250 characters
              />
            ),
          },
          {
            heading: "Address Details",
          },
          {
            key: "address",
            label: "Address",
            width: 12,
            rules: [
              { required: !isAddAddress, message: "Address is required!" },
            ],
            Component: (
              <Select
                placeholder="Select address"
                size="large "
                className="ant-select-lg"
                name="address"
                onChange={(e) => {
                  handleAddressSelect(e);
                }}
                value={addresssaved}
              >
                {addressOption &&
                  addressOption.map((val) => (
                    <Option key={val.id} value={val.location}>
                      {val.location}
                    </Option>
                  ))}
              </Select>
            ),
          },
          {
            name: "button",
            Component: (
              <Button
                onClick={() => {
                  setIsAddAddress((prev) => !prev);
                  setAddresssaved("");
                  setUser(User);
                }}
              >
                {isAddAddress ? "Close" : "Add New Address"}

                {/* {isAddAddress  ? "true" : "false"} */}
              </Button>
            ),
          },
          ...NewAddressFields,
        ]}
        onAddressSaved={handleAddressSaved} // Pass the handleAddressSaved function
        addressData={addresssaved}
        key={`${onClearCount}`}
      />
    </Spin>
  );
}
export default BookForm;
