import React, { useState } from "react";
import { Form, Row, Col, Input, Button, Typography, Select } from "antd";
import { useEffect } from "react";
import { getNextFiveDays } from "../Booking/booking";
const { Option } = Select;
const { Title } = Typography;
const ListForm = ({
  formItems = [],
  onSubmit,
  user,
  addressOption,
  onAddressSaved,
  discounts,
  MRP1,
  total_payable,
  formattedDates,
  addressData,
  quantity,
  finalprogram,
  allcategory,
  service,
  categorybased,
  collectionFees,
}) => {
  const [form] = Form.useForm();
  const [showNewAddressForm, setShowNewAddressForm] = useState(false);
  const [addressType, setAddressType] = useState("");
  const [address, setAddress] = useState("");
  const [anu ,setAnu] = useState(0);
  const handleSaveAddress = (address) => {
    setAddress(address);
    // onSubmit(formValues, address); // Call onSubmit and pass both form values and address
  };
  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onSubmit({ ...values }, address);
      })
      .catch((errorInfo) => {});
  };

  useEffect(() => {
    if (addressOption) {
      form.setFieldsValue({
        name: user?.name || "",
        email: user?.email || "",
        dob: user?.dob || "",
        gender: user?.gender,
        address: addressData || addressOption,
        mobileNumber: user?.phone || "",
      });
      // form.validateFields(['name', 'dob', 'gender']);
    } else if (user) {
      form.setFieldsValue({
        name: user?.name || "",
        email: user?.email || "",
        address: addressData || "",
        mobileNumber: user?.phone || "",
      });
      form.resetFields(["name", "phone"]);
    }
  }, [user, addressOption, addressData]);

  useEffect(() => {
    const defaultDates = getNextFiveDays();
    form.setFieldValue("dates", defaultDates);
  }, []);
  useEffect(() => {
    if (addressType) {
      form.setFieldValue("addressType", addressType);
    }
  }, [addressType]);
  // useEffect(() => {
  //   console.log("quantity changes",quantity)
  //   if (quantity > 0) {
  //     console.log("ssssss",quantity)
  //     form.setFieldValue("quantity", quantity);
  //   }
  // }, [quantity]);

  useEffect(() => {
    form.setFieldValue("category", "");
    form.setFieldValue("product", null);
    form.setFieldValue("subcatgeory", null);
    form.setFieldValue("quantity",anu);
  }, [service]);
  useEffect(() => {
    form.setFieldValue("product", null);
    form.setFieldValue("subcatgeory", null);
    form.setFieldValue("quantity",0);

  }, [allcategory]);

  // console.log("getFieldsValue", form.getFieldsValue());

  return (
    <Form className="booking-form" form={form} layout="vertical">
      <Col xs={24} md={16} lg={16}>
        {" "}
        <Row gutter={24}>
          {formItems.map(({ Component, ...formItem }) => {
            if (formItem?.heading) {
              return (
                <Col
                  className="gutter-row section-heading"
                  xs={24}
                  md={24}
                  lg={24}
                >
                  <Title
                    level={2}
                    className="d-flex"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {formItem?.heading}
                  </Title>
                </Col>
              );
            }

            if (formItem?.name == "button") {
              return (
                <Col xs={24} md={12} lg={12} key={formItem?.name}>
                  <div className="addressFormBtn">{Component}</div>
                </Col>
              );
            }
            if (!formItem?.key) {
              return;
            }

            if (formItem.key === "map") {
              return (
                <Col xs={24} md={24} lg={24} key={formItem.key}>
                  {Component || null}
                </Col>
              );
            }
            return (
              <Col xs={24} md={12} lg={12} key={formItem.key}>
                <Form.Item
                  name={formItem.key}
                  {...formItem}
                  label={formItem.label}
                >
                  {Component || (
                    <Input placeholder={formItem.label} size="large" />
                  )}
                </Form.Item>
              </Col>
            );
          })}
        </Row>
        <div className="adddress_detailslist">
          <label htmlFor="fare-calculation">
            Collection Fees: {collectionFees ? collectionFees : ""}
          </label>
        </div>
        <div className="adddress_detailslist">
          <label htmlFor="fare-calculation">
            No of Days / Quantity : {quantity ? quantity : ""}
          </label>
        </div>
        <div className="adddress_detailslist">
          <label htmlFor="fare-calculation">
            Total MRP : {MRP1 ? MRP1 : ""}
          </label>
        </div>
        <div className="adddress_detailslist">
          <label htmlFor="fare-calculation">
            Total Payable : {total_payable >= 0 ? total_payable : ""}{" "}
          </label>
        </div>
        <div className="adddress_detailslist">
          <label htmlFor="fare-calculation">
            Total Savings:{discounts >= 0 ? discounts : ""}{" "}
          </label>
        </div>
        <Row justify="end">
          <Col>
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Col>
        </Row>
      </Col>
    </Form>
  );
};

export default ListForm;
