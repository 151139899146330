import * as actionType from '../actionType';
import * as homeHealthcareBookingApi from '../../api/homeHealthcareVendor/booking';

export const getAllHomeHealthcareBookings = (queryString,pageSize,currentPage) => ({
  type: actionType.HOME_HEALTHCARE_GET_ALL_BOOKINGS,
  payload: homeHealthcareBookingApi.getAllHomeHealthcareBookings(queryString,pageSize,currentPage),
});

export const cancelHomeHealthcareBooking = (params) => ({
  type: actionType.HOME_HEALTHCARE_CANCEL_BOOKING,
  payload: homeHealthcareBookingApi.cancelHomeHealthcareBooking(params),
});

export const getSingleHomeHealthcareBooking = (params) => ({
  type: actionType.HOME_HEALTHCARE_GET_SINGLE_BOOKING,
  payload: homeHealthcareBookingApi.getSingleHomeHealthcareBooking(params),
});
export const getAllHomeHealthcareBookingCaregivers = (params) => ({
  type: actionType.HOME_HEALTHCARE_GET_ALL_BOOKING_CAREGIVERS,
  payload: homeHealthcareBookingApi.getAllHomeHealthcareBookingCaregivers(params),
});
export const confirmHomeHealthcareBooking = (params) => ({
  type: actionType.HOME_HEALTHCARE_CONFIRM_BOOKING,
  payload: homeHealthcareBookingApi.confirmHomeHealthcareBooking(params),
});

export const changeHomeHealthcareBookingCareGiver = (params) => ({
  type: actionType.HOME_HEALTHCARE_CHANGE_BOOKING_CAREGIVER,
  payload: homeHealthcareBookingApi.changeHomeHealthcareBookingCareGiver(params),
});

export const getSessionCareGiverProfile = (params) => ({
  type: actionType.GET_SESSION_CAREGIVER_PROFILE,
  payload: homeHealthcareBookingApi.getSessionCareGiverProfile(params),
});

export const getAllHomeHealthcareBookingCase = (params) => ({
  type: actionType.HOME_HEALTHCARE_GET_ALL_BOOKING_CASE,
  payload: homeHealthcareBookingApi.getAllHomeHealthcareBookingCase(params),
});

export const getAllUser = (params) => ({
  type: actionType.ALL_USER,
  payload: homeHealthcareBookingApi.filterUser(params),
});

export const saveAddress =(params) => ({
  type:actionType.SAVE_ADDRESS,
  payload: homeHealthcareBookingApi.saveAddress(params),
});
export const saveData =(params) => ({
  type:actionType.SAVE_DATA,
  payload: homeHealthcareBookingApi.saveData(params),
});
export const saveLabData =(params) => ({
  type:actionType.VENDOR_LAB_BOOKING,
  payload: homeHealthcareBookingApi.saveLabData(params),
});
export const saveLabData1 =(params) => ({
  type:actionType.VENDOR_LAB_BOOKING1,
  payload: homeHealthcareBookingApi.saveLabData1(params),
});
export const saveLabData2 = (params) => ({
  type:actionType.VENDOR_LAB_BOOKING2,
  payload: homeHealthcareBookingApi.saveLabData2(params),
});
