import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Button,
  Typography,
  Select,
  Modal,
  Card,
  Image,
  DatePicker,
  Row,
  Col,
  Tooltip,
  message,
} from "antd";
import moment from "moment";
import { isEmpty } from "lodash";
import documentsIcon from "../../../assets/document.png";
import { SearchOutlined, SyncOutlined } from "@ant-design/icons";
import AdvancedSearchForm from "../../../common/SearchForm";
import PharmacyCompanyBranchInfoDrawer from "./components/CompanyBranchInfoDrawer";
import ConfimBookingButton from "./components/ConfirmBookingButton";
import SurgeryDetailsFormModal from "./components/SurgeryDetailsFormModal";

const { Title, Link, Text, Paragraph } = Typography;
const { Option } = Select;

const DocumentsComponent = (value) => {
  return (
    <Row className="case-file-section">
      {value.values.map((val) => (
        <Col
          span={4}
          justify="start"
          style={{ padding: "10", margin: "0 10px" }}
        >
          <Card>
            {val.url.match(/\.(pdf|doc|xls|xml)$/) != null ? (
              <Link href={val.url} target="_blank">
                <Image
                  preview={false}
                  src={documentsIcon}
                  style={{ marginRight: 6, width: "110px", height: "86px" }}
                  alt="case_files"
                />
              </Link>
            ) : (
              <Image
                style={{ width: "110px", height: "86px" }}
                alt="case_files"
                src={val.url}
              />
            )}
          </Card>
        </Col>
      ))}
    </Row>
  );
};

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div style={{ marginRight: "10px" }}>
      {showValue ? (
        <Text className="darkFont">{value}</Text>
      ) : (
        <Text className="darkFont" type="secondary">
          Not Provided
        </Text>
      )}
    </div>
  </Paragraph>
);

function AppointmentClinic(props) {
  const afterGetAllOrders = useSelector(
    (state) => state.medicalReducer.vendorMedicalSurgeryBookingsAll
  );

  //const [modalVisible, setModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [logsVisible, setLogsVisible] = useState(false);
  const [caseVisible, setCaseVisible] = useState(false);
  const [searchOption, setSearchOption] = useState(false);
  const [moreInfoModal, setMoreInfoModal] = useState(false);
  const [surgeryDetInfo, setSurgeryDetInfo] = useState(false);
  const [columnValue, setColumnValue] = useState("");
  const [surgeryDetails, setSurgeryDetails] = useState("");
  const [surgeryViewInfo, setSurgeryViewInfo] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  let today = new Date();
  const showLogsModal = (data) => {
    setLogsVisible(true);
    setCurrentColumn(data);
  };
  const showCaseModal = (data) => {
    setCaseVisible(true);
    setCurrentColumn(data);
  };

  const handleOk = () => {
    setLogsVisible(false);
    setCaseVisible(false);
    setCurrentColumn({});
  };

  const handleLogsCancel = () => {
    setLogsVisible(false);
    setCaseVisible(false);
    setCurrentColumn({});
  };

  const renderData = useCallback(
    (currentPage) => {
      props.getAllVendorMedicalAppointmentSurgeryOrders(
        undefined,
        20,
        currentPage
      );
    },
    [props.getAllVendorMedicalAppointmentSurgeryOrders]
  );

  const onSurgeryConfirmSubmit = async (payload) => {
    await props.vendorMedicalAppointmentConfirmSurgery(payload);
    await props.getAllVendorMedicalAppointmentSurgeryOrders(
      undefined,
      20,
      currentPage
    );
  };
  const onSurgeryCancelSubmit = async (payload) => {
    await props.vendorMedicalAppointmentCancelSurgery(payload);
    await props.getAllVendorMedicalAppointmentSurgeryOrders(
      undefined,
      20,
      currentPage
    );
  };
  const onSurgeryStartSubmit = async (payload) => {
    await props.vendorMedicalAppointmentStartSurgery(payload);
    await props.getAllVendorMedicalAppointmentSurgeryOrders(
      undefined,
      20,
      currentPage
    );
  };
  const onSurgeryCompleteSubmit = async (payload) => {
    await props.vendorMedicalAppointmentCompleteSurgery(payload);
    await props.getAllVendorMedicalAppointmentSurgeryOrders(
      undefined,
      20,
      currentPage
    );
  };

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  const handleRefresh = () => {
    props.getAllVendorMedicalAppointmentSurgeryOrders(
      undefined,
      20,
      currentPage
    );
    if (afterGetAllOrders && afterGetAllOrders) {
      message.success(afterGetAllOrders.message);
    }
  };

  const confirmHomeHealthcareBooking = async () => {
    // console.log("confirmHomeHealthcareBooking");
  };

  const columns = [
    {
      title: "Surgery",
      key: "booking_id",
      dataIndex: "booking_id",
      width: "35%",
      render: (_, columnData) => (
        <Row className="name-details-row">
          <Col span={14} offset={1}>
            <div className="table-fc-name">{columnData.centre_name}</div>
            <div className="table-fc-email">
              Booking ID: {columnData.booking_id}
            </div>
            <div className="table-fc-email">
              Date: {columnData.booking_date}
            </div>
            <div className="view-details">
              <Link
                onClick={() => {
                  setDescriptionModal(true);
                  setCurrentColumn(columnData);
                }}
              >
                VIEW DETAILS
              </Link>
            </div>
            {columnData.surgery_details_button === 1 ? (
              // <Button style={{marginTop: '10px'}} onClick={() => {setSurgeryDetInfo(true); setColumnValue(columnData)}} icon={<PlusOutlined />} type="primary">
              //   <span style={{fontWeight: 500}}>Surgery Details</span>
              // </Button>
              <div className="view-details">
                <div className="fw500">SURGERY DETAILS</div>
                <Link
                  onClick={() => {
                    setSurgeryViewInfo(true);
                    setColumnValue(columnData);
                  }}
                >
                  VIEW |
                </Link>
                <Link
                  onClick={() => {
                    setSurgeryDetInfo(true);
                    // setColumnValue(columnData);
                    setSurgeryDetails(columnData);
                  }}
                >
                  {" "}
                  EDIT
                </Link>
              </div>
            ) : null}
          </Col>
        </Row>
      ),
    },
    {
      title: "Patient Details",
      key: "patient_name",
      width: "20%",
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>{columnData.patient_name} </Col>
            <Col span={24} className="lightFont">
              {columnData.patient_age} / {columnData.patient_gender}
            </Col>
            <Col span={24} className="lightFont">
              {columnData.patient_phone}
            </Col>
          </Row>
          {columnData.case_files.length > 0 && (
            <div className="view-details">
              <Link onClick={() => showCaseModal(columnData)}>CASE FILES</Link>
            </div>
          )}
          {/* <div className="view-details">
            <Link onClick={() => showLogsModal(columnData)}>
              PRESCRIPTION
            </Link>
          </div> */}
          {columnData.offer_details !== "" ? (
            <div>
              <Tooltip
                className="view-details"
                placement="bottom"
                title={columnData.offer_details}
              >
                <Link>OFFER DETAILS</Link>
              </Tooltip>
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "Centre Details",
      key: "medic_name",
      width: "25%",
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>{columnData.medic_name} </Col>
          </Row>
          <Row>
            <Col span={24} className="lightFont">
              {columnData.medic_speciality}
            </Col>
          </Row>
          <Row>
            <Col span={24} className="lightFont" style={{ marginTop: "10px" }}>
              Schedule: {columnData.schedule_date}
            </Col>
          </Row>
          <Row>
            <Col span={24} className="lightFont">
              ({columnData.schedule_time})
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Status",
      key: "status_code",
      dataIndex: "status_code",
      width: "25%",
      render: (_, columnData) => (
        <Row>
          <Col span={24}>
            {columnData.status_code === 1 ? (
              <span className="booking-placed">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 2 ? (
              <span className="booking-confirmed">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 3 ? (
              <span className="booking-inprogress">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 4 ? (
              <span className="booking-completed">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 5 ? (
              <span className="booking-cancelled">{columnData.status}</span>
            ) : null}
          </Col>
          <Col span={24} className="fw500">
            Consult Fee: {columnData.currency}
            {columnData.consult_fee}
          </Col>
          {/* <Col span={24}>Payment{`  `}
            {columnData.payment_status === true ? (
              <span className="inActivateNotverified">Pending</span>
            ) : <span className="activate">Completed</span>}
          </Col> */}
          <Col span={24} className="fw500" style={{ marginBottom: "10px" }}>
            Payment Mode:{" "}
            <span className="lightFont">{columnData.payment_mode}</span>
          </Col>
          <Col span={24}>
            {columnData.surgery_outcome === 0 ? null : null}
            {columnData.surgery_outcome === 1 ? (
              <span className="booking-confirmed">
                {columnData.surgery_outcome_status}
              </span>
            ) : null}
            {columnData.surgery_outcome === 2 ? (
              <span className="booking-inprogress">
                {columnData.surgery_outcome_status}
              </span>
            ) : null}
            {columnData.surgery_outcome === 3 ? (
              <span className="booking-confirmed">
                {columnData.surgery_outcome_status}
              </span>
            ) : null}
          </Col>
          <Col span={24}>
            {columnData.surgery_status_code === 1 ? (
              <span className="booking-confirmed">
                {columnData.surgery_status}
              </span>
            ) : null}
            {columnData.surgery_status_code === 2 ? (
              <span className="booking-inprogress">
                {columnData.surgery_status}
              </span>
            ) : null}
            {columnData.surgery_status_code === 3 ? (
              <span className="booking-completed">
                {columnData.surgery_status}
              </span>
            ) : null}
            {columnData.surgery_status_code === 4 ? (
              <>
                <p className="booking-cancelled">{columnData.surgery_status}</p>
                <Link
                  onClick={() => {
                    setMoreInfoModal(true);
                    setColumnValue(columnData);
                  }}
                >
                  MORE INFO
                </Link>
              </>
            ) : null}
          </Col>
        </Row>
      ),
    },
    {
      title: "Action",
      key: "actions",
      render: (_, columnData) => (
        <>
          <ConfimBookingButton
            columnData={columnData}
            onSurgeryConfirmSubmit={onSurgeryConfirmSubmit}
            onSurgeryCancelSubmit={onSurgeryCancelSubmit}
            onSurgeryCompleteSubmit={onSurgeryCompleteSubmit}
            onSurgeryStartSubmit={onSurgeryStartSubmit}
          />
        </>
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAllVendorMedicalAppointmentSurgeryOrders(
      queryString,
      20,
      currentPage
    );
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };
  const onSurgeryDetailsSubmit = async (data) => {
    console.log("onSurgeryDetailsSubmit", surgeryDetails);
    const payloadObj = {
      id: surgeryDetails.surgery_details.surgery_booking_id,
      payload: {
        surgery_detail: {
          surgery_name: data.surgery_name,
          doctor_name: data.doctor_name,
          hospital_name: data.hospital_name,
          location: data.location,
          surgery_date: moment(data.surgery_date._d)
            .local()
            .format("DD/MM/YYYY"),
          total_cost: data.total_cost,
        },
      },
    };
    await props.vendorMedicalAppointmentSurgeryDetailEdit(payloadObj);
    await props.getAllVendorMedicalAppointmentSurgeryOrders(
      undefined,
      20,
      currentPage
    );
    setSurgeryDetails("");
    setSurgeryDetInfo(false);
  };

  // useEffect(() => {
  //   setSurgeryDetails(columnValue);
  // }, [surgeryDetInfo, columnValue]);
  return (
    <div>
      <Modal
        className="logs-modal"
        title="Prescription"
        footer={false}
        visible={logsVisible}
        onOk={handleOk}
        onCancel={handleLogsCancel}
      >
        <p>
          <span>Prescription : </span>
          {currentColumn.prescription && currentColumn.prescription.html}
        </p>
      </Modal>
      <Modal
        footer={false}
        visible={surgeryViewInfo}
        onOk={() => setSurgeryViewInfo(false)}
        onCancel={() => setSurgeryViewInfo(false)}
        width={600}
        title={`Surgery Details - ${columnValue && columnValue.booking_id}`}
        className="common-modal-form"
      >
        <div>
          <Row>
            <Col span={12}>
              <DescrtiptionItem
                title="Surgery Name"
                value={columnValue && columnValue.surgery_details.surgery_name}
              />
            </Col>
            <Col span={12}>
              <DescrtiptionItem
                title="Doctor Name"
                value={columnValue && columnValue.surgery_details.doctor_name}
              />
            </Col>
            <Col span={12}>
              <DescrtiptionItem
                title="Hospital Name"
                value={columnValue && columnValue.surgery_details.hospital_name}
              />
            </Col>
            <Col span={12}>
              <DescrtiptionItem
                title="Location"
                value={columnValue && columnValue.surgery_details.location}
              />
            </Col>
            <Col span={12}>
              <DescrtiptionItem
                title="Surgery Date"
                // value={moment(
                //   columnValue && columnValue.surgery_details.surgery_date
                // ).format("DD/MM/YYYY")}
                value={
                  (columnValue &&
                    columnValue.surgery_details.surgery_date === "") ||
                  (columnValue &&
                    columnValue.surgery_details.surgery_date === "-") ||
                  (columnValue &&
                    columnValue.surgery_details.surgery_date === null) ||
                  (columnValue &&
                    columnValue.surgery_details.surgery_date === undefined)
                    ? "-"
                    : moment(
                        columnValue && columnValue.surgery_details.surgery_date
                      ).format("DD/MM/YYYY")
                }
              />
            </Col>
            <Col span={12}>
              <DescrtiptionItem
                title="Total Cost"
                value={columnValue && columnValue.surgery_details.total_cost}
              />
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        footer={false}
        visible={moreInfoModal}
        onOk={() => setMoreInfoModal(false)}
        onCancel={() => setMoreInfoModal(false)}
        width={600}
        className="common-modal-form"
      >
        <div>
          <Text className="ant-modal-title">
            Order ID: {columnValue && columnValue.orderid}
          </Text>
          <div style={{ marginTop: "20px" }}>
            <span className="form-title">Reason for Cancellation</span>
            <p>{columnValue && columnValue.cancel_reason}</p>
            <span className="form-title">Notes for Cancellation</span>
            <p>{columnValue && columnValue.cancel_notes}</p>
          </div>
        </div>
      </Modal>
      {/* <Modal
        footer={false}
        visible={surgeryDetInfo}
        onOk={() => setSurgeryDetInfo(false)}
        onCancel={() => setSurgeryDetInfo(false)}
        width={450}
        title={`Surgery Details - ${columnValue && columnValue.surgery_booking_id}`}
        className="common-modal-form"
      >
        <div>   
          <Row>
            <Col span={12}>       
              <DescrtiptionItem title="Surgery Name" value={columnValue && columnValue.surgery_name} />
            </Col>
            <Col span={12}>       
              <DescrtiptionItem title="Doctor Name" value={columnValue && columnValue.doctor_name} />
            </Col>
            <Col span={12}>       
              <DescrtiptionItem title="Hospital Name" value={columnValue && columnValue.hospital_name} />
            </Col>
            <Col span={12}>       
              <DescrtiptionItem title="Location" value={columnValue && columnValue.location} />
            </Col>
            <Col span={12}>       
              <DescrtiptionItem title="Surgery Date" value={columnValue && columnValue.surgery_date} />
            </Col>
            <Col span={12}>       
              <DescrtiptionItem title="Total Cost" value={columnValue && columnValue.total_cost} />
            </Col>
          </Row>
        </div>
      </Modal> */}
      {surgeryDetails !== "" && (
        <SurgeryDetailsFormModal
          // data={columnValue && columnValue}
          data={{
            surgery_name:
              surgeryDetails && surgeryDetails.surgery_details.surgery_name,
            doctor_name:
              surgeryDetails && surgeryDetails.surgery_details.doctor_name,
            hospital_name:
              surgeryDetails && surgeryDetails.surgery_details.hospital_name,
            location: surgeryDetails && surgeryDetails.surgery_details.location,
            surgery_date:
              (surgeryDetails &&
                surgeryDetails.surgery_details.surgery_date === "") ||
              (surgeryDetails &&
                surgeryDetails.surgery_details.surgery_date === null)
                ? moment(today)
                : moment(
                    surgeryDetails &&
                      surgeryDetails.surgery_details.surgery_date
                  ),
            total_cost:
              surgeryDetails &&
              surgeryDetails.surgery_details.total_cost.toString(),
          }}
          // data={{
          //   surgery_name:
          //   columnValue && columnValue.surgery_details.surgery_name,
          //   doctor_name:
          //   columnValue && columnValue.surgery_details.doctor_name,
          //   hospital_name:
          //   columnValue && columnValue.surgery_details.hospital_name,
          //   location: columnValue && columnValue.surgery_details.location,
          //   surgery_date:
          //     (columnValue && columnValue.surgery_details.surgery_date === "") ||
          //     (columnValue && columnValue.surgery_details.surgery_date === null)
          //       ? moment(today)
          //       : moment(
          //         columnValue && columnValue.surgery_details.surgery_date
          //         ),
          //   total_cost:
          //   columnValue &&
          //     columnValue.surgery_details.total_cost.toString(),
          // }}
          onSubmit={onSurgeryDetailsSubmit}
          onCancel={() => {
            setSurgeryDetInfo(false);
            setSurgeryDetails("");
          }}
          visible={surgeryDetInfo}
          title={`Surgery Details - ${columnValue && columnValue.booking_id}`}
          submitText="UPDATE"
          width={450}
        />
      )}
      <Modal
        className="logs-modal"
        title="Case Files"
        footer={false}
        visible={caseVisible}
        onOk={handleOk}
        onCancel={handleLogsCancel}
        width={900}
      >
        {caseVisible && currentColumn.case_files.length > 0 ? (
          <DocumentsComponent values={currentColumn.case_files} />
        ) : (
          "No Case files"
        )}
      </Modal>
      <PharmacyCompanyBranchInfoDrawer
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        All Appointments - Surgery Consultation
      </Title> */}
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Surgery Consultation
          </Title>
        </Col>
        <Col className="gutter-row section-heading" span={3} offset={0}>
          <Title
              level={4}
              className="d-flex title"
              style={{ marginTop: '10px' }}
            >
              Total: {props.vendorMedicalSurgeryBookings?.total_count}
            </Title>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={2} offset={0}>
          <Button
            shape="circle"
            icon={<SyncOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => handleRefresh()}
          />
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Surgery Consultation"
          formItems={[
            {
              key: "q[bid_cont]",
              label: "Booking ID",
            },
            {
              key: "q[patient_name_cont]",
              label: "Patient name",
            },
            {
              key: "q[medic_name_cont]",
              label: "Doctor name",
            },
            {
              key: "q[health_centre_name_cont]",
              label: "Centre name",
            },
            {
              key: "q[status_code_eq]",
              label: "Status",
              Component: (
                <Select placeholder="Select Booking status" size="large">
                  <Option value="1">Booking Placed</Option>
                  <Option value="2">Booking Confirmed</Option>
                  <Option value="3">In Progress</Option>
                  <Option value="4">Booking Completed</Option>
                  <Option value="5">Booking Cancelled</Option>
                </Select>
              ),
            },
            {
              key: "q[surgery_confirm_status_eq]",
              label: "Surgery Status",
              Component: (
                <Select placeholder="Select Surgery status" size="large">
                  <Option value="1">Surgery is Required</Option>
                  <Option value="2">Surgery is not Required</Option>
                  <Option value="3">Follow-up Appoinment</Option>
                </Select>
              ),
            },
            {
              key: "q[created_at_gteq]",
              label: "From Date",
              Component: <DatePicker size="large" />,
            },
            {
              key: "q[created_at_lteq]",
              label: "To Date",
              Component: <DatePicker size="large" />,
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={
          props.vendorMedicalSurgeryBookings
            ? props.vendorMedicalSurgeryBookings.data
            : null
        }
        pagination={{
          pageSize: 20,
          total: props.vendorMedicalSurgeryBookings?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default AppointmentClinic;
