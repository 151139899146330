import React, { useEffect } from "react";
import { Select, Input, Dropdown, Menu, Checkbox, Button } from "antd";
import { isNaN, isInteger } from "lodash";
import TextArea from "antd/lib/input/TextArea";
import FormModal from "../../../../common/FormModal";
import { duration } from "../../../../common/variables/dropdownConstants";
import { useState } from "react";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";

const { Option } = Select;
const PackagesFormModal = ({ data = {}, ...props }) => {
  console.log("package data", data);
  console.log("package props", props);
  const [currentCity, setCurrentCity] = useState(null);
  const [regionData, setRegionData] = useState(null);
  // props.homeSupportedLocations?.find(each => each.name == currentCity??data?.city)?.regions

  const [selectAll, setSelectAll] = useState(false);
  const [regionValues, setRegionValues] = useState([]);

  useEffect(() => {
    let isCancelled = false;

    // if(data?.city){
    //   setRegionData(props.homeSupportedLocations?.find(each => each.name == data?.city)?.regions)
    //   }else{
    //     currentCity && setRegionData(props.homeSupportedLocations?.find(each => each.name == currentCity)?.regions)
    //   }

    if (!isCancelled) {
      if (currentCity) {
        setRegionData(
          props.homeSupportedLocations?.find((each) => each.name == currentCity)
            ?.regions
        );
      } else {
        data?.city &&
          setRegionData(
            props.homeSupportedLocations?.find(
              (each) => each.name == data?.city
            )?.regions
          );
      }
    }

    return () => {
      isCancelled = true;
    };
  }, [data, currentCity]);

  useEffect(() => {
    if (data?.region?.length) {
      setRegionValues(data?.region);
    }
  }, [data]);

  const handleSingleRegionSelect = (e, region) => {
    if (e.target.checked) {
      setRegionValues([...regionValues, region]);
    } else {
      const updatedLocations = regionValues.filter((item) => item !== region);
      setRegionValues(updatedLocations);
    }
  };

  const handleAllRegionSelect = (e, region) => {
    if (e.target.checked) {
      let allRegionValues = regionData?.map((each) => each.name);
      setRegionValues([...allRegionValues]);
    } else {
      setRegionValues([]);
    }
    setSelectAll(e.target.checked);
  };

  // useEffect(() => {
  // if(regionValues?.length &&JSON.stringify(regionValues) !== JSON.stringify(data?.region)) {
  //   setRegionSelectedValue(regionValues)
  // }
  // },[regionValues])
  const allRegionValues = regionData?.map((each) => each.name) ?? [];
  const handleSubmit = async (values) => {
    return await props.onSubmit({ ...values, region: ['selectAll',...allRegionValues]?.length === regionValues?.length ? allRegionValues:values?.region  });
  };

  return (
    <FormModal
      width={1000}
      data={{...data,region: regionValues}}
      // data={data}
      {...props}
      onSubmit={handleSubmit}
      formItems={[
        {
          key: "name",
          label: "Package Name",
          width: 8,
          rules: [{ required: true, message: "Please input Package's name!" }],
        },
        {
          key: "category",
          label: "Category",
          width: 8,
          rules: [{ required: true, message: "Please input Category!" }],
          Component: (
            <Select
              autoComplete="off"
              placeholder="Select Category"
              size="large"
            >
              {props.homeHealthCareServiceCategories &&
                props.homeHealthCareServiceCategories.map((val) => (
                  <Option key={val}>{val}</Option>
                ))}
            </Select>
          ),
        },
        {
          key: "duration",
          label: "Duration",
          width: 8,
          rules: [{ required: true, message: "Enter Duration!" }],
          Component: (
            <Select placeholder="Select Duration" size="large">
              {duration.map((val) => (
                <Option key={val}>{val}</Option>
              ))}
            </Select>
          ),
        },
        {
          key: "slot_per_day",
          label: "Slot Per Day",
          rules: [
            { required: true, message: "Please enter slot per day!" },
            () => ({
              validator(rule, value) {
                if (isNaN(Number(value))) {
                  return Promise.reject("Not a valid Number");
                }
                if (value && Number(value) <= 0) {
                  return Promise.reject("Slot count cannot be less than 1!");
                }
                if (value && !isInteger(Number(value))) {
                  return Promise.reject("Slot count has to be a whole number!");
                }
                if (Number(value) > 10) {
                  return Promise.reject("Cannot be more than 10");
                }
                return Promise.resolve();
              },
            }),
          ],
          width: 8,
        },
        {
          key: "mrp",
          label: "MRP",
          rules: [
            { required: true, message: "Please input MRP!" },
            () => ({
              validator(rule, value) {
                if (isNaN(Number(value))) {
                  return Promise.reject("Not a valid Number");
                }
                if (value && Number(value) <= 0) {
                  return Promise.reject("MRP cannot be less than 1!");
                }
                if (value && !isInteger(Number(value))) {
                  return Promise.reject("MRP has to be a whole number!");
                }
                if (Number(value) > 1000000) {
                  return Promise.reject("Cannot be more than 10Lakhs");
                }
                return Promise.resolve();
              },
            }),
          ],
          width: 8,
        },

        {
          key: "discount",
          label: "Discount %",
          dependencies: ["mrp"],
          rules: [
            { required: true, message: "Please enter Discount!" },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value && Number(value) !== 0 && !getFieldValue("mrp")) {
                  return Promise.reject("MRP not set!");
                }
                if (value && Number(value) < 0) {
                  return Promise.reject("Discount cannot be less than 0!");
                }
                if (isNaN(Number(value))) {
                  return Promise.reject("Not a valid Number");
                }
                if (Number(value) >= 100) {
                  return Promise.reject("Cannot be more than 100");
                }
                return Promise.resolve();
              },
            }),
          ],
          width: 8,
        },
        {
          key: "price",
          label: "Price",
          rules: [{ required: false, message: "Please input User's name!" }],
          width: 8,
          Component: <Input size="large" disabled />,
        },
        {
          key: "assessment_fee",
          label: "Assessment Fee",
          rules: [
            { required: true, message: "Please input Assessment fee!" },
            {
              pattern: new RegExp(/^[0-9\s]*$/),
              message: "Field does not accept Alphabets or Special Characters",
            },
          ],
          width: 8,
        },
        {
          key: "city",
          label: "City",
          width: 8,
          rules: [{ required: true, message: "Please input City!" }],
          Component: (
            <Select
              placeholder="Select City"
              size="large"
              onChange={(e) => {
                console.log(e);
                // console.log(e[1])
                e && setCurrentCity(e);
                // e[0] && setCurrentCity(e[0])
              }}
            >
              {(props.homeSupportedLocations || []).map((val) => (
                <Option value={val.name} key={val.name}>
                  {val.name}
                </Option>
              ))}
            </Select>
          ),
        },
        {
          key: "region",
          label: "Region",
          width: 8,
          rules: [{ required: true, message: "Please input region!" }],
          Component: (
            //     <Dropdown
            //   overlay={
            //     <Menu
            //       style={{ margin: "5px", height: "200px", overflow: "scroll" }}
            //     >
            //       {regionData &&
            //         regionData
            //           .map((region, index) => (
            //             <div style={{ margin: "auto" }} key={index}>
            //               {index == 0 && (
            //                 <div>
            //                   <Checkbox
            //                     style={{ margin: "5px" }}
            //                     key="All"
            //                     value="0"
            //                     onChange={handleAllRegionSelect}
            //                     checked={selectAll}
            //                   >
            //                     Select All
            //                   </Checkbox>
            //                 </div>
            //               )}

            //               <div style={{ margin: "auto" }} key={region.id}>
            //                 <Checkbox
            //                   style={{ margin: "5px" }}
            //                   key={`region_` + region.id}
            //                   value={region.name}
            //                   className="region_checkbox"
            //                   checked={regionValues.includes(region.name) ?? false}
            //                   onChange={(e) => handleSingleRegionSelect(e,region.name)}
            //                 >
            //                   {region.name}
            //                 </Checkbox>
            //               </div>
            //             </div>
            //           ))}
            //     </Menu>
            //   }
            //   trigger={["click"]}
            // >
            //   <Button className="table-btn">
            //     <span
            //       className="ant-dropdown-link"
            //       onClick={(e) => e.preventDefault()}
            //     >
            //       Select Regions
            //     </span>
            //     <DownOutlined />
            //   </Button>
            //     </Dropdown>

            <Select
              mode="multiple"
              placeholder="Select items"
              style={{ width: "300px" }}
              defaultValue={regionValues}
              value={regionValues}
              // onChange={(value) => {
              //   // if (!value.includes('selectAll')) {
              //   setRegionValues(value);
              //   // }
              // }}
              onDeselect={(value) => {
                if (value == "selectAll") {
                  setRegionValues([]);
                }
              }}
              onSelect={(value) => {
                if (value == "selectAll") {
                  let allRegionValues =
                    regionData?.map((each) => each.name) ?? [];
                  setRegionValues(["selectAll", ...allRegionValues]);
                }
              }}
            >
              <Option value="selectAll" key={29102192190}>
                Select All
              </Option>
              {regionData?.map((region) => (
                <Option value={region.name} key={region?.id}>
                  {region.name}
                </Option>
              ))}
            </Select>
          ),
        },
        {
          key: "status",
          label: "Status",
          width: 8,
          rules: [{ required: true, message: "Please input User's status!" }],
          Component: (
            <Select placeholder="Select Status" size="large">
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          ),
        },
        {
          key: "equipment_info",
          label: "Equipment(s) used for this service",
          rules: [
            { required: true, message: "Please Enter Equipment Information" },
          ],
          Component: <TextArea rows={4} placeholder="Enter Equipments" />,
          width: 8,
        },
        {
          key: "desc",
          label: "Description about the service offered",
          Component: <TextArea rows={4} placeholder="Enter Description" />,
          width: 16,
        },
        // {
        //   key: 'category',
        //   label: 'Category',
        //   data: props.category,
        //   rules: [{ required: false, message: "Please input User's name!" }],
        //   hidden: true,

        // },
      ]}
      key={1}
    />
  );
};
export default PackagesFormModal;
