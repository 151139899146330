import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Button,
  Input,
  Typography,
  Select,
  Modal,
  Card,
  Image,
  DatePicker,
  Row,
  Col,
  Form,
  Radio,
  message,
  Tooltip,
} from "antd";
import { SearchOutlined, SyncOutlined } from "@ant-design/icons";
import AdvancedSearchForm from "../../../common/SearchForm";
import DetailDrawer from "./components/detailDrawer";
import moment from "moment";
import documentsIcon from "../../../assets/document.png";

function Appointment({ data = {}, ...props }) {
  const vendorEquipmentRefresh = useSelector(
    (state) => state.vendorEquipmenReducer.allBookingsRefresh
  );

  const { Title, Link } = Typography;
  const { Option } = Select;
  const [selectedOptions, setSelectedOptions] = useState("");
  const [reasonComments, setReasonComments] = useState("");
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const [descriptionModal, setDescriptionModal] = useState(false);
  const [caseVisible, setCaseVisible] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [columnValue, setColumnValue] = useState();
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [surgeryPlanModal, setSurgeryPlanModal] = useState(false);
  const [surgeryPlansData, setSurgeryPlansData] = useState();

  const DocumentsComponent = (value) => {
    return (
      <Row className="case-file-section">
        {value.map((val) => (
          <Col
            span={4}
            justify="start"
            style={{ padding: "10", margin: "0 10px" }}
          >
            <Card>
              {val.url.match(/\.(pdf|doc|xls|xml)$/) != null ? (
                <Link href={val.url} target="_blank">
                  <Image
                    preview={false}
                    src={documentsIcon}
                    style={{ marginRight: 6, width: "110px", height: "86px" }}
                    alt="Route"
                  />
                </Link>
              ) : (
                <Image
                  style={{ width: "86px", height: "86px" }}
                  alt="Proof files"
                  src={val.url}
                />
              )}
            </Card>
          </Col>
        ))}
      </Row>
    );
  };

  const hideConfirmModal = () => {
    setConfirmModal(false);
    setCompleteModal(false);
  };

  const showCaseModal = (data) => {
    setCaseVisible(true);
    setColumnValue(data);
  };

  const handleOk = () => {
    setCaseVisible(false);
    setConfirmModal(false);
    setCompleteModal(false);
    setColumnValue();
  };

  const handleConfirm = () => {
    props.confirmMedicalAppointmentTourism(
      `id=${columnValue && columnValue.id}`
    );
    setConfirmModal(false);
    setColumnValue();
    props.getAllAtmedBookingAbroad(undefined, 20, currentPage);
  };

  const handleCompleteBooking = () => {
    props.completeMedicalAppointmentTourism(
      `id=${columnValue && columnValue.id}`
    );
    setConfirmModal(false);
    setColumnValue();
    props.getAllAtmedBookingAbroad(undefined, 20, currentPage);
  };

  const handleLogsCancel = () => {
    setCaseVisible(false);
    setColumnValue();
  };

  const onCancelOrder = () => {
    let id = columnValue.id;
    const payload = {
      abroad_booking: {
        cancel_reason: selectedOptions,
        cancel_notes: reasonComments,
      },
    };
    props.cancelMedicalAppointmentTourism({ id, payload });
    props.getAllAtmedBookingAbroad();
    setCancelModalVisible(false);
  };
  const viewDetailsAction = (columnData) => {
    setColumnValue(columnData);
    setDescriptionModal(true);
  };
  const showSurgeryPlanModal = (data) => {
    setSurgeryPlansData(data.surgery_plans);
    setSurgeryPlanModal(true);
    setColumnValue(data);
  };
  const columns = [
    {
      title: "Surgery",
      key: "booking_id",
      dataIndex: "booking_id",
      width: "35%",
      render: (_, columnData) => (
        <Row className="name-details-row">
          <Col span={14} offset={1}>
            <div className="table-fc-name">{columnData.centre_name}</div>
            <div className="table-fc-email">
              Booking ID: {columnData.booking_id}
            </div>
            <div className="table-fc-email">
              Date: {columnData.booking_date}
            </div>
            <div className="view-details">
              <Link onClick={() => viewDetailsAction(columnData)}>
                VIEW DETAILS
              </Link>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Patient Details",
      key: "patient_name",
      width: "20%",
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>{columnData.patient_name} </Col>
            <Col span={24} className="lightFont">
              {columnData.patient_age} / {columnData.patient_gender}
            </Col>
            <Col span={24} className="lightFont">
              {columnData.patient_phone}
            </Col>
          </Row>
          {columnData.case_files.length > 0 && (
          <div>
            <Link className="view-details" onClick={() => showCaseModal(columnData)}>CASE FILES</Link>
          </div>
          )}
          <div>
            <Link className="view-details" onClick={() => showSurgeryPlanModal(columnData)}>SURGERY PLAN</Link>
          </div>
          {columnData.offer_details !== "" ? (
            <div>
              <Tooltip
                className="view-details"
                placement="bottom"
                title={columnData.offer_details}
              >
                <Link>OFFER DETAILS</Link>
              </Tooltip>
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "Booking Details",
      key: "medic_name",
      width: "25%",
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>{columnData.medic_name} </Col>
          </Row>
          <Row>
            <Col span={24} className="lightFont" style={{ marginBottom: 20 }}>
              {columnData.medic_speciality}
            </Col>
          </Row>
          <Row>
            <Col span={24} className="lightFont">
              First consult:{" "}
              {moment(columnData.schedule_date).local().format("DD/MM/YYYY")}
            </Col>
          </Row>
          <Row>
            <Col span={24} className="lightFont">
              ({columnData.schedule_time})
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Status",
      key: "status_code",
      dataIndex: "status_code",
      width: "25%",
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24} style={{ marginBottom: 20 }}>
              {columnData.status_code === 1 ? (
                <span className="booking-placed">{columnData.status}</span>
              ) : null}
              {columnData.status_code === 2 ? (
                <span className="booking-confirmed">{columnData.status}</span>
              ) : null}
              {columnData.status_code === 3 ? (
                <span className="booking-inprogress">{columnData.status}</span>
              ) : null}
              {columnData.status_code === 4 ? (
                <span className="booking-completed">{columnData.status}</span>
              ) : null}
              {columnData.status_code === 5 ? (
                <span className="booking-cancelled">{columnData.status}</span>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col span={24} className="lightFont">
              {columnData.final_schedule_date === "" ? (
                ""
              ) : (
                <span>
                  Final consult:{" "}
                  {moment(columnData.final_schedule_date)
                    .local()
                    .format("DD/MM/YYYY")}
                </span>
              )}{" "}
              <span>{columnData.final_schedule_time}</span>
            </Col>
          </Row>
        </>
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAllAtmedBookingAbroad(queryString, 20, currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  const renderData = useCallback(
    (currentPage) => {
      props.getAllAtmedBookingAbroad(undefined, 20, currentPage);
    },
    [props.getAllAtmedBookingAbroad]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  const handleRefresh = () => {
    props.getAllAtmedBookingAbroad(undefined, 20, currentPage);
    if (vendorEquipmentRefresh && vendorEquipmentRefresh) {
      message.success(vendorEquipmentRefresh.message);
    }
  };

  const confirmModalFooter = (
    <>
      <Button onClick={handleConfirm} type="primary">
        Confirm
      </Button>
    </>
  );
  const completeModalFooter = (
    <>
      <Button onClick={handleCompleteBooking} type="primary">
        Complete Booking
      </Button>
    </>
  );
  const surgeryPlanColumns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (_, columnData) => (
        <>
          <p>{columnData.title}</p>
          {columnData.status === true ? (
            <span className="booking-completed">Active</span>
          ) : (
            <span className="inActivateNotverified">Blocked</span>
          )}
        </>
      ),
    },
    {
      title: "Description",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Schedule",
      dataIndex: "Schedule",
      key: "Schedule",
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.schedule_date).format("DD/MM/YYYY")}</p>
          <p>{columnData.schedule_time}</p>
        </>
      ),
    },
    // {
    //   title: "MORE",
    //   key: "actions",
    //   render: (_, columnD) => (
    //     <PlansActionsColumn
    //       columnData={columnD}
    //       tableData={columnValue}
    //       onClose={() => setSurgeryPlanModal(false)}
    //       updateMedicalAppoinmentAbroadPlan={
    //         props.updateMedicalAppoinmentAbroadPlan
    //       }
    //       deleteMedicalAppoinmentAbroadPlan={
    //         props.deleteMedicalAppoinmentAbroadPlan
    //       }
    //       props={props}
    //     />
    //   ),
    // },
  ];
  const surgeryPlanTitle = (
    <>
      <Row>
        <Col span={3}>
          <p>Surgery Plan</p>
        </Col>
        <Col span={21}></Col>
        {/* <Col span={3}>
          <Button
            onClick={showAddPlanModal}
            icon={<PlusOutlined />}
            type="primary"
          >
            <span style={{ fontWeight: "600" }}>ADD PLAN</span>
          </Button>
        </Col> */}
      </Row>
    </>
  );
  return (
    <div>
      {cancelModalVisible ? (
        <Modal
          className="cancel-order-modal"
          title={`Order ID: ${columnValue.id} `}
          footer={false}
          visible={cancelModalVisible}
          onOk={() => setCancelModalVisible(false)}
          onCancel={() => setCancelModalVisible(false)}
          width={800}
        >
          <Form
            form={form}
            // initialValues={status}
            layout="vertical"
            className="cancel-order"
            name="canselOrder"
          >
            <p className="form-title required">Reason for cancellation</p>
            <Row>
              <Col span={12}>
                <Radio.Group
                  onChange={(e) => setSelectedOptions(e.target.value)}
                >
                  {props.helpOptions &&
                    props.helpOptions.abroad.map((options) => (
                      <>
                        <p>
                          <Radio value={options}>{options}</Radio>
                        </p>
                      </>
                    ))}
                </Radio.Group>
              </Col>
              <Col span={12}>
                <p className="lightFont required">Comments</p>
                <TextArea
                  onChange={(e) => setReasonComments(e.target.value)}
                  placeholder="Enter your comments"
                  rows={4}
                />
              </Col>
            </Row>
            <Form.Item className="button-item">
              <Button type="primary" htmlType="submit" onClick={onCancelOrder}>
                CANCEL ORDER
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      ) : (
        ""
      )}
      <Modal
        className="logs-modal"
        title="Confirm Booking"
        footer={confirmModalFooter}
        visible={confirmModal}
        onOk={handleOk}
        onCancel={hideConfirmModal}
      >
        <p>
          <span>Booking Id : </span>
          {columnValue && columnValue.id}
        </p>
      </Modal>
      <Modal
        className="surgery-plan-modal"
        title={surgeryPlanTitle}
        footer={false}
        visible={surgeryPlanModal}
        onOk={() => setSurgeryPlanModal(false)}
        onCancel={() => setSurgeryPlanModal(false)}
        width={1000}
      >
        <Table
          className="list_table"
          scroll={{ x: 800 }}
          columns={surgeryPlanColumns}
          dataSource={columnValue && columnValue.surgery_plans}
        />
      </Modal>
      <Modal
        className="logs-modal"
        title="Complete Booking"
        footer={completeModalFooter}
        visible={completeModal}
        onOk={handleOk}
        onCancel={hideConfirmModal}
      >
        <p>
          <span>Booking Id : </span>
          {columnValue && columnValue.id}
        </p>
      </Modal>
      <Modal
        className="logs-modal"
        title="Case Files"
        footer={false}
        visible={caseVisible}
        onOk={handleOk}
        onCancel={handleLogsCancel}
        width={900}
      >
        {columnValue && columnValue.case_files.length > 0
          ? DocumentsComponent(columnValue.case_files)
          : "No Case files"}
      </Modal>
      {columnValue && columnValue ? (
        <DetailDrawer
          data={columnValue}
          visible={descriptionModal}
          onCancel={() => setDescriptionModal(false)}
        />
      ) : (
        ""
      )}

      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Abroad Consultation
          </Title>
        </Col>
        <Col className="gutter-row section-heading" span={3} offset={0}>
          <Title
              level={4}
              className="d-flex title"
              style={{ marginTop: '10px' }}
            >
              Total: {props.results?.total_count}
            </Title>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={2} offset={0}>
          <Button
            shape="circle"
            icon={<SyncOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => handleRefresh()}
          />
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Surgery Consultation"
          formItems={[
            {
              key: "q[bid_cont]",
              label: "Booking ID",
            },
            {
              key: "q[patient_name_cont]",
              label: "Patient name",
            },
            {
              key: "q[medic_name_cont]",
              label: "Doctor name",
            },
            {
              key: "q[health_centre_name_cont]",
              label: "Centre name",
            },
            {
              key: "q[status_code_eq]",
              label: "Status",
              Component: (
                <Select placeholder="Select Booking status" size="large">
                  <Option value="1">Booking Placed</Option>
                  <Option value="2">Booking Confirmed</Option>
                  <Option value="3">In Progress</Option>
                  <Option value="4">Booking Completed</Option>
                  <Option value="5">Booking Cancelled</Option>
                </Select>
              ),
            },
            {
              key: "q[created_at_gteq]",
              label: "From Date",
              Component: <DatePicker size="large" />,
            },
            {
              key: "q[created_at_lteq]",
              label: "To Date",
              Component: <DatePicker size="large" />,
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={props.results ? props.results.data : null}
        pagination={{
          pageSize: 20,
          total: props.results?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default Appointment;
