import * as actionType from './actionType';
import * as medicalApi from '../api/medicalApi';

//Company Profile
export const getMedicalCompanyProfile = (params) => ({
  type: actionType.VENDOR_MEDICAL_COMPANY_PROFILE_GET,
  payload: medicalApi.getMedicalCompanyProfile(params),
});

// Administration - Hospital Stuff
export const getAllAdmHospitalStaff = (params) => ({
  type: actionType.VENDOR_MEDICAL_GET_ALL_ADM_HOSPITAL_STUFF,
  payload: medicalApi.getAllAdmHospitalStaff(params),
});

export const addAdmHospitalStaff = (params) => ({
  type: actionType.VENDOR_MEDICAL_ADD_HOSPITAL_STUFF,
  payload: medicalApi.addAdmHospitalStaff(params),
});

export const activateAdmHospitalStaff = (params) => ({
  type: actionType.VENDOR_MEDICAL_ACTIVATE_HOSPITAL_STUFF,
  payload: medicalApi.activateAdmHospitalStaff(params),
});

export const resendActivateAdmHospitalStuffOTP = (params) => ({
  type: actionType.VENDOR_MEDICAL_RESEND_ACTIVATE_HOSPITAL_STAFF_OTP,
  payload: medicalApi.resendActivateAdmHospitalStuffOTP(params),
});

export const updateAdmHospitalStaff = (params) => ({
  type: actionType.VENDOR_MEDICAL_UPDATE_HOSPITAL_STUFF,
  payload: medicalApi.updateAdmHospitalStaff(params),
});

export const updateAdmHospitalStaffProfile = (params) => ({
  type: actionType.VENDOR_MEDICAL_UPDATE_HOSPITAL_STUFF_PROFILE,
  payload: medicalApi.updateAdmHospitalStaffProfile(params),
});

export const updateAdmHospitalPermission = (params) => ({
  type: actionType.VENDOR_MEDICAL_UPDATE_HOSPITAL_STAFF_PERMISSION,
  payload: medicalApi.updateAdmHospitalPermission(params),
});

export const getSingleAdmHospitalStaff = (params) => ({
  type: actionType.VENDOR_MEDICAL_GET_SINGLE_HOSPITAL_STAFF,
  payload: medicalApi.getSingleAdmHospitalStaff(params),
});

export const getAdmHospitalStaffProfileInfo = (params) => ({
  type: actionType.VENDOR_MEDICAL_GET_HOSPITAL_STAFF_PROFILE_INFO,
  payload: medicalApi.getAdmHospitalStaffProfileInfo(params),
});

export const setAdmHospitalStaffProfileInfo = (params) => ({
  type: actionType.VENDOR_MEDICAL_SET_HOSPITAL_STAFF_PROFILE_INFO,
  payload: medicalApi.setAdmHospitalStaffProfileInfo(params),
});

//Adm - Health Centres
export const getAllMedicalAdmHealthCentres = (params) => ({
  type: actionType.VENDOR_MEDICAL_ADM_GET_ALL_HEALTH_CENTRES,
  payload: medicalApi.getAllMedicalAdmHealthCentres(params),
});

export const getMedicalAdmHealthCentreInfo = (params) => ({
  type: actionType.VENDOR_MEDICAL_ADM_GET_SINGLE_HEALTH_CENTRE,
  payload: medicalApi.getMedicalAdmHealthCentreInfo(params),
});

export const updateMedicalAdmHealthCentre = (params) => ({
  type: actionType.VENDOR_MEDICAL_ADM_UPDATE_HEALTH_CENTRE,
  payload: medicalApi.updateMedicalAdmHealthCentre(params),
});

export const addMedicalAdmHealthCentreAsset = (params) => ({
  type: actionType.VENDOR_MEDICAL_ADM_ADD_HEALTH_CENTRE_ASSET,
  payload: medicalApi.addMedicalAdmHealthCentreAsset(params),
});
export const deleteMedicalAdmHealthCentreAsset = (params) => ({
  type: actionType.VENDOR_MEDICAL_ADM_DELETE_HEALTH_CENTRE_ASSET,
  payload: medicalApi.deleteMedicalAdmHealthCentreAsset(params),
});

//Administrator - Log Management
export const getAllMedicalLog = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_MEDICAL_GET_ALL_LOG,
  payload: medicalApi.getAllMedicalLog(queryString,pageSize,currentPage),
});

//Surgery - Packages
export const getAllMedicalCatalogSurgeries = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_MEDICAL_GET_ALL_CATALOG_SURGERIES,
  payload: medicalApi.getAllMedicalCatalogSurgeries(queryString,pageSize,currentPage),
});

export const getAllMedicalSurgeryAutoComplete = (params) => ({
  type: actionType.VENDOR_MEDICAL_SURGERY_SEARCH_AUTOCOMPLETE,
  payload: medicalApi.getAllMedicalSurgeryAutoComplete(params),
});

export const getMedicalCatalogSurgeryInfo = (params) => ({
  type: actionType.VENDOR_MEDICAL_GET_CATALOG_SURGERY_INFO,
  payload: medicalApi.getMedicalCatalogSurgeryInfo(params),
});

export const addMedicalCatalogSurgery = (params) => ({
  type: actionType.VENDOR_MEDICAL_ADD_CATALOG_SURGERY,
  payload: medicalApi.addMedicalCatalogSurgery(params),
});

export const updateMedicalCatalogSurgery = (params) => ({
  type: actionType.VENDOR_MEDICAL_UPDATE_CATALOG_SURGERY,
  payload: medicalApi.updateMedicalCatalogSurgery(params),
});

export const getAllMedicalSurgeryMedics = (params) => ({
  type: actionType.VENDOR_MEDICAL_SURGERY_GET_ALL_MEDICS,
  payload: medicalApi.getAllMedicalSurgeryMedics(params),
});

export const addMedicalSurgerySpecialist = (params) => ({
  type: actionType.VENDOR_MEDICAL_SURGERY_ADD_SPECIALIST,
  payload: medicalApi.addMedicalSurgerySpecialist(params),
});

export const deleteMedicalSurgerySpecialist = (params) => ({
  type: actionType.VENDOR_MEDICAL_SURGERY_REMOVE_SPECIALIST,
  payload: medicalApi.deleteMedicalSurgerySpecialist(params),
});

export const getAllMedicalSurgeryCentres = (params) => ({
  type: actionType.VENDOR_MEDICAL_SURGERY_GET_ALL_CENTRES,
  payload: medicalApi.getAllMedicalSurgeryCentres(params),
});
export const addMedicalSurgeryCentres = (params) => ({
  type: actionType.VENDOR_MEDICAL_SURGERY_ADD_CENTRES,
  payload: medicalApi.addMedicalSurgeryCentres(params),
});
export const deleteMedicalSurgeryCentres = (params) => ({
  type: actionType.VENDOR_MEDICAL_SURGERY_REMOVE_CENTRES,
  payload: medicalApi.deleteMedicalSurgeryCentres(params),
});

//Surgery - Insurance
export const getAllVendorMedSurgerylnsurance = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_MEDICAL_SURGERY_INSURANCE_GET_ALL,
  payload: medicalApi.getAllVendorMedSurgerylnsurance(queryString,pageSize,currentPage),
});

export const updateVendorMedSurgeryInsurance = (params) => ({
  type: actionType.VENDOR_MEDICAL_SURGERY_INSURANCE_UPDATE,
  payload: medicalApi.updateVendorMedSurgeryInsurance(params),
});

//Surgery - Spot Finance
export const getAllVendorMedSurgerySpotFinance = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_MEDICAL_SURGERY_SPOT_FINANCE_GET_ALL,
  payload: medicalApi.getAllVendorMedSurgerySpotFinance(queryString,pageSize,currentPage),
});

export const updateVendorMedSurgerySpotFinance = (params) => ({
  type: actionType.VENDOR_MEDICAL_SURGERY_SPOT_FINANCE_UPDATE,
  payload: medicalApi.updateVendorMedSurgerySpotFinance(params),
});

//Catalog - Consultant Doctors
export const getAllMedicalCatalogConsultantDoctors = (params) => ({
  type: actionType.VENDOR_MEDICAL_GET_ALL_CONSULTANT_DOCTORS,
  payload: medicalApi.getAllMedicalCatalogConsultantDoctors(params),
});

export const updateMedicalCatalogConsultantDoctors = (params) => ({
  type: actionType.VENDOR_MEDICAL_UPDATE_CONSULTANT_DOCTOR,
  payload: medicalApi.updateMedicalCatalogConsultantDoctors(params),
});

//Abroad - Packages
export const getAllMedicalAbroadPackages = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_MEDICAL_ABROAD_PACKAGE_GET_ALL,
  payload: medicalApi.getAllMedicalAbroadPackages(queryString,pageSize,currentPage),
});

export const getAllMedicalAbroadPackageAutoComplete = (params) => ({
  type: actionType.VENDOR_MEDICAL_ABROAD_PACKAGE_SEARCH_AUTOCOMPLETE,
  payload: medicalApi.getAllMedicalAbroadPackageAutoComplete(params),
});

export const getAllMedicalAbroadCentres = (params) => ({
  type: actionType.VENDOR_MEDICAL_ABROAD_CENTRES,
  payload: medicalApi.getAllMedicalAbroadCentres(params),
});

export const getMedicalAbroadPackageInfo = (params) => ({
  type: actionType.VENDOR_MEDICAL_ABROAD_PACKAGE_INFO,
  payload: medicalApi.getMedicalAbroadPackageInfo(params),
});

export const addMedicalAbroadPackage = (params) => ({
  type: actionType.VENDOR_MEDICAL_ABROAD_PACKAGE_ADD,
  payload: medicalApi.addMedicalAbroadPackage(params),
});

export const updateMedicalAbroadPackage = (params) => ({
  type: actionType.VENDOR_MEDICAL_ABROAD_UPDATE_PACKAGE,
  payload: medicalApi.updateMedicalAbroadPackage(params),
});

export const getAllMedicalAbroadPackageMedics = (params) => ({
  type: actionType.VENDOR_MEDICAL_ABROAD_PACKAGE_GET_ALL_MEDICS,
  payload: medicalApi.getAllMedicalAbroadPackageMedics(params),
});

export const addMedicalAbroadPackageSpecialist = (params) => ({
  type: actionType.VENDOR_MEDICAL_ABROAD_PACKAGE_ADD_SPECIALIST,
  payload: medicalApi.addMedicalAbroadPackageSpecialist(params),
});

export const deleteMedicalAbroadPackageSpecialist = (params) => ({
  type: actionType.VENDOR_MEDICAL_ABROAD_PACKAGE_REMOVE_SPECIALIST,
  payload: medicalApi.deleteMedicalAbroadPackageSpecialist(params),
});

export const addMedicalAbroadStoryContent = (params) => ({
  type: actionType.ADD_MEDICAL_ABROAD_STORY_CONTENT,
  payload: medicalApi.addMedicalAbroadStoryContent(params),
});

export const deleteMedicalAbroadStoryContent = (params) => ({
  type: actionType.DELETE_MEDICAL_ABROAD_STORY_CONTENT,
  payload: medicalApi.deleteMedicalAbroadStoryContent(params),
});

//Abroad - Insurance
export const getAllVendorMedAbroadInsurance = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_MEDICAL_ABROAD_INSURANCE_GET_ALL,
  payload: medicalApi.getAllVendorMedAbroadInsurance(queryString,pageSize,currentPage),
});

export const updateVendorMedAbroadInsurance = (params) => ({
  type: actionType.VENDOR_MEDICAL_ABROAD_INSURANCE_UPATE,
  payload: medicalApi.updateVendorMedAbroadInsurance(params),
});

//Appointment - Clinic
export const getAllVendorMedicalAppointmentClincOrders = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_MEDICAL_APPOINTMENT_GET_ALL_CLINIC_ORDERS,
  payload: medicalApi.getAllVendorMedicalAppointmentClincOrders(queryString,pageSize,currentPage),
});
export const getSingleVendorMedicalClinicOrder = (params) => ({
  type: actionType.VENDOR_MEDICAL_APPOINTMENT_GET_SINGLE_CLINIC_ORDER,
  payload: medicalApi.getSingleVendorMedicalClinicOrder(params),
});

//Appointment - Surgery
export const getAllVendorMedicalAppointmentSurgeryOrders = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_MEDICAL_APPOINTMENT_GET_ALL_SURGERY_ORDERS,
  payload: medicalApi.getAllVendorMedicalAppointmentSurgeryOrders(queryString,pageSize,currentPage),
});
export const getSingleVendorMedicalSurgeryOrder = (params) => ({
  type: actionType.VENDOR_MEDICAL_APPOINTMENT_GET_SINGLE_SURGERY_ORDER,
  payload: medicalApi.getSingleVendorMedicalSurgeryOrder(params),
});
export const vendorMedicalAppointmentConfirmSurgery = (params) => ({
  type: actionType.VENDOR_MEDICAL_APPOINTMENT_CONFIRM_SURGERY,
  payload: medicalApi.vendorMedicalAppointmentConfirmSurgery(params),
});
export const vendorMedicalAppointmentCancelSurgery = (params) => ({
  type: actionType.VENDOR_MEDICAL_APPOINTMENT_CANCEL_SURGERY,
  payload: medicalApi.vendorMedicalAppointmentCancelSurgery(params),
});
export const vendorMedicalAppointmentStartSurgery = (params) => ({
  type: actionType.VENDOR_MEDICAL_APPOINTMENT_START_SURGERY,
  payload: medicalApi.vendorMedicalAppointmentStartSurgery(params),
});
export const vendorMedicalAppointmentCompleteSurgery = (params) => ({
  type: actionType.VENDOR_MEDICAL_APPOINTMENT_COMPLETE_SURGERY,
  payload: medicalApi.vendorMedicalAppointmentCompleteSurgery(params),
});
export const vendorMedicalAppointmentSurgeryDetailEdit = (params) => ({
  type: actionType.VENDOR_MEDICAL_APPOINTMENT_SURGERY_DETAIL_EDIT,
  payload: medicalApi.vendorMedicalAppointmentSurgeryDetailEdit(params),
});


//Appointment - Medical Tourism
export const getAllVendorMedicalAppointmentTourism = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_MEDICAL_APPOINTMENT_GET_ALL_TOURISM,
  payload: medicalApi.getAllVendorMedicalAppointmentTourism(queryString,pageSize,currentPage),
});
export const getSingleVendorMedicalTourism = (params) => ({
  type: actionType.VENDOR_MEDICAL_APPOINTMENT_GET_SINGLE_TOURISM,
  payload: medicalApi.getSingleVendorMedicalTourism(params),
});
export const confirmMedicalAppointmentTourism = (params) => ({
  type: actionType.VENDOR_MEDICAL_APPOINTMENT_CONFIRM_TOURISM,
  payload: medicalApi.confirmMedicalAppointmentTourism(params),
});
export const cancelMedicalAppointmentTourism = (params) => ({
  type: actionType.VENDOR_MEDICAL_APPOINTMENT_CANCEL_TOURISM,
  payload: medicalApi.cancelMedicalAppointmentTourism(params),
});
export const firstConsultationMedicalAppointmentTourism = (params) => ({
  type: actionType.FIRST_CONSULTATION_VENDOR_MEDICAL_APPOINTMENT_TOURISM,
  payload: medicalApi.firstConsultationMedicalAppointmentTourism(params),
});
export const confirmFirstConsultationMedicalAppointmentTourism = (params) => ({
  type: actionType.CONFIRM_FIRST_CONSULTATION_VENDOR_MEDICAL_APPOINTMENT_TOURISM,
  payload: medicalApi.confirmFirstConsultationMedicalAppointmentTourism(params),
});
export const completeFirstConsultationMedicalAppointmentTourism = (params) => ({
  type: actionType.COMPLETE_FIRST_CONSULTATION_VENDOR_MEDICAL_APPOINTMENT_TOURISM,
  payload: medicalApi.completeFirstConsultationMedicalAppointmentTourism(params),
});
export const confirmFinalConsultationMedicalAppointmentTourism = (params) => ({
  type: actionType.CONFIRM_FINAL_CONSULTATION_VENDOR_MEDICAL_APPOINTMENT_TOURISM,
  payload: medicalApi.confirmFinalConsultationMedicalAppointmentTourism(params),
});
export const completeFinalConsultationMedicalAppointmentTourism = (params) => ({
  type: actionType.COMPLETE_FINAL_CONSULTATION_VENDOR_MEDICAL_APPOINTMENT_TOURISM,
  payload: medicalApi.completeFinalConsultationMedicalAppointmentTourism(params),
});

export const finalConsultationMedicalAppointmentTourism = (params) => ({
  type: actionType.FINAL_CONSULTATION_VENDOR_MEDICAL_APPOINTMENT_TOURISM,
  payload: medicalApi.finalConsultationMedicalAppointmentTourism(params),
});
export const helpOptionVendormedical = (params) => ({
  type: actionType.HELP_OPTION_MEDICAL_VENDOR,
  payload: medicalApi.helpOptionVendormedical(params),
});

//Appointment - Emergency
export const getAllVendorMedAppointmentEmergency = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_MEDICAL_APPOINTMENT_EMERGENCY_GET_ALL,
  payload: medicalApi.getAllVendorMedAppointmentEmergency(queryString,pageSize,currentPage),
});

export const getSingleVendorMedAppointmentEmergency = (params) => ({
  type: actionType.VENDOR_MEDICAL_APPOINTMENT_GET_SINGLE_EMERGENCY,
  payload: medicalApi.getSingleVendorMedAppointmentEmergency(params),
});

//Settlement - Surgery
export const getAllVendorMedSettlementSurgery = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_MEDICAL_SETTLEMENT_GET_ALL_SURGERY,
  payload: medicalApi.getAllVendorMedSettlementSurgery(queryString,pageSize,currentPage),
});

export const getVendorMedSettlementSurgeryInfo = (params) => ({
  type: actionType.VENDOR_MEDIAL_SETTLEMENT_GET_SURGERY,
  payload: medicalApi.getVendorMedSettlementSurgeryInfo(params),
});

//Settlement - Medical Tourism
export const getAllVendorMedSettlementAbroad = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_MEDICAL_SETTLEMENT_GET_ALL_ABROAD,
  payload: medicalApi.getAllVendorMedSettlementAbroad(queryString,pageSize,currentPage),
});

export const getVendorMedSettlementAbroadInfo = (params) => ({
  type: actionType.VENDOR_MEDIAL_SETTLEMENT_GET_ABROAD,
  payload: medicalApi.getVendorMedSettlementAbroadInfo(params),
});

//Feedback
export const getAllMedicalFeedbackClinic = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_MEDICAL_GET_ALL_FEEDBACK_CLINIC,
  payload: medicalApi.getAllMedicalFeedbackClinic(queryString,pageSize,currentPage),
});

export const getAllMedicalFeedbackSurgery = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_MEDICAL_GET_ALL_FEEDBACK_SURGERY,
  payload: medicalApi.getAllMedicalFeedbackSurgery(queryString,pageSize,currentPage),
});

export const getAllMedicalFeedbackEmergency = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_MEDICAL_GET_ALL_FEEDBACK_EMERGENCY,
  payload: medicalApi.getAllMedicalFeedbackEmergency(queryString,pageSize,currentPage),
});

export const getAllMedicalFeedbackTourism = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_MEDICAL_GET_ALL_FEEDBACK_TOURISM,
  payload: medicalApi.getAllMedicalFeedbackTourism(queryString,pageSize,currentPage),
});

//Help and Support
export const getAllMedicalHelpSupport = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_MEDICAL_GET_ALL_HELP_SUPPORT,
  payload: medicalApi.getAllMedicalHelpSupport(queryString,pageSize,currentPage),
});

export const updateMedicalHelpSupport = (params) => ({
  type: actionType.VENDOR_MEDICAL_UPDATE_HELP_SUPPORT,
  payload: medicalApi.updateMedicalHelpSupport(params),
});

export const completeMedicalAppointmentTourism = (params) => ({
  type: actionType.VENDOR_MEDICAL_APPOINTMENT_COMPLETE_TOURISM,
  payload: medicalApi.completeMedicalAppointmentTourism(params),
});

export const getAllMedicalAppoinmentAbroadDocuments = (params) => ({
  type: actionType.GET_VENDOR_MEDICAL_APPOINTMENT_TOURISM_DOCUMENTS,
  payload: medicalApi.getAllMedicalAppoinmentAbroadDocuments(params),
});
export const postMedicalAppoinmentAbroadPlan = (params) => ({
  type: actionType.POST_VENDOR_MEDICAL_APPOINTMENT_TOURISM_PLANS,
  payload: medicalApi.postMedicalAppoinmentAbroadPlan(params),
});
export const updateMedicalAppoinmentAbroadPlan = (params) => ({
  type: actionType.UPDATE_VENDOR_MEDICAL_APPOINTMENT_TOURISM_PLANS,
  payload: medicalApi.updateMedicalAppoinmentAbroadPlan(params),
});
export const deleteMedicalAppoinmentAbroadPlan = (params) => ({
  type: actionType.DELETE_VENDOR_MEDICAL_APPOINTMENT_TOURISM_PLANS,
  payload: medicalApi.deleteMedicalAppoinmentAbroadPlan(params),
});
export const postMedicalAppoinmentAbroadDocuments = (params) => ({
  type: actionType.POST_VENDOR_MEDICAL_APPOINTMENT_TOURISM_DOCUMENTS,
  payload: medicalApi.postMedicalAppoinmentAbroadDocuments(params),
});
export const confirmMedicalAppointmentEmergency = (params) => ({
  type: actionType.VENDOR_MEDICAL_APPOINTMENT_CONFIRM_EMERGENCY,
  payload: medicalApi.confirmMedicalAppointmentEmergency(params),
});
export const cancelMedicalAppointmentEmergency = (params) => ({
  type: actionType.VENDOR_MEDICAL_APPOINTMENT_CANCEL_EMERGENCY,
  payload: medicalApi.cancelMedicalAppointmentEmergency(params),
});
export const vendorMedicalAppointmentEmergencyUsers = (params) => ({
  type: actionType.VENDOR_MEDICAL_APPOINTMENT_EMERGENCY_USERS,
  payload: medicalApi.vendorMedicalAppointmentEmergencyUsers(params),
});
export const assignVendorMedAppointmentEmergencyExecutive = (params) => ({
  type: actionType.ASSIGN_MEDICAL_APPOINTMENT_EMERGENCY_EXECUTIVE,
  payload: medicalApi.assignVendorMedAppointmentEmergencyExecutive(params),
});

export const updateVendorMedicalAbroadStories = (params) => ({
  type: actionType.UPDATE_VENDOR_MEDICAL_ABROAD_STORIES,
  payload: medicalApi.updateVendorMedicalAbroadStories(params),
});
export const addVendorMedicalAbroadStories = (params) => ({
  type: actionType.CREATE_VENDOR_MEDICAL_ABROAD_STORIES,
  payload: medicalApi.addVendorMedicalAbroadStories(params),
});
export const deleteVendorMedicalAbroadStories = (params) => ({
  type: actionType.DELETE_VENDOR_MEDICAL_ABROAD_STORIES,
  payload: medicalApi.deleteVendorMedicalAbroadStories(params),
});

export const deleteVendorMedicalAppoinmentDocuments = (params) => ({
  type: actionType.DELETE_VENDOR_MEDICAL_APPOINMENTS_DOCUMENTS,
  payload: medicalApi.deleteVendorMedicalAppoinmentDocuments(params),
});
export const vendorMedicaltourismPaymentOtp = (params) => ({
  type: actionType.VENDOR_MEDICAL_ORDER_PAYMENT_OTP,
  payload: medicalApi.vendorMedicaltourismPaymentOtp(params),
});
export const vendorMedicalOrderPaymentVerifyOtp = (params) => ({
  type: actionType.VENDOR_MEDICAL_ORDER_PAYMENT_OTP_VERIFY,
  payload: medicalApi.vendorMedicalOrderPaymentVerifyOtp(params),
});
