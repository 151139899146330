import React from "react";
import { Modal, Form, Input, Button } from "antd";
import { noop } from "lodash";

const ActionModalForm = ({
  data = {},
  visible,
  onSubmit,
  onCancel,
  onCloseClick,
  title,
  submitText,
  formItems = [],
  footer,
  columnData,
  columnValue,
  isAssignTechnicianButton,
  ...props
}) => {
  const [form] = Form.useForm();
  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        await onSubmit(values);
        form.resetFields();
      })
      .catch(noop);
  };

  const onModalClose = () => {
    onCloseClick();
  };
  console.log("Columdata",columnData);
  console.log("columnValue",columnValue)

  const modalFooter = (
    <>
      {footer}

      <Button onClick={onOk} type="primary">
        {submitText}
      </Button>
    </>
  );

  return (
    <Modal
      visible={visible}
      onCancel={onModalClose}
      onOk={onOk}
      title={
        submitText === "Confirm Payment"
          ? `Order ID: ${columnData?.booking_id} (₹ ${columnData?.consult_fee})`
          : `Order ID: ${columnData?.booking_id}`
      }
      okText={submitText}
      destroyOnClose
      footer={modalFooter}
      {...props}
    >
      <div>
        <p>{`Program Name: ${columnData?.surgery_name}`}</p>
        <p>{`Patient Name: ${columnData?.patient_name}`}</p>
        <p>{`Mode: ${columnData?.surgery_category}`}</p>
        <p>{`Schedule : ${columnData?.schedule_date} - ${columnData?.schedule_time}`}</p>
        <p>{`Branch Name: ${columnData?.centre_name}`}</p>
        <p>{`Doctor Name : ${columnData?.medic_name}`} </p>
        <p>{`Payment Status: Pending`}</p>
        <p>{`Total Payable: ${columnData?.currency}${columnData?.consult_fee}`}</p>
      </div>
      <Form form={form} initialValues={data} layout="vertical">
        {formItems.map(({ Component, ...formItem }) => (
          <Form.Item name={formItem.key} {...formItem}>
            {Component || <Input />}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
export default ActionModalForm;
