/* global google */
import React from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  DirectionsRenderer,
} from "react-google-maps";

import TrackSource from "../../../assets/Trackbee.png"
import TrackDestination from "../../../assets/TrackDestination.png";



class MapDirectionsRenderer extends React.Component {
  state = {
    directions: null,
    error: null,
  };

  componentDidMount() {
    const { places, travelMode } = this.props;
    const waypoints = places.map((p) => ({
      location: { lat: p.latitude, lng: p.longitude },
      stopover: true,
    }));
    const origin = waypoints.shift().location;
    const destination = waypoints.pop().location;

    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: travelMode,
        waypoints: waypoints,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result,
          });
        } else {
          this.setState({ error: result });
        }
      }
    );
  }
  componentWillReceiveProps(nextProps) {
    const { places, travelMode } = nextProps;
    const waypoints = places.map((p) => ({
      location: { lat: p.latitude, lng: p.longitude },
      stopover: true,
    }));
    const origin = waypoints.shift().location;
    const destination = waypoints.pop().location;

    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: travelMode,
        waypoints: waypoints,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result,
          });
        } else {
          this.setState({ error: result });
        }
      }
    );
  }
  render() {
    if (this.state.error) {
      return <h1>{this.state.error}</h1>;
    }
    return (
      this.state.directions && (
        <DirectionsRenderer
          directions={this.state.directions}
          options={{ suppressMarkers: true }}
        />
      )
    );
  }
}

const Map = withScriptjs(
  withGoogleMap((props) =>
    props.location !== {} ? (
      <GoogleMap
        defaultCenter={props.defaultCenter}
        defaultZoom={props.defaultZoom}
      >
        {props.markers.map((marker, index) => {
          const position = { lat: marker.latitude, lng: marker.longitude };
          if (index === 0) {
            return (
              <Marker
                key={index}
                position={position}
                icon={{
                  url: TrackSource,
                }}
              />
            );
          } else {
            return (
              <Marker
                key={index}
                position={position}
                icon={{
                  url: TrackDestination,
                }}
              />
            );
          }
        })}

        <MapDirectionsRenderer
          places={props.markers}
          travelMode={google.maps.TravelMode.DRIVING}
        />
        {/* <Marker position={{ lat: 13.0697753, lng: 80.2042857 }} /> */}
      </GoogleMap>
    ) : (
      <GoogleMap
        defaultCenter={props.defaultCenter}
        defaultZoom={props.defaultZoom}
      >
        {props.markers.map((marker, index) => {
          const position = { lat: marker.latitude, lng: marker.longitude };
          if (index === 0) {
            return (
              <Marker
                key={index}
                position={position}
                icon={{
                  url: TrackSource,
                  scale: 0.05,
                }}
              />
            );
          } else {
            return (
              <Marker
                key={index}
                position={position}
                icon={{
                  url: TrackDestination,
                }}
              />
            );
          }
        })}
        <Marker
          position={{
            lat: props.markers[0].latitude,
            lng: props.markers[0].longitude,
          }}
        />
      </GoogleMap>
    )
  )
);

export default Map;
