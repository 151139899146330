import React, { useEffect } from 'react';
import _ from 'lodash';
import {
  Drawer, Spin, Typography, Row, Col, Divider
} from 'antd';

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue
        ? <Text className="darkFont">{value}</Text>
        : <Text className="darkFont" type="secondary">Not Provided</Text>}
    </div>
  </Paragraph>
);

const DetailsDrawer = ({
  getData,
  data,
  config = [],
  title,
  visible,
  setVisible,
  showMessageIfEmpty,
}) => {
  useEffect(() => {
    if (visible) {
      getData();
    }
  }, [visible]);
  return (
    <Drawer
      title={title}
      placement="right"
      closable
      visible={visible}
      width={500}
      onClose={() => setVisible(false)}
    >
      {!data && (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin />
        </div>
      )}
      {data
        && (
          <>
            {/* <Paragraph>
              <Text className="lightFont">{`${row.displayName}: `}</Text>
              <div>
                {!showMessageIfEmpty || _.get(data, row.key)
                  ? <Text className="darkFont">{_.get(data, row.key)}</Text>
                  : <Text className="darkFont" type="secondary">Not Provided</Text>}
              </div>
            </Paragraph> */}
            <Row>
              <Col span={24} className="fw500 mb-10">
                <Text>BOOKING SUMMARY</Text>
              </Col>
              <Col span={12}>
                <DescrtiptionItem title="Booking ID" value={data.bid} />
              </Col>
              <Col span={12}>
                <DescrtiptionItem title="Booking Status" value={data.status} />
              </Col>
              <Col span={12}>
                <DescrtiptionItem title="Booking Date" value={data.booking_date} />
              </Col>
              <Col span={12}>
                <DescrtiptionItem title="Service" value={data.module} />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <DescrtiptionItem title="Program Name" value={data.program_name} />
              </Col>
              <Col span={24}>
                <DescrtiptionItem title="Program Duration" value={data.program_duration} />
              </Col>
              <Col span={12}>
                <DescrtiptionItem title="Preferred Caregiver" value={data.caregiver_gender} />
              </Col>
              <Col span={12}>
                <DescrtiptionItem title="Total Sessions" value={data.total_sessions} />
              </Col>
              <Col span={12}>
                {/* <DescrtiptionItem title="" value={data.selected_dates} /> */}
                <Paragraph>
                  {title && <Text className="lightFont">Selected Dates</Text>}
                  <div>
                    {data.selected_dates.map(val => (
                      <p style={{ marginBottom: 'auto'}} className="darkFont">{val}</p>
                    ))}
                  </div>
                </Paragraph>
              </Col>
              <Col span={12}>
                <DescrtiptionItem title="Preferred Time" value={data.preferred_time} />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                {/* <DescrtiptionItem title="Patient Name" value={data.payment_status} /> */}
                <Paragraph>
                  <Text className="lightFont">Patient Name</Text>
                  <div><Text className="darkFont">{data.patient_name}</Text></div>
                  <div><Text className="darkFont">{data.patient_phone}</Text></div>
                  <div><Text className="darkFont">{data.patient_age}, {data.patient_gender}</Text></div>
                </Paragraph>
              </Col>
              <Col span={24}>
                <DescrtiptionItem title="Patient Address" value={data.address} />
              </Col>
              <Col span={24}>
                <DescrtiptionItem title="Ailment Information" value={data.ailment_desc} />
              </Col>
            </Row>
          </>
        )}
    </Drawer>
  );
};
export default DetailsDrawer;
