import React, { useState, useEffect, Component, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Typography,
  Form,
  Input,
  Select,
  Button,
  message,
  Row,
  Col,
  Spin,
  Modal,
  Divider,
  Space,
  AutoComplete,
} from "antd";
import moment from "moment";
import ListForm from "../../../common/ListForm";
import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import GoogleMapComponent from "../../../common/googlemap";
import GooglePlacesAutocomplete from "react-google-autocomplete";
const { Title } = Typography;
const { Option } = Select;
const today = new Date();
const maxDate = new Date(today.getFullYear(), today.getMonth() + 2, 0);

export const getNextFiveDays = () => {
  const currentDate = new DateObject();
  const nextFiveDays = [];

  for (let i = 0; i < 5; i++) {
    const nextDate = new DateObject().set({
      day: currentDate.day + i, // Adding i+1 to skip today
    });
    nextFiveDays.push(nextDate);
  }
  return nextFiveDays;
};

function BookForm(props) {
  const inputRef = useRef();
  const [duration1 ,setDuration1 ] = useState();
  const [prognam,setprogname] =useState("");
  const [finalprogram ,setFinalprogram] = useState([]);
  const [programcatgory ,SetProgramcategory]=useState();
  const [programOptions, setProgramOptions] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [serviceListOption, setServiceListOption] = useState(false);
  const [addressOption, setAddressOption] = useState("");
  const [programListOption, setProgramListOption] = useState(false);
  const [staffListOption, setStaffListOption] = useState(false);
  const defaultDates = [
    new Date(today.getFullYear(), today.getMonth(), today.getDate() + 4),
    new Date(today.getFullYear(), today.getMonth(), today.getDate() + 3),
    new Date(today.getFullYear(), today.getMonth(), today.getDate() + 2),
    new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1),
    new Date(),
  ];

  const nextFiveDays = getNextFiveDays();

  const [selectedDates, setSelectedDates] = useState(nextFiveDays || []);
  const [giver, setcaregiver] = useState("");
  const [addresssaved, setAddresssaved] = useState("");
  const [dob, setDob] = useState("");
  const [user, setUser] = useState([{}]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [name1, setName] = useState("");
  const [MRP, setMRP] = useState("");
  const [newNumber, setNewNumber] = useState("");
  const [categories, setServiceCategories] = useState("");
  const [newCategory, setNewCategory] = useState(false);
  // State to hold user information
  const [phoneNumberList, setPhoneNumberList] = useState([]);
  const [searchPhoneNumber, setSearchPhoneNumber] = useState("");
  const [onClearCount, setOnClearCount] = useState(0);
  const [category, setCategory] = useState("");
  const [duration ,setDuration] = useState('');
  // New Address

  const [houseNumber, setHouseNumber] = useState("");
  const [landmark, setLandmark] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [otherAddressType, setOtherAddressType] = useState("");
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [longitude, setLong] = useState(null);
  const [latitude, setLat] = useState(null);
  const [isAddAddress, setIsAddAddress] = useState(false);
  const [addressType, setAddressType] = useState("");

  const handleAddressTypeChange = (value) => {
    setAddressType(value);
  };

  // const handleSaveAddress = (values) => {
  //   console.log("🚀 ~ handleSaveAddress ~ values:", values)
  //   let city = "";
  //   let country = "";
  //   let state = "";
  //   let locality = "";
  //   let zip = "";
  //   if (selectedPlace && selectedPlace?.address_components) {
  //     const addressComponents = selectedPlace?.address_components;
  //     console.log("addressComponents:", addressComponents)
  //     addressComponents.forEach((component) => {
  //       if (component.types.includes("locality")) {
  //         city = component.long_name;
  //       }
  //       if (component.types.includes("sublocality")) {
  //         locality = component.long_name;
  //       }
  //       if (component.types.includes("administrative_area_level_1")) {
  //         state = component.long_name;
  //       }
  //       if (component.types.includes("country")) {
  //         country = component.long_name;
  //       }
  //       if (component.types.includes("postal_town")) {
  //         city = component.long_name;
  //       }
  //       if (component.types.includes("postal_code")) {
  //         zip = component.long_name;
  //       }
  //     });
  //     const Newaddress = {
  //       category: addressType,
  //       location: selectedPlace?.formatted_address || "",
  //       house_no: houseNumber,
  //       locality: locality,
  //       city: city,
  //       state: state,
  //       zip: zip,
  //       landmark: landmark,
  //       country: country,
  //       latitude: latitude,
  //       longitude: longitude,
  //       phone_code: "+91",
  //       phone: mobileNumber || `+91 ${values.phone}`,
  //       otherAddressType: otherAddressType,
  //     };
  //     console.log("SaveAddress ~ Newaddress:", Newaddress)
  //     return Newaddress;
  //   }
  // };
  
  const handleSaveAddress = (values) => {
    console.log("🚀 ~ handleSaveAddress ~ values:", values);
    let city = "";
    let country = "";
    let state = "";
    let locality = "";
    let zip = "";
  
    if (selectedPlace && selectedPlace?.address_components) {
      const addressComponents = selectedPlace?.address_components;
      console.log("addressComponents:", addressComponents);
  
      addressComponents.forEach((component) => {
        if (component.types.includes("locality")) {
          city = component.long_name;
        }
        if (
          component.types.includes("sublocality_level_1") ||
          component.types.includes("sublocality")
        ) {
          locality = component.long_name;
        }
        // Handle sublocality_level_2 if present
        if (component.types.includes("sublocality_level_2")) {
          locality = component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          state = component.long_name;
        }
        if (component.types.includes("country")) {
          country = component.long_name;
        }
        if (component.types.includes("postal_town")) {
          city = component.long_name;
        }
        if (component.types.includes("postal_code")) {
          zip = component.long_name;
        }
      });
  
      // Fallback for locality if not found
      if (!locality && city) {
        locality = city;
      }
  
      const Newaddress = {
        category: addressType,
        location: selectedPlace?.formatted_address || "",
        house_no: houseNumber,
        locality: locality || "Unknown locality", // Fallback to avoid empty locality
        city: city,
        state: state,
        zip: zip,
        landmark: landmark,
        country: country,
        latitude: latitude,
        longitude: longitude,
        phone_code: "+91",
        phone: mobileNumber || `+91 ${values.phone}`,
        otherAddressType: otherAddressType,
      };
  
      console.log("SaveAddress ~ Newaddress:", Newaddress);
      return Newaddress;
    }
  };
  
  console.log("program region:", MRP?.region)


  const handleAddressSelect = (e) => {
    setAddresssaved(e);
    setIsAddAddress(false);
    setAddressType("");
    setMobileNumber("");
    setLandmark("");
    setHouseNumber("");
    setSelectedPlace("");
    setUser(user);
  };
  const handleAddressSaved = (address) => {
    onSubmit(address); // Call onSubmit and pass both form values and address
  };

  // Patient details
  const handleOnSearch = (searchValue) => {
    setUser({});
    // add api here - send searchvalue into api and get the list in console
    props.getAllUser(searchValue).then((response) => {
      const phoneNumbers = response?.value?.data?.data.map((user) => ({
        phone: user.phone,
      }));
      if (phoneNumbers?.length == 0) {
        setSearchPhoneNumber(searchValue);
      } else {
        setSearchPhoneNumber("");
      }
      setPhoneNumberList(phoneNumbers);
      // Assuming the API response contains user data
      // setUser(response?.value?.data?.data);
      // setName(response?.value?.data?.data[0].name);
    });

    // --------------------------
  };

  const handleOnSelect = (data) => {
    props.getAllUser(data).then((response) => {
      const findUser = response.value.data.data?.find(
        (each) => each.phone == data
      );

      setPhoneNumber(findUser?.phone);

      // Assuming the API response contains user data
      setUser(findUser);
      // setName(response?.value?.data?.data[0].name);
    });

    // setPhoneNumber
  };

// user info
  const userInfo = JSON.parse(localStorage.getItem("userObj"));

// Dates

  const TodayDateFormated = moment()?.format("DD/MM/YYYY");
  const formattedDates = selectedDates?.map(
    (date) => date?.format("DD/MM/YYYY") || moment(date).format("DD/MM/YYYY")
  );

  const formattedDate = dob ? dob.format("DD/MM/YYYY") : ""; // Format the single date
  const maxDate1 = new Date(
    today.getFullYear() - 15,
    today.getMonth(),
    today.getDate()
  );
  const handledates = (dates) => {
    setSelectedDates(dates);
  };
  // ADDRESS API 

  useEffect(() => {
    const id = user?.id; // Retrieve the id from formValues

    // Call props.saveAddress() with the id parameter
    props
      .saveAddress(id)
      // Inside your useEffect hook where you're setting addressOption
      .then((response) => {
        // Filter the received data based on the category property
        const filteredAddress = response?.value?.data?.data;
        setAddressOption(filteredAddress);
      })

      .catch((error) => {});
  }, [user.id]); 

  useEffect(() => {
    const Category = selectedService?.id;

    if (Category) {
      props
        .getProgramCategories(`service_id=${Category}`)
        .then((response) => {
          const filtercategory = response?.value?.data?.data;
          setCategory(filtercategory);
        })
        .catch((error) => {
          console.error("Error fetching program categories:", error);
        });
    }
  }, [selectedService?.id]);


  useEffect(() => {
    const Category = selectedService?.id;
    if (Category && programcatgory) {
      props
      .getFilterCategory(`category_name=${programcatgory}&service_id=${Category}`)
      .then((response) => {
        const FinalProgarm = response?.value?.data?.data;
        setFinalprogram(FinalProgarm);
      })
      .catch((error) => {
        console.error("Error fetching program categories:", error);
      });
    }
  
},[selectedService?.id  ,programcatgory])

  const handleCategorySelect = (value) => {
    SetProgramcategory(value)
  };
  // TIME LIST

  const timeOptions = [
    "09:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "01:00 PM",
    "02:00 PM",
    "03:00 PM",
    "04:00 PM",
    "05:00 PM",
    "06:00 PM",
    "07:00 PM",
    "08:00 PM",
    "09:00 PM",
  ];
  const [timeListOptions, setTimeListOptions] = useState([]);

  const handlePreferredTimeList = () => {
    const isToday = formattedDates?.includes(TodayDateFormated);
    if (isToday) {
      const currentHour = parseInt(moment().format("HH"), 10); // Using 24-hour format for easier comparison
      const currentMinute = moment().format("mm");

      const newArray = timeOptions.filter((each) => {
        const [time, period] = each.split(" ");
        let [hour, minute] = time.split(":").map(Number);

        if (period === "PM" && hour !== 12) hour += 12;
        if (period === "AM" && hour === 12) hour = 0;

        // Check if the time is after the current time
        if (hour > currentHour || (hour === currentHour && minute > currentMinute)) {
          return true;
        }

        return false;
      });

      setTimeListOptions(newArray);
    } else {
      setTimeListOptions(timeOptions);
    }
  };

  useEffect(() => {
    handlePreferredTimeList();
  }, [selectedDates]); //

  

// SERVICE AND PROGRAM AND DURATION

  const handleServiceSelect = (e) => {

    // Find the selected object based on the ID
    const selectedObject = categories.find((category) => category.id === e);

    if (selectedObject) {
      setSelectedService(selectedObject);
      setNewCategory(selectedObject?.name);
    } else {
      console.warn("Selected object not found in categories.");
    }
  };
  const handleProgramSelect = (e,children) => {

    // Find the selected program object by its ID
    const selectedProgram = finalprogram.find((program) => program.id === e);
    
    if (selectedProgram) {
      setProgramOptions(e);
      setMRP(selectedProgram);
      setprogname(selectedProgram?.name);
      setDuration(selectedProgram?.duration);
    } else {
      console.warn("Selected program not found.");
    }
  };
  const handleduration  =(values)=>{
    setDuration1(values)
  }

// MRP
  const MRP1 = MRP?.mrp * formattedDates?.length;
  const discounts = MRP?.discount * formattedDates?.length;
  const total_payable = MRP?.mrp * formattedDates?.length - discounts;

// API

  useEffect(() => {
    props.getAllHomeHealthcareServicesList().then((response) => {
      setServiceListOption(response?.value?.data?.data);
    });

    props.getAllHomeHealthcareCaregiverStaff().then((response) => {
      setStaffListOption(response?.value?.data?.data);
    });

    props.getAllHomeHealthcareServices().then((response) => {
      const services_data = response?.value?.data?.data;
      setServiceCategories(services_data);
      const packges_data = [];

      services_data.forEach((item) => {
        const packages = item.packages;
        packages.forEach((data) => {
          packges_data.push({
            package_id: data.id,
            package_name: data.name,
            service_id: item.home_service_id,
            service_name: item.name,
            discount: data.discount,
            mrp: data.mrp,
            price: data.price,
            region: data.region,
          });
        });
      });
      setProgramListOption(packges_data);
      // setMRP(packges_data);
    });
  }, []);

// USER ONCHANGE
  const handleNameChange = (e) => {
    const { value } = e.target;
    setUser((prevValues) => ({
      ...prevValues,
      name: value,
    }));
  };
  const handleEmailChange = (e) => {
    const { value } = e.target;
    setUser((prevValues) => ({
      ...prevValues,
      email: value,
    }));
  };

  const handleDobChange = (date) => {
    // Handle date change and update the state
    setUser((prevValues) => ({
      ...prevValues,
      dob: date,
    }));
    setDob(date); // Update the dob state directly with the selected date
  };

  const handleGenderChange = (value) => {
    // Handle gender change and update the state
    setUser((prevValues) => ({
      ...prevValues,
      gender: value,
    }));
  };



  // submit
  const onSubmit = (values) => {
    
    const address = handleSaveAddress(values);
    const isAddress =
      typeof address?.location === "string" && address?.location?.length;
    const isNewAddress =
      typeof values.address === "string" && values.address?.length;

    if (isAddress || isNewAddress) {
      const addressObj = addressOption?.find(
        (each) => each.location === values?.address
      );
      const selectedRegion =
        address?.locality ||
        addressOption?.find((each) => each.location == values?.address)
          ?.locality;
          console.log("selectedRegion for adress:", selectedRegion)

      const isRegionMatched = MRP.region
        ?.toLowerCase()
        ?.split(",")
        ?.includes(selectedRegion?.toLowerCase());

      if (isRegionMatched) {
        // check panunga

        const discounts = MRP?.discount * formattedDates?.length;
        const total_payable = MRP?.mrp * formattedDates?.length - discounts;
        const payload = {
          home_booking: {
            home_vendor_id: userInfo?.permission.home_vendor_id,
            vendor_name: userInfo?.profile.company_name,
            patient_id: user.id,
            patient_name: values.name,
            reg_phone:`+91 ${values?.phone}`,
            patient_age: "",
            contact_phone: `+91 ${values?.phone}`,
            email: values.email,
            patient_gender: values?.gender,
            latitude: addressObj?.latitude
              ? addressObj.latitude
              : address.latitude,
            longitude: addressObj?.longitude
              ? addressObj.longitude
              : address.longitude,
            module: selectedService?.name,
            program_name: prognam,
            caregiver_gender: giver,
            selected_dates: formattedDates?.join(", "),
            dob: formattedDate || values.dob,
            address: address?.location ? address?.location : values?.address, // Include address if provided
            preferred_time: values?.PreferedTime,
            total_mrp: MRP.mrp * formattedDates?.length,
            total_discount: discounts,
            total_savings: discounts,
            total_payable: total_payable,
            program_duration: duration1,
            mrp: MRP.mrp * formattedDates?.length,
            price: MRP.price,
            discount: discounts,
            total_sessions: formattedDates?.length,
            program_category: values?.category,
          },
          address,
        };

        // Check if the user already exists based on the phone number
        const existingUser = user;

        // Only include patient details if the user does not already exist
        if (!existingUser?.phone) {
          payload.patient = {
            phone_code: "+91",
            phone: values.phone,
            name: values.name,
            email: values.email,
            gender: values.gender,
            dob: formattedDate,
          };
        }
        // if (!existingUser?.phone) {
        //   payload.address = address;
        // }
        console.log("onSubmitpayload", payload);

        props.saveData(payload);
        setUser("");
        setOnClearCount((prev) => prev + 1);
        setMRP("");
        setAddresssaved("");
        setSelectedPlace(null);
        setSelectedDates([]);
      } else {
        message.error("Service  is not available for this address");
      }
    } else {
      message.error("Address cannot be empty");
    }
  };

  // return 
  const otherFieldInput =
    addressType === "Others"
      ? {
          key: "otherInput",
          name: "otherInput",
          label: "Others specify",
          width: 12,
          Component: (
            <Input
              placeholder="Specify Address Type"
              size="large"
              value={otherAddressType}
              onChange={(e) => setOtherAddressType(e.target.value)}
            />
          ),
        }
      : {};

  const NewAddressFields = isAddAddress
    ? [
        {
          key: "newAddress",
          name: "newAddress",
          width: 24, // Adjust the width according to your layout
          Component: (
            <Form.Item
              label="New Address"
              name="newAddress"
              rules={[{ required: true, message: "New address is required!" }]}
            >
              <div style={{ width: "100%", height: "40px" }}>
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyAxjwom-B5LHIh_8MYyq61_lyCqCBohz6Y"
                  onPlaceSelected={(place) => {
                    if (place && place.geometry && place.geometry.location) {
                      const lat = place.geometry.location.lat();
                      const lng = place.geometry.location.lng();
                      setLat(lat);
                      setLong(lng);
                      // console.log("Latitude:", lat, "Longitude:", lng);
                    } else {
                      console.error("Invalid place object:", place);
                    }
                    setSelectedPlace(place);
                  }}
                  options={{
                    types: ["establishment", "geocode"],
                    componentRestrictions: { country: "IN" },
                  }}
                  style={{ width: "100%", height: "40px" }}
                />
              </div>
            </Form.Item>
          ),
        },
        {
          key: "houseNumber",
          name: "houseNumber",
          label: "House No/Flat/Building No",
          width: 12,
          rules: [{ required: true, message: "House No is required!" }],
          Component: (
            <Input
              autoComplete="off"
              placeholder="Enter House No/Flat/Building No"
              size="large"
              value={houseNumber}
              onChange={(e) => setHouseNumber(e.target.value)}
            />
          ),
        },
        {
          key: selectedPlace ? "map" : null,
          name: "map",
          Component: selectedPlace && (
            <div
              style={{
                width: "100%",
                height: "300px",
                marginBottom: "30px",
              }}
            >
              <GoogleMapComponent
                location={{ lat: latitude, lng: longitude }}
              />
            </div>
          ),
        },
        {
          key: "landmark",
          name: "landmark",
          label: "Landmark",
          width: 12,
          rules: [{ required: true, message: "Landmark is required!" }],

          Component: (
            <Input
              autoComplete="off"
              placeholder="Enter Landmark"
              size="large"
              value={landmark}
              onChange={(e) => setLandmark(e.target.value)}
            />
          ),
        },
        {
          key: "mobileNumber",
          name: "mobileNumber",
          label: "Mobile Number",
          width: 12,
          rules: [
            { required: true, message: "Phone number is required!" },
            {
              pattern: /^[0-9]{10}$/,
              message: "Phone number must be 10 digits!",
            },
          ],
          Component: (
            <Input
              autoComplete="off"
              placeholder="Enter Mobile Number"
              size="large"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          ),
        },

        {
          key: "addressType",
          name: "addressType",
          label: "Address Type",
          width: 12,
          rules: [{ required: true, message: "AddressType is required!" }],
          Component: (
            // <>
            <Select
              placeholder="Select Address Type"
              size="large"
              onChange={handleAddressTypeChange}
              value={addressType} // Ensure addressType is initialized correctly
            >
              {addressOption?.find(
                (each) => each.category === "Home"
              ) ? null : (
                <Option value="Home">Home</Option>
              )}
              <Option value="Work">Work</Option>
              <Option value="Others">Others</Option>
            </Select>
            // {/* Additional field for specifying address type if "Others" is selected */}
            // {addressType === "Others" && (
            // <Form.Item label="Please specify">
            // <Input placeholder="Specify Address Type" size="large" value={otherAddressType} onChange={(e) => setOtherAddressType(e.target.value)} />
            // </Form.Item>
            // )}
            // </>
          ),
        },
        otherFieldInput,
      ]
    : [];

  return (
    <Spin spinning={props.Loading === true}>
      <Row gutter={[0, 16]}></Row>
      <ListForm
        programcatgory={programcatgory}
        selectedDates={selectedDates}
        selectedProgram={programOptions}
        onSubmit={onSubmit}
        user={user}
        addressOption={addressOption}
        discounts={discounts}
        MRP1={MRP1}
        newCategory={newCategory}
        formattedDates={formattedDates}
        total_payable={total_payable}
        addressType={addressType}
        formItems={[
          {
            heading: "Patient Details",
          },
          {
            key: "contact_phone",
            label: "Customer Phone Number",
            name: "phone",
            width: 12,
            rules: [
              { required: true, message: "phone number is required!" },
              {
                pattern: /^[0-9]{10}$/,
                message: "Phone number must be 10 digits!",
              },
            ],
            Component: (
              <AutoComplete
                size="large"
                options={phoneNumberList?.map((phoneObj, index) => ({
                  value: phoneObj.phone, // assuming phone is string
                  label: phoneObj.phone, // assuming phone is string
                  key: index.toString(), // use a unique identifier for key
                }))}
                onSelect={handleOnSelect}
                onSearch={handleOnSearch}
                placeholder="Enter phone number"
              />
            ),
          },
          {
            key: "name",
            name: "name",
            label: "Name",
            width: 12,
            rules: [{ required: true, message: "User's name is required!" }],
            Component: (
              <Input
                size="large"
                value={name1}
                placeholder="Enter name"
                onChange={handleNameChange}
              />
            ),
          },

          {
            label: "Date of Birth",
            key: "dob",
            rules: [
              { required: true, message: " Select Date of Birth is required!" },
            ],
            Component: (
              <DatePicker
                placeholder="Enter date of birth"
                value={dob}
                onChange={handleDobChange}
                maxDate={maxDate1}
                currentDate={new DateObject().set({
                  year: today.getFullYear() - 15,
                  format: "DD/MM/YYYY",
                })}
                className="ant-input ant-input-lg"
                style={{ width: "100%" }}
              />
            ),
          },


          {
            key: "gender",
            label: "Patient Gender",
            width: 12,
            rules: [{ required: true, message: "User's gender is required!" }],
            Component: (
              <Select
                placeholder="Select gender"
                size="large"
                // name="gender"
                onChange={(e) => {
                  handleGenderChange(e);
                }}
              >
                <Option value={"Male"}>Male</Option>
                <Option value={"Female"}>Female</Option>
              </Select>
            ),
          },
          {
            key: "email",
            label: "Email ID",
            name: "email",
            width: 12,
            // rules: [{ required: true, message: "  User's email !" }],
            Component: (
              <Input
                size="large"
                onChange={handleEmailChange}
                placeholder="Enter email"
              />
            ),
          },
          // {
          //   key: "address",
          //   label: "Address",
          //   name: "address",
          //   width: 12,
          //   Component: <Input size="large"  placeholder="Enter Address" />,
          // },
          {
            heading: "Booking Details",
          },
          {
            key: "service",
            label: "Service",
            width: 12,
            rules: [{ required: true, message: "User's service is required!" }],
            Component: (
              <Select
                showSearch
                placeholder="Select service"
                size="large"
                onChange={handleServiceSelect}
              >
                {categories &&
                  categories.map((val) => (
                    <Option key={val.id} value={val.id}>
                      {val.name}
                    </Option>
                  ))}
              </Select>
            ),
          },
          {
            key: "category",
            label: "Category",
            width: 8,
            rules: [{ required: true, message: "User's Category is required !" }],
            Component: (
              <Select
                autoComplete="off"
                placeholder="Select category"
                size="large"
                onChange={handleCategorySelect}
              >
                {category &&
                  category.map((val, index) => (
                    <Option 
            key={val} 
            value={val}
          >
            {val}
          </Option>
                  ))}
              </Select>
            ),
          },
          {
            key: "program",
            label: "Program",
            width: 12,
            rules: [
              { required: true, message: "User's program is required! " },
            ],
            Component: (
              <Select
                onChange={handleProgramSelect}
                showSearch
                placeholder="Select program"
                size="large"
              >
                {finalprogram 
                      .map((val) => (
                        <Option key={val.id} value={val.id}>
                          {val.name}
                        </Option>
                      ))
                }
              </Select>
            ),
          },
          {
            key: "programduration",
            label: "Service Duration",
            width: 8,
            rules: [{ required: true, message: "Please select duration!" }],
            Component: (
              <Select
                autoComplete="off"
                placeholder="Select duration"
                size="large"
                onChange={handleduration}
              >
                  <Option key={duration} values={duration}>
                    {duration}
                  </Option>
                
              </Select>
            ),
          },

          {
            key: "Prefered caregiver",
            label: "Prefered caregiver",
            name: "prefered caregiver",
            width: 12,
            rules: [
              { required: true, message: "User's cargiver is required!" },
            ],
            Component: (
              <Select
                placeholder="Select caregiver"
                size="large"
                name="gender"
                onChange={(e) => {
                  setcaregiver(e);
                }}
              >
                <Option value={"Male"}>Male</Option>
                <Option value={"Female"}>Female</Option>
              </Select>
            ),
          },
          {
            key: "dates",
            label: "Select Date",
            rules: [{ required: true, message: "Select Date's is required!" }],
            Component: (
              <DatePicker
                className="ant-input ant-input-lg"
                multiple
                value={selectedDates}
                onChange={handledates}
                style={{ width: "100%" }} // Adjust the width value as needed
                minDate={today}
                maxDate={maxDate}
                format="DD/MM/YYYY"
                plugins={[<DatePanel />]}
              />
            ),
          },
          {
            key: "PreferedTime",
            label: "Prefered Time",
            width: 12,
            rules: [{ required: true, message: " Time is required!" }],
            Component: (
              <Select
                size="large"
                placeholder="Select time"
                className="ant-input ant-input-lg"
              >
                {timeListOptions.map((option) => (
                  <Option key={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            ),
          },

          {
            heading: "Address Details",
          },
          {
            key: "address",
            label: "Address",
            width: 12,
            rules: [
              { required: !isAddAddress, message: "Address is required!" },
            ],
            Component: (
              <Select
                placeholder="Select address"
                size="large "
                className="ant-select-lg"
                name="address"
                onChange={(e) => {
                  handleAddressSelect(e);
                }}
                value={addresssaved}
              >
                {addressOption &&
                  addressOption.map((val) => (
                    <Option key={val.id} value={val.location}>
                      {val.location}
                    </Option>
                  ))}
              </Select>
            ),
          },
          {
            name: "button",
            Component: (
              <Button
                onClick={() => {
                  setIsAddAddress((prev) => !prev);
                  setAddresssaved("");
                  setUser(user);
                }}
              >
                {isAddAddress ? "Close" : "Add New Address"}

                {/* {isAddAddress  ? "true" : "false"} */}
              </Button>
            ),
          },
          ...NewAddressFields,
        ]}
        onAddressSaved={handleAddressSaved} // Pass the handleAddressSaved function
        key={`${onClearCount}`}
        addressData={addresssaved}
      />
    </Spin>
  );
}
export default BookForm;
