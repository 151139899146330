import React from 'react';
import {
  Form, Row, Col, Input, Button, Typography,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const { Title } = Typography;

const AdvancedSearchForm = ({
  formItems = [],
  onSearch,
  onClose,
  clearSelectedService,
  title,
  columnWidth = {
    xs: 24,
    md: 12,
    lg: 6,
  },
}) => {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      name="advanced_search"
      className="ant-advanced-search-form"
      onFinish={onSearch}
      layout="vertical"
      // style={{
      //   margin: '25px 0px',
      //   background: '#ededed',
      //   padding: ' 25px',
      //   borderRadius: ' 15px',
      // }}
    >
      {title && (
      <Title
        level={4}
        className="search-heading"
      >
        Search <span onClick={onClose}><CloseOutlined /></span>
      </Title>
      )}
      <Row gutter={24}>
        {formItems.map(({ Component, ...formItem }) => (
          <Col xs={columnWidth.xs} md={columnWidth.md} lg={columnWidth.lg} key={formItem.key} className="gutter-row" span={6} offset={0}>
            <Form.Item name={formItem.key} {...formItem} label={formItem.label}>
              {Component || <Input autoComplete='off' placeholder={formItem.label} size="large" />}
            </Form.Item>
          </Col>
        ))}
      </Row>
      <Row>
        <Col
          span={24}
          className="buttons"
          style={{
            textAlign: 'center',
          }}
        >
          <Button
            style={{
              margin: '0 8px',
            }}
            className="clear-button"
            onClick={async () => {
              await form.resetFields();
              onSearch({});
              clearSelectedService({});
            }}
          >
            Clear
          </Button>
          <Button type="primary" htmlType="submit" className="search-button">
            Search
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AdvancedSearchForm;
