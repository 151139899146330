import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Typography,
  Form,
  Input,
  Select,
  Button,
  message,
  Row,
  Col,
  Spin,
  Modal,
  Divider,
  Space,
  AutoComplete,
} from "antd";
import axios from "axios";
import moment from "moment";
import ListForm from "./listform";
import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import GoogleMapComponent from "../../../common/googlemap";
import GooglePlacesAutocomplete from "react-google-autocomplete";
import { values } from "lodash";
import PhoneNumberInput from "../bookingLabVendor/phonenumber";
const { Title } = Typography;
const { Option } = Select;

const today = new Date();
const maxDate = new Date(today.getFullYear(), today.getMonth() + 2, 0);
const maxDate1 = new Date(
  today.getFullYear() - 15,
  today.getMonth(),
  today.getDate()
);
function BookForm(props) {
  const [code, setCountryCode] = useState([]);
  const [phoneCode, setPhoneCode] = useState("+91");
  const [newNumber, setNewNumber] = useState("");
  // State to hold user information
  const [phoneNumberList, setPhoneNumberList] = useState([]);
  const [searchPhoneNumber, setSearchPhoneNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [addressOption, setAddressOption] = useState("");
  const inputRef = useRef();
  const [dob, setDob] = useState("");
  console.log("dob", dob);
  const [name1, setName] = useState("");
  // lab branch and service and program
  const [branchList, setBranchListOption] = useState([null]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  // State to hold selected branch's data
  const [currentPage, setCurrentPage] = useState(1);
  const [programList1, setprogramListOption1] = useState([]);
  const [programList2, setprogramListOption2] = useState([]);
  const [programList3, setprogramListOption3] = useState([]);

  const [selectedService, setSelectedService] = useState("");
  const [selectedProgram, setSelectedProgram] = useState(undefined); // State to hold selected program's data

  const handleServiceChange = (value) => {
    setSelectedService(value);
    setSelectedProgram("");
    getProgramOptions(""); // Reset selected program when service changes
  };
  const getProgramOptions = () => {
    if (selectedService === "lab_near") {
      return [
        ...programList1.filter((program) => program.status === true),
        ...programList2.filter((program) => program.status === true),
      ];
    } else if (selectedService === "lab_home") {
      return [
        ...programList1.filter(
          (program) => program.home_status === true && program.status === true
        ),
        ...programList2.filter(
          (program) => program.home_status === true && program.status === true
        ),
      ];
    } else if (selectedService === "diagnostic_near") {
      return programList3.filter((program) => program.status === true);
    }
    return [];
  };
  const handleProgramChange = (value) => {
    const allPrograms = [...programList1, ...programList2, ...programList3];
    const selected = allPrograms.find((program) => program.id === value);
    setSelectedProgram(selected);
  };
  // distnce

  const [collectionFees, setCollectionFees] = useState(null);
  const haversineDistance = (coords1, coords2) => {
    const toRad = (x) => (x * Math.PI) / 180;
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = toRad(coords2.latitude - coords1.latitude);
    const dLon = toRad(coords2.longitude - coords1.longitude);
    const lat1 = toRad(coords1.latitude);
    const lat2 = toRad(coords2.latitude);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
  };

  // const [collectionFees, setCollectionFees] = useState(null);

  // const toRad = (angle) => (angle * Math.PI) / 180;

  // const vincentyDistance = (coords1, coords2) => {
  //   const a = 6378137; // Semi-major axis of the Earth in meters
  //   const f = 1 / 298.257223563; // Flattening of the Earth
  //   const b = 6356752.314245; // Semi-minor axis

  //   const L = toRad(coords2.longitude - coords1.longitude);
  //   const U1 = Math.atan((1 - f) * Math.tan(toRad(coords1.latitude)));
  //   const U2 = Math.atan((1 - f) * Math.tan(toRad(coords2.latitude)));
  //   const sinU1 = Math.sin(U1);
  //   const cosU1 = Math.cos(U1);
  //   const sinU2 = Math.sin(U2);
  //   const cosU2 = Math.cos(U2);

  //   let lambda = L;
  //   let lambdaP;
  //   let iterLimit = 100;
  //   let cosSqAlpha;
  //   let sinSigma;
  //   let cos2SigmaM;
  //   let cosSigma;
  //   let sigma;

  //   do {
  //     const sinLambda = Math.sin(lambda);
  //     const cosLambda = Math.cos(lambda);
  //     sinSigma = Math.sqrt(
  //       (cosU2 * sinLambda) * (cosU2 * sinLambda) +
  //       (cosU1 * sinU2 - sinU1 * cosU2 * cosLambda) * (cosU1 * sinU2 - sinU1 * cosU2 * cosLambda)
  //     );
  //     if (sinSigma === 0) return 0; // Co-incident points
  //     cosSigma = sinU1 * sinU2 + cosU1 * cosU2 * cosLambda;
  //     sigma = Math.atan2(sinSigma, cosSigma);
  //     const sinAlpha = cosU1 * cosU2 * sinLambda / sinSigma;
  //     cosSqAlpha = 1 - sinAlpha * sinAlpha;
  //     cos2SigmaM = cosSigma - 2 * sinU1 * sinU2 / cosSqAlpha;
  //     const C = f / 16 * cosSqAlpha * (4 + f * (4 - 3 * cosSqAlpha));
  //     lambdaP = lambda;
  //     lambda = L + (1 - C) * f * sinAlpha *
  //       (sigma + C * sinSigma * (cos2SigmaM + C * cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM)));
  //   } while (Math.abs(lambda - lambdaP) > 1e-12 && --iterLimit > 0);

  //   if (iterLimit === 0) return NaN; // Formula failed to converge

  //   const uSq = cosSqAlpha * (a * a - b * b) / (b * b);
  //   const A = 1 + uSq / 16384 * (4096 + uSq * (-768 + uSq * (320 - 175 * uSq)));
  //   const B = uSq / 1024 * (256 + uSq * (-128 + uSq * (74 - 47 * uSq)));
  //   const deltaSigma = B * sinSigma * (
  //     cos2SigmaM + B / 4 * (cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM) -
  //     B / 6 * cos2SigmaM * (-3 + 4 * sinSigma * sinSigma) * (-3 + 4 * cos2SigmaM * cos2SigmaM))
  //   );

  //   const distance = b * A * (sigma - deltaSigma);
  //   return distance / 1000; // Convert to kilometers
  // };

  const [User, setUser] = useState("");

  const formattedDate = dob ? dob.format("DD/MM/YYYY") : ""; // Format the single date
  console.log("Selected Date:", formattedDate);

  const calculateAge = (dob) => {
    const now = moment();
    const age = now.diff(dob, "years");
    return age;
  };

  useEffect(() => {
    const id = User?.id; // Retrieve the id from formValues

    // Call props.saveAddress() with the id parameter
    props
      .saveAddress(id)
      // Inside your useEffect hook where you're setting addressOption
      .then((response) => {
        // Filter the received data based on the category property
        const filteredAddress = response?.value?.data?.data;
        setAddressOption(filteredAddress);
      })

      .catch((error) => {});
  }, [User.id]); // Make sure useEffect is triggered when formValues.id changes
  // New Address

  const [houseNumber, setHouseNumber] = useState("");
  const [landmark, setLandmark] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [otherAddressType, setOtherAddressType] = useState("");
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [longitude, setLong] = useState(null);
  const [latitude, setLat] = useState(null);
  const [isAddAddress, setIsAddAddress] = useState(false);
  const [addressType, setAddressType] = useState("");
  const [addresssaved, setAddresssaved] = useState("");
  const [newaddress, setNewAddress] = useState("");
  const [onClearCount, setOnClearCount] = useState(0);

  const handleAddressTypeChange = (value) => {
    setAddressType(value);
  };

  const handleBranchChange = (value) => {
    const selected = branchList.find((branch) => branch?.id === value);
    setSelectedBranch(selected);
    calculateDistance(selected, selectedAddress);
  };

  const handleAddressSelect = (value) => {
    const selected = addressOption.find(
      (address) => address?.location === value
    );
    setAddresssaved(value);
    setSelectedAddress(selected);
    calculateDistance(selectedBranch, selected);
    setIsAddAddress(false);
    setAddressType("");
    setMobileNumber("");
    setLandmark("");
    setHouseNumber("");
    setSelectedPlace("");
    setUser(User);
  };

  const handlenewAddressSelect = (lat, lng) => {
    // Round latitude and longitude values to 7 decimal places
    const roundedLng = parseFloat(lng).toFixed(7);
    const roundedLat = parseFloat(lat).toFixed(7);

    // Create a new address object with rounded latitude and longitude
    const newAddress = {
      latitude: roundedLat,
      longitude: roundedLng,
    };

    // Call calculateDistance with the new address
    calculateDistance(selectedBranch, newAddress);
  };

  const apiKey = "AIzaSyAxjwom-B5LHIh_8MYyq61_lyCqCBohz6Y";

  const calculateDistance = (origin, destination) => {
    console.log("Calculating distance",origin ,destination)
    return new Promise((resolve, reject) => {
      if (!window.google || !window.google.maps) {
        reject("Google Maps JavaScript API not loaded.");
        return;
      }

      const originLatLng = new window.google.maps.LatLng(
        origin?.latitude,
        origin?.longitude
      );
      const destinationLatLng = new window.google.maps.LatLng(
        destination?.latitude,
        destination?.longitude
      );
      const config =(
        origin?.collection_fee,
        origin?.max_distance,
        origin?.min_distance
      )
      console.log("config ",config)
      const service = new window.google.maps.DistanceMatrixService();

      service.getDistanceMatrix(
        {
          origins: [originLatLng],
          destinations: [destinationLatLng],
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          let collectionFee = 0;
          if (status === window.google.maps.DistanceMatrixStatus.OK) {
            if (response.rows[0].elements[0].hasOwnProperty("distance")) {
              const distanceInMeters =
                response.rows[0].elements[0].distance.value;
              const distanceInKm = distanceInMeters / 1000;
              console.log("returnnewPromise ~ distanceInKm:", distanceInKm);
              resolve(distanceInKm);
              const roundedDistanceInKm = Math.round(distanceInKm);
              console.log("roundedDistanceInKm", roundedDistanceInKm);
              const formattedDistance = `${roundedDistanceInKm} km`;
              if (roundedDistanceInKm <= origin?.min_distance) {
                collectionFee = 0;
              } else if (
                roundedDistanceInKm > origin?.min_distance &&
                roundedDistanceInKm <= origin?.max_distance
              ) {
                collectionFee = origin?.collection_fee;
              } else {
                // Service not provided for locations beyond 20 km
                message.error("Service not provided for locations beyond " + origin?.max_distance + " km");
                setCollectionFees(null); // Reset the collection fee
                return;
              }
            } else {
              // Service not provided for locations beyond 20 km
              message.error("Service not provided for locations beyond " + origin?.max_distance + " km");
              setCollectionFees(null); // Reset the collection fee
              return;
            }

            setCollectionFees(collectionFee);
          } else {
            reject(`Error: ${status}`);
          }
        }
      );
    });
  };

  // const calculateDistance = (branch, address) => {
  //   console.log("branch", branch);
  //   console.log("yellow", address);

  //   if (branch && address) {
  //     const distanceInKm = haversineDistance(
  //       { latitude: parseFloat(branch.latitude), longitude: parseFloat(branch.longitude) },
  //       { latitude: parseFloat(address.latitude), longitude: parseFloat(address.longitude) }
  //     );
  //     console.log("distanceInKm", distanceInKm);
  //       const roundedDistanceInKm = Math.round(distanceInKm);
  //     const formattedDistance = `${roundedDistanceInKm} km`;
  //     console.log("formattedDistance", formattedDistance);
  //     let collectionFee = 0;
  //     if (roundedDistanceInKm <= 10) {
  //       collectionFee = 0;
  //     } else if (roundedDistanceInKm > 10 && roundedDistanceInKm <= 20) {
  //       collectionFee = 100;
  //     } else {
  //       // Service not provided for locations beyond 20 km
  //       message.error("Service not provided for locations beyond 20 km");
  //       setCollectionFees(null); // Reset the collection fee
  //       return;
  //     }

  //     setCollectionFees(collectionFee);
  //   }
  // };

  // const calculateDistance = (branch, address) => {
  //   if (branch && address) {
  //     // Make an HTTP request to the Distance Matrix API using Axios
  //     axios.get('https://maps.googleapis.com/maps/api/distancematrix/json', {
  //       params: {
  //         origins: `${branch.latitude},${branch.longitude}`,
  //         destinations: `${address.latitude},${address.longitude}`,
  //         key: '7A3MXb5pTzE7zMuBRspN'
  //       }
  //     })
  //     .then(response => {
  //       // Parse the response and extract the distance
  //       const distanceText = response.data.rows[0].elements[0].distance.text;
  //       setCollectionFees(distanceText); // Save the distance
  //     })
  //     .catch(error => {
  //       console.error('Error:', error);
  //     });
  //   }
  // };

  const handleSaveAddress = (values) => {
    let city = "";
    let country = "";
    let state = "";
    let locality = "";
    let zip = "";
    if (selectedPlace && selectedPlace?.address_components) {
      const addressComponents = selectedPlace?.address_components;
      addressComponents.forEach((component) => {
        if (component.types.includes("locality")) {
          city = component.long_name;
        }
        if (component.types.includes("sublocality")) {
          locality = component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          state = component.long_name;
        }
        if (component.types.includes("country")) {
          country = component.long_name;
        }
        if (component.types.includes("postal_town")) {
          city = component.long_name;
        }
        if (component.types.includes("postal_code")) {
          zip = component.long_name;
        }
      });
      const Newaddress = {
        category: addressType,
        location: selectedPlace?.formatted_address || "",
        house_no: houseNumber,
        locality: locality,
        city: city,
        state: state,
        zip: zip,
        landmark: landmark,
        country: country,
        latitude: latitude,
        longitude: longitude,
        phone_code: phoneCode,
        phone: mobileNumber || `${phoneCode} ${values?.phone}`,
        otherAddressType: otherAddressType,
      };
      return Newaddress;
    }
  };
  const handlePlaceSelected = (place) => {
    if (place && place.geometry && place.geometry.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setLat(lat);
      setLong(lng);
      setSelectedPlace(place);

      // Call handleAddressSelect with the selected place's name
      handlenewAddressSelect(lat, lng);
    } else {
      console.error("Invalid place object:", place);
    }
  };
  const handleAddressSaved = (address) => {
    onSubmit(address); // Call onSubmit and pass both form values and address
  };
  const handleNameChange = (e) => {
    const { value } = e.target;
    setUser((prevValues) => ({
      ...prevValues,
      name: value,
    }));
  };
  const handleEmailChange = (e) => {
    const { value } = e.target;
    setUser((prevValues) => ({
      ...prevValues,
      email: value,
    }));
  };

  const handleDobChange = (date) => {
    // Handle date change and update the state
    setUser((prevValues) => ({
      ...prevValues,
      dob: date,
    }));
    setDob(date); // Update the dob state directly with the selected date
  };

  const handleGenderChange = (value) => {
    // Handle gender change and update the state
    setUser((prevValues) => ({
      ...prevValues,
      gender: value,
    }));
  };
  useEffect(() => {
    props.getAllUser().then((response) => {
      const user = response?.value?.data?.data;
      setUser(user);
    });
  }, []);
  // patient details
  const handleOnSearch = (searchValue) => {
    setUser({});
    // add api here - send searchvalue into api and get the list in console
    props.getAllUser(searchValue).then((response) => {
      const phoneNumbers = response?.value?.data?.data.map((user) => ({
        phone: user.phone,
      }));
      if (phoneNumbers?.length == 0) {
        setSearchPhoneNumber(searchValue);
      } else {
        setSearchPhoneNumber("");
      }
      setPhoneNumberList(phoneNumbers);
      // Assuming the API response contains user data
      // setUser(response?.value?.data?.data);
      // setName(response?.value?.data?.data[0].name);
    });
  };
  // const handleOnSelect = (data) => {
  //   props.getAllUser(data).then((response) => {
  //     const findUser = response.value.data.data?.find(
  //       (each) => each.phone == data
  //     );

  //     setPhoneNumber(findUser?.phone);

  //     setUser(findUser);
  //   });

  // };

  const handleOnSelect = (data) => {
    props.getAllUser(data).then((response) => {
      const findUser = response.value.data.data?.find(
        (each) => each.phone == data
      );

      setPhoneNumber(findUser?.phone);
      setUser(findUser);
    });
  };

  const userInfo = JSON.parse(localStorage.getItem("userObj"));
  console.log("🚀 ~ BookForm ~ userInfo:", userInfo);

  // selected dates and prefered time
  const timeOptions = [
    "06:00 AM - 07:00 AM",
    "07:00 AM - 08:00 AM",
    "08:00 AM - 09:00 AM",
    "09:00 AM - 10:00 AM",
    "10:00 AM - 11:00 AM",
    "11:00 AM - 12:00 PM",
    "12:00 PM - 01:00 PM",
    "01:00 PM - 02:00 PM",
    "02:00 PM - 03:00 PM",
    "03:00 PM - 04:00 PM",
    "04:00 PM - 05:00 PM",
    "05:00 PM - 06:00 PM",
    "06:00 PM - 07:00 PM",
    "07:00 PM - 08:00 PM",
    "08:00 PM - 09:00 PM",
  ];

  const [selectedDates, setSelectedDates] = useState(moment());
  const [timeListOptions, setTimeListOptions] = useState(timeOptions);

  const todayDateFormatted = moment().format("DD/MM/YYYY");
  console.log("🚀 ~ BookForm ~ todayDateFormatted:", todayDateFormatted);

  const DateFormatted = selectedDates.format("DD/MM/YYYY");
  console.log("DateFormatted", DateFormatted);

  const handlePreferredTimeList = () => {
    const selectedDateFormatted = selectedDates.format("DD/MM/YYYY");
    console.log(
      "🚀 ~ handlePreferredTimeList ~ selectedDateFormatted:",
      selectedDateFormatted
    );
    const isToday = selectedDateFormatted === todayDateFormatted;

    if (isToday) {
      const currentHour = moment().hour();
      const currentMinute = moment().minute();

      const newArray = timeOptions.filter((each) => {
        const [startTime] = each.split(" - ");
        const startMoment = moment(startTime, "hh:mm A");

        return (
          startMoment.hour() > currentHour ||
          (startMoment.hour() === currentHour &&
            startMoment.minute() > currentMinute)
        );
      });

      setTimeListOptions(newArray);
    } else {
      setTimeListOptions(timeOptions);
    }
  };

  useEffect(() => {
    handlePreferredTimeList();
  }, [selectedDates]);

  // lab mange,ent

  useEffect(() => {
    props.getAllLabCompanyBranches().then((response) => {
      setBranchListOption(response?.value?.data?.data);
    });
    props.getAllLabTests(undefined, 20, currentPage).then((response) => {
      setprogramListOption1(response?.value?.data?.data);
    });
    props.getAllLabPackages(undefined, 20, currentPage).then((response) => {
      setprogramListOption2(response?.value?.data?.data);
    });
    props
      .getAllVendorLabCatalogDiagnostics(undefined, 20, currentPage)
      .then((response) => {
        setprogramListOption3(response?.value?.data?.data);
      });
    props.getAllCountryCodes().then((response) => {
      setCountryCode(response?.value?.data?.data);
    });
  }, []);
  const otherFieldInput =
    addressType === "Others"
      ? {
          key: "otherInput",
          name: "otherInput",
          label: "Others specify",
          width: 12,
          Component: (
            <Input
              placeholder="Specify Address Type"
              size="large"
              value={otherAddressType}
              onChange={(e) => setOtherAddressType(e.target.value)}
            />
          ),
        }
      : {};

  const NewAddressFields = isAddAddress
    ? [
        {
          key: "newAddress",
          name: "newAddress",
          width: 24, // Adjust the width according to your layout
          Component: (
            <Form.Item
              label="New Address"
              name="newAddress"
              rules={[{ required: true, message: "New address is required!" }]}
            >
              {/* <div style={{ width: "100%", height: "40px" }}>
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyAxjwom-B5LHIh_8MYyq61_lyCqCBohz6Y"
                  onPlaceSelected={(place) => {
                    console.log("Selected Place:", place);
                    if (place && place.geometry && place.geometry.location) {
                      const lat = place.geometry.location.lat();
                      const lng = place.geometry.location.lng();
                      setLat(lat);
                      setLong(lng);
                      console.log("Latitude:", lat, "Longitude:", lng);
                    } else {
                      console.error("Invalid place object:", place);
                    }
                    setSelectedPlace(place);
                  }}
                  options={{
                    types: ["establishment","geocode"],
                    componentRestrictions: { country: "IN" },
                  }}
                  style={{ width: "100%", height: "40px" }}
                />
              </div> */}
              <div style={{ width: "100%", height: "40px" }}>
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyAxjwom-B5LHIh_8MYyq61_lyCqCBohz6Y"
                  onPlaceSelected={handlePlaceSelected} // Assign handlePlaceSelected to onPlaceSelected
                  options={{
                    types: ["establishment", "geocode"],
                    componentRestrictions: { country: "IN" },
                  }}
                  style={{ width: "100%", height: "40px" }}
                />
              </div>
            </Form.Item>
          ),
        },
        {
          key: "houseNumber",
          name: "houseNumber",
          label: "House No/Flat/Building No",
          width: 12,
          rules: [{ required: true, message: "House No is required!" }],
          Component: (
            <Input
              autoComplete="off"
              placeholder="Enter House No/Flat/Building No"
              size="large"
              value={houseNumber}
              onChange={(e) => setHouseNumber(e.target.value)}
            />
          ),
        },
        {
          key: selectedPlace ? "map" : null,
          name: "map",
          Component: selectedPlace && (
            <div
              style={{
                width: "100%",
                height: "300px",
                marginBottom: "30px",
              }}
            >
              <GoogleMapComponent
                location={{ lat: latitude, lng: longitude }}
              />
            </div>
          ),
        },
        {
          key: "landmark",
          name: "landmark",
          label: "Landmark",
          width: 12,
          rules: [{ required: true, message: "Landmark is required!" }],

          Component: (
            <Input
              autoComplete="off"
              placeholder="Enter Landmark"
              size="large"
              value={landmark}
              onChange={(e) => setLandmark(e.target.value)}
            />
          ),
        },
        {
          key: "mobileNumber",
          name: "mobileNumber",
          label: "Mobile Number",
          width: 12,
          rules: [
            { required: true, message: "Phone number is required!" },
            {
              pattern: /^[0-9]{10}$/,
              message: "Phone number must be 10 digits!",
            },
          ],
          Component: (
            <Input
              autoComplete="off"
              placeholder="Enter Mobile Number"
              size="large"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          ),
        },

        {
          key: "addressType",
          name: "addressType",
          label: "Address Type",
          width: 12,
          rules: [{ required: true, message: "AddressType is required!" }],
          Component: (
            // <>
            <Select
              placeholder="Select Address Type"
              size="large"
              onChange={handleAddressTypeChange}
              value={addressType} // Ensure addressType is initialized correctly
            >
              {addressOption?.find(
                (each) => each.category === "Home"
              ) ? null : (
                <Option value="Home">Home</Option>
              )}
              <Option value="Work">Work</Option>
              <Option value="Others">Others</Option>
            </Select>
          ),
        },
        otherFieldInput,
      ]
    : [];

  const total_payable =
    selectedProgram?.mrp - selectedProgram?.discount + collectionFees;
  const total_mrp = selectedProgram?.mrp;
  const total_savings = selectedProgram?.discount;
  const total_discount = selectedProgram?.discount;

  const onSubmit = (values) => {
    const phoneNumber = `${phoneCode} ${values?.phone}`;

    const age = calculateAge(formattedDate || values?.dob);
    console.log("age:", age);

    console.log("onSubmit values:", values.module);
    const DateFormatted = values.schedule_date.format("DD/MM/YYYY");
    const commonPayload = {
      lab_order: {
        lab_branch_id: selectedBranch?.id,
        module: values?.module,
        schedule_date: DateFormatted,
        schedule_time: values?.PreferedTime,
        patient_id: User.id ? User.id : "",
        patient_name: values?.name,
        patient_age: age ? age : "",
        patient_gender: values?.gender,
        address: "",
        latitude: "",
        longitude: "",
        reg_phone: phoneNumber,
        contact_phone: phoneNumber,
        total_payable: total_payable,
        total_savings: total_savings,
        total_mrp: total_mrp,
        total_discount: total_savings,
        additional_discount: total_savings,
        collection_fee: collectionFees ? collectionFees : 0,
      },
      lab_payment: {
        total_amount: total_payable,
        mode: "COD",
        mode_type: "Cash on Collection",
      },
      lab_items: [
        {
          test_id: selectedProgram?.id,
          lab_test_id: selectedProgram?.id,
          mode: selectedProgram?.mode,
          mrp: selectedProgram?.mrp,
          price: selectedProgram?.price,
          discount: selectedProgram?.discount,
        },
      ],
    };
    // Check if the user already exists based on the phone number
    const existingUser = User;
    // Only include patient details if the user does not already exist
    if (!existingUser?.phone) {
      commonPayload.patient = {
        phone_code: phoneCode,
        phone: values?.phone,
        name: values?.name,
        email: values?.email,
        gender: values?.gender,
        dob: DateFormatted,
      };
    }
    if (values?.module === "diagnostic_near") {
      props.saveLabData2(commonPayload);
      setUser("");
      setOnClearCount((prev) => prev + 1);
      setAddresssaved("");
      setSelectedPlace(null);
      setSelectedProgram("");
      setCollectionFees("");
    } else if (values?.module === "lab_near") {
      console.log("meera", commonPayload);
      props.saveLabData1(commonPayload);
      setUser("");
      setOnClearCount((prev) => prev + 1);
      setAddresssaved("");
      setSelectedPlace(null);
      setSelectedProgram("");
      setCollectionFees("");
    } else {
      // Construct additional parts for other modules
      const address = handleSaveAddress(values);
      const isAddress =
        typeof address?.location === "string" && address?.location?.length;
      const isNewAddress =
        typeof values?.address === "string" && values?.address?.length;
      if (isAddress || isNewAddress) {
        const addressObj = addressOption?.find(
          (each) => each.location === values?.address
        );

        // Add address details to the payload
        const addressPayload = {
          ...commonPayload,
          lab_order: {
            ...commonPayload.lab_order,
            address: address?.location ? address.location : values.address, // Include address if provided
            latitude: addressObj?.latitude
              ? addressObj.latitude
              : address.latitude,
            longitude: addressObj?.longitude
              ? addressObj.longitude
              : address.longitude,
          },
          address,
        };

        // Check if collectionFees is not null
        if (collectionFees != null) {
          console.log("onSubmit payload", addressPayload);
          props.saveLabData(addressPayload);
          setUser("");
          setOnClearCount((prev) => prev + 1);
          setAddresssaved("");
          setSelectedPlace(null);
          setSelectedProgram("");
          setCollectionFees("");
        } else {
          message.error("Service not provided for locations beyond 20 km");
        }
      } else {
        message.error("Address cannot be empty");
      }
    }
  };
  return (
    <Spin spinning={props.Loading === true}>
      {/* Patient Details Section */}
      <Row gutter={[0, 16]}></Row>
      <ListForm
        selectedDates={selectedDates}
        addressOption={addressOption}
        addressType={addressType}
        User={User}
        total_mrp={total_mrp}
        total_payable={total_payable}
        total_savings={total_savings}
        total_discount={total_discount}
        selectedService={selectedService}
        onSubmit={onSubmit}
        collectionFees={collectionFees}
        formItems={[
          {
            heading: "Branch Details",
          },
          {
            key: "lab_branch_id",
            label: "Select branch",
            width: 12,
            rules: [{ required: true, message: " Branch is required!" }],
            Component: (
              <Select
                size="large"
                placeholder="Select Branch"
                className="ant-input ant-input-lg"
                onChange={handleBranchChange}
              >
                {branchList?.map((option) => (
                  <Option key={option?.name} value={option?.id}>
                    {option?.name}
                  </Option>
                ))}
              </Select>
            ),
          },

          {
            heading: "Patient Details",
          },
          {
            key: "contact_phone",
            label: "Customer Phone Number",
            name: "phone",
            width: 12,
            rules: [{ required: true, message: "Phone number is required!" }],
            Component: (
              <Input.Group
                compact
                validateTrigger={["onBlur", "onChange"]}
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.phone !== currentValues.phone
                }
                style={{ width: "100%" }}
              >
                <Select
                  value={phoneCode}
                  size="large"
                  onSelect={(value) => setPhoneCode(value)}
                  style={{ width: "25%" }}
                >
                  {code?.map((program) => (
                    <Option key={program?.id} value={program?.id}>
                      {program?.phone_code}
                    </Option>
                  ))}
                  {/* Add more options as needed */}
                </Select>
                <Form.Item
                  name="phone"
                  rules={[
                    // { required: true, message: "Phone number is required!" },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Phone number must be 10 digits!",
                    },
                  ]}
                  noStyle
                >
                  <AutoComplete
                    className="autoCompletewidth"
                    size="large"
                    options={phoneNumberList?.map((phoneObj, index) => ({
                      value: phoneObj.phone,
                      label: phoneObj.phone,
                      key: index.toString(),
                    }))}
                    onSelect={handleOnSelect}
                    onSearch={handleOnSearch}
                    placeholder="Enter phone number"
                    style={{ width: "75%" }}
                  />
                </Form.Item>
              </Input.Group>
            ),
          },
          {
            key: "name",
            name: "name",
            label: "Name",
            width: 12,
            rules: [{ required: true, message: "User's name is required!" }],
            Component: (
              <Input
                size="large"
                value={name1}
                placeholder="Enter name"
                onChange={handleNameChange}
              />
            ),
          },
          {
            label: "Date of Birth",
            key: "dob",
            rules: [
              { required: true, message: " Select Date of Birth is required!" },
            ],
            Component: (
              <DatePicker
                placeholder="Enter Date of birth"
                value={dob}
                onChange={handleDobChange}
                maxDate={maxDate1}
                currentDate={new DateObject().set({
                  year: today.getFullYear() - 15,
                  format: "DD/MM/YYYY",
                })}
                className="ant-input ant-input-lg"
                style={{ width: "100%" }}
              />
            ),
          },
          {
            key: "gender",
            label: "Patient Gender",
            name: "gender",
            width: 12,
            rules: [{ required: true, message: "User's gender is required!" }],
            Component: (
              <Select
                style={{ width: "100%", color: "#5a5959" }} // Custom style for the Select component
                size="large"
                name="gender"
                onChange={(e) => {
                  handleGenderChange(e);
                }}
              >
                <Option value="" disabled>
                  Select gender
                </Option>
                <Option value={"Male"}>Male</Option>
                <Option value={"Female"}>Female</Option>
              </Select>
            ),
          },
          {
            key: "email",
            label: "Email ID",
            name: "email",
            width: 12,
            // rules: [{ required: true, message: "  User's email !" }],
            Component: (
              <Input
                size="large"
                onChange={handleEmailChange}
                placeholder="Enter email"
              />
            ),
          },
          // {
          //   key: "address",
          //   label: "Address",
          //   name: "address",
          //   width: 12,
          //   Component: <Input size="large"  placeholder="Enter Address" />,
          // },
          {
            heading: "Booking Details",
          },
          {
            key: "module",
            label: "Service Type",
            width: 12,
            rules: [{ required: true, message: "User's service is required!" }],
            Component: (
              <Select
                showSearch
                placeholder="Select service"
                size="large"
                onChange={handleServiceChange}
              >
                <Option value="lab_near">Labs Near You</Option>
                {selectedBranch && selectedBranch?.home_status ? (
                  <Option value="lab_home">Labs at Home</Option>
                ) : null}{" "}
                <Option value="diagnostic_near">Diagnostics</Option>
              </Select>
            ),
          },
          {
            key: "program",
            label: "Program Type",
            width: 12,
            rules: [
              { required: true, message: "User's program is required! " },
            ],
            Component: (
              <Select
                showSearch
                placeholder="Select program"
                size="large"
                onChange={handleProgramChange}
                value={selectedProgram}
              >
                {getProgramOptions().map((program) => (
                  <Option key={program?.id} value={program?.id}>
                    {program?.name}
                  </Option>
                ))}
              </Select>
            ),
          },
          {
            key: "schedule_date",
            label: "Select Date",
            width: 12,
            rules: [{ required: true, message: "Select Date's is required!" }],
            Component: (
              <DatePicker
                placeholder="Enter date"
                className="ant-input ant-input-lg"
                style={{ width: "100%" }} // Adjust the width value as needed
                onChange={setSelectedDates}
                minDate={today}
                maxDate={maxDate}
                format="DD/MM/YYYY"
                size="large"
              />
            ),
          },
          {
            key: "PreferedTime",
            label: "Prefered Time",
            width: 12,
            rules: [{ required: true, message: " Time is required!" }],
            Component: (
              <Select
                size="large"
                placeholder="Select time"
                className="ant-input ant-input-lg"
              >
                {timeListOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}{" "}
              </Select>
            ),
          },
          {
            heading: "Address Details",
          },
          {
            key: "address",
            label: "Address",
            width: 12,
            rules: [
              { required: !isAddAddress, message: "Address is required!" },
            ],
            Component: (
              <Select
                placeholder="Select address"
                size="large "
                className="ant-select-lg"
                name="address"
                onChange={(e) => {
                  handleAddressSelect(e);
                }}
                value={addresssaved}
              >
                {addressOption &&
                  addressOption.map((val) => (
                    <Option key={val?.id} value={val?.location}>
                      {val?.location}
                    </Option>
                  ))}
              </Select>
            ),
          },

          {
            name: "button",
            Component: (
              <Button
                onClick={() => {
                  setIsAddAddress((prev) => !prev);
                  setAddresssaved("");
                  setUser(User);
                  // setSelectedPlace("");
                  // setLandmark("");
                }}
              >
                {isAddAddress ? "Close" : "Add New Address"}

                {/* {isAddAddress  ? "true" : "false"} */}
              </Button>
            ),
          },
          ...NewAddressFields,
        ]}
        onAddressSaved={handleAddressSaved} // Pass the handleAddressSaved function
        addressData={addresssaved}
        key={`${onClearCount}`}
      />
    </Spin>
  );
}
export default BookForm;
