import React, { useState } from 'react';
//import { Modal } from 'antd';
import { bindActionCreators } from 'redux';
import _, {isEmpty} from 'lodash';
import { connect } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import BranchFormModal from './BranchFormModal';
import { Link } from 'react-router-dom';
import { getAtmedVendorLabsDiagnosticsBranchInfo } from '../../../../store/actions';

const formatBranchData = (profileInfo) => {
  if (!profileInfo || _.isEmpty(profileInfo)) return undefined;
  console.log("isEmpty", profileInfo && profileInfo.sun)
  return ({ 
    ...profileInfo, 
    sun: isEmpty(profileInfo && profileInfo.sun) ? [] : profileInfo && profileInfo.sun.split(','),
    mon: isEmpty(profileInfo && profileInfo.mon) ? [] : profileInfo && profileInfo.mon.split(','),
    tue: isEmpty(profileInfo && profileInfo.tue) ? [] : profileInfo && profileInfo.tue.split(','),
    wed: isEmpty(profileInfo && profileInfo.wed) ? [] : profileInfo && profileInfo.wed.split(','),
    thu: isEmpty(profileInfo && profileInfo.thu) ? [] : profileInfo && profileInfo.thu.split(','),
    fri: isEmpty(profileInfo && profileInfo.fri) ? [] : profileInfo && profileInfo.fri.split(','),
    sat: isEmpty(profileInfo && profileInfo.sat) ? [] : profileInfo && profileInfo.sat.split(','),
   });
};

const EditBranchesButton = ({
  branches, updateBranches, deleteBranches, categoryTitle, singleBranchInfo, ...props
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = async () => {
    props.getAtmedVendorLabsDiagnosticsBranchInfo(branches.id);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  // const onSubmit = async (data) => {
  //   data.sun = data.sun.toString();
  //   data.mon = data.mon.toString();
  //   data.tue = data.tue.toString();
  //   data.wed = data.wed.toString();
  //   data.thu = data.thu.toString();
  //   data.fri = data.fri.toString();
  //   data.sat = data.sat.toString();
  //   const payloadObj = {
  //     id: branches.id,
  //     payload: { lab_branch: data },
  //   };
  //   await updateBranches(payloadObj);
  //   hideModal();
  // };
  const onSubmit = async (data) => {
    console.log("onSubmit", data);
    data.sun = data.sun.toString();
    data.mon = data.mon.toString();
    data.tue = data.tue.toString();
    data.wed = data.wed.toString();
    data.thu = data.thu.toString();
    data.fri = data.fri.toString();
    data.sat = data.sat.toString();
    hideModal();
    await updateBranches({
      id: branches.id,
      payload: { lab_branch: { ...data } },
    });
  };
  // const renderContent = () => {
  //   return (
  //     <div style={{ paddingTop: 12, paddingBottom: 30 }}>This action will delete the sub-category({branches.name}) permanently. Are you sure?</div>
  //   )
  // };

  // const confirmDelete = () => {
  //   Modal.confirm({
  //     title: `Remove Sub-Category`,
  //     icon: '',
  //     content: renderContent(),
  //     closable: true,
  //     okText: `REMOVE`,
  //     onOk() {
  //       deleteBranches({ id: branches.id });
  //     },
  //     cancelButtonProps: { style: { display: 'none' } }
  //   });
  // };
  // const footer = (
  //   <Button
  //     icon={<DeleteOutlined />}
  //     onClick={confirmDelete}
  //     danger
  //   >
  //     Delete
  //   </Button>
  // );
  return (
    <>
      <BranchFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Edit Branch - ${categoryTitle}`}
        submitText="UPDATE BRANCH"
        data={singleBranchInfo}
      // footer={footer}
      />
      <div>
        <>
          {branches.name}
        </>
      </div>
    </>
  );
};
const mapStateToProps = ({ vendorEquipmentReducer = {} }) => ({
  singleBranchInfo: formatBranchData(vendorEquipmentReducer.singleBranchInfo),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAtmedVendorLabsDiagnosticsBranchInfo,
  },
  dispatch,
);
export default connect(mapStateToProps, mapDispatchToProps)(EditBranchesButton);
