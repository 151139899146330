/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback } from 'react';
import {
  Drawer, Col, Row, Typography, Divider
} from 'antd';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getVendorLabSettlementInfo } from '../../../../store/actions';

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue
        ? <Text className="darkFont">{value}</Text>
        : <Text className="darkFont" type="secondary">Not Provided</Text>}
    </div>
  </Paragraph>
);

const formatInfoTestLog = (info) => {
  if (!info || _.isEmpty(info)) return undefined;
  return ({ ...info });
};

const drawerTitle = (result) => {
  if (!result || _.isEmpty(result)) return undefined;
  return (
    <Row>
      <Col span={24}>
        <DescrtiptionItem value={result.branch_name} />
        <div className="fw500">
          Order #{result.orderid} &nbsp;&nbsp;({result.module})
        </div>
      </Col>
    </Row>
  )
};


const FeedbackDetailDrawerModal = ({
  data = {},
  result,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getVendorLabSettlementInfo.call(null, data.id);
    }
  }, [data, props.getVendorLabSettlementInfo])

  useEffect(() => {
    renderData()
  }, [renderData]);

  return (<Drawer
    title={drawerTitle(data)}
    placement="right"
    onClose={onCancel}
    visible={visible}
    onCancel={onCancel}
    // destroyOnClose
    width={500}
    {...props}
  >
    {
      result
        ? (
          <>
            <Row>
              <Col span={24} className="fw500 mb-10">
                <Text>Order Summary</Text>
              </Col>
              <Col span={12}>
                <DescrtiptionItem title="Order Date" value={result.order_date} />
                <DescrtiptionItem title="Service" value={result.module} />
              </Col>
              <Col span={12} className='right-align-col'>
                <DescrtiptionItem title="Order Status" value={result.status} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Col span={12} className="lightFont">
                  <Text className="lightFont">Schedule Details</Text>
                </Col>
                <Col span={24} className="mb-10">
                  <Text>{result.schedule_date}, {result.schedule_time}</Text>
                </Col>
              </Col>
              <Col span={24} className="lightFont">
                <Text className="lightFont">Customer Name</Text>
              </Col>
              <Col span={12} className="mb-10">
                <Text>{result.patient_name} <br />
                  {result.patient_age}, {result.patient_gender}</Text>
              </Col>
              <Col span={24} className="lightFont">
                <Text className="lightFont">Customer Address</Text>
              </Col>
              <Col span={24} className="mb-10">
                <Text>{result.address} </Text>
              </Col>

            </Row>
            <Divider />
            <Row>
              <Col span={24} className="fw500 mb-10">
                <Text>Delivery Information</Text>
              </Col>
              <Col span={24}>
                <DescrtiptionItem title="From Address" value={result.from_address} />
              </Col>
              <Col span={24}>
                <DescrtiptionItem title="To Address" value={result.address} />
              </Col>
              <Col span={24}>
                <DescrtiptionItem title="Distance In KM" value={result.distance_km} />
              </Col>
            </Row>

            <Divider />
            <Row>
              <Col span={24}>
                <DescrtiptionItem title="Branch Name" value={result.branch_name} />
                <DescrtiptionItem title="Tests Ordered" value={result.tests} />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24} className="fw500 mb-10">
                <Text>Payment Information</Text>
              </Col>
              <Col span={12}>
                <Col span={24}>
                  <DescrtiptionItem title="Payment Status" value={result.payment_status} />
                </Col>
              </Col>
              <Col span={12} className='right-align-col'>
                <Col span={24}>
                  <DescrtiptionItem title="Payment Mode" value={result.payment_mode} />
                </Col>
              </Col>
              <Col span={8}>MRP</Col>
              <Col span={4} offset={12} className="fw500">{result.currency}{result.total_mrp}</Col>
              <Col span={8}>Discount</Col>
              <Col span={4} offset={12} className="fw500">{result.currency}{result.total_discount}</Col>
              <Col span={8}>Additional Discount</Col>
              <Col span={4} offset={12} className="fw500">{result.currency}{result.additional_discount}</Col>
              <Col span={8}>Collection Fee</Col>
              <Col span={4} offset={12} className="fw500">{result.currency}{result.collection_fee}</Col>
              <Col span={8} className="mb-10">Total Payable</Col>
              <Col span={4} offset={12} className="fw500">{result.currency}{result.total_payable}</Col>
            </Row>
          </>
        ) : null
    }
  </Drawer>
  )
};

const mapStateToProps = ({ labDiagnosticsReducer = {} }) => ({
  result: formatInfoTestLog(labDiagnosticsReducer.singleLabSettlementInfo),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getVendorLabSettlementInfo,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackDetailDrawerModal);
