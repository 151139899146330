import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllHomeHealthcareBookings,
  getSingleHomeHealthcareBooking,
  confirmHomeHealthcareBooking,
  cancelHomeHealthcareBooking,
  getAllUser,
  saveAddress,
  saveData,
} from '../../../store/actions/homeHealthcareVendor/booking';

import {
  getAllHomeHealthcareHelpOption,getAllMedicalAbroadPackages,getAllMedicalCatalogConsultantDoctors,addcasefilesAsset
} from '../../../store/actions';
import {getAllMedicalAdmHealthCentres,getAllCountryCodes,
  getconsultcategory,
  getsurgerycategory,gettimeslot,gettimevalidate,getabroadcategory,getdoctorlist,getdoctorvalidation,
  createbooking,searchcategory,searchtourismcategory,searchtourismsurgery,doctorTourismdetails,
  searchsurgery,doctordetails,createbookingsurgery,validationTourismdetails,
  createbookingtourism,createbookingEmegrency} from '../../../store/actions'
import Booking from './booking';
import { getAllHomeHealthcareServicesList, getAllHomeHealthcareServices } from "../../../store/actions/homeHealthcareVendor/programsServices";
import {
  getAllHomeHealthcareCaregiverStaff } from "../../../store/actions/homeHealthcareVendor/caregiverStaff";
const mapStateToProps = ({ homeHealthcareReducer, vendorEquipmenReducer }) => ({
  bookings: homeHealthcareReducer.bookings,
  singleBooking: homeHealthcareReducer.singleBooking,
  helpOption: vendorEquipmenReducer.helpOption,
  cancelBooking: homeHealthcareReducer.cancelBooking,
  Loading: homeHealthcareReducer.Loading
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllHomeHealthcareBookings,
    getSingleHomeHealthcareBooking,
    confirmHomeHealthcareBooking,
    getAllHomeHealthcareHelpOption,
    cancelHomeHealthcareBooking,
    getAllHomeHealthcareServicesList,
    getAllHomeHealthcareCaregiverStaff,
    getAllHomeHealthcareServices,
    getAllMedicalAdmHealthCentres,
    getAllMedicalCatalogConsultantDoctors,
    getAllCountryCodes,
    getconsultcategory,
    getsurgerycategory,
    getabroadcategory,
    getdoctorvalidation,
    addcasefilesAsset,
    getAllUser,
    getdoctorlist,
    gettimeslot,
    gettimevalidate,
    getAllMedicalAbroadPackages,
    searchcategory,
    doctordetails,
    searchsurgery,
    createbooking,
    createbookingsurgery,
    createbookingtourism,
    createbookingEmegrency,
    doctordetails,
    doctorTourismdetails,
    searchtourismcategory,
    searchtourismsurgery,
    validationTourismdetails,
    saveAddress,
    saveAddress,
    saveData
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
