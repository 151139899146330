import React, { useState } from "react";
import { Card, Col, Row, Dropdown, Menu, Typography } from "antd";
import { MoreOutlined } from "@ant-design/icons";

const IconImg = (props) => <img src={props.name} alt={props.name} />;

const { Link } = Typography;

export default function CardComponent(props) {
  console.log("CardComponent", props);
  const [showCardCompValue, setCardCompValue] = useState("TOTAL");

  const cardTitle = (
    <Row className="card-title">
      <Col span={6} className="icon-bg">
        <div>
          <IconImg name={props.OrdersIcon} />
        </div>
      </Col>
      <Col span={18} className="title-content">
        {/* {showCardCompValue === 'TOTAL' && (
          <>
            <p>Total Rental</p>
            <span>{props.data?.total_orders_rental && props.data?.total_orders_rental?.total}</span>
          </>
        )} */}
        {showCardCompValue === "TOTAL" && (
          <>
            <p>Total Rental</p>
            <span>
              {props.data?.total_orders_rental_completed &&
              props.data?.total_orders_rental_cancelled
                ? props.data.total_orders_rental_completed.total +
                  props.data.total_orders_rental_cancelled.total
                : 0}
            </span>
          </>
        )}
        {showCardCompValue === "ONGOING" && (
          <>
            <p>Ongoing Orders</p>
            <span>
              {props.data?.total_orders_rental_completed &&
                props.data?.total_orders_rental_completed?.total}
            </span>
          </>
        )}
        {showCardCompValue === "CANCELLED" && (
          <>
            <p>Cancel Orders</p>
            <span>
              {props.data?.total_orders_rental_cancelled &&
                props.data?.total_orders_rental_cancelled?.total}
            </span>
          </>
        )}
      </Col>
    </Row>
  );

  const dropDown = (
    <Dropdown
      placement="bottomRight"
      overlay={
        <Menu>
          <Menu.Item key="0" onClick={() => setCardCompValue("TOTAL")}>
            <span>Total</span>
          </Menu.Item>
          <Menu.Item key="1" onClick={() => setCardCompValue("ONGOING")}>
            <span>Ongoing</span>
          </Menu.Item>
          <Menu.Item key="2" onClick={() => setCardCompValue("CANCELLED")}>
            Cancelled
          </Menu.Item>
        </Menu>
      }
      trigger={["click"]}
    >
      <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <MoreOutlined className="moreOutlined" />
      </Link>
    </Dropdown>
  );

  return props.data ? (
    <Card
      bordered={false}
      title={cardTitle}
      className="dashboard-card"
      extra={dropDown}
    >
      {showCardCompValue === "TOTAL" && (
        <>
          <p className="card-component-content">
            Monthly: <span>{props.data.total_orders_rental_completed.monthly + props.data.total_orders_rental_cancelled.monthly}</span>
          </p>
          <p className="card-component-content">
            Weekly: <span>{props.data.total_orders_rental_completed.weekly + props.data.total_orders_rental_cancelled.weekly}</span>
          </p>
          <p className="card-component-content">
            Today: <span>{props.data.total_orders_rental_completed.today + props.data.total_orders_rental_cancelled.today}</span>
          </p>
        </>
      )}
      {showCardCompValue === "ONGOING" && (
        <>
          <p className="card-component-content">
            Monthly:{" "}
            <span>{props.data.total_orders_rental_completed.monthly}</span>
          </p>
          <p className="card-component-content">
            Weekly:{" "}
            <span>{props.data.total_orders_rental_completed.weekly}</span>
          </p>
          <p className="card-component-content">
            Today: <span>{props.data.total_orders_rental_completed.today}</span>
          </p>
        </>
      )}
      {showCardCompValue === "CANCELLED" && (
        <>
          <p className="card-component-content">
            Monthly:{" "}
            <span>{props.data.total_orders_rental_cancelled.monthly}</span>
          </p>
          <p className="card-component-content">
            Weekly:{" "}
            <span>{props.data.total_orders_rental_cancelled.weekly}</span>
          </p>
          <p className="card-component-content">
            Today: <span>{props.data.total_orders_rental_cancelled.today}</span>
          </p>
        </>
      )}
    </Card>
  ) : null;
}
