import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllBookingSettings, updateBookingSettings
} from '../../../store/actions';
import BookingSettings from './BookingSettings';

const mapStateToProps = ({ bookingSettingsReducers }) => ({
  misDefault: bookingSettingsReducers.misDefault,
  misDefaultUpdate: bookingSettingsReducers.misDefaultUpdate
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllBookingSettings,
    updateBookingSettings
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(BookingSettings);
