import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllUser,
  saveAddress,
  saveData,
} from '../../../store/actions/homeHealthcareVendor/booking';
import {saveMedicalData} from "../../../store/actions/vendorEquipmentActions"
import {
  getAllCountryCodes,getAllEquipmentProducts,getAllEquipmentCategories,getSearchEquipmentProducts,getAllCitie
} from '../../../store/actions';
import Booking from './booking';
import {
  getAllHomeHealthcareCaregiverStaff } from "../../../store/actions/homeHealthcareVendor/caregiverStaff";
const mapStateToProps = ({vendorEquipmenReducer }) => ({
  helpOption: vendorEquipmenReducer.helpOption,

});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {

    getAllHomeHealthcareCaregiverStaff,
    getAllUser,
    saveAddress,
    saveAddress,
    saveData,
    getAllCountryCodes,
    getAllEquipmentProducts,
    getAllEquipmentCategories,
    saveMedicalData,
    getSearchEquipmentProducts,
    getAllCitie
    
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Booking);