/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Typography,
  Spin,
  Dropdown,
  Button,
  Menu,
  Select,
  DatePicker,
  message,
  Row,
  Col,
} from "antd";
import { useHistory } from "react-router-dom";

import { DownOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";
import moment from "moment";
import { unset, get, set } from "lodash";
import ViewDetailsDrawer from "./ViewDetailsDrawer";
import DetailsDrawer from "./SessionViewDetailsDrawer";
import ChangeCaregiverform from "./ChangeCaregiverform";
import ConfirmCaseSheetButton from "./ConfirmCaseSheetButton";
import AdvancedSearchForm from "../../../common/SearchForm";
import TrackPartner from "./TrackPartner";
import Track from "../../track";
const { Title, Link } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const modeVsDisplayName = {
  current: "Active Sessions",
  upcoming: "Upcoming Sessions",
  completed: "Completed Sessions",
};

function UserManagement(props) {
  const sessionsMessage = useSelector(
    (state) => state.homeHealthcareReducer.sessionsMessage
  );

  const [mode, setMode] = useState("current");
  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);
  const [showBookingDrawer, setShowBookingDrawer] = useState(false);
  const [showCaregiverDrawer, setShowCaregiverDrawer] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [trackDeliveryModal, setTrackDeliveryModal] = useState(false);
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [serviceListOption, setServiceListOption] = useState(false);
  const [staffListOption, setStaffListOption] = useState(false);
  const [selectedService, setSelectedService] = useState(false);
  const [programListOption, setProgramListOption] = useState(false);
  const [searchValues, setSearchValues] = useState();

  let history = useHistory();
  const toggleTrackDeliveryModal = () => {
    setTrackDeliveryModal(!trackDeliveryModal);
  };
  useEffect(() => {
    if (searchValues) {
      props.getAllHomeHealthcareSessions(
        `${searchValues}&mode=${mode}`,
        20,
        currentPage
      );
    } else {
      props.getAllHomeHealthcareSessions(`mode=${mode}`, 20, currentPage);
    }
    // props.getAllHomeHealthcareSessions('mode=current',20,currentPage);
    props.getAllHomeHealthcareServicesList().then((response) => {
      setServiceListOption(response?.value?.data?.data);
    });
    props.getAllHomeHealthcareCaregiverStaff().then((response) => {
      setStaffListOption(response?.value?.data?.data);
      console.log("caregiver", response?.value?.data?.data);
    });
    props.getAllHomeHealthcareServices().then((response) => {
      console.log("response", response);

      const services_data = response?.value?.data?.data;

      // const newResult = services_data.filter(each => each.packages?.length).map(each => {
      // return each.packages?.map(eachPack => ({id: eachPack?.id,service_id:eachPack.service_id,name:eachPack.name}))});

      const packges_data = [];

      services_data.forEach((item) => {
        const packages = item.packages;
        packages.forEach((data) => {
          packges_data.push({
            package_id: data.id,
            package_name: data.name,
            service_id: item.home_service_id,
            service_name: item.name,
          });
        });
      });

      console.log("packges_data", packges_data);
      setProgramListOption(packges_data);
    });
  }, [currentPage]);

  const confirmHomeHealthcareBooking = async (params) => {
    await props.changeHomeHealthcareBookingCareGiver(params);
    await props.getAllHomeHealthcareSessions(`mode=${mode}`, 20, currentPage);
  };

  const handleServiceSelect = (e) => {
    console.log("setSelectedService", e);
    setSelectedService(e);
  };

  const clearSelectedService = () => {
    setSelectedService();
  };
  const handleChange = (id) => {
    history.push(`/home/track/${id}`);
  };
  // const handleChange = (id) => {
  //   history.push({
  //     pathname: "/home/track",
  //     state: { id },
  //   });
  // };

  const columns = [
    {
      title: "Session",
      key: "id",
      width: "25%",
      render: (_, columnData) => (
        <>
          {/*          
          <a onClick={() => setShowDetailsDrawer(`id=${columnData.id}&booking_id=${columnData.booking_id}`)}>{columnData.sid}</a>
          <div>{columnData.session_date}</div>
          <Link onClick={() => setShowBookingDrawer(columnData.booking_id)}>View Booking Detail</Link> */}
          <Row className="name-details-row">
            <Col>
              <div className="table-fc-name">{columnData.module}</div>
              <div className="table-fc-email">Session ID: {columnData.sid}</div>
              <div className="table-fc-email">
                Date: {columnData.session_date}
              </div>
              <div className="view-details">
                <Link
                  onClick={() =>
                    setShowDetailsDrawer(
                      `id=${columnData.id}&booking_id=${columnData.booking_id}`
                    )
                  }
                >
                  VIEW DETAILS
                </Link>
              </div>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Patient/Caregiver",
      key: "patient_name",
      width: "20%",
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>{columnData.patient_name} </Col>
            <Col span={24} className="lightFont">
              {columnData.patient_age} / {columnData.patient_gender}
            </Col>
            <Col span={24} className="lightFont">
              {columnData.patient_phone}
            </Col>
          </Row>
          <Row style={{ marginTop: 16 }}>
            <Col span={24}>
              <div className="table-fc-name">{columnData.caregiver_name}</div>
              <div className="view-details" style={{ paddingTop: 0 }}>
                <Link onClick={() => toggleActivateModalVisibility(columnData)}>
                  VIEW DETAILS
                </Link>
              </div>
              {/* <div>
                {columnData.progress_button ? (
                  <ConfirmCaseSheetButton
                    columnData={columnData}
                    confirmHomeHealthcareBooking={confirmHomeHealthcareBooking}
                  />
                ) : null}
              </div> */}
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Start/End Date",
      key: "session_date",
      width: "25%",
      render: (_, columnData) => (
        <>
          <div>Start Date :{columnData.started_at}</div>
          <div>End Date :{columnData.ended_at}</div>
        </>
      ),
    },
    {
      title: "Status",
      key: "session_date",
      width: "15%",
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>
              {columnData.status_code === 1 ? (
                <span className="booking-placed">{columnData.status}</span>
              ) : null}
              {columnData.status_code === 2 ? (
                <span className="booking-confirmed">{columnData.status}</span>
              ) : null}
              {columnData.status_code === 3 ? (
                <span className="booking-completed">{columnData.status}</span>
              ) : null}
              {columnData.status_code === 4 ? (
                <span className="booking-cancelled">{columnData.status}</span>
              ) : null}
              {columnData.status_code === 5 ? (
                <span className="booking-cancelled">{columnData.status}</span>
              ) : null}
              {columnData.status_code === 6 ? (
                <span className="booking-placed">{columnData.status}</span>
              ) : null}
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Action",
      key: "session_date",
      render: (_, columnData) => (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            {(columnData.track_button && (
              <div>
                <Button
                  onClick={() => {
                    toggleTrackDeliveryModal();
                    setSelectedColumn(columnData);
                  }}
                  style={{ borderColor: "green", color: "green" }}
                >
                  Track Now
                </Button>
              </div>
            )) ||
              null}
            {/* {(columnData.track_button && (
              <div>
                <Button
                  onClick={() => {
                    handleChange(columnData?.id);
                    toggleTrackDeliveryModal();
                  }}
                  style={{ borderColor: "green", color: "green" }}
                >
                  Public Track
                </Button>
              </div>
             )) ||
              null}  */}
          </div>

          {columnData.progress_button ? (
            <ConfirmCaseSheetButton
              columnData={columnData}
              confirmHomeHealthcareBooking={confirmHomeHealthcareBooking}
            />
          ) : null}
          {(columnData.caregiver_button && (
            <ChangeCaregiverform
              columnData={columnData}
              getAllHomeHealthcareHelpOption={
                props.getAllHomeHealthcareHelpOption
              }
              helpOption={props.helpOption}
              module={columnData.module}
              confirmHomeHealthcareBooking={confirmHomeHealthcareBooking}
            />
          )) ||
            null}
        </>
      ),
    },
  ];
  const toggleActivateModalVisibility = (columnData) => {
    setShowCaregiverDrawer(!showCaregiverDrawer);
    setSelectedColumn(columnData);
  };
  const setSessionType = (m) => {
    props.getAllHomeHealthcareSessions(`mode=${m}`, 20, currentPage);
    setMode(m);
  };
  const menu = (
    <Menu>
      <Menu.Item onClick={() => setSessionType("current")}>
        ACTIVE SESSIONS
      </Menu.Item>
      <Menu.Item onClick={() => setSessionType("upcoming")}>
        UPCOMING SESSIONS
      </Menu.Item>
      <Menu.Item onClick={() => setSessionType("completed")}>
        COMPLETED SESSIONS
      </Menu.Item>
    </Menu>
  );
  const drawerConfig = [
    {
      displayName: "Session ID",
      key: "sid",
    },
    {
      displayName: "Session Status",
      key: "status",
    },
    {
      displayName: "Module",
      key: "module",
    },
    {
      displayName: "Program Name",
      key: "program_name",
    },
    {
      displayName: "Program Duration",
      key: "program_duration",
    },
    {
      displayName: "Caregiver",
      key: "caregiver_name",
    },
    {
      displayName: "Patient Name",
      key: "patient_name",
    },
    {
      displayName: "Patient Age",
      key: "patient_age",
    },
    {
      displayName: "Patient Gender",
      key: "patient_gender",
    },
    {
      displayName: "Patient Address",
      key: "patient_address",
    },
    {
      displayName: "Patient Phone",
      key: "patient_phone",
    },
    {
      displayName: "Session Date",
      key: "session_date",
    },
    {
      displayName: "Started At",
      key: "started_at",
    },
    {
      displayName: "Ended At",
      key: "ended_at",
    },
    {
      displayName: "Payment Status",
      key: "payment_status",
    },
  ];
  const drawerConfigCareGiver = [
    {
      displayName: "Agency / Individual",
      key: "mode",
    },
    {
      displayName: "Program Name",
      key: "module",
    },
    {
      displayName: "Summary",
      key: "summary",
    },
    {
      displayName: "Vendor Name",
      key: "vendor_name",
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    if (newValues["q[date_from_to]"]) {
      const [from, to] = get(newValues, "q[date_from_to]");
      unset(newValues, "q[date_from_to]");
      set(
        newValues,
        ["q[session_date_gteq]"],
        moment(from).format("DD/MM/YYYY")
      );
      set(newValues, ["q[session_date_lteq]"], moment(to).format("DD/MM/YYYY"));
    }
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");

    setSearchValues(queryString);

    props.getAllHomeHealthcareSessions(`${queryString}&mode=${mode}`, 20, 1);
    setCurrentPage(1);
  };
  const handleRefresh = () => {
    props.getAllHomeHealthcareSessions("mode=current", 20, currentPage);
    if (sessionsMessage && sessionsMessage) {
      message.success(sessionsMessage.message);
    }
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <Spin spinning={props.Loading === true}>
      <ViewDetailsDrawer
        getData={() => props.getSingleHomeHealthcareSession(showDetailsDrawer)}
        visible={showDetailsDrawer}
        data={props.singleSession}
        setVisible={setShowDetailsDrawer}
        config={drawerConfig}
        title={`Details for Session ID: ${get(props.singleSession, "sid")}`}
      />
      <DetailsDrawer
        getData={() =>
          props.getSingleHomeHealthcareBooking(`id=${showBookingDrawer}`)
        }
        visible={showBookingDrawer}
        data={props.singleBooking}
        setVisible={setShowBookingDrawer}
        config={drawerConfig}
        title={`Details for Booking ID: ${get(props.singleBooking, "bid")}`}
      />
      <DetailsDrawer
        getData={() =>
          props.getSessionCareGiverProfile(
            `id=${selectedColumn.id}&booking_id=${selectedColumn.booking_id}&user_id=${selectedColumn.caregiver_id}`
          )
        }
        visible={showCaregiverDrawer}
        data={props.sessionCareGiverProfile}
        setVisible={setShowCaregiverDrawer}
        config={drawerConfigCareGiver}
        title={`Details of Caregiver: ${get(
          props.sessionCareGiverProfile,
          "caregiver_name"
        )}`}
      />
      {trackDeliveryModal && (
        <TrackPartner
          columnData={selectedColumn}
          onCancel={toggleTrackDeliveryModal}
          visible={trackDeliveryModal}
        />
      )}

      {/* <   Track columnData={selectedColumn}/> */}
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        {modeVsDisplayName[mode]}
        <div>
          <Button shape="circle" icon={<SyncOutlined />} style={{ marginRight: 8 }} onClick={() => handleRefresh(mode)} />
          <Dropdown overlay={menu} trigger={['click']}>
            <Button>
              <span style={{ fontWeight: '600' }}>
                Select Session Type
              </span>
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </Title> */}
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={6} offset={0}>
          <Title level={2} className="d-flex title">
            {modeVsDisplayName[mode]}
          </Title>
        </Col>

        <Col className="gutter-row section-heading" span={3} offset={0}>
          <Title
            level={4}
            className="d-flex title"
            style={{ marginTop: "10px" }}
          >
            Total: {props.sessions?.total_count}
          </Title>
        </Col>

        {/* <Col className="gutter-row" span={3} offset={0}></Col> */}
        <Col className="gutter-row header-buttons" span={5} offset={1}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>

        <Col className="gutter-row header-dropdown" span={5} offset={1}>
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button>
              <span>Select Session Type</span>
              <DownOutlined />
            </Button>
          </Dropdown>
        </Col>
        <Col className="gutter-row" span={2} offset={1}>
          <Button
            shape="circle"
            icon={<SyncOutlined />}
            style={{ marginRight: 8, marginLeft: 10 }}
            onClick={() => handleRefresh(mode)}
          />
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          clearSelectedService={clearSelectedService}
          onClose={() => setSearchOption(false)}
          title="Sessions"
          formItems={[
            {
              key: "q[sid_cont]",
              label: "Session ID",
            },
            {
              key: "q[home_booking_bid_eq]",
              label: "Booking ID",
            },
            {
              key: "q[home_booking_module_eq]",
              label: "Service name",
              Component: (
                <Select
                  showSearch
                  placeholder="Select Service"
                  size="large"
                  onChange={handleServiceSelect}
                >
                  {serviceListOption &&
                    serviceListOption?.map((val) => (
                      <Option key={val} value={val}>
                        {val}
                      </Option>
                    ))}
                </Select>
              ),
            },
            {
              key: "q[user_id_eq]",
              label: "Caregiver name",
              Component: (
                <Select
                  showSearch
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  placeholder="Select Caregiver"
                  size="large"
                >
                  {staffListOption && selectedService
                    ? staffListOption
                        ?.filter(
                          (each) => each.caregiver_type == selectedService
                        )
                        ?.map((val) => (
                          <Option key={val.name} value={val.id}>
                            {val.name}
                          </Option>
                        ))
                    : staffListOption?.map((val) => (
                        <Option key={val.name} value={val.id}>
                          {val.name}
                        </Option>
                      ))}
                </Select>
              ),
            },
            {
              key: "q[home_booking_patient_name_cont]",
              label: "Patient name",
            },
            {
              key: "q[home_booking_address_cont]",
              label: "Patient location",
            },
            {
              key: "q[home_booking_program_name_cont]",
              label: "Program name",
              Component: (
                <Select
                  showSearch
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  placeholder="Select Program"
                  size="large"
                >
                  {programListOption && selectedService
                    ? programListOption
                        ?.filter((each) => each.service_name == selectedService)
                        ?.map((val) => (
                          <Option key={val.package_name} value={val.id}>
                            {val.package_name}
                          </Option>
                        ))
                    : programListOption?.map((val) => (
                        <Option key={val.package_name} value={val.id}>
                          {val.package_name}
                        </Option>
                      ))}
                </Select>
              ),
            },
            {
              key: "q[status_eq]",
              label: "Session Status",
              Component: (
                <Select
                  showSearch
                  placeholder="Select Session status"
                  size="large"
                >
                  <Option key="Yet to Start" value="Yet to Start">
                    Yet to Start
                  </Option>
                  <Option key="In Progress" value="In Progress">
                    In Progress
                  </Option>
                  <Option key="Completed" value="Completed">
                    Completed
                  </Option>
                  {/* <Option value="4">Booking Completed</Option> */}
                  <Option key="Cancelled" value="Cancelled">
                    Cancelled
                  </Option>
                </Select>
              ),
            },
            {
              key: "q[date_from_to]",
              label: "Date",
              Component: (
                <RangePicker
                  format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                  size="large"
                />
              ),
            },
          ]}
        />
      ) : (
        ""
      )}

      <Table
        className="list_table"
        dataSource={props.sessions ? props.sessions.data : null}
        pagination={{
          pageSize: 20,
          defaultCurrent: 1,
          current: currentPage,
          total: props.sessions?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </Spin>
  );
}

export default UserManagement;
