import React, { useState } from "react";
import { Form, Row, Col, Input, Button, Typography, Select } from "antd";
import { useEffect } from "react";

const { Option } = Select;
const { Title } = Typography;
const ListForm = ({
  formItems = [],
  onSubmit,
  User,
  addressOption,
  total_savings,
  addressData,
  collectionFees,
  total_payable,
  total_discount,
  total_mrp,
  selectedService,
  selectedDates,
}) => {
  console.log("collectionFees", collectionFees);
  const [form] = Form.useForm();
  const [addressType, setAddressType] = useState("");
  const [showNewAddressForm, setShowNewAddressForm] = useState(false);
  const [address, setAddress] = useState("");
  const handleSaveAddress = (address) => {
    setAddress(address);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        console.log("🚀 ~ .then ~ values:", values);
        onSubmit({ ...values }, address);
      })
      .catch((errorInfo) => {});
  };
  useEffect(() => {
    if (addressOption) {
      form.setFieldsValue({
        name: User?.name || "",
        email: User?.email || "",
        dob: User?.dob || "",
        gender: User?.gender || "",
        address: addressData || addressOption,
        mobileNumber: User?.phone || "",
      });
      // form.validateFields(['name', 'dob', 'gender']);
    } else if (User) {
      form.setFieldsValue({
        name: User?.name || "",
        email: User?.email || "",
        address: addressData || "",
        mobileNumber: User?.phone || "",

      });
      form.resetFields(['name']);    }
  }, [User, addressOption, addressData]);

  useEffect(() => {
    if (addressType) {
      form.setFieldValue("addressType", addressType);
    }
  }, [addressType]);
  console.log("selected service", selectedService);
  useEffect(() => {
    form.setFieldValue("PreferedTime", "");
  }, [selectedDates]);
  useEffect(() => {
    form.setFieldValue("program", "");
  }, [selectedService]);

  console.log("getFieldsValue", form.getFieldsValue());
  return (
    <Form className="booking-form" form={form} layout="vertical">
      <Col xs={24} md={16} lg={16}>
        {" "}
        <Row gutter={24}>
          {formItems.map(({ Component, ...formItem }) => {
            if (
              (selectedService !== "lab_near" &&
                selectedService !== "diagnostic_near" &&
                formItem?.heading === "Address Details") ||
              formItem?.heading === "Patient Details" ||
              formItem?.heading === "Booking Details" ||
              formItem?.heading === "Branch Details"
            ) {
              return (
                <Col
                  className="gutter-row section-heading"
                  xs={24}
                  md={24}
                  lg={24}
                >
                  <Title
                    level={2}
                    className="d-flex"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {formItem?.heading}
                  </Title>
                </Col>
              );
            }
            if (
              formItem?.name === "button" &&
              selectedService !== "lab_near" &&
              selectedService !== "diagnostic_near"
            ) {
              return (
                <Col xs={24} md={12} lg={12} key={formItem?.name}>
                  <div className="addressFormBtn">{Component}</div>
                </Col>
              );
            }

            if (!formItem?.key) {
              return;
            }
            if (
              formItem?.key === "address" &&
              (selectedService === "lab_near" ||
                selectedService === "diagnostic_near")
            ) {
              return null;
            }

            if (formItem?.name == "button") {
              return null;
            }
            if (formItem?.key === "map") {
              return (
                <Col xs={24} md={24} lg={24} key={formItem?.key}>
                  {Component || null}
                </Col>
              );
            }
            return (
              <Col xs={24} md={12} lg={12} key={formItem?.key}>
                <Form.Item
                  name={formItem.key}
                  {...formItem}
                  label={formItem.label}
                >
                  {Component || (
                    <Input placeholder={formItem.label} size="large" />
                  )}
                </Form.Item>
              </Col>
            );
          })}
        </Row>
        <div className="trackingbox adddress_detailslist ">
          <p className="lightFont fare-calculation">
            <span className="first_sp">Collection Fee</span>
            {/* <span className="darkFont">{collectionFees >= 0 ? collectionFees : 0 }
            {""}</span> */}
            <span className="darkFont">{collectionFees || 0}</span>
          </p>
          <p className="lightFont fare-calculation">
            <span className="first_sp">Total MRP</span>
            <span className="darkFont">
              {total_mrp >= 0 ? total_mrp : ""}
              {""}
            </span>
          </p>

          <p className="lightFont fare-calculation">
            <span className="first_sp">Total Payable</span>
            <span className="darkFont">
              {total_payable >= 0 ? total_payable : ""}
              {""}
            </span>
          </p>
          <p className="lightFont fare-calculation">
            <span className="first_sp">Total Savings</span>
            <span className="darkFont">
              {total_savings >= 0 ? total_savings : ""}
              {""}
            </span>
          </p>
        </div>
        <Row justify="end">
          <Col>
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Col>
        </Row>
      </Col>
    </Form>
  );
};

export default ListForm;
