import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Table, Button, Typography, Select, DatePicker, Row, Col, message
} from 'antd';
import { SearchOutlined, SyncOutlined } from '@ant-design/icons';
import AdvancedSearchForm from '../../../common/SearchForm';
import DetailDrawerModal from './components/detailDrawer';

const { Title, Link } = Typography;
const { Option } = Select;

function AppointmentClinic(props) {

  const vendorEquipmentRefresh = useSelector(state => state.vendorEquipmenReducer.allBookingsRefresh);

  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage,setCurrentPage]=useState(1);

  const renderData = useCallback((currentPage) => {
    props.getAllAtmedBookingEmergency(undefined,20,currentPage);
  }, [props.getAllAtmedBookingEmergency])

  useEffect(() => {
    renderData(currentPage);
  }, [renderData,currentPage]);

  const handleRefresh = () => {
    props.getAllAtmedBookingEmergency(undefined,20,currentPage);
    if (vendorEquipmentRefresh && vendorEquipmentRefresh) {
      message.success(vendorEquipmentRefresh.message);
    }
  }

  const columns = [
    {
      title: 'Booking Details',
      key: 'booking_id',
      dataIndex: 'booking_id',
      width: '35%',
      render: (_, columnData) => (
        <Row className="name-details-row">
          <Col span={14} offset={1}>
            <div className="table-fc-name">{columnData.vendor_name}</div>
            <div className="table-fc-email">Booking ID: {columnData.orderid}
            </div>
            <div className="table-fc-email">
              Date: {columnData.booking_date}
            </div>
            <div className="view-details">
              <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}>
                VIEW DETAILS
              </Link>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: 'Patient Details',
      key: 'patient_name',
      width: '20%',
      render: (_, columnData) => (
        <Row>
          <Col span={24}>
            {columnData.patient_name} </Col>
          <Col span={24} className="lightFont">
            {columnData.patient_age} / {columnData.patient_gender}</Col>
          <Col span={24} className="lightFont">
            {columnData.reg_phone}</Col>
        </Row>
      ),
    },
    {
      title: 'Executive Info',
      key: 'medic_name',
      width: '25%',
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>
              {columnData.delivery_name} </Col>
          </Row >
          <Row>
            <Col span={24} className="lightFont">
              {columnData.delivery_phone}</Col>
          </Row>
        </>
      ),
    },
    {
      title: 'Status',
      key: 'status_code',
      dataIndex: 'status_code',
      width: '25%',
      render: (_, columnData) => (
        <Row>
          <Col span={24}>
            {columnData.status_code === 1 ? (
              <span className="booking-placed">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 2 ? (
              <span className="emergency-book-confirm">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 3 ? (
              <span className="booking-inprogress">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 4 ? (
              <span className="booking-completed">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 5 ? (
              <span className="booking-cancelled">{columnData.status}</span>
            ) : null}
          </Col>
        </Row>
      ),
    }
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllAtmedBookingEmergency(queryString,20,currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  };

  return (
    <div>
      <DetailDrawerModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        All Appointments - Surgery Consultation
      </Title> */}
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Emergency Bookings
          </Title>
        </Col>
        <Col className="gutter-row section-heading" span={3} offset={0}>
          <Title
              level={4}
              className="d-flex title"
              style={{ marginTop: '10px' }}
            >
              Total: {props.results?.total_count}
            </Title>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={2} offset={0}>
          <Button shape="circle" icon={<SyncOutlined />} style={{ marginRight: 8 }} onClick={() => handleRefresh()} />
        </Col> 
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Surgery Consultation"
          formItems={[
            {
              key: 'q[bid_cont]',
              label: 'Consultation Booking ID',
            },
            {
              key: 'q[patient_name_cont]',
              label: 'Patient name',
            },
            {
              key: 'q[health_centre_name_cont]',
              label: 'Health Centre',
            },
            {
              key: 'q[status_code_eq]',
              label: 'Status',
              Component: (
                <Select placeholder="Select Booking status" size="large">
                  <Option value="1">Booking Placed</Option>
                  <Option value="2">Booking Confirmed</Option>
                  <Option value="3">In Progress</Option>
                  <Option value="4">Booking Completed</Option>
                  <Option value="5">Booking Cancelled</Option>
                </Select>
              ),
            },
            {
              key: 'q[created_at_gteq]',
              label: 'From Date',
              Component: (<DatePicker size="large" />),
            },
            {
              key: 'q[created_at_lteq]',
              label: 'To Date',
              Component: (<DatePicker size="large" />),
            },
          ]}
        /> : ''}
     <Table 
      className="list_table" 
      dataSource={props.results?props.results.data:null} 
      pagination={{ pageSize: 20, total: props.results?.total_count, showSizeChanger:false }}  
      columns={columns} 
      scroll={{ x: 800 }} 
      rowKey="id" 
      onChange={(e)=>{
        setCurrentPage(e.current);
      }}
      />
    </div>
  );
}

export default AppointmentClinic;
