/* eslint-disable max-len */
/* eslint-disable camelcase */
import { protectedServiceInstance } from "../instance";
import { message } from "antd";
const apiKey = "7A3MXb5pTzE7zMuBRspN";
const search_key = "7558169247"
const modules ="lab_near"
const modulevalue ="diagnostic_near"


export const getAllHomeHealthcareBookings = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `home/bookings/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    { params: { api_key: apiKey } }
  );
export const getSingleHomeHealthcareBooking = (queryString) =>
  protectedServiceInstance.get(`/home/booking/show?${queryString}`, {
    params: { api_key: apiKey },
  });
export const getAllHomeHealthcareBookingCaregivers = (queryString) =>
  protectedServiceInstance.get(`home/bookings/caregivers/all?${queryString}`, {
    params: { api_key: apiKey },
  });
export const confirmHomeHealthcareBooking = (payload) =>
  protectedServiceInstance
    .post(
      "home/booking/confirm",
      {},
      {
        params: { api_key: apiKey, ...payload },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const cancelHomeHealthcareBooking = ({ id, payload }) =>
  protectedServiceInstance
    .post(`home/booking/cancel?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const changeHomeHealthcareBookingCareGiver = (payload) =>
  protectedServiceInstance
    .post(
      "home/booking/session/change-caregiver",
      {},
      {
        params: { api_key: apiKey, ...payload },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const getSessionCareGiverProfile = (queryString) =>
  protectedServiceInstance.get(
    `home/booking/session/caregiver-profile?${queryString}`,
    { params: { api_key: apiKey } }
  );

export const getAllHomeHealthcareBookingCase = (queryString) =>
  protectedServiceInstance.get(`/home/booking/cases/all?id=${queryString}`, {
    params: { api_key: apiKey },
  });

//  export const filterUser =(queryString) =>
//  protectedServiceInstance.get(`/atmed/patients/filter?search_key=${queryString} `, {
// });
export const filterUser = (searchValue) =>
  protectedServiceInstance.get(`/atmed/patients/filter`, {
    params: { 
      search_key: searchValue, 
      api_key: apiKey 
    },
  });

  export const saveAddress = (id) => protectedServiceInstance.get(
    'atmed/patients/address',
    {
      params: { api_key: apiKey, patient_id: id }
    }
  ).then(response => {
    console.log("Response from saveAddress API:", response);
    return response; // Return the response to the caller
  }).catch(error => {
    console.error("Error calling saveAddress API:", error);
    throw error; // Throw the error to be handled by the caller
  });
  
  export const saveData = (payload) =>
    protectedServiceInstance
      .post(
        "atmed/home/booking/create",
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
      });

      export const saveLabData = (payload) =>
        protectedServiceInstance
          .post(
            "atmed/lab/booking/create",
            payload,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then((res) => {
            message.success(res.data.message);
          });
          export const saveLabData1 = (payload,module) =>
            protectedServiceInstance
              .post(
                "atmed/lab/booking/create",
                payload,
                {
                  params: {
                    api_key: apiKey,
                    module: modules,
                  },
                  headers: {
                    'Content-Type': 'application/json',
                  },
                },
              )
              .then((res) => {
                message.success(res.data.message);
              });
              export const saveLabData2 = (payload) =>
                protectedServiceInstance
                  .post(
                    "atmed/lab/booking/create",
                    payload,
                    {
                      params: {
                        api_key: apiKey,
                        module: modulevalue,
                      },
                      headers: {
                        'Content-Type': 'application/json',
                      },
                    },
                  )
                  .then((res) => {
                    message.success(res.data.message);
                  });

          

