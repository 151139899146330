import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Modal, Form, Input, Button, Select, Col, Row } from "antd";
import { noop, isEmpty, _ } from "lodash";

import { getAtmedVendorLabsDiagnosticsBranchInfo } from "../../../../store/actions";

//const { Step } = Steps;

const formatUserProfile = (profileInfo) => {
  if (!profileInfo) return undefined;
  return {
    ...profileInfo,
    phone: `${profileInfo.phone}`,
  };
};

const BranchFormModal = ({
  data = {},
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [currentValue, setCurrentValue] = useState(null);
  const { Option } = Select;

  useEffect(() => {
    setCurrentValue(props.singleBranchInfo);
    form.setFieldsValue(props.singleBranchInfo);
  }, [props.singleBranchInfo]);

  useEffect(() => {
    // if (title === 'Add Branch') {
    //   setCurrentValue(null);
    //   form.resetFields();
    // }
    if (isEmpty(data)) {
      form.resetFields();
    }
  }, [data]);

  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        console.log("Values", values)
        await onSubmit({ ...currentValue, ...values });
        form.resetFields();
      })
      .catch(noop);
  };
  const onModalCancel = () => {
    if (!isEmpty(data)) {
      form.resetFields();
    }
    onCancel();
    setCurrentValue(null);
    setCurrentStep(0);
  };

  const formItems = [
    {
      key: "name",
      label: "Branch Name",
      rules: [
        { required: true, message: "Please input Branch name!" },
        { max: 100, message: "Branch Name must be maximum 100 characters." },
      ],
    },
    {
      key: "home_status",
      label: "Home Collection",
      rules: [
        { required: true, message: "Please select Home Collection status!" },
      ],
      Component: (
        <Select size="large" placeholder="Select Home Collection Status">
          <Option value>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      ),
    },
    {
      key: "status",
      label: "Branch Status",
      rules: [{ required: true, message: "Please select Branch status!" }],
      Component: (
        <Select size="large" placeholder="Select Branch Status">
          <Option value>Active</Option>
          <Option value={false}>Blocked</Option>
        </Select>
      ),
    },
    {
      key: "verified",
      label: "Verified Status",
      rules: [{ required: true, message: "Please select Verified status!" }],
      Component: (
        <Select size="large" placeholder="Select Verified Status">
          <Option value>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      ),
    },
    {
      key: "license",
      label: "License",
      rules: [{ required: true, message: "Please select License!" }],
      Component: (
        <Select size="large" placeholder="Select License">
          <Option value>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      ),
    },
    {
      key: "iso",
      label: "ISO",
      rules: [{ required: true, message: "Please select ISO!" }],
      Component: (
        <Select size="large" placeholder="Select ISO">
          <Option value>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      ),
    },
    {
      key: "delivery_time",
      label: "Delivery Time",
      rules: [{ required: true, message: "Please select Delivery Time!" }],
      Component: (
        <Select size="large" placeholder="Select Delivery Time">
          <Option value='10 minutes delivery'>10 minutes delivery</Option>
          <Option value='20 minutes delivery'>20 minutes delivery</Option>
          <Option value='30 minutes delivery'>30 minutes delivery</Option>
          <Option value='45 minutes delivery'>45 minutes delivery</Option>
        </Select>
      ),
    },
    {
      key: "phone",
      label: "Phone Number",
      // rules: [{ required: true, message: 'Please input Phone number!' }],
      rules: [
        { required: true, message: "Please input Phone number!" },
        { max: 15, message: "Phone must be maximum 15 numbers." },
        {
          pattern: new RegExp(/^[0-9\s,+]*$/),
          message: "field does not accept Alphabets or Special Characters",
        },
      ],
    },
    {
      key: "whatsapp",
      label: "Whatsapp Number",
      // rules: [{ required: true, message: 'Please input Phone number!' }],
      rules: [
        { required: true, message: "Please input Phone number!" },
        { max: 15, message: "Whatsapp must be maximum 15 numbers." },
        {
          pattern: new RegExp(/^[0-9\s,+]*$/),
          message: "field does not accept Alphabets or Special Characters",
        },
      ],
    },
    {
      key: "alt_phone",
      label: "Alt. Number",
      // rules: [{ required: true, message: 'Please input Phone number!' }],
      rules: [
        { required: true, message: "Please input Phone number!" },
        { max: 15, message: "alt phone must be maximum 15 numbers." },
        {
          pattern: new RegExp(/^[0-9\s,+]*$/),
          message: "field does not accept Alphabets or Special Characters",
        },
      ],
    },
    {
      key: "email",
      label: "Email Address",
      rules: [
        { required: true, message: "Please input Email!" },
        { type: "email", message: "Please enter a valid Email address" },
      ],
    },
    {
      key: "website",
      label: "Website",
      rules: [{ required: true, message: "Please input Website!" }],
    },
    {
      key: "city",
      label: "City",
      rules: [{ required: true, message: "Please input City!" }],
    },
    {
      key: "locality",
      label: "Locality",
      rules: [{ required: true, message: "Please input Locality!" }],
    },
    {
      key: "state",
      label: "State",
      rules: [{ required: true, message: "Please input State!" }],
    },
    {
      key: "country",
      label: "Country",
      //  rules: [{ required: true, message: "Please input Country!" }],
      rules: [
        { required: true, message: "Please input Country!" },
        { max: 15, message: "Country must be maximum 15 letters." },
        {
          pattern: new RegExp("^[a-zA-Z]+$"),
          message: "field does not accept numbers or Special Characters",
        },
      ],
    },
    {
      key: "address",
      label: "Address",
      rules: [{ required: true, message: "Please input Vendor's address!" }],
      // Component: <TextArea />,
    },
    {
      key: "landmark",
      label: "Landmark",
      // rules: [{ required: true, message: "Please input User's name!" }],
    },
    {
      key: "working_hours",
      label: "Working Hours",
      rules: [{ required: true, message: "Please select Working Hours!" }],
      Component: (
        <Select size="large" placeholder="Select Working Hours">
          <Option value='Open 24 Hours'>Open 24 Hours</Option>
          <Option value='07:00 AM - 10:00 PM'>07:00 AM - 10:00 PM</Option>
          <Option value='09:00 AM - 10:00 PM'>09:00 AM - 10:00 PM</Option>
          <Option value='09:00 AM - 11:00 PM'>09:00 AM - 11:00 PM</Option>
        </Select>
      ),
    },
    {
      key: "latitude",
      label: "Latitude",
      rules: [
        { max: 15, message: "Latitude must be maximum 15 letters." },
        {
          pattern: new RegExp(/^[\\+\\-]{0,1}\d*(\.)?(\d{0,20})?$/),
          message: "field does not accept Alphabets or Special Characters",
        },
      ],
    },
    {
      key: "longitude",
      label: "Longitude",
      rules: [
        { max: 15, message: "Longitude must be maximum 15 letters." },
        {
          pattern: new RegExp(/^[\\+\\-]{0,1}\d*(\.)?(\d{0,20})?$/),
          message: "field does not accept Alphabets or Special Characters",
        },
      ],
    },
  ];

  const modalFooter = (
    <>
      {footer}
      <Button type="primary" onClick={onOk}>
        {submitText}
      </Button>
    </>
  );

  return (
    <Modal
      visible={visible}
      onCancel={onModalCancel}
      onOk={onOk}
      title={title}
      okText={submitText}
      destroyOnClose
      footer={modalFooter}
      width={1000}
      {...props}
    >
      <Form form={form} initialValues={data} layout="vertical">
        <Row gutter={24}>
          {formItems.map(({ Component, ...formItem }) => (
            <Col xs={24} md={12} lg={8} key={formItem.key}>
              <Form.Item name={formItem.key} {...formItem}>
                {Component || (
                  <Input
                    size="large"
                    placeholder={`Enter ${
                      formItem.label === undefined
                        ? formItem.placeholder
                        : formItem.label
                    }`}
                  />
                )}
              </Form.Item>
            </Col>
          ))}
        </Row>
      </Form>
    </Modal>
  );
};

const mapStateToProps = ({ vendorEquipmentReducer = {} }) => ({
  singleBranchInfo: formatUserProfile(vendorEquipmentReducer.singleBranchInfo),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAtmedVendorLabsDiagnosticsBranchInfo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BranchFormModal);
