import typeToReducer from "type-to-reducer";
import * as actionType from "../actions/actionType";

const initialState = {
  users: [],
};

const userReducer = typeToReducer(
  {
    [actionType.GET_ALL_EQUIPMENT_USERS]: {
      PENDING: (state) => ({
        ...state,
        fetchUsersLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        users: action.payload.data.data,
        fetchUsersLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        users: [],
        fetchUsersLoading: false,
      }),
    },
    // [actionType.VENDOR_EQUIPMENT_RESENDOTP_COMPANY_USER]: {
    //   PENDING: (state) => ({
    //     ...state,
    //   }),
    //   FULFILLED: (state, action) => ({
    //     ...state,
    //     resendOtpUsers: action.payload.data,
    //   }),
    //   REJECTED: (state) => ({
    //     ...state,
    //     users: [],
    //   }),
    // },
    [actionType.ADD_EQUIPMENT_USER]: {
      PENDING: (state) => ({
        ...state,
        addUserLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addUserSuccess: true,
        addUserLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addUserSuccess: false,
        addUserLoading: false,
      }),
    },
    // [actionType.UPDATE_EQUIPMENT_USER]: {
    //   PENDING: (state) => ({
    //     ...state,
    //     updateUserLoading: true,
    //   }),
    //   FULFILLED: (state, action) => ({
    //     ...state,
    //     updateUserSuccess: true,
    //     updateUserLoading: false,
    //   }),
    //   REJECTED: (state) => ({
    //     ...state,
    //     updateUserSuccess: false,
    //     updateUserLoading: false,
    //   }),
    // },
    [actionType.VENDOR_EQUIPMENT_USER_PROFILE_UPDATE]: {
      PENDING: (state) => ({
        ...state,
        updateUserLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateUserProfileSuccess: true,
        updateUserLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateUserSuccess: false,
        updateUserLoading: false,
      }),
    },
    [actionType.UPDATE_EQUIPMENT_USER_PERMISSION]: {
      PENDING: (state) => ({
        ...state,
        updateUserPermissionLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateUserPermissionSuccess: true,
        updateUserPermissionLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateUserPermissionSuccess: false,
        updateUserPermissionLoading: false,
      }),
    },
    [actionType.GET_ALL_EQUIPMENT_PRODUCTS]: {
      FULFILLED: (state, action) => ({
        ...state,
        equipmentProducts: action.payload.data,
        fetchUsersLoading: false,
      }),
    },
    [actionType.UPDATE_EQUIPMENT_PRODUCT]: {
      FULFILLED: (state, action) => ({
        ...state,
        fetchUsersUpdateLoading: true,
        fetchUsersUpdateLoading: false,
      }),
    },
    [actionType.GET_SINGLE_EQUIPMENT_PRODUCT]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleEquipmentProduct: action.payload.data.data,
        fetchUsersLoading: false,
      }),
    },

    [actionType.DELETE_PRODUCT_ASSET]: {
      FULFILLED: (state, action) => ({
        ...state,
        deleteProductAsset: true,
        fetchUsersLoading: false,
      }),
    },
    [actionType.ADD_EQUIPMENT_PRODUCT_ASSET]: {
      FULFILLED: (state, action) => ({
        ...state,
        addProductAsset: true,
        fetchUsersLoading: false,
      }),
    },
    [actionType.GET_ALL_VENDOR_EQUIPMENT_ORDERS]: {
      FULFILLED: (state, action) => ({
        ...state,
        vendorEquipmentOrders: action.payload.data,
        vendorEquipmentOrderMessage: action.payload.data,
        fetchUsersLoading: false,
      }),
    },
    [actionType.GET_SINGLE_VENDOR_EQUIPMENT_ORDER]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleVendorEquipmentOrder: action.payload.data.data,
        fetchUsersLoading: false,
      }),
    },
    [actionType.GET_ALL_VENDOR_EQUIPMENT_DELIVERY_EXECUTIVE]: {
      FULFILLED: (state, action) => ({
        ...state,
        deliveryExecutives: action.payload.data.data,
        fetchUsersLoading: false,
      }),
    },
    [actionType.CONFIRM_VENDOR_EQUIPMENT_ORDER]: {
      PENDING: (state) => ({
        ...state,
        Loading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateUserPermissionSuccess: true,
        Loading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateUserPermissionSuccess: false,
        Loading: false,
      }),
    },
    [actionType.CHANGE_MODE_EQUIPMENT_ORDER]: {
      PENDING: (state) => ({
        ...state,
        Loading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateUserPermissionSuccess: true,
        Loading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateUserPermissionSuccess: false,
        Loading: false,
      }),
    },
    [actionType.CHANGE_EXECUTIVE_EQUIPMENT_ORDER]: {
      PENDING: (state) => ({
        ...state,
        Loading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateUserPermissionSuccess: true,
        Loading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateUserPermissionSuccess: false,
        Loading: false,
      }),
    },
    [actionType.VENDOR_EQUIPMENT_ORDER_COMPLETE_ORDER]: {
      PENDING: (state) => ({
        ...state,
        Loading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateUserPermissionSuccess: true,
        Loading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateUserPermissionSuccess: false,
        Loading: false,
      }),
    },
    [actionType.VENDOR_EQUIPMENT_ORDER_COURIER_DISPATCH]: {
      PENDING: (state) => ({
        ...state,
        Loading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateUserPermissionSuccess: true,
        Loading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateUserPermissionSuccess: false,
        Loading: false,
      }),
    },
    [actionType.ASSIGN_VENDOR_EQUIPMENT_ORDER_DELIVERY_EXECUTIVE]: {
      PENDING: (state) => ({
        ...state,
        Loading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateUserPermissionSuccess: true,
        Loading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateUserPermissionSuccess: false,
        Loading: false,
      }),
    },
    [actionType.CANCEL_VENDOR_EQUIPMENT_ORDER]: {
      PENDING: (state) => ({
        ...state,
        Loading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        cancelOrder: true,
        Loading: false,
      }),
    },
    [actionType.HELP_OPTION_VENDOR_EQUIPMENT_ORDER]: {
      FULFILLED: (state, action) => ({
        ...state,
        helpOptions: action.payload.data.data,
        fetchUsersLoading: false,
      }),
    },
    [actionType.GET_ALL_HEALTHCARE_VENDOR]: {
      FULFILLED: (state, action) => ({
        ...state,
        healthcareVendors: action.payload.data,
        fetchUsersLoading: false,
      }),
    },
    [actionType.ADD_HEALTHCARE_VENDOR]: {
      PENDING: (state) => ({
        ...state,
        updateVendorEquipmentLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addHealthCareVendor: true,
        updateVendorEquipmentLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addHealthCareVendor: false,
        updateVendorEquipmentLoading: false,
      }),
    },
    [actionType.GET_SINGLE_HEALTHCARE_VENDOR]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleHealthcareVendor: action.payload.data.data,
        fetchUsersLoading: false,
      }),
    },
    [actionType.GET_ALL_HOME_SERVICES]: {
      FULFILLED: (state, action) => ({
        ...state,
        homeHealthcareServices: action.payload.data.data,
        fetchUsersLoading: false,
      }),
    },
    [actionType.GET_ALL_EQUIPMENT_SUPPORTED_LOCATIONS]: {
      FULFILLED: (state, action) => ({
        ...state,
        equipmentSupportedLocations: action.payload.data.data,
      }),
    },
    [actionType.GET_ALL_EQUIPMENT_CITIES]: {
      FULFILLED: (state, action) => ({
        ...state,
        equipmentAllCities: action.payload.data.data,
      }),
    },
    [actionType.GET_ALL_HOME_SUPPORTED_LOCATIONS]: {
      FULFILLED: (state, action) => ({
        ...state,
        homeSupportedLocations: action.payload.data.data,
      }),
    },
    [actionType.GET_ALL_HOME_CITIES]: {
      FULFILLED: (state, action) => ({
        ...state,
        homeAllCities: action.payload.data.data,
      }),
    },
    [actionType.GET_ALL_HOME_REGIONS]: {
      FULFILLED: (state, action) => ({
        ...state,
        homeAllRegions: action.payload.data.data,
      }),
    },
    [actionType.UPDATE_HOME_SUPPORTED_CITIES]: {
      FULFILLED: (state, action) => ({
        ...state,
        updateHomeSupportedCities: action.payload.data.data,
      }),
    },
    [actionType.UPDATE_HOME_SUPPORTED_REGIONS]: {
      FULFILLED: (state, action) => ({
        ...state,
        updateHomeSupportedRegions: action.payload.data.data,
      }),
    },
    [actionType.UPDATE_EQUIPMENT_SUPPORTED_LOCATIONS]: {
      FULFILLED: (state, action) => ({
        ...state,
        updateSupportedLocation: action.payload.data.data,
      }),
    },
    [actionType.GET_PATIENT_PRODUCT_FAQS]: {
      FULFILLED: (state, action) => ({
        ...state,
        allPatientProductFAQs: action.payload.data.data,
      }),
    },
    [actionType.GET_ALL_EQUIPMENT_CATEGORIES]: {
      FULFILLED: (state, action) => ({
        ...state,
        allEquipmentCategories: action.payload.data.data,
      }),
    },
    [actionType.GET_ALL_EQUIPMENT_PAYMENTS]: {
      FULFILLED: (state, action) => ({
        ...state,
        allEquipmentPayments: action.payload.data,
        allEquipmentPaymentsMessage: action.payload.data,
      }),
    },
    [actionType.GET_EQUIPMENT_PAYMENT_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleEquipmentPayment: action.payload.data.data,
      }),
    },
    [actionType.GET_ALL_EQUIPMENT_FEEBACK]: {
      FULFILLED: (state, action) => ({
        ...state,
        allEquipmentFeeback: action.payload.data,
        allEquipmentFeebackMessage: action.payload.data,
      }),
    },
    [actionType.GET_EQUIPMENT_FEEBACK_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleEquipmentFeedback: action.payload.data.data,
      }),
    },
    [actionType.GET_ALL_HOME_PAYMENTS]: {
      FULFILLED: (state, action) => ({
        ...state,
        allHomePayments: action.payload.data,
        allHomePaymentsRefresh: action.payload.data,
      }),
    },
    [actionType.GET_ALL_HOME_PAYMENT_DETAILS]: {
      FULFILLED: (state, action) => ({
        ...state,
        homePaymentDetails: action.payload.data.data,
      }),
    },
    [actionType.GET_HOME_COMPANY_PROFILE]: {
      FULFILLED: (state, action) => ({
        ...state,
        homeCompanyProfile: action.payload.data.data,
      }),
    },
    [actionType.GET_EQUIPMENT_COMPANY_PROFILE]: {
      FULFILLED: (state, action) => ({
        ...state,
        equipmentCompanyProfile: action.payload.data.data,
      }),
    },
    [actionType.GET_ATMED_PAYMENTS]: {
      FULFILLED: (state, action) => ({
        ...state,
        atmedPayments: action.payload.data,
        atmedPaymentsRefresh: action.payload.data,
      }),
    },
    [actionType.UPDATE_ATMED_PAYMENTS]: {
      FULFILLED: (state, action) => ({
        ...state,
        equipmentSettlementUpdate: true,
      }),
    },
    [actionType.UPDATE_ATMED_PAYMENTS_HOME]: {
      FULFILLED: (state, action) => ({
        ...state,
        atmedPaymentsHomeUpdate: true,
      }),
    },
    [actionType.GET_ATMED_PAYMENTS_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        atmedHealthProductSettlementInfo: action.payload.data.data,
      }),
    },
    [actionType.GET_ATMED_PAYMENTS_HOME]: {
      FULFILLED: (state, action) => ({
        ...state,
        atmedPaymentsHome: action.payload.data,
        atmedPaymentsHomeRefresh: action.payload.data,
      }),
    },
    [actionType.GET_ATMED_PAYMENTS_HOME_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        atmedHhomeHealthSettlementInfo: action.payload.data.data,
      }),
    },
    [actionType.GET_ATMED_ASSETS]: {
      FULFILLED: (state, action) => ({
        ...state,
        atmedAssets: action.payload.data,
      }),
    },
    [actionType.GET_ATMED_ASSETS_HOME]: {
      FULFILLED: (state, action) => ({
        ...state,
        atmedAssetsHome: action.payload.data,
      }),
    },
    [actionType.GET_EQUIPMENTS_FEEDBACK]: {
      FULFILLED: (state, action) => ({
        ...state,
        allEquipmentFeedback: action.payload.data,
        allEquipmentFeedbackAll: action.payload.data,
      }),
    },
    [actionType.GET_EQUIPMENTS_FEEDBACK_SINGLE_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleEquipmentFeedbackHealth: action.payload.data.data,
      }),
    },
    [actionType.GET_HOME_FEEDBACK]: {
      FULFILLED: (state, action) => ({
        ...state,
        allHomeFeedback: action.payload.data,
        allHomeFeedbackRefresh: action.payload.data,
      }),
    },
    [actionType.GET_HOMEHEALTH_FEEDBACK]: {
      FULFILLED: (state, action) => ({
        ...state,
        allHomeFeedbackHome: action.payload.data,
        allHomeFeedbackHomeMessage: action.payload.data,
      }),
    },
    [actionType.HOME_FEEDBACK_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleHomeFeedback: action.payload.data.data,
      }),
    },
    [actionType.ATMED_HOME_FEEDBACK_SINGLE_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleAtmedHomeFeedback: action.payload.data.data,
      }),
    },
    [actionType.ATMED_FEEDBACK_LAB_DIAGNOSTICS_GET_ALL]: {
      FULFILLED: (state, action) => ({
        ...state,
        allEquipmentFeedback: action.payload.data,
        allHomeFeedLabMessage: action.payload.data,
      }),
    },
    [actionType.ATMED_FEEDBACK_LAB_DIAGNOSTICS_GET]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleAtmedFeedbackLab: action.payload.data.data,
      }),
    },
    [actionType.ATMED_GET_ALL_FEEDBACK_PHARMACY]: {
      FULFILLED: (state, action) => ({
        ...state,
        allEquipmentFeedback: action.payload.data.data,
      }),
    },
    [actionType.ATMED_GET_FEEDBACK_PHARMACY]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleAtmedFeedbackPharmacy: action.payload.data.data,
      }),
    },
    [actionType.ATMED_GET_ALL_FEEDBACK_MEDICAL_DOCTOR_CONSULTATION]: {
      FULFILLED: (state, action) => ({
        ...state,
        allEquipmentMedicalFeedback: action.payload.data,
        allEquipmentMedicalFeedbackAll: action.payload.data,
      }),
    },
    [actionType.ATMED_GET_ALL_FEEDBACK_MEDICAL_SURGERY_CONSULTATION]: {
      FULFILLED: (state, action) => ({
        ...state,
        allEquipmentMedicalSurgeryFeedback: action.payload.data,
        allEquipmentMedicalSurgeryFeedbackAll: action.payload.data,
      }),
    },
    [actionType.ATMED_GET_ALL_FEEDBACK_MEDICAL_ONLINE_CONSULTATION]: {
      FULFILLED: (state, action) => ({
        ...state,
        allEquipmentMedicalOnlineFeedback: action.payload.data,
        allEquipmentMedicalOnlineFeedbackAll: action.payload.data,
      }),
    },
    [actionType.ATMED_GET_ALL_FEEDBACK_MEDICAL_TOURISM]: {
      FULFILLED: (state, action) => ({
        ...state,
        allEquipmentMedicalTourism: action.payload.data,
        allEquipmentMedicalTourismAll: action.payload.data,
      }),
    },
    [actionType.ATMED_GET_ALL_FEEDBACK_MEDICAL_EMERGENCY]: {
      FULFILLED: (state, action) => ({
        ...state,
        allEquipmentMedicalEmergency: action.payload.data,
        allEquipmentMedicalEmergencyAll: action.payload.data,
      }),
    },
    [actionType.ATMED_GET_ALL_ASSET_APPROVAL_PHARMACY]: {
      FULFILLED: (state, action) => ({
        ...state,
        atmedAssetsPharmacy: action.payload.data.data,
      }),
    },
    [actionType.ATMED_GET_ALL_ASSET_APPROVAL_MEDIC]: {
      FULFILLED: (state, action) => ({
        ...state,
        atmedAssetsMedic: action.payload.data,
      }),
    },
    [actionType.ATMED_GET_ALL_ASSET_APPROVAL_HOSPITAL]: {
      FULFILLED: (state, action) => ({
        ...state,
        atmedAssetsHospital: action.payload.data,
      }),
    },
    [actionType.GET_ALL_EQUIPMENT_LOG]: {
      FULFILLED: (state, action) => ({
        ...state,
        allEquipmentLog: action.payload.data,
      }),
    },
    [actionType.HOME_HEALTHCARE_LOG]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
      }),
    },
    [actionType.HOME_HEALTHCARE_GET_ALL_HELP_SUPPORT]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        vendorHomeHelp: action.payload.data,
      }),
    },
    [actionType.HOME_HEALTHCARE_GET_ALL_HELP_OPTION]: {
      FULFILLED: (state, action) => ({
        ...state,
        helpOption: action.payload.data.data,
      }),
    },
    [actionType.HOME_HEALTHCARE_UPDATE_HELP_SUPPORT]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_EQUIPMENT_GET_ALL_HELP_SUPPORT]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        eqptHelpSupportMessage: action.payload.data,
      }),
    },
    [actionType.VENDOR_EQUIPMENT_UPDATE_HELP_SUPPORT]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data.data,
      }),
    },
    [actionType.ATMED_HELP_SUPPORT_MEDICAL_EQUIPMENT]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        helpSupportRefresh: action.payload.data,
      }),
    },
    [actionType.UPDATE_ATMED_HELP_SUPPORT]: {
      FULFILLED: (state, action) => ({
        ...state,
        updateResult: true,
      }),
    },
    [actionType.ATMED_HELP_SUPPORT_HOME_HEALTHCARE]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        helpSupportRefresh: action.payload.data,
      }),
    },
    [actionType.ATMED_HELP_SUPPORT_LAB]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        helpSupportRefresh: action.payload.data,
      }),
    },
    [actionType.ATMED_HELP_SUPPORT_EMERGENCY]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        helpSupportRefresh: action.payload.data,
      }),
    },
    [actionType.ATMED_HELP_SUPPORT_CLINIC_CONSULTATION]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        helpSupportRefresh: action.payload.data,
      }),
    },
    [actionType.ATMED_HELP_SUPPORT_ONLINE_CONSULTATION]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        helpSupportRefresh: action.payload.data,
      }),
    },
    [actionType.ATMED_HELP_SUPPORT_SURGERY_CONSULTATION]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        helpSupportRefresh: action.payload.data,
      }),
    },
    [actionType.ATMED_HELP_SUPPORT_ABROAD_CONSULTATION]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        helpSupportRefresh: action.payload.data,
      }),
    },
    [actionType.ATMED_BOOKING_CLINIC_GET_ALL]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        allBookingsRefresh: action.payload.data,
      }),
    },
    [actionType.ATMED_BOOKING_CLINIC_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleAtmedBookingClinic: action.payload.data.data,
      }),
    },
    [actionType.ATMED_BOOKING_SURGERY_GET_ALL]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        allBookingsRefresh: action.payload.data,
      }),
    },
    [actionType.ATMED_BOOKING_SURGERY_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleAtmedBookingSurgery: action.payload.data.data,
      }),
    },
    [actionType.ATMED_BOOKING_ABROAD_GET_ALL]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        allBookingsRefresh: action.payload.data,
      }),
    },
    [actionType.ATMED_BOOKING_ABROAD_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleAtmedBookingAbroad: action.payload.data.data,
      }),
    },
    [actionType.ATMED_BOOKING_EMERGENCY_GET_ALL]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        allBookingsRefresh: action.payload.data,
      }),
    },
    [actionType.ATMED_BOOKING_EMERGENCY_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleAtmedBookingEmergency: action.payload.data.data,
      }),
    },
    [actionType.ATMED_BOOKING_ONLINE_GET_ALL]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        allBookingsRefresh: action.payload.data,
      }),
    },
    [actionType.ATMED_BOOKING_ONLINE_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleAtmedBookingOnline: action.payload.data.data,
      }),
    },
    [actionType.ATMED_BOOKING_LAB_GET_ALL]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        allBookingsRefresh: action.payload.data,
      }),
    },
    [actionType.ATMED_BOOKING_LAB_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleAtmedBookingLab: action.payload.data.data,
      }),
    },
    [actionType.ATMED_BOOKING_HOME_HEALTHCARE_GET_ALL]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        allBookingsRefresh: action.payload.data,
      }),
    },
    [actionType.ATMED_BOOKING_HOME_HEALTHCARE_SERVICES_GET_ALL]: {
      FULFILLED: (state, action) => ({
        ...state,
        services: action.payload.data.data,
      }),
    },
    [actionType.ATMED_BOOKING_HOME_HEALTHCARE_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleAtmedBookingHomeHealthcare: action.payload.data.data,
      }),
    },
    [actionType.ATMED_BOOKING_MEDICAL_EQUIPMENT_GET_ALL]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        atmedBookingsEquipment: action.payload.data,
      }),
    },
    [actionType.ATMED_BOOKING_MEDICAL_EQUIPMENT_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleAtmedBookingMedicalEquipment: action.payload.data.data,
      }),
    },
    [actionType.ATMED_LOG_MANAGEMENT]: {
      FULFILLED: (state, action) => ({
        ...state,
        allAtmedLog: action.payload.data,
      }),
    },
    [actionType.ATMED_NOTIFICATION_MANAGEMENT]: {
      FULFILLED: (state, action) => ({
        ...state,
        allAtmedNotification: action.payload.data,
      }),
    },
  },
  initialState
);

export default userReducer;
