import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAtmedNotification,
} from '../../../store/actions';
import Notification from './notification';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  allAtmedNotification: vendorEquipmenReducer.allAtmedNotification,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAtmedNotification,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
