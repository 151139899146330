import React, { useState, useEffect } from 'react';
import {
  Card, Col, Row, Typography, Divider, Rate
} from 'antd';
import styles from '../profile/profile.module.scss';
import { Column } from '@ant-design/charts';
import SalesIcon from '../../assets/dashboard/sales.svg';
import OrdersIcon from '../../assets/dashboard/orders.svg';
import SettlementsIcon from '../../assets/dashboard/settlements.svg';
import RatingIcon from '../../assets/dashboard/rating.svg';
import StaffsIcon from '../../assets/dashboard/staffs.svg';
import BranchesIcon from '../../assets/dashboard/branches.svg';
import PackagesIcon from '../../assets/dashboard/packages.svg';
import VendorHomeOrderComponent from './VendorHomeOrderComponent'
import VendorSessionsComponent from './VendorSessionsComponent'
import VendorTodaySessionsComponent from './VendorTodaySessionsComponent'

const { Title } = Typography;

const IconImg = (props) => (
  <img src={props.name} alt="Home Healthcare" />
);

const CardComponent = ({ content, title, icon }) => {
  return (
    <Card bordered={false} className='dashboard-card'>
      <Row className="card-title">
        <Col span={6} className="icon-bg">
          <div><IconImg name={icon} /></div>
        </Col>
        <Col span={18} className="title-content">
          <p>{title}</p>
          <span>{content.total}</span>
        </Col>
      </Row>
      <Divider />
      <p className='card-component-content' style={{ marginTop: 25 }}>Monthly: <span>{content.monthly}</span></p>
      <p className='card-component-content'>Weekly: <span>{content.weekly}</span></p>
      <p className='card-component-content'>Today: <span>{content.today}</span></p>
    </Card>
  )
}

const BestSellersComponent = ({ content, title }) => {
  return (
    <div bordered={false} className='best-sellers-card'>
      <Row className="card-title">
        <Col span={18} className="title-content">
          <p>{title}</p>
        </Col>
      </Row>
      {content.map((d, index) => {
        return (
          <p><span className="count">{index + 1}</span>
            <span className="text-trunc">{d.title}</span>
            <span className="value">{d.value}</span>
          </p>
        )
      })}
    </div>
  )
}

const CaregiverComponent = ({ content }) => {
  return (
    <>
      <Row>
        {content.map(item => {
          return (
            <>
              <Col span={12} style={{ padding: 6 }}>{item.title}</Col>
              <Col span={4} offset={8} className="fw500" style={{ padding: 6 }}>{item.value}</Col>
            </>
          )
        })}
      </Row>
      <Row>
        <Col span={12} style={{ paddingBottom: 6 }}>
          <p className='card-component-content' style={{ paddingBottom: 110 }}>&nbsp;</p>
        </Col>
      </Row>
    </>
  )
};

export default function VendorHomeDashboard(props) {

  const [showBarChartValue, setBarChartValue] = useState([]);
  const [currentBarChartValue, setCurrentBarChartValue] = useState('YEAR');

  var paletteSemanticRed = '#F4664A';
  var brandColor = '#5B8FF9';

  const barChartTitle = (
    <Row className="bar-chart-title">
      <Col span={6} className="icon-bg">
        <p>Orders</p>
      </Col>
      <Col span={18} className="title-content">
        {props.data && (
          <div className='orders-sort'>
            <p className={currentBarChartValue === 'TODAY' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data && props.data.order_daily_data); setCurrentBarChartValue('TODAY') }}>TODAY</p>
            <p className={currentBarChartValue === 'WEEK' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data && props.data.order_weekly_data); setCurrentBarChartValue('WEEK') }}>THIS WEEK</p>
            <p className={currentBarChartValue === 'MONTH' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data && props.data.order_monthly_data); setCurrentBarChartValue('MONTH') }}>THIS MONTH</p>
            <p className={currentBarChartValue === 'YEAR' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data && props.data.order_yearly_data); setCurrentBarChartValue('YEAR') }}>THIS YEAR</p>
          </div>
        )}
      </Col>
    </Row>
  );
  var barConfig = {
    data: showBarChartValue,
    xField: 'type',
    yField: 'value',
    seriesField: '',
    color: function color(_ref) {
      var type = _ref.type;
      if (type === '10-30分' || type === '30+分') {
        return paletteSemanticRed;
      }
      return brandColor;
    },
    label: {
      // content: function content(originData) {
      //   var val = parseFloat(originData.value);
      //   if (val < 0.05) {
      //     return (val * 100).toFixed(1) + '%';
      //   }
      // },
      offset: 10,
    },
    legend: false,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };
  useEffect(() => {
    if (props.data && props.data.order_yearly_data) {
      setBarChartValue(props.data && props.data.order_yearly_data);
    }
  }, [props.data]);

  return (
    props.data ? (
      <div className={styles.mainDashboard} className='dashboard' >
        <Title
          level={4}
          className="dashboard-title"
        >
          Dashboard
        </Title>
        <Row gutter={16}>
          <Col className="card-top" span={8}>
            {props.data.total_sales &&
              <CardComponent
                content={props.data.total_sales}
                title='Total Sales'
                icon={SalesIcon}
              />
            }
          </Col>
          <Col className="card-top" span={8}>
            {props.data.total_orders &&
              <>
                <VendorHomeOrderComponent OrdersIcon={OrdersIcon} data={props.data} />
              </>
            }
          </Col>
          <Col className="card-top" span={8}>
            {props.data.total_settlements &&
              <CardComponent
                content={props.data.total_settlements}
                title='Total Settlements'
                icon={SettlementsIcon}
              />
            }
          </Col>
        </Row>
        <Row gutter={16}>
          <Col style={{ marginTop: '16px' }} className="card-top" span={8}>
            {props.data.total_sessions &&
              <>
                <VendorSessionsComponent OrdersIcon={OrdersIcon} data={props.data} />
              </>
            }
          </Col>

          <Col style={{ marginTop: '16px' }} className="card-top" span={8}>
            {props.data.today_total_sessions &&
              <>
                <VendorTodaySessionsComponent OrdersIcon={OrdersIcon} data={props.data} />
              </>
            }
          </Col>

        </Row>
        <Row gutter={16}>
          <Card bordered={false} title={barChartTitle} className='bar-chart-card'>
            <Row>
              <Col style={{ padding: 16 }} span={props.data.bestsellers && props.data.bestsellers.length > 0 ? 16 : 24}>
                {props.data.order_yearly_data && (
                  <Column style={{ height: '300px' }} {...barConfig} />
                )}
              </Col>
              {props.data.bestsellers && props.data.bestsellers.length > 0 && (
                <Col style={{ padding: 16 }} span={8}>
                  {props.data.bestsellers &&
                    <BestSellersComponent
                      content={props.data.bestsellers}
                      title='Best Sellers'
                    />
                  }
                </Col>
              )}
            </Row>
          </Card>
        </Row>
        <Row gutter={16}>
          <Col style={{ padding: 16 }} span={8}>
            {props.data.home_health_packages &&
              <Card bordered={false} className='dashboard-card'>
                <Row className="card-title">
                  <Col span={6} className="icon-bg">
                    <div><IconImg name={PackagesIcon} /></div>
                  </Col>
                  <Col span={18} className="title-content">
                    <p>Home Healthcare Packages</p>
                    <span>{props.data.home_health_packages.total_count}</span>
                  </Col>
                  <Divider />
                  <Col>
                    {props.data.caregiver_staff &&
                      <CaregiverComponent
                        content={props.data.home_health_packages.package_list}
                      />
                    }
                  </Col>
                </Row>
              </Card>
            }
          </Col>
          <Col style={{ padding: 16 }} span={8}>
            {props.data.caregiver_staff &&
              <Card bordered={false} className='dashboard-card'>
                <Row className="card-title">
                  <Col span={6} className="icon-bg">
                    <div><IconImg name={StaffsIcon} /></div>
                  </Col>
                  <Col span={18} className="title-content">
                    <p>Caregiver Staff</p>
                    <span>{props.data.caregiver_staff.total_count}</span>
                  </Col>
                  <Divider />
                  <Col>
                    {props.data.caregiver_staff &&
                      <CaregiverComponent
                        content={props.data.caregiver_staff.caregiver_list}
                      />
                    }
                  </Col>
                </Row>
              </Card>
            }
          </Col>
          <Col span={8}>
            <Row style={{ padding: 16 }} span={8} className="ant-col-24">
              {props.data.overall_ratings &&
                <Card bordered={false} className='dashboard-card ratings-section'>
                  <Row className="card-title">
                    <Col span={6} className="icon-bg">
                      <div><IconImg name={RatingIcon} /></div>
                    </Col>
                    <Col span={18} className="title-content">
                      <p>Overall Ratings</p>
                      {/* <span>{content.total}</span> */}
                    </Col>
                  </Row>
                  <Divider />
                  <p><Rate disabled defaultValue={5} /><span className='rating-value'>{props.data.overall_ratings.five_star}</span></p>
                  <p><Rate disabled defaultValue={4} /><span className='rating-value'>{props.data.overall_ratings.four_star}</span></p>
                  <p><Rate disabled defaultValue={3} /><span className='rating-value'>{props.data.overall_ratings.three_star}</span></p>
                  <p><Rate disabled defaultValue={2} /><span className='rating-value'>{props.data.overall_ratings.two_star}</span></p>
                  <p><Rate disabled defaultValue={1} /><span className='rating-value'>{props.data.overall_ratings.one_star}</span></p>
                </Card>
              }
            </Row>
            <Row style={{ padding: 16 }} span={8} className="ant-col-24">
              {props.data.company_staff &&
                <Card bordered={false} className='dashboard-card'>
                  <Row className="card-title">
                    <Col span={6} className="icon-bg">
                      <div><IconImg name={BranchesIcon} /></div>
                    </Col>
                    <Col span={18} className="title-content">
                      <p>Company Staff</p>
                      <span>{props.data.company_staff.total_count}</span>
                    </Col>
                  </Row>
                </Card>
              }
            </Row>
          </Col>
        </Row>
      </div>
    ) : null

  );
}
