import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  Tag,
  Typography,
  Select,
  Button,
  Row,
  Col,
  message,
  Spin,
  DatePicker,
  Popover
} from "antd";

import { SearchOutlined, SyncOutlined,InfoCircleOutlined} from "@ant-design/icons";
import moment from "moment";
import { unset, get, set } from "lodash";
import ActionsColumn from "./components/ActionsColumn";
import AdvancedSearchForm from "../../../common/SearchForm";
import ProductDescriptionModal from "./components/productDescriptionModal";

const { Title, Link, Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

function UserManagement(props) {
  const [value, setValue] = useState(null);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState([]);
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const confirmVendorEquipmentOrder = async (payload) => {
    await props.confirmVendorEquipmentOrder(payload);
    await props.getAllVendorEquipmentOrders(undefined, 20, currentPage);
  };
  const assignVendorEquipmentOrderDeliveryExecutive = async (payload) => {
    await props.assignVendorEquipmentOrderDeliveryExecutive(payload);
    await props.getAllVendorEquipmentOrders(undefined, 20, currentPage);
  };
  const vendorEquipmentOrderCourierDispatch = async (payload) => {
    await props.vendorEquipmentOrderCourierDispatch(payload);
    await props.getAllVendorEquipmentOrders(undefined, 20, currentPage);
  };
  const vendorEquipmentOrderCompleteOrder = async (payload) => {
    await props.vendorEquipmentOrderCompleteOrder(payload);
    await props.getAllVendorEquipmentOrders(undefined, 20, currentPage);
  };
  const vendorEquipmentCancelOrder = async ({ id, payload }) => {
    await props.cancelVendorEquipmentOrder({ id, payload });
    await props.getAllVendorEquipmentOrders(undefined, 20, currentPage);
  };
  const changeModeEquipmentOrder = async (payload) => {
    await props.changeModeEquipmentOrder(payload);
    await props.getAllVendorEquipmentOrders(undefined, 20, currentPage);
  };
  const changeExecutiveEquipmentOrder = async (payload) => {
    await props.changeExecutiveEquipmentOrder(payload);
    await props.getAllVendorEquipmentOrders(undefined, 20, currentPage);
  };
  const columns = [
    {
      title: "PRODUCT",
      key: "product_name",
      width: "30%",
      render: (_, columnData) => (
        <>
          <Row className="name-details-row">
            <Col span={14}>
              <Text>
                <div>{columnData.product_name}</div>
              </Text>
              <div className="lightFont">
                <span>Order ID : </span>
                {columnData.orderid}
              </div>
              <div className="lightFont">
                <span>Order Date : </span>
                {columnData.order_date.substring(0, 11)}
              </div>
              <div className="view-details">
                <Link
                  onClick={() => {
                    setDescriptionModal(true);
                    setCurrentColumn(columnData);
                  }}
                >
                  VIEW DETAILS
                </Link>
              </div>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Quantity",
      key: "name",
      dataIndex: "product_name",
      render: (_, columnData) => (
        <>
          <div>
            {columnData.mode === "buy" ? (
              <>
                <div className="darkFont">
                  {columnData.qty}
                  {" item"}
                  {columnData.qty === 1 ? "" : "s"}
                </div>
                <Tag className="bg-green">Purchase</Tag>
              </>
            ) : (
              <>
                <Tag className="bg-blue">Rental</Tag>{" "}
                <span>
                  {columnData.qty}
                  day(s)
                </span>
              </>
            )}
          </div>
        </>
      ),
    },
    {
      title: "Delivery Info",
      key: "delivery_mode",
      dataIndex: "product_name",
      render: (_, columnData) => (
        <>
          {columnData.delivery_mode === 1 ? (
            <>
              <div>Delivery Executive</div>
              <div>{`${columnData.delivery_name} - ${columnData.delivery_phone}`}</div>
            </>
          ) : columnData.delivery_mode === 2 ? (
            <>
              <div>
                <Tag color="#e5f4fe">Courier</Tag>
              </div>
              <div className="darkFont">{columnData.tracking_id}</div>
              <div className="darkFont">{`${columnData.courier_name}`}</div>
            </>
          ) : null}
          {columnData.estimated_delivery &&
            <div className="lightFont">
              <span>Est. Delivery : </span>
              {moment(columnData.estimated_delivery).format("DD MMM YYYY - hh:mm A")}
            </div>
          }
        </>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: "24",
      render: (_, columnData) => (
        
        <>
          <div>
            {columnData.status_code === 1 ? (
              <span className="booking-placed">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 2 ? (
              <span className="emergency-book-confirm">
                {columnData.status}
              </span>
            ) : null}
            {columnData.status_code === 3 ? (
              <span className="booking-inprogress">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 4 ? (
              <span className="booking-completed">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 5 ? (
              <span className="booking-cancelled">{columnData.status}</span>
            ) : null}
          </div>
          <div className="lightFont">
            {columnData.status_date}
          </div>

           {columnData.payment_status && (
            <>
              <span className="fw500">Payment Status</span>
              <Popover
                content={
                  <>
                    <Row>
                      <Col span={18}>Payment Status</Col>
                      <Col span={6} className="fw500">
                        {columnData.payment_status}
                      </Col>
                      <Col span={18}>Total Payable</Col>
                      <Col span={6} className="fw500">
                        {/* {columnData.currency} */}
                        ₹
                        {columnData.total_payable}
                      </Col>
                    </Row>
                  </>
                }

                // title="Receivable Amount"
              >
                <Button type="text" icon={<InfoCircleOutlined />} />
              </Popover>
            </>
          )}
        </>
      ),
    },
    {
      title: "Action",
      key: "actions",
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          confirmVendorEquipmentOrder={confirmVendorEquipmentOrder}
          assignVendorEquipmentOrderDeliveryExecutive={
            assignVendorEquipmentOrderDeliveryExecutive
          }
          vendorEquipmentOrderCourierDispatch={
            vendorEquipmentOrderCourierDispatch
          }
          vendorEquipmentOrderCompleteOrder={vendorEquipmentOrderCompleteOrder}
          getAllVendorEquipmentOrderDeliveryExecutive={
            props.getAllVendorEquipmentOrderDeliveryExecutive
          }
          deliveryExecutives={props.deliveryExecutives}
          getAllVendorEquipmentOrders={props.getAllVendorEquipmentOrders}
          helpOptionVendorEquipmentOrder={props.helpOptionVendorEquipmentOrder}
          helpOption={props.helpOption}
          vendorEquipmentCancelOrder={vendorEquipmentCancelOrder}
          changeModeEquipmentOrder={changeModeEquipmentOrder}
          changeExecutiveEquipmentOrder={changeExecutiveEquipmentOrder}
        />
      ),
    },
  ];

  const handleRefresh = async () => {
    await props.getAllVendorEquipmentOrders(undefined, 20, currentPage);
    if (
      props.vendorEquipmentOrderMessage &&
      props.vendorEquipmentOrderMessage
    ) {
      setValue(props.vendorEquipmentOrderMessage.data);
      message.success(props.vendorEquipmentOrderMessage.message);
    }
  };

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    if (newValues["q[date_from_to]"]) {
      const [from, to] = get(newValues, "q[date_from_to]");
      unset(newValues, "q[date_from_to]");
      set(newValues, ["q[created_at_gteq]"], moment(from).format("DD/MM/YYYY"));
      set(newValues, ["q[created_at_lteq]"], moment(to).format("DD/MM/YYYY"));
    }
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAllVendorEquipmentOrders(queryString, 20, currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  const renderData = useCallback(
    (currentPage) => {
      props.getAllVendorEquipmentOrders(undefined, 20, currentPage);
      setValue(props.vendorEquipmentOrders);
    },
    [props.getAllVendorEquipmentOrders]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  return (
    <Spin spinning={props.Loading === true}>
      <div className="all-orders">
        <ProductDescriptionModal
          data={currentColumn}
          visible={descriptionModal}
          onCancel={() => setDescriptionModal(false)}
        />
        <Row gutter={[0, 16]}>
          <Col className="gutter-row section-heading" span={12} offset={0}>
            <Title level={2} className="d-flex title">
              All Orders
            </Title>
          </Col>
          <Col className="gutter-row" span={7} offset={0}></Col>
          <Col className="gutter-row header-buttons" span={3} offset={0}>
            <Button
              className={`${
                searchOption === true ? "search-open" : "search-close"
              } search-toggle-button`}
              onClick={() => toggleSearch()}
            >
              <SearchOutlined /> Search
            </Button>
          </Col>
          <Col className="gutter-row header-buttons" span={2} offset={0}>
            <Button
              shape="circle"
              icon={<SyncOutlined />}
              style={{ marginRight: 8 }}
              onClick={() => handleRefresh()}
            />
          </Col>
        </Row>
        {searchOption === true ? (
          <AdvancedSearchForm
            onSearch={onSearch}
            onClose={() => setSearchOption(false)}
            title="Orders"
            formItems={[
              {
                key: "q[orderid_cont]",
                label: "Order ID",
              },
              {
                key: "q[product_name_cont]",
                label: "Product Name",
              },
              {
                key: "q[mode_eq]",
                label: "Rental / Purchases",
                Component: (
                  <Select placeholder="Select Rental / Purchase" size="large">
                    <Option value="rent">Rental</Option>
                    <Option value="buy">Purchase</Option>
                  </Select>
                ),
              },
              {
                key: "q[equip_transaction_patient_name_cont]",
                label: "Customer name / Phone",
              },
              {
                key: "q[delivery_mode_eq]",
                label: "Delivery mode",
                Component: (
                  <Select placeholder="Select Delivery mode" size="large">
                    <Option value="1">Delivery Executive</Option>
                    <Option value="2">Courier</Option>
                  </Select>
                ),
              },
              {
                key: "q[courier_name_cont]",
                label: "Courier name / Tracking number",
              },
              {
                key: "q[status_code_eq]",
                label: "Order status",
                Component: (
                  <Select placeholder="Select Order status">
                    <Option value="1">Order Placed</Option>
                    <Option value="2">Order Confirmed</Option>
                    <Option value="3">Order Dispatched</Option>
                    <Option value="4">Order Completed</Option>
                    <Option value="5">Order Cancelled</Option>
                  </Select>
                ),
              },
              {
                key: "q[date_from_to]",
                label: "Date",
                Component: (
                  <RangePicker format={["DD/MM/YYYY", "DD/MM/YYYY"]} />
                ),
              },
              {
                key: "q[user_id_eq]",
                label: "Delivery Executive",
                Component: (
                 <Select placeholder="Select Delivery Executive">
                    {(props.vendorEquipmentOrders.user_data || []).map((val) => (
                      <Option value={val.id} key={val.id}>
                        {val.name}
                      </Option>
                    ))}

                  </Select>
                ),
              },

            ]}
          />
        ) : (
          ""
        )}
        <Table
          className="list_table"
          dataSource={
            props.vendorEquipmentOrders
              ? props.vendorEquipmentOrders.data
              : null
          }
          pagination={{
            pageSize: 20,
            total: props.vendorEquipmentOrders?.total_count,
            showSizeChanger: false,
          }}
          columns={columns}
          scroll={{ x: 800 }}
          onChange={(e) => {
            setCurrentPage(e.current);
          }}
        />
      </div>
    </Spin>
  );
}

export default UserManagement;
