import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllHomeHealthcareBookings,
  getSingleHomeHealthcareBooking,
  confirmHomeHealthcareBooking,
  cancelHomeHealthcareBooking,
  getAllUser,
  saveAddress,
  saveData,
  saveLabData,
  saveLabData1,
  saveLabData2
} from '../../../store/actions/homeHealthcareVendor/booking';
import {
  getAllCountryCodes
} from '../../../store/actions';
import {
  getAllHomeHealthcareHelpOption
} from '../../../store/actions';
import {
  getAllLabCompanyBranches,
} from '../../../store/actions';
import {
  getAllLabTests,getAllLabPackages
} from '../../../store/actions';
import {
  getAllVendorLabCatalogDiagnostics,
} from '../../../store/actions';
import Booking from './booking';
import { getAllHomeHealthcareServicesList, getAllHomeHealthcareServices } from "../../../store/actions/homeHealthcareVendor/programsServices";
import {
  getAllHomeHealthcareCaregiverStaff } from "../../../store/actions/homeHealthcareVendor/caregiverStaff";
const mapStateToProps = ({ homeHealthcareReducer, vendorEquipmenReducer }) => ({
  bookings: homeHealthcareReducer.bookings,
  singleBooking: homeHealthcareReducer.singleBooking,
  helpOption: vendorEquipmenReducer.helpOption,
  countryCode: vendorEquipmenReducer.countryCode,
  cancelBooking: homeHealthcareReducer.cancelBooking,
  Loading: homeHealthcareReducer.Loading
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllHomeHealthcareBookings,
    getSingleHomeHealthcareBooking,
    confirmHomeHealthcareBooking,
    getAllHomeHealthcareHelpOption,
    cancelHomeHealthcareBooking,
    getAllHomeHealthcareServicesList,
    getAllHomeHealthcareCaregiverStaff,
    getAllHomeHealthcareServices,
    getAllUser,
    saveAddress,
    saveData,
    saveLabData,
    saveLabData1,
    saveLabData2,
    getAllLabCompanyBranches,
    getAllLabTests,
    getAllLabPackages,
    getAllVendorLabCatalogDiagnostics,
    getAllCountryCodes
    },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
