import * as actionType from './actionType';
import * as userApi from '../api/userApi';

export const getAllUsers = (params) => ({
  type: actionType.GET_ALL_USERS,
  payload: userApi.getAllUsers(params),
});
export const addUser = (params) => ({
  type: actionType.ADD_USER,
  payload: userApi.addUser(params),
});
export const updateUser = (params) => ({
  type: actionType.UPDATE_USER,
  payload: userApi.updateUser(params),
});
export const updateUserPermission = (params) => ({
  type: actionType.UPDATE_USER_PERMISSION,
  payload: userApi.updateUserPermission(params),
});
export const getProfileInfo = (params) => ({
  type: actionType.GET_PROFILE_INFO,
  payload: userApi.getProfileInfo(params),
});
export const getCompanyStaffHomeProfileInfo = (params) => ({
  type: actionType.GET_COMPANYSTAFF_HOME_PROFILE_INFO,
  payload: userApi.getCompanyStaffHomeProfileInfo(params),
});
export const setCompanyStaffHomeProfileInfo = (params) => ({
  type: actionType.SET_COMPANYSTAFF_HOME_PROFILE_INFO,
  payload: userApi.setCompanyStaffHomeProfileInfo(params),
});
export const getCareGiverStaffHomeProfileInfo = (params) => ({
  type: actionType.GET_CAREGIVERSTAFF_HOME_PROFILE_INFO,
  payload: userApi.getCareGiverStaffHomeProfileInfo(params),
});
export const setCareGiverStaffHomeProfileInfo = (params) => ({
  type: actionType.SET_CAREGIVERSTAFF_HOME_PROFILE_INFO,
  payload: userApi.setCareGiverStaffHomeProfileInfo(params),
});
export const getCompanyStaffHealthProductsProfileInfo = (params) => ({
  type: actionType.GET_COMPANYSTAFF_HEALTH_PROFILE_INFO,
  payload: userApi.getCompanyStaffHealthProductsProfileInfo(params),
});
export const setCompanyStaffHealthProductsProfileInfo = (params) => ({
  type: actionType.SET_COMPANYSTAFF_HEALTH_PROFILE_INFO,
  payload: userApi.setCompanyStaffHealthProductsProfileInfo(params),
});
export const activateUser = (params) => ({
  type: actionType.ACTIVATE_ATMED_ADMIN_USER,
  payload: userApi.activateUser(params),
});

export const resendActivateUserOTP = (params) => ({
  type: actionType.RESENDOTP_ACTIVATE_ADMIN_ATMED_USER,
  payload: userApi.resendActivateUserOTP(params),
});

export const changePasswordAction = (params) => ({
  type: actionType.CHANGE_PASSWORD,
  payload: userApi.changePassword(params),
});

export const getNotifications = (params) => ({
  type: actionType.GET_NOTIFICATIONS,
  payload: userApi.getNotifications(params),
});

export const setNotificationsUnread = (params) => ({
  type: actionType.SET_NOTIFICATIONS_UNREAD,
  payload: userApi.setNotificationsUnread(params),
});

