import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Typography,
  Tag,
  Button,
  DatePicker,
  Modal,
  Select,
  Popover,
  message,
  Row,
  Col,
  Divider,
  Tooltip,
  Card,
  Image,
} from "antd";
import { useLocation } from "react-router-dom";
import moment from "moment";
import {
  InfoCircleOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { unset, get, set } from "lodash";
import OrderDetailDrawerModal from "./components/OrderDetailDrawerModal";
import documentsIcon from "../../../assets/document.png";
import AdvancedSearchForm from "../../../common/SearchForm";
import styles from "../../../common/Layout.module.scss";
import UpdateStatusProofModal from "./components/UpdateStatusProof";
import * as XLSX from "xlsx";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Title, Link, Text, Paragraph } = Typography;

const ProofComponent = (value) => {
  return (
    <Row className="document-section">
      <Col span={12} justify="start" style={{ padding: 10 }}>
        <Card>
          {value.values.settlement_proof_url.match(/\.(pdf|doc|xls|xml)$/) !=
          null ? (
            <Link href={value.values.settlement_proof_url} target="_blank">
              <Image
                preview={false}
                src={documentsIcon}
                style={{ marginRight: 6, width: "86px", height: "86px" }}
                alt="Route"
              />
            </Link>
          ) : (
            <Image
              style={{ width: "86px", height: "86px" }}
              alt="Proof files"
              src={value.values.settlement_proof_url}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
};

function FAQS(props) {
  const location = useLocation();
  let searchQuery = "q[equip_payment_status_eq]=false";
  searchQuery = searchQuery + "&" + location.search.substring(1);
  const atmedPaymentsRefresh = useSelector(
    (state) => state.vendorEquipmenReducer.atmedPaymentsRefresh
  );

  const [descriptionModal, setDescriptionModal] = useState(false);
  const [proofVisible, setProofVisible] = useState(false);
  const [stateColumnData, setStateColumnData] = useState({});
  const [currentColumn, setCurrentColumn] = useState([]);
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [settlementVisible, setSettlementVisible] = useState(false);

  const showModal = () => {
    setSettlementVisible(true);
  };

  const hideModal = () => {
    setSettlementVisible(false);
  };

  const onUpdateSettlement = async (payloadObj) => {
    await props.updatePaymentStatusBulk(payloadObj);
    await props.getAtmedPayments(searchQuery, 20, currentPage);
  };

  const showProofModal = (data) => {
    setProofVisible(true);
    setStateColumnData(data);
  };

  const handleProofOk = () => {
    setProofVisible(false);
    setStateColumnData({});
  };

  const handleProofCancel = () => {
    setProofVisible(false);
    setStateColumnData({});
  };

  const columns = [
    {
      title: "Product",
      key: "orderid",
      render: (_, columnData) => (
        <Row>
          <Col>
            <div className="table-fc-name">{columnData.product_name}</div>
            <div className="table-fc-email">Order ID: {columnData.orderid}</div>
            <div className="table-fc-email">
              {columnData.payment_method} : {columnData.payment_id}
            </div>
            <div>
              {columnData.mode === "buy" ? (
                <Tag color="#B9D4B7" className="text-color-black">
                  Purchase
                </Tag>
              ) : (
                <Tag color="#E0CCF3" className="text-color-black">
                  Rental
                </Tag>
              )}
            </div>
            <div className="view-details">
              <Link
                onClick={() => {
                  setDescriptionModal(true);
                  setCurrentColumn(columnData);
                }}
              >
                VIEW DETAILS
              </Link>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Customer Name",
      key: "patient_name",
      render: (_, columnData) => (
        <Row>
          <Col span={24}>{columnData.patient_name} </Col>
          <Col span={24} className="lightFont">
            {columnData.patient_phone}
          </Col>
        </Row>
      ),
    },
    {
      title: "Payment",
      key: "amount",
      render: (_, columnData) => (
        <>
          <div className="fw500">{`${columnData.currency}${columnData.amount}`}</div>
          <div>{columnData.payment_mode}</div>
        </>
      ),
    },

    {
      title: "Settlement",
      key: "payment_mode",
      render: (_, columnData) => (
        <>
          <span className="fw500">{`${columnData.currency}${columnData.vendor_price}`}</span>
          <Popover
            content={
              <>
                <Row>
                  <Col span={12}>Atmed percentage</Col>
                  <Col span={4} offset={8} className="fw500">
                    {columnData.atmed_fee_percent}%
                  </Col>
                  <Col span={12}>Atmed Price</Col>
                  <Col span={4} offset={8} className="fw500">
                    {columnData.currency}
                    {columnData.atmed_price}
                  </Col>
                </Row>
                <Divider style={{ border: 0, margin: "8px 0" }} />
                <Row>
                  <Col span={12}>Company percentage</Col>
                  <Col span={4} offset={8} className="fw500">
                    {100 - columnData.atmed_fee_percent}%
                  </Col>
                  <Col span={12}>Company Price</Col>
                  <Col span={4} offset={8} className="fw500">
                    {columnData.currency}
                    {columnData.vendor_price}
                  </Col>
                </Row>
              </>
            }
            title="Receivable Amount"
          >
            <Button type="text" icon={<InfoCircleOutlined />} />
          </Popover>
        </>
      ),
    },
    {
      title: "Status",
      key: "settlement_status",
      render: (_, columnData) => (
        <Row>
          <Col>
            {columnData.settlement_status ? (
              <span className="activate">Completed</span>
            ) : (
              <span className="inActivateNotverified">Pending</span>
            )}
            {columnData.notes !== null ? (
              <div>
                <Tooltip placement="bottom" title={columnData.notes}>
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            ) : (
              ""
            )}
            {columnData.settlement_proof_url !== null && (
              <div className="view-details">
                <Link onClick={() => showProofModal(columnData)}>
                  VIEW PROOF
                </Link>
              </div>
            )}
          </Col>
        </Row>
      ),
    },
  ];

  const handleRefresh = () => {
    props.getAtmedPayments(searchQuery, 20, currentPage);
    if (atmedPaymentsRefresh && atmedPaymentsRefresh) {
      message.success(atmedPaymentsRefresh.message);
    }
  };

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    if (newValues["q[date_from_to]"]) {
      const [from, to] = get(newValues, "q[date_from_to]");
      unset(newValues, "q[date_from_to]");
      set(newValues, ["q[created_at_gteq]"], moment(from));
      set(newValues, ["q[created_at_lteq]"], moment(to));
    }
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAtmedPayments(`${queryString}&${searchQuery}`, 20, currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  useEffect(() => {
    console.log("searchQuery", searchQuery);
    props.getAtmedPayments(searchQuery, 20, currentPage);
  }, []);

  const renderData = useCallback(
    (currentPage) => {
      props.getAtmedPayments(searchQuery, 20, currentPage);
    },
    [props.equipmentSettlementUpdate]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  const downloadExcel = () => {
    var Heading = [
      [
        "S.No",
        "Order Id",
        "Date Of Purchase",
        "Product Name",
        "Product Quantity",
        "Product Rate",
        "Product MRP",
        "Product Discount Price(%)",
        "GST(%)",
        "Net Amount",
        "Atmed Percentage Amount",
        "Vendor Percentage Amount",
        "Mode Of Payment",
        "Status",
      ],
    ];

    var Heading2 = [
      [
        "S.No",
        "Order Id",
        "Date Of Purchase",
        "Product Name",
        "Product Quantity",
        "Product Rate",
        "Online Payments",
        "Cash on Delivery",
        "Net Amount",
        "Atmed Percentage Amount",
        "Final Vendor Settlement",
      ],
    ];
    console.log("props.atmedPayments", props.atmedPayments);

    var total_price = 0;
    var total_mrp = 0;
    var total_net_amount = 0;
    var total_atmed_percent = 0;
    var total_vendor_percent = 0;
    var s_no = 0;

    const exportData_row = props.atmedPayments.data?.map((row) => {
      total_price = total_price + row.price;
      total_mrp = total_mrp + row.mrp;
      total_net_amount = total_net_amount + row.net_amount;
      total_atmed_percent = total_atmed_percent + row.atmed_price;
      total_vendor_percent = total_vendor_percent + row.vendor_price;
      s_no = s_no + 1;
      return {
        s_no: s_no,
        order_id: row.orderid,
        order_date: row.order_date,
        product_name: row.product_name,
        qty: row.qty,
        price: row.price,
        mrp: row.mrp,
        discount_amount: row.discount,
        gst: "18",
        net_amount: row.net_amount,
        atmed_percent: row.atmed_price,
        vendor_percent: row.vendor_price,
        payment_mode: row.payment_mode,
        status: row.order_status,
      };
    });

    s_no = 0;
    const exportData_row2 = props.atmedPayments.data?.map((row) => {
      total_price = total_price + row.price;
      total_mrp = total_mrp + row.mrp;
      total_net_amount = total_net_amount + row.net_amount;
      total_atmed_percent = total_atmed_percent + row.atmed_price;
      total_vendor_percent = total_vendor_percent + row.vendor_price;
      s_no = s_no + 1;
      return {
        s_no: s_no,
        order_id: row.orderid,
        order_date: row.order_date,
        product_name: row.product_name,
        qty: row.qty,
        price: row.price,
        online_amount: row.net_amount,
        cash_amount: "-",
        net_amount: row.net_amount,
        atmed_percent: row.atmed_price,
        vendor_percent: row.vendor_price,
      };
    });

    //   const total_price = exportData_row.map(each =>  each.price).reduce(function (acc, current) {
    //     return acc + current;
    // }, 0)

    // const total_mrp = exportData_row.map(each =>  each.mrp).reduce(function (acc, current) {
    //   return acc + current;
    // }, 0)

    // const total_net_amount = exportData_row.map(each =>  each.net_amount).reduce(function (acc, current) {
    //   return acc + current;
    // }, 0)

    // const total_atmed_percent = exportData_row.map(each =>  each.atmed_percent).reduce(function (acc, current) {
    //   return acc + current;
    // }, 0)

    // const total_vendor_percent = exportData_row.map(each =>  each.vendor_percent).reduce(function (acc, current) {
    //   return acc + current;
    // }, 0)

    const exportData_total = {
      s_no: "",
      order_id: "",
      order_date: "",
      product_name: "",
      qty: "",
      price: "",
      mrp: "Total: "+total_mrp,
      discount_amount: "",
      gst: "",
      net_amount: "Total: "+total_net_amount,
      atmed_percent: "Total: "+total_atmed_percent,
      vendor_percent: "Total: "+total_vendor_percent,
      payment_mode: "",
      status: "",
    };

    const exportData_total2 = {
      s_no: "",
      order_id: "",
      order_date: "",
      product_name: "",
      qty: "",
      price: "",
      online_amount: "Total: "+total_net_amount,
      cash_amount: "Total: 0",
      net_amount: "Total: "+total_net_amount,
      atmed_percent: "Total: "+total_atmed_percent,
      vendor_percent: "Total: "+total_vendor_percent,
    };

    const exportData = [...exportData_row, exportData_total];
    const exportData2 = [...exportData_row2, exportData_total2];

    console.log("newData", exportData);
    const workSheet = XLSX.utils.json_to_sheet(exportData, { defval: "" });
    const workSheet2 = XLSX.utils.json_to_sheet(exportData2, { defval: "" });

    //protect
    workSheet['!protect'] = {
      password: 'Atmed@123',
      /* enable formatting rows and columns */
      formatRows:false,
      formatColumns:false,
      /* disable editing objects and scenarios */
      objects:true,
      scenarios:true
    }

    workSheet2['!protect'] = {
      password: 'Atmed@123',
      /* enable formatting rows and columns */
      formatRows:false,
      formatColumns:false,
      /* disable editing objects and scenarios */
      objects:true,
      scenarios:true
    }

    workSheet['A1'].s = { // styling for all cells
      font: {
        name: "arial",
        bold: true
      },
      alignment: {
          vertical: "center",
          horizontal: "center",
          wrapText: '1', // any truthy value here
      },
      border: {
          right: {
              style: "thin",
              color: "000000"
          },
          left: {
              style: "thin",
              color: "000000"
          },
      }
    };

   
    // Set header row bold
    // const headerCellStyle = { font: { bold: true } };
    // const headers = Heading;
    // headers.forEach((header, index) => {
    //   const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
    //   console.log('cellAddress',cellAddress);
    //   if (!workSheet[cellAddress]) return;
    //   console.log('in');
    //   workSheet[cellAddress].s = headerCellStyle;
    // });

    // Calculate and set column widths
    const columnWidths = {};
    exportData.forEach(row => {
      Object.keys(row).forEach(key => {
        const cellValue = row[key];
        console.log('cellKey',key)
        console.log('cellValue',cellValue)
        const cellWidth = cellValue ? cellValue.length * 1.2 : 10; // Adjust the multiplier as needed
        if (!columnWidths[key] || cellWidth > columnWidths[key]) {
          columnWidths[key] = cellWidth;
        }
      });
    });
    
    workSheet['!cols'] = Object.keys(columnWidths).map(key => ({ width: columnWidths[key] }));

    // Calculate and set column widths
    const columnWidths2 = {};
    exportData2.forEach(row => {
      Object.keys(row).forEach(key => {
        const cellValue = row[key];
        console.log('cellKey',key)
        console.log('cellValue',cellValue)
        const cellWidth = cellValue.length > 0 ? cellValue.length * 1.2 : 15; // Adjust the multiplier as needed
        if (!columnWidths2[key] || cellWidth > columnWidths2[key]) {
          columnWidths2[key] = cellWidth;
        }
      });
    });
            
    workSheet2['!cols'] = Object.keys(columnWidths2).map(key => ({ width: columnWidths2[key] }));
    
    const workBook = XLSX.utils.book_new();

    XLSX.utils.sheet_add_aoa(workSheet, Heading); //heading: array of arrays
    XLSX.utils.sheet_add_aoa(workSheet2, Heading2); //heading: array of arrays
    XLSX.utils.book_append_sheet(workBook, workSheet, "Product Sales Report");
    XLSX.utils.book_append_sheet(workBook, workSheet2, "Vendor Settlement");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "Reports.xlsx");
  };

  return (
    <div>
      {proofVisible && (
        <Modal
          className="Proof-modal"
          title="Proof"
          footer={false}
          visible={proofVisible}
          onOk={handleProofOk}
          onCancel={handleProofCancel}
          width={300}
        >
          <ProofComponent
            className={styles.detValue}
            values={stateColumnData}
          />
        </Modal>
      )}
      <OrderDetailDrawerModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />

      <UpdateStatusProofModal
        visible={settlementVisible}
        columnData={stateColumnData}
        onUpdateSettlement={onUpdateSettlement}
        getAtmedPayments={props.atmedPayments ? props.atmedPayments.data : null}
        hideModal={hideModal}
        title="Settlement"
        submitText="Submit"
        onCancel={hideModal}
      />

      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Settlements - Medical Equipment
          </Title>
        </Col>
        <Col className="gutter-row section-heading" span={3} offset={0}>
          <Title
              level={4}
              className="d-flex title"
              style={{ marginTop: '10px' }}
            >
              Total: {props.atmedPayments?.total_count}
            </Title>
        </Col>
        <Col className="gutter-row" span={3} offset={0}>
          <Button onClick={showModal} type="primary">
            <span style={{ fontWeight: "600" }}>Settlement</span>
          </Button>
        </Col>
        <Col className="gutter-row" span={3} offset={0}>
          <Button onClick={downloadExcel} type="primary">
            <span style={{ fontWeight: "600" }}>Export</span>
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={2} offset={0}>
          <Button
            shape="circle"
            icon={<SyncOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => handleRefresh()}
          />
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Health Products"
          formItems={[
            {
              key: "q[orderid_cont]",
              label: "Order ID",
            },
            {
              key: "q[patient_name_cont]",
              label: "Customer Name",
            },
            {
              key: "q[product_name_cont]",
              label: "Product Name",
            },
            {
              key: "q[mode_eq]",
              label: "Rental / Purchase",
              Component: (
                <Select placeholder="Rental / Purchase" size="large">
                  <Option value="buy">Purchase</Option>
                  <Option value="rent">Rental</Option>
                </Select>
              ),
            },
            {
              key: "q[payment_mode_eq]",
              label: "Mode Of Payment",
              Component: (
                <Select placeholder="Mode Of Payment" size="large">
                  <Option value="Online">Online</Option>
                  <Option value="Cash On Delivery">Cash On Delivery</Option>
                </Select>
              ),
            },
            {
              key: "q[date_from_to]",
              label: "Date",
              Component: <RangePicker size="large" />,
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={props.atmedPayments ? props.atmedPayments.data : null}
        pagination={{
          pageSize: 20,
          total: props.atmedPayments?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default FAQS;
