import React, { useState } from 'react';
import { Select, AutoComplete, Input } from 'antd';

const { Option } = Select;

const PhoneNumberInput = (props) => {
    const [phoneCode, setPhoneCode] = useState("+91");
    const [phoneNumberList, setPhoneNumberList] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [user, setUser] = useState({});
    const [searchPhoneNumber, setSearchPhoneNumber] = useState("");
  
    const handleOnSelect = (data) => {
      props.getAllUser(data).then((response) => {
        const findUser = response.value.data.data?.find(
          (each) => each.phone === data
        );
  
        setPhoneNumber(findUser?.phone);
        setUser(findUser);
      });
    };
  
    const handleOnSearch = (searchValue) => {
      setUser({});
      props.getAllUser(searchValue).then((response) => {
        console.log("API Response", response);
        const phoneNumbers = response?.value?.data?.data.map((user) => ({
          phone: user.phone,
        }));
        if (phoneNumbers?.length === 0) {
          setSearchPhoneNumber(searchValue);
        } else {
          setSearchPhoneNumber("");
        }
        setPhoneNumberList(phoneNumbers);
      });
    };
  
    const selectBefore = (
      <Select
        value={phoneCode}
        placeholder="Phone Code"
        size="large"
        onSelect={(value) => setPhoneCode(value)}
      >
        <Option value="+91">+91</Option>
        {/* Add more options as needed */}
      </Select>
    );
  
    return (
  
      <Input.Group compact>
      <Select
        value={phoneCode}
        size="large"
        onSelect={(value) => setPhoneCode(value)}
        style={{ width: '25%' }}
      >
        <Option value="+91">+91</Option>
        {/* Add more options as needed */}
      </Select>
      <AutoComplete
        style={{ width: '80%' }}
        size="large"
        options={phoneNumberList?.map((phoneObj, index) => ({
          value: phoneObj.phone,
          label: phoneObj.phone,
          key: index.toString(),
        }))}
        onSelect={handleOnSelect}
        onSearch={handleOnSearch}
        placeholder="Enter phone number"
        value={phoneNumber}
        onChange={(value) => setPhoneNumber(value.replace(phoneCode, ''))}
      />
    </Input.Group>
    );
  };

export default PhoneNumberInput;