import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Typography,
  Select,
  DatePicker,
  Button,
  Row,
  Col,
  message,
  Popover,
  Spin,
} from "antd";
import {
  SearchOutlined,
  SyncOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { unset, get, set } from "lodash";
import ActionsColumn from "./components/ActionsColumn";
import AdvancedSearchForm from "../../../common/SearchForm";
import ProductDescriptionModal from "./components/productDescriptionModal";

const { Title, Link, Text } = Typography;
const { Option } = Select;

function UserManagement(props) {
  const afterGetAllOrders = useSelector(
    (state) => state.labDiagnosticsReducer.vendorLabOrderMessage
  );

  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState([]);
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [refreshPage, setRefreshPage] = useState(false);

  const handleRefresh = useCallback(async () => {
    if (refreshPage) return
    setRefreshPage(true)
    await props.getAllVendorLabOrders(undefined, 20, currentPage);
    if (afterGetAllOrders && afterGetAllOrders) {
      message.success(afterGetAllOrders.message);
    }
    setRefreshPage(false)
  }, [refreshPage])

  const renderData = useCallback(
    (currentPage) => {
      props.getAllVendorLabOrders(undefined, 20, currentPage);
      props.getAllVendorLabTechnicians.call();
    },
    [
      props.getAllVendorLabOrders,
      props.getAllVendorLabTechnicians,
      props.cancelledLabOrders,
      props.labCollectPaymentVerifyOtp,
    ]
  );

  useEffect(() => {
    renderData(currentPage);
    console.log("Refreshed");
  }, [renderData, currentPage, 
    refreshPage]);

  const confirmVendorLabOrder = async (payload) => {
    await props.confirmVendorLabOrder(payload);
    await props.getAllVendorLabOrders(undefined, 20, currentPage);
  };
  const updateEquipmentProduct = async (payloadObj) => {
    await props.updateLabOrderReportCreate(payloadObj);
    await props.getAllVendorLabOrders(undefined, 20, currentPage);
  };
  const assignVendorLabOrderTechnician = async (payload) => {
    await props.assignVendorLabOrderTechnician(payload);
    await props.getAllVendorLabOrders(undefined, 20, currentPage);
  };
  const vendorEquipmentOrderCourierDispatch = async (payload) => {
    await props.vendorEquipmentOrderCourierDispatch(payload);
    await props.getAllVendorLabOrders(undefined, 20, currentPage);
  };
  const vendorLabOrderCompleteOrder = async (payload) => {
    await props.vendorLabOrderCompleteOrder(payload);
    await props.getAllVendorLabOrders(undefined, 20, currentPage);
  };

  const columns = [
    {
      title: "Order ID",
      key: "orderid",
      dataIndex: "orderid",
      width: "17%",
      render: (_, columnData) => (
        <>
          <Row>
            <Col>
              <Text>
                <div>{columnData.module}</div>
              </Text>
              <div className="lightFont">
                <span>Order ID : </span>
                {columnData.orderid}
              </div>
              <div className="lightFont">
                <span>Order Date : </span>
                {columnData.order_date &&
                  columnData.order_date.substring(0, 11)}
              </div>
              <div className="view-details">
                <Link
                  onClick={() => {
                    setDescriptionModal(true);
                    setCurrentColumn(columnData);
                  }}
                >
                  {/* VIEW DETAILSss */}
                  VIEW DETAILS
                </Link>
              </div>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Patient Details",
      key: "patient_name",
      width: "17%",
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>{columnData.patient_name} </Col>
            <Col span={24} className="lightFont">
              {columnData.patient_age} / {columnData.patient_gender}
            </Col>
            <Col span={24} className="lightFont">
              {columnData.patient_phone}
            </Col>
          </Row>
          <Row>
            <Col span={24} className="lightFont">
              {columnData.schedule_date}
            </Col>
            <Col span={24} className="lightFont">
              ({columnData.schedule_time})
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Test Details",
      key: "name",
      dataIndex: "product_name",
      width: "25%",
      render: (_, columnData) => (
        <>
          <div>{columnData.tests}</div>
          <div className="lightFont">{columnData.branch_name}</div>
        </>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, columnData) => (
        <>
          <Col span={24}>
            {columnData.status_code === 1 ? (
              <span className="booking-placed">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 2 ? (
              <span className="emergency-book-confirm">
                {columnData.status}
              </span>
            ) : null}
            {columnData.status_code === 3 ? (
              <span className="booking-inprogress">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 4 ? (
              <span className="booking-completed">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 5 ? (
              <span className="booking-cancelled">{columnData.status}</span>
            ) : null}
          </Col>
          {columnData.technician && (
            <Col>
              <div>Lab Technician:</div>
              <div className="lightFont">{columnData.technician_name}</div>
              <div className="lightFont">{columnData.technician_phone}</div>
            </Col>
          )}
          {columnData.payment_status && (
            <>
              <span className="fw500">Payment Status</span>
              <Popover
                content={
                  <>
                    <Row>
                      <Col span={18}>Payment Status</Col>
                      <Col span={6} className="fw500">
                        {columnData.payment_status}
                      </Col>
                      <Col span={18}>Total Payable</Col>
                      <Col span={6} className="fw500">
                        {columnData.currency}
                        {columnData.total_payable}
                      </Col>
                    </Row>
                  </>
                }

                // title="Receivable Amount"
              >
                <Button type="text" icon={<InfoCircleOutlined />} />
              </Popover>
            </>
          )}
        </>
      ),
    },
    {
      title: "Action",
      key: "actions",
      width: "25%",
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          confirmVendorLabOrder={confirmVendorLabOrder}
          assignVendorLabOrderTechnician={assignVendorLabOrderTechnician}
          vendorEquipmentOrderCourierDispatch={
            vendorEquipmentOrderCourierDispatch
          }
          vendorLabOrderCompleteOrder={vendorLabOrderCompleteOrder}
          getAllVendorLabTechnicians={props.getAllVendorLabTechnicians}
          allLabTechnicians={props.allLabTechnicians}
          updateEquipmentProduct={updateEquipmentProduct}
          getAllVendorLabOrdersReport={props.getAllVendorLabOrdersReport}
          vendorLabOrderPaymentOtp={props.vendorLabOrderPaymentOtp}
          vendorLabOrderPaymentVerifyOtp={props.vendorLabOrderPaymentVerifyOtp}
          vendorLabOrderPaymentResendOtp={props.vendorLabOrderPaymentResendOtp}
          labOrderCancel={props.labOrderCancel}
          getAllVendorLabOrders={props.getAllVendorLabOrders}
          helpOptionVendorLabDiagnostics={props.helpOptionVendorLabDiagnostics}
          helpOption={props.helpOption}
        />
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    if (newValues["q[date_from_to]"]) {
      const [from, to] = get(newValues, "q[date_from_to]");
      unset(newValues, "q[date_from_to]");
      set(newValues, ["q[created_at_gteq]"], moment(from).format("DD/MM/YYYY"));
      set(newValues, ["q[created_at_lteq]"], moment(to).format("DD/MM/YYYY"));
    }
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAllVendorLabOrders(queryString, 20, currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <Spin spinning={props.Loading === true}>
      <ProductDescriptionModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title level={2} className="d-flex title">
            All Orders
          </Title>
        </Col>
        <Col className="gutter-row" span={7} offset={0}></Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={2} offset={0}>
          <Button
            shape="circle"
            icon={<SyncOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => handleRefresh()}
          />
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Orders"
          formItems={[
            {
              key: "q[orderid_cont]",
              label: "Order ID",
            },
            {
              key: "q[module_eq]",
              label: "Service Name",
              Component: (
                <Select placeholder="Select Service" size="large">
                  <Option value="lab_home">Labs at Home</Option>
                  <Option value="lab_near">Labs Near You</Option>
                  <Option value="diagnostic_near">Diagnostics</Option>
                </Select>
              ),
            },
            {
              key: "q[patient_name_cont]",
              label: "Patient name / Phone",
            },
            {
              key: "q[status_code_eq]",
              label: "Order Status",
              Component: (
                <Select placeholder="Select Order status" size="large">
                  <Option value="1">Order Placed</Option>
                  <Option value="2">Order Confirmed</Option>
                  <Option value="3">Order Dispatched</Option>
                  <Option value="4">Order Completed</Option>
                  <Option value="5">Order Cancelled</Option>
                </Select>
              ),
            },
            {
              key: "q[created_at_gteq]",
              label: "From Date",
              Component: <DatePicker size="large" />,
            },
            {
              key: "q[created_at_lteq]",
              label: "To Date",
              Component: <DatePicker size="large" />,
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={props.results ? props.results.data : null}
        pagination={{
          pageSize: 20,
          total: props.results?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </Spin>
  );
}

export default UserManagement;
