/* eslint-disable camelcase */
import { protectedServiceInstance } from "./instance";
import { message } from "antd";

const apiKey = "7A3MXb5pTzE7zMuBRspN";

export const getAllCountryCodes = (queryString) =>
  protectedServiceInstance.get(`atmed/countries/code/all`, {
    params: { api_key: apiKey },
  });

export const getAllEquipmentUsers = (queryString) =>
  protectedServiceInstance.get(`equipment/users/all?${queryString}`, {
    params: { api_key: apiKey },
  });

export const addEquipmentUser = (payload) =>
  protectedServiceInstance
    .post("equipment/user/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const updateEquipmentUser = ({ payload, id }) =>
  protectedServiceInstance
    .post("equipment/user/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
      getAllEquipmentUsers();
    });

export const updateEquipmentUserProfile = ({ payload, id }) =>
  protectedServiceInstance
    .post("equipment/user/profile/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
      getAllEquipmentUsers();
    });

export const updateEquipmentUserPermission = ({ payload, id }) =>
  protectedServiceInstance
    .post("equipment/user/permission", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
      getAllEquipmentUsers();
    });

export const activateEquipmentUser = ({ id, otp_digest }) =>
  protectedServiceInstance.post(
    "equipment/user/activate",
    {},
    {
      params: { api_key: apiKey, id, otp_digest },
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const resendEquipmentActivateUserOTP = ({ id }) =>
  protectedServiceInstance
    .post(
      "equipment/user/resend-otp",
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllEquipmentProducts = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `equipment/products/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );
// export const getSearchEquipmentProducts = (catgory_name) =>
//   protectedServiceInstance.get(
//     `equipment/products/search?category_eq=Baby Care&limit=20&offset=0`,
//     {
//       params: { api_key: apiKey },
//     }
//   );
export const getSearchEquipmentProducts = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `equipment/products/search?${queryString}&limit=20&offset=0`,
    {
      params: { api_key: apiKey },
    }
  );
export const getSingleEquipmentProduct = (queryString) =>
  protectedServiceInstance.get(`equipment/product/show?${queryString}`, {
    params: { api_key: apiKey },
  });
export const addEquipmentProduct = (payload) =>
  protectedServiceInstance
    .post("equipment/product/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const updateEquipmentProduct = ({ payload, id }) =>
  protectedServiceInstance
    .post("equipment/product/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const addEquipmentProductAsset = ({ payload, id }) =>
  protectedServiceInstance
    .post("equipment/product/asset/create", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
// CASEFILES

export const addcasefilesAsset = ({ payload, id }) => {
  console.log("Payload:", payload);
  console.log("ID:", id);

  return protectedServiceInstance
    .post("medical/abroad/case-files/create", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
      return res.data;
    })
    .catch((error) => {
      message.error("Adding case files failed");
      throw error;
    });
};

export const addcasefilesurgeryAsset = ({ payload, id }) =>
  protectedServiceInstance
    .post("abroad/case-files/create", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteProductAsset = ({ id }) =>
  protectedServiceInstance
    .post(
      "equipment/product/asset/delete",
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllVendorEquipmentOrders = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `equipment/orders/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );
export const getSingleVendorEquipmentOrder = (queryString) =>
  protectedServiceInstance.get(`equipment/order/show?${queryString}`, {
    params: { api_key: apiKey },
  });
export const getAllVendorEquipmentOrderDeliveryExecutive = ({ id }) =>
  protectedServiceInstance.get("equipment/order/delivery-users", {
    params: { api_key: apiKey, id },
  });
export const confirmVendorEquipmentOrder = ({ id, payload }) =>
  protectedServiceInstance
    .post("equipment/order/confirm", payload, {
      params: { api_key: apiKey, id },
    })
    .then((res) => {
      if (res.data.code === "422") {
        message.error(res.data.message);
      } else {
        message.success(res.data.message);
      }
    });
export const changeModeEquipmentOrder = ({ id, payload }) =>
  protectedServiceInstance
    .post("equipment/order/change-mode", payload, {
      params: { api_key: apiKey, id },
    })
    .then((res) => {
      if (res.data.code === "422") {
        message.error(res.data.message);
      } else {
        message.success(res.data.message);
      }
    });
export const changeExecutiveEquipmentOrder = ({ id, payload }) =>
  protectedServiceInstance
    .post("equipment/order/change-executive", payload, {
      params: { api_key: apiKey, id },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const cancelVendorEquipmentOrder = ({ id, payload }) =>
  protectedServiceInstance
    .post(`equipment/order/cancel?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const helpOptionVendorEquipmentOrder = ({ id, payload }) =>
  protectedServiceInstance.get("equipment/help/options", payload, {
    params: { api_key: apiKey, id },
  });
export const assignVendorEquipmentOrderDeliveryExecutive = ({
  id,
  payload,
  userId,
}) =>
  protectedServiceInstance
    .post("equipment/order/assign-delivery", payload, {
      params: { api_key: apiKey, id, user_id: userId },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const vendorEquipmentOrderCourierDispatch = ({ id, payload }) =>
  protectedServiceInstance
    .post("equipment/order/courier-dispatch", payload, {
      params: { api_key: apiKey, id },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const vendorEquipmentOrderCompleteOrder = ({ id }) =>
  protectedServiceInstance
    .post(
      "equipment/order/complete",
      {},
      {
        params: { api_key: apiKey, id },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllHealthcareVendor = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `atmed/vendors/home/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );
export const getSingleHealthcareVendor = (queryString) =>
  protectedServiceInstance.get(`atmed/vendors/home/show?${queryString}`, {
    params: { api_key: apiKey },
  });
export const addHealthcareVendor = (payload) =>
  protectedServiceInstance
    .post("atmed/vendors/home/create", payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });
// export const updateHealthcareVendor = ({ id, payload }) => protectedServiceInstance.post(
//   'atmed/vendors/home/update',
//   payload,
//   {
//     params: { api_key: apiKey, id },
//   },
// ).then((res) => {
//   message.success(res.data.message);
// });

export const updateHealthcareVendor = ({ payload, id }) =>
  protectedServiceInstance
    .post("atmed/vendors/home/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllHomeServices = ({ id }) =>
  protectedServiceInstance.get("atmed/vendors/home/services/all", {
    params: { api_key: apiKey, id },
  });
export const associateServiceToVendor = ({ id, serviceId }) =>
  protectedServiceInstance.post(
    "atmed/vendors/home/services/update",
    {},
    {
      params: { api_key: apiKey, id, service_id: serviceId },
    }
  );
export const addHomeHealthcareOwner = ({ id, payload }) =>
  protectedServiceInstance
    .post("atmed/vendors/home/user/create", payload, {
      params: { api_key: apiKey, id },
    })
    .then((res) => {
      setTimeout(() => message.success(res.data.message), 2000);
    });
export const updateHomeHealthcareOwner = ({ id, payload, userId }) =>
  protectedServiceInstance
    .post("atmed/vendors/home/user/update", payload, {
      params:
        payload.user.password === undefined
          ? { id, user_id: userId }
          : { api_key: apiKey, id, user_id: userId },
    })
    .then((res) => {
      setTimeout(() => message.success(res.data.message), 2000);
    });

export const getAllEquipmentSupportedLocations = (queryString) =>
  protectedServiceInstance.get(`equipment/locations/all?${queryString}`, {
    params: { api_key: apiKey },
  });

export const getAllHomeSupportedLocations = (queryString) =>
  protectedServiceInstance.get(`home/locations/all?${queryString}`, {
    params: { api_key: apiKey },
  });

export const getAllEquipmentCities = (queryString) =>
  protectedServiceInstance.get(`equipment/locations/cities?${queryString}`, {
    params: { api_key: apiKey },
  });

export const getAllHomeCities = (queryString) =>
  protectedServiceInstance.get(`home/locations/cities?${queryString}`, {
    params: { api_key: apiKey },
  });

export const getAllHomeRegions = (queryString) =>
  protectedServiceInstance.get(`home/locations/regions?${queryString}`, {
    params: { api_key: apiKey },
  });

export const getAllEquipmentCategories = (queryString) =>
  protectedServiceInstance.get(
    `equipment/products/category/all?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllCitie = () =>
  protectedServiceInstance.get(`equipment/products/cities/all`, {
    params: { api_key: apiKey },
  });
// eslint-disable-next-line max-len
export const updateAllEquipmentSupportedLocations = ({ payload }) =>
  protectedServiceInstance.post("equipment/locations/update", payload, {
    params: { api_key: apiKey },
  });
export const updateAllHomeSupportedCities = ({ payload }) =>
  protectedServiceInstance.post("home/locations/city-update", payload, {
    params: { api_key: apiKey },
  });
export const updateAllHomeSupportedRegions = ({ payload }) =>
  protectedServiceInstance.post("home/locations/region-update", payload, {
    params: { api_key: apiKey },
  });

export const getPatientProductFAQs = (queryString) =>
  protectedServiceInstance.get(
    `equipment/product/faq-patient/all?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const updatePatientProductFAQ = ({ payload, faq_id }) =>
  protectedServiceInstance.post(
    `equipment/product/faq-patient/update?id=${faq_id}`,
    payload,
    {
      params: { api_key: apiKey },
    }
  );

export const deletePatientProductFAQ = ({ faq_id }) =>
  protectedServiceInstance.post(
    "equipment/product/faq-patient/delete",
    {},
    {
      params: { api_key: apiKey, faq_id },
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const getEquipmentFeeback = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `equipment/feedback/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getEquipmentFeebackInfo = (queryString) =>
  protectedServiceInstance.get(
    `equipment/feedback/order/detail?order_id=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const getEquipmentPayments = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `equipment/settlements/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getEquipmentPaymentInfo = (queryString) =>
  protectedServiceInstance.get(
    `equipment/settlements/order/detail?id=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const getHomePayments = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `home/settlements/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );
export const getHomePaymentDetails = (queryString) =>
  protectedServiceInstance.get(
    `home/settlements/booking/detail?id=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );
export const getHomeCompanyProfile = (queryString) =>
  protectedServiceInstance.get(`home/company/profile?${queryString}`, {
    params: { api_key: apiKey },
  });

export const getAtmedPayments = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `atmed/settlements/equipment/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const updatePaymentStatus = ({ payload, id }) =>
  protectedServiceInstance
    .post(`atmed/settlements/equipment/update?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updatePaymentStatusBulk = ({ payload, id }) =>
  protectedServiceInstance
    .post(`atmed/settlements/equipment/updatebulk?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAtmedHealthProductSettlementInfo = (queryString) =>
  protectedServiceInstance.get(
    `atmed/equipment/settlement/order/detail?id=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedPaymentsHome = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `atmed/settlements/home/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedHomeHealthcareSettlement = (queryString) =>
  protectedServiceInstance.get(
    `atmed/home/settlement/booking/detail?id=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const updatePaymentStatusHome = ({ payload, id }) =>
  protectedServiceInstance
    .post(`atmed/settlements/home/update?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updatePaymentStatusHomeBulk = ({ payload, id }) =>
  protectedServiceInstance
    .post(`atmed/settlements/home/updatebulk?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAtmedAssets = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `atmed/equipment/assets/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const updateAssetStatus = ({ payload, id }) =>
  protectedServiceInstance
    .post(`atmed/equipment/asset/update?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteAssets = ({ id }) =>
  protectedServiceInstance
    .post(
      "atmed/equipment/asset/delete",
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const activateUser = ({ id, otp_digest }) =>
  protectedServiceInstance.post(
    "equipment/user/activate",
    {},
    {
      params: { api_key: apiKey, id, otp_digest },
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const resendActivateUserOTP = ({ id }) =>
  protectedServiceInstance.post(
    "equipment/user/resend-otp",
    {},
    {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const getAtmedAssetsHome = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `atmed/home/assets/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const updateAssetStatusHome = ({ payload, id }) =>
  protectedServiceInstance
    .post(`atmed/home/asset/update?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteAssetsHome = ({ id }) =>
  protectedServiceInstance
    .post(
      "atmed/home/asset/delete",
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const getAtmedEquipmentFeedBack = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `atmed/equipment/feedback/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );
export const getAtmedEquipmentFeedBackSingleInfo = (queryString) =>
  protectedServiceInstance.get(
    `atmed/equipment/order/detail?order_id=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );
export const getAtmedHealthFeedBack = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `atmed/home/feedback/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedHomeFeedBack = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `home/feedback/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedHomeFeedBackInfo = (queryString) =>
  protectedServiceInstance.get(
    `atmed/home/session/detail?session_id=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const getHomeFeedBackInfo = (queryString) =>
  protectedServiceInstance.get(`home/feedback/session/detail?${queryString}`, {
    params: { api_key: apiKey },
  });

export const getEquipmentCompanyProfile = (queryString) =>
  protectedServiceInstance.get(`equipment/company/profile?${queryString}`, {
    params: { api_key: apiKey },
  });

export const getAllAtmedSettlementsLabDiagnostics = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/settlements/lab/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedSettlementsLabDiagnostics = (queryString) =>
  protectedServiceInstance.get(
    `atmed/lab/settlement/order/detail?id=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const updateAtmedSettlementsLabDiagnostics = ({ payload, id }) =>
  protectedServiceInstance
    .post(`atmed/settlements/lab/update?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateAtmedSettlementsLabDiagnosticsBulk = ({ payload, id }) =>
  protectedServiceInstance
    .post(`atmed/settlements/lab/updatebulk?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllAtmedSettlementsPharmacy = (queryString) =>
  protectedServiceInstance.get(
    `atmed/settlements/pharmacy/all?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const updateAtmedSettlementsPharmacy = ({ payload, id }) =>
  protectedServiceInstance
    .post(`atmed/settlements/pharmacy/update?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAtmedSettlementsPharmacy = (queryString) =>
  protectedServiceInstance.get(
    `atmed/pharmacy/settlement/order/detail/?id=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllAtmedSettlementsDoctorConsultation = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/settlements/medical/consultation/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedSettlementsDoctorConsultationInfo = (queryString) =>
  protectedServiceInstance.get(
    `atmed/settlements/medical/consultation/detail?id=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const updateAtmedSettlementsDoctorConsultation = ({ payload, id }) =>
  protectedServiceInstance
    .post(`atmed/settlements/medical/consultation/update?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllAtmedSettlementsSurgeryConsultation = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/settlements/medical/surgery/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedSurgerySettlementInfo = (queryString) =>
  protectedServiceInstance.get(
    `atmed/settlements/medical/surgery/detail?id=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const updateAtmedSettlementsSurgeryConsultation = ({ payload, id }) =>
  protectedServiceInstance
    .post(`atmed/settlements/medical/surgery/update?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateAtmedSettlementsSurgeryConsultationBulk = ({
  payload,
  id,
}) =>
  protectedServiceInstance
    .post(`atmed/settlements/medical/surgery/updatebulk?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllAtmedSettlementsOnlineConsultation = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/settlements/medical/online/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );
export const getAtmedOnlineSettlementInfo = (queryString) =>
  protectedServiceInstance.get(
    `atmed/settlements/medical/online/detail?id=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );
export const updateAtmedSettlementsOnlineConsultation = ({ payload, id }) =>
  protectedServiceInstance
    .post(`atmed/settlements/medical/online/update?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllAtmedSettlementsAbroad = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/settlements/medical/abroad/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedAbroadSettlementInfo = (queryString) =>
  protectedServiceInstance.get(
    `atmed/settlements/medical/abroad/detail?id=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const updateAtmedSettlementsAbroad = ({ payload, id }) =>
  protectedServiceInstance
    .post(`atmed/settlements/medical/abroad/update?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllAtmedFeedBackLabDiagnostics = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/lab/feedback/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedFeedBackLabDiagnostics = (queryString) =>
  protectedServiceInstance.get(
    `atmed/lab/order/detail?order_id=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllAtmedFeedBackPharmacy = (queryString) =>
  protectedServiceInstance.get(`atmed/pharmacy/feedback/all?${queryString}`, {
    params: { api_key: apiKey },
  });

export const getAtmedFeedBackPharmacy = (queryString) =>
  protectedServiceInstance.get(
    `atmed/pharmacy/order/detail?order_id=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllAtmedFeedBackMedicalDoctorConsultation = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/medical/consultation/feedback/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllAtmedFeedBackMedicalSurgeryConsultation = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/medical/surgery/feedback/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllAtmedFeedBackMedicalOnlineConsultation = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/medical/online/feedback/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllAtmedFeedBackMedicalTourism = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/medical/abroad/feedback/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllAtmedFeedBackMedicalEmergency = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/medical/emergency/feedback/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

// Atmed - Vendor - Lab Diagnostics
export const getAllVendorLabsDiagnostics = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/vendors/labs/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedVendorLabsDiagnosticsInfo = (id) =>
  protectedServiceInstance.get("atmed/vendors/labs/show", {
    params: { api_key: apiKey, id },
  });

export const addAtmedVenderLabsDiagnostics = (payload) =>
  protectedServiceInstance
    .post("atmed/vendors/labs/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateAtmedVendorLabsDiagnostics = ({ payload, id }) =>
  protectedServiceInstance
    .post("atmed/vendors/labs/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const addAtmedVendorLabsDiagnosticsOwner = ({ id, payload }) =>
  protectedServiceInstance
    .post("atmed/vendors/labs/user/create", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const updateAtmedVendorLabsDiagnosticsOwner = ({
  payload,
  id,
  userId,
}) =>
  protectedServiceInstance
    .post("atmed/vendors/labs/user/update", payload, {
      params:
        payload.user.password === undefined
          ? { id, user_id: userId }
          : { api_key: apiKey, id, user_id: userId },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

// Branches
export const getAllVendorLabsDiagnosticsBranches = (queryString) =>
  protectedServiceInstance.get(
    `atmed/vendors/labs/branches/all?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedVendorLabsDiagnosticsBranchInfo = (id) =>
  protectedServiceInstance.get("atmed/vendors/labs/branches/show", {
    params: { api_key: apiKey, id },
  });

export const addAtmedVenderLabsDiagnosticsBranch = ({ id, payload }) =>
  protectedServiceInstance
    .post(`atmed/vendors/labs/branches/create`, payload, {
      params: { api_key: apiKey, id },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateAtmedVenderLabsDiagnosticsBranch = ({ payload, id }) =>
  protectedServiceInstance
    .post(`atmed/vendors/labs/branches/update?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });

// ATMED MEDIA PHARMACY
export const getAtmedAssetsPharmacy = (queryString) =>
  protectedServiceInstance.get(`atmed/pharmacy/assets/all?${queryString}`, {
    params: { api_key: apiKey },
  });

export const updateAssetStatusPharmacy = ({ payload, id }) =>
  protectedServiceInstance
    .post(`atmed/pharmacy/asset/update?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteAssetsPharmacy = ({ id }) =>
  protectedServiceInstance
    .post(
      "atmed/pharmacy/asset/delete",
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const getAtmedAssetsMedic = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `atmed/medical/medic/assets/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const updateAssetStatusMedic = ({ payload, id }) =>
  protectedServiceInstance
    .post(`atmed/medical/medic/asset/update?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteAssetsMedic = ({ id }) =>
  protectedServiceInstance
    .post(
      "atmed/medical/medic/asset/delete",
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const getAtmedAssetsHospital = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `atmed/medical/centre/assets/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const updateAssetStatusHospital = ({ payload, id }) =>
  protectedServiceInstance
    .post(`atmed/medical/centre/asset/update?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteAssetsHospital = ({ id }) =>
  protectedServiceInstance
    .post(
      "atmed/medical/centre/asset/delete",
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllAtmedVendorMedic = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `atmed/medical/medics/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllAtmedVendorMedicCategory = (queryString) =>
  protectedServiceInstance.get(
    `atmed/medical/medic/category/search?search_term=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllAtmedVendorMedicCategories = (queryString) =>
  protectedServiceInstance.get(`atmed/medical/medic/category/all`, {
    params: { api_key: apiKey },
  });

export const getAtmedVendorMedicInfo = (id) =>
  protectedServiceInstance.get("atmed/medical/medic/show", {
    params: { api_key: apiKey, id },
  });

// export const getAllCatalogSurgeryCategories = (queryString) => protectedServiceInstance.get(`atmed/medical/surgeries/categories/all`, {
//   params: { api_key: apiKey },
// });

export const addAtmedVendorMedicUser = (payload) =>
  protectedServiceInstance
    .post("atmed/medical/medics/user/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const addAtmedVendorMedic = (payload) =>
  protectedServiceInstance
    .post("atmed/medical/medic/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateAtmedVendorMedicUser = ({ payload, user_id }) =>
  protectedServiceInstance
    .post("atmed/medical/medics/user/update", payload, {
      // params: { api_key: apiKey, user_id },
      params:
        payload.user.password === undefined
          ? { user_id }
          : { api_key: apiKey, user_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateAtmedVendorMedic = ({ payload, id }) =>
  protectedServiceInstance
    .post("atmed/medical/medic/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateimageAtmedVendorMedic = ({ payload, id }) =>
  protectedServiceInstance
    .post("atmed/medical/medic/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllVendorMedicalHospital = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/vendors/medical/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedVendorMedicalHospitalInfo = (id) =>
  protectedServiceInstance.get("atmed/vendors/medical/show", {
    params: { api_key: apiKey, id },
  });

export const addAtmedVenderMedicalHospital = (payload) =>
  protectedServiceInstance
    .post(`atmed/vendors/medical/create`, payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateAtmedVendorMedicalHospital = ({ payload, id }) =>
  protectedServiceInstance
    .post("atmed/vendors/medical/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const addAtmedVendorHospitalOwner = ({ id, payload }) =>
  protectedServiceInstance
    .post("atmed/vendors/medical/user/create", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const updateAtmedVendorHospitalOwner = ({ payload, id, userId }) =>
  protectedServiceInstance
    .post("atmed/vendors/medical/user/update", payload, {
      params:
        payload.user.password === undefined
          ? { id, user_id: userId }
          : { api_key: apiKey, id, user_id: userId },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllAtmedVendorHospitalBranches = (queryString) =>
  protectedServiceInstance.get(
    `atmed/vendors/medical/branches/all?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedVendorHospitalBranchInfo = (id) =>
  protectedServiceInstance.get("atmed/vendors/medical/branches/show", {
    params: { api_key: apiKey, id },
  });

export const addAtmedVenderHospitalBranch = ({ id, payload }) =>
  protectedServiceInstance
    .post(`atmed/vendors/medical/branches/create`, payload, {
      params: { api_key: apiKey, id },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateAtmedVenderHospitalBranch = ({ payload, id }) =>
  protectedServiceInstance
    .post(`atmed/vendors/medical/branches/update?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllAtmedVendorPharmacy = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `atmed/vendors/pharmacy/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedVendorPharmacyInfo = (id) =>
  protectedServiceInstance.get("atmed/vendors/pharmacy/show", {
    params: { api_key: apiKey, id },
  });

export const addAtmedVenderPharmacy = (payload) =>
  protectedServiceInstance
    .post(`atmed/vendors/pharmacy/create`, payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateAtmedVendorPharmacy = ({ payload, id }) =>
  protectedServiceInstance
    .post("atmed/vendors/pharmacy/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const addAtmedVendorPharmacyOwner = ({ id, payload }) =>
  protectedServiceInstance
    .post("atmed/vendors/pharmacy/user/create", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const updateAtmedVendorPharmacyOwner = ({ payload, id, userId }) =>
  protectedServiceInstance
    .post("atmed/vendors/pharmacy/user/update", payload, {
      // params: { api_key: apiKey, id, user_id: userId },
      params:
        payload.user.password === undefined
          ? { id, user_id: userId }
          : { api_key: apiKey, id, user_id: userId },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllAtmedVendorPharmacyBranches = (queryString) =>
  protectedServiceInstance.get(
    `atmed/vendors/pharmacy/branches/all?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedVendorPharmacyBranchInfo = (id) =>
  protectedServiceInstance.get("atmed/vendors/pharmacy/branches/show", {
    params: { api_key: apiKey, id },
  });

export const addAtmedVenderPharmacyBranch = ({ id, payload }) =>
  protectedServiceInstance
    .post(`atmed/vendors/pharmacy/branches/create`, payload, {
      params: { api_key: apiKey, id },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateAtmedVenderPharmacyBranch = ({ payload, id }) =>
  protectedServiceInstance
    .post(`atmed/vendors/pharmacy/branches/update?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });

//Registration - Medic
export const getAllAtmedRegMedics = (queryString) =>
  protectedServiceInstance.get(`atmed/registration/medics/all?${queryString}`, {
    params: { api_key: apiKey },
  });

export const getAtmedRegMedic = (queryString) =>
  protectedServiceInstance.get(
    `atmed/registration/medic/detail?id=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const updateAtmedRegMedic = ({ payload, id }) =>
  protectedServiceInstance
    .post(`atmed/registration/medic/update?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });

//Registration - Vendor
export const getAllAtmedRegVendors = (queryString) =>
  protectedServiceInstance.get(
    `atmed/registration/vendors/all?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedRegVendor = (queryString) =>
  protectedServiceInstance.get(
    `atmed/registration/vendor/detail?id=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const updateAtmedRegVendor = ({ payload, id }) =>
  protectedServiceInstance
    .post(`atmed/registration/vendor/update?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });

//Log Management
export const getEquipmentLog = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `logs/equipment/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getHomeHealthcareLog = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `logs/home/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

//Vendor Equipment - Help and Support
export const getAllVendorEquipmentHelpSupport = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `equipment/help/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const updateVendorEquipmentHelpSupport = ({ payload, id }) =>
  protectedServiceInstance
    .post("equipment/help/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

//Home Healthcare - Help and Support
export const getAllHomeHealthcareHelpSupport = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `home/help/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllHomeHealthcareHelpOption = () =>
  protectedServiceInstance.get(`home/help/options`, {
    params: { api_key: apiKey },
  });

export const updateHomeHealthcareHelpSupport = ({ payload, id }) =>
  protectedServiceInstance
    .post("home/help/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

// export const getEquipmentLogInfo = (queryString) => protectedServiceInstance.get(`equipment/feedback/order/detail?order_id=${queryString}`, {
//   params: { api_key: apiKey },
// });

//Atmed - All Bookings
export const getAllAtmedBookingClinic = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `atmed/medical/clinic/bookings/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedBookingClinic = (queryString) =>
  protectedServiceInstance.get(
    `atmed/medical/clinic/booking/detail?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllAtmedBookingSurgery = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `atmed/medical/surgery/bookings/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedBookingSurgery = (queryString) =>
  protectedServiceInstance.get(
    `atmed/medical/surgery/booking/detail?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllAtmedBookingAbroad = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `atmed/medical/abroad/bookings/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedBookingAbroad = (queryString) =>
  protectedServiceInstance.get(
    `atmed/medical/abroad/booking/detail?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllAtmedBookingEmergency = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/medical/emergency/bookings/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedBookingEmergency = (queryString) =>
  protectedServiceInstance.get(
    `atmed/medical/emergency/booking/detail?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllAtmedBookingOnline = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `atmed/medical/online/bookings/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedBookingOnline = (queryString) =>
  protectedServiceInstance.get(
    `atmed/medical/online/booking/detail?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllAtmedBookingLab = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `atmed/lab/bookings/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedBookingLab = (queryString) =>
  protectedServiceInstance.get(`atmed/lab/booking/detail?${queryString}`, {
    params: { api_key: apiKey },
  });

export const getAllAtmedBookingHomeHealthcare = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/home/bookings/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllAtmedBookingHomeHealthcareServices = () =>
  protectedServiceInstance.get(`atmed/home/services/all`, {
    params: { api_key: apiKey },
  });

export const getAtmedBookingHomeHealthcare = (queryString) =>
  protectedServiceInstance.get(`atmed/home/booking/detail?${queryString}`, {
    params: { api_key: apiKey },
  });

export const getAllAtmedBookingMedicalEquipment = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/equipment/bookings/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedBookingMedicalEquipment = (queryString) =>
  protectedServiceInstance.get(
    `atmed/equipment/booking/detail?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

//Atmed - Help and Support
export const getAllAtmedHelpSupportMedicalEquip = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/equipment/help/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const updateAtmedHelpSupport = ({ payload, id, mode }) =>
  protectedServiceInstance
    .post("atmed/equipment/help/update", payload, {
      params: { api_key: apiKey, id, mode },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllAtmedHelpSupportHomeHealthcare = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/home/help/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );
export const updateAtmedHelpSupportHomeHealthcare = ({ payload, id, mode }) =>
  protectedServiceInstance
    .post("atmed/home/help/update", payload, {
      params: { api_key: apiKey, id, mode },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllAtmedHelpSupportLab = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `atmed/lab/help/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );
export const updateAtmedHelpSupportLab = ({ payload, id, mode }) =>
  protectedServiceInstance
    .post("atmed/lab/help/update", payload, {
      params: { api_key: apiKey, id, mode },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllAtmedHelpSupportEmergency = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/medical/emergency/help/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );
export const updateAtmedHelpSupportEmergency = ({ payload, id, mode }) =>
  protectedServiceInstance
    .post("atmed/medical/emergency/help/update", payload, {
      params: { api_key: apiKey, id, mode },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllAtmedHelpSupportClinicConsultation = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/medical/clinic/help/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );
export const updateAtmedHelpSupportClinicConsultation = ({
  payload,
  id,
  mode,
}) =>
  protectedServiceInstance
    .post("atmed/medical/clinic/help/update", payload, {
      params: { api_key: apiKey, id, mode },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllAtmedHelpSupportSurgeryConsultation = (queryString) =>
  protectedServiceInstance.get(
    `atmed/medical/surgery/help/all?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );
export const updateAtmedHelpSupportSurgeryConsultation = ({
  payload,
  id,
  mode,
}) =>
  protectedServiceInstance
    .post("atmed/medical/surgery/help/update", payload, {
      params: { api_key: apiKey, id, mode },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllAtmedHelpSupportOnlineConsultation = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/medical/online/help/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );
export const updateAtmedHelpSupportOnlineConsultation = ({
  payload,
  id,
  mode,
}) =>
  protectedServiceInstance
    .post("atmed/medical/online/help/update", payload, {
      params: { api_key: apiKey, id, mode },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllAtmedHelpSupportAbroadConsultation = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/medical/abroad/help/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );
export const updateAtmedHelpSupportAbroadConsultation = ({
  payload,
  id,
  mode,
}) =>
  protectedServiceInstance
    .post("atmed/medical/abroad/help/update", payload, {
      params: { api_key: apiKey, id, mode },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

//Atmed - Help and Support
export const getAtmedLog = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `logs/atmed/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAtmedNotification = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `notifications/atmed/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );
export const saveMedicalData = (payload) =>
  protectedServiceInstance
    .post("atmed/equipment/booking/create", payload, {
      // params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
// hospital
export const getconsultcategory = (queryString) =>
  protectedServiceInstance.get(`atmed/medical/medic/all?${queryString}`, {
    params: { api_key: apiKey },
  });
export const getsurgerycategory = (queryString) =>
  protectedServiceInstance.get(`atmed/medical/surgery/all?${queryString}`, {
    params: { api_key: apiKey },
  });
export const getabroadcategory = (queryString) =>
  protectedServiceInstance.get(`atmed/medical/abroad/all?${queryString}`, {
    params: { api_key: apiKey },
  });
export const getdoctorlist = (queryString) =>
  protectedServiceInstance.get(
    `atmed/consultation/filter-search?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );
export const getdoctorvalidation = (queryString) =>
  protectedServiceInstance.get(
    `atmed/consultation/appointment-booking/validate?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );
export const gettimeslot = (queryString) =>
  protectedServiceInstance.get(
    `atmed/consultation/appointment-booking?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );
export const gettimevalidate = (queryString) =>
  protectedServiceInstance.get(
    `medical/surgeries/appointment-booking?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );
export const createbooking = (payload) =>
  protectedServiceInstance
    .post(`atmed/consultation/booking/create`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const searchcategory = (queryString) =>
  protectedServiceInstance.get(
    `medical/surgeries/filter-search-category?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );
export const searchsurgery = (queryString) =>
  protectedServiceInstance.get(
    `medical/surgeries/filter-search-surgery?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const doctordetails = (queryString) =>
  protectedServiceInstance.get(
    `medical/surgeries/surgery-details?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );
export const createbookingsurgery = (payload) =>
  protectedServiceInstance
    .post(`medical/surgeries/booking/create`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      console.log("created", res.data.id);
      message.success(res.data.message);
      return res?.data; // Return the response data
    })
    .catch((error) => {
      message.error("Booking creation failed");
      throw error; // Throw the error so it can be handled by the caller
    });

// export const createbookingtourism = (payload) =>
//   protectedServiceInstance
//     .post(`medical/abroad/booking/create`, payload, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     })
//     .then((res) => {
//       console.log("created",res.data.id);
//       message.success(res.data.message);
//     });
export const createbookingtourism = (payload) =>
  protectedServiceInstance
    .post(`medical/abroad/booking/create`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      console.log("created", res.data.id);
      message.success(res.data.message);
      return res?.data; // Return the response data
    })
    .catch((error) => {
      message.error("Booking creation failed");
      throw error; // Throw the error so it can be handled by the caller
    });

export const createbookingEmegrency = (payload) =>
  protectedServiceInstance
    .post(`atmed/emergency/booking/create`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
// emergency
export const searchtourismcategory = (queryString) =>
  protectedServiceInstance.get(
    `medical/abroad/filter-search-category?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );
export const searchtourismsurgery = (queryString) =>
  protectedServiceInstance.get(
    `medical/abroad/filter-search-surgery?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const doctorTourismdetails = (queryString) =>
  protectedServiceInstance.get(
    `medical/abroad/procedure-hospital-detail?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );
export const validationTourismdetails = (queryString) =>
  protectedServiceInstance.get(
    `medical/abroad/appointment-booking?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );
