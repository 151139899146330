import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import OtpInput from "react-otp-input";
import "./AboutUs.css";
import {
  Layout,
  Input,
  Button,
  message,
  Typography,
  Row,
  Col,
  Image,
} from "antd";

const { Content, Footer } = Layout;
const { Text, Title } = Typography;


function Index() {
  return (
    <div className="loginScreen cmsScreen">
      <Layout style={{ minHeight: "100vh" }} className="ant-layout">
        <section class="section-padding" id="terms_page">
          <div class="container">
            <h2>About Us</h2>

            <div class="privacy">
              <p>
                Atmed Healthcare Private Limited (“us”, “we”, or “Atmed”, which
                also includes its affiliates) is the author and publisher of the
                internet resource www.atmed.com (“Website”) on the world wide
                web as well as the software and applications provided by Atmed,
                including but not limited to the mobile application ‘Atmed’, and
                the software and applications (together with the Website,
                referred to as the “Services”).
              </p>
              <p>
                This privacy policy ("<b>Privacy Policy</b>") explains how we
                collect, use, share, disclose and protect Personal information
                about the Users of the Services, including the Practitioners (as
                defined in the Terms of Use, which may be accessed via the
                following weblink https://atmed.com/privacy (the “Terms of
                Use”)), the End-Users (as defined in the Terms of Use), and the
                visitors of Website (jointly and severally referred to as “you”
                or “Users” in this Privacy Policy). We created this Privacy
                Policy to demonstrate our commitment to the protection of your
                privacy and your personal information. Your use of and access to
                the Services is subject to this Privacy Policy and our Terms of
                Use. Any capitalized term used but not defined in this Privacy
                Policy shall have the meaning attributed to it in our Terms of
                Use.
              </p>
              <p>
                BY USING THE SERVICES OR BY OTHERWISE GIVING US YOUR
                INFORMATION, YOU WILL BE DEEMED TO HAVE READ, UNDERSTOOD AND
                AGREED TO THE PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY
                POLICY AND AGREE TO BE BOUND BY THE PRIVACY POLICY. YOU HEREBY
                CONSENT TO OUR COLLECTION, USE AND SHARING, DISCLOSURE OF YOUR
                INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY. WE RESERVE THE
                RIGHT TO CHANGE, MODIFY, ADD OR DELETE PORTIONS OF THE TERMS OF
                THIS PRIVACY POLICY, AT OUR SOLE DISCRETION, AT ANY TIME. IF YOU
                DO NOT AGREE WITH THIS PRIVACY POLICY AT ANY TIME, DO NOT USE
                ANY OF THE SERVICES OR GIVE US ANY OF YOUR INFORMATION. IF YOU
                USE THE SERVICES ON BEHALF OF SOMEONE ELSE (SUCH AS YOUR CHILD)
                OR AN ENTITY (SUCH AS YOUR EMPLOYER), YOU REPRESENT THAT YOU ARE
                AUTHORISED BY SUCH INDIVIDUAL OR ENTITY TO (I) ACCEPT THIS
                PRIVACY POLICY ON SUCH INDIVIDUAL’S OR ENTITY’S BEHALF, AND (II)
                CONSENT ON BEHALF OF SUCH INDIVIDUAL OR ENTITY TO OUR
                COLLECTION, USE AND DISCLOSURE OF SUCH INDIVIDUAL’S OR ENTITY’S
                INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY.
              </p>
              <ul class="custom-list">
                <li>
                  <h5>
                    <span class="item-no">1.</span>WHY THIS PRIVACY POLICY?
                  </h5>
                  <p>
                    This Privacy Policy is published in compliance with, inter
                    alia:
                  </p>
                  <ul class="lower-rom-list">
                    <li>
                      Section 43A of the Information Technology Act, 2000;
                    </li>
                    <li>
                      Regulation 4 of the Information Technology (Reasonable
                      Security Practices and Procedures and Sensitive Personal
                      Information) Rules, 2011 (the “SPI Rules”);
                    </li>
                    <li>
                      Regulation 3(1) of the Information Technology
                      (Intermediaries Guidelines) Rules, 2011.
                    </li>
                  </ul>
                  <p>This Privacy Policy states the following:</p>
                  <ul class="lower-rom-list">
                    <li>
                      The type of information collected from the Users,
                      including Personal Information (as defined in paragraph 2
                      below) and Sensitive Personal Data or Information (as
                      defined in paragraph 2 below) relating to an individual;
                    </li>
                    <li>
                      The purpose, means and modes of collection, usage,
                      processing, retention and destruction of such information;
                      and
                    </li>
                    <li>
                      How and to whom Atmed will disclose such information.
                    </li>
                  </ul>
                </li>
                <li>
                  <h5>
                    <span class="item-no">2.</span>COLLECTION OF PERSONAL
                    INFORMATION
                  </h5>
                  <p>
                    Generally some of the Services require us to know who you
                    are so that we can best meet your needs. When you access the
                    Services, or through any interaction with us via emails,
                    telephone calls or other correspondence, we may ask you to
                    voluntarily provide us with certain information that
                    personally identifies you or could be used to personally
                    identify you. You hereby consent to the collection of such
                    information by Atmed. Without prejudice to the generality of
                    the above, information collected by us from you may include
                    (but is not limited to) the following:
                  </p>
                  <ul class="lower-rom-list">
                    <li>
                      contact data (such as your email address and phone
                      number);
                    </li>
                    <li>
                      demographic data (such as your gender, your date of birth
                      and your pin code);
                    </li>
                    <li>
                      data regarding your usage of the services and history of
                      the appointments made by or with you through the use of
                      Services;
                    </li>
                    <li>
                      insurance data (such as your insurance carrier and
                      insurance plan);
                    </li>
                    <li>
                      other information that you voluntarily choose to provide
                      to us (such as information shared by you with us through
                      emails or letters.
                    </li>
                  </ul>
                  <p>
                    The information collected from you by Atmed may constitute
                    ‘personal information’ or ‘sensitive personal data or
                    information’ under the SPI Rules.
                  </p>
                  <p>
                    <b>“Personal Information”</b> is defined under the SPI Rules
                    to mean any information that relates to a natural person,
                    which, either directly or indirectly, in combination with
                    other information available or likely to be available to a
                    body corporate, is capable of identifying such person.
                  </p>
                  <p>
                    The SPI Rules further define “Sensitive Personal Data or
                    Information” of a person to mean personal information about
                    that person relating to:
                  </p>
                  <ul class="lower-rom-list">
                    <li>passwords;</li>
                    <li>
                      financial information such as bank accounts, credit and
                      debit card details or other payment instrument details;
                    </li>
                    <li>
                      physical, physiological and mental health condition;
                    </li>
                    <li>sexual orientation;</li>
                    <li>medical records and history;</li>
                    <li>biometric information;</li>
                    <li>
                      information received by body corporate under lawful
                      contract or otherwise;
                    </li>
                    <li>
                      visitor details as provided at the time of registration or
                      thereafter; and
                    </li>
                    <li>call data records.</li>
                  </ul>
                  <p>
                    Atmed will be free to use, collect and disclose information
                    that is freely available in the public domain without your
                    consent.
                  </p>
                </li>
                <li>
                  <h5>
                    <span class="item-no">3.</span>PRIVACY STATEMENTS
                  </h5>
                  <ul class="custom-list">
                    <li>
                      <span class="item-no">3.1</span>ALL USERS NOTE:
                      <p>This section applies to all users.</p>
                      <ul class="custom-list">
                        <li>
                          <span class="item-no">3.1.1</span>Accordingly, a
                          condition of each User’s use of and access to the
                          Services is their acceptance of the Terms of Use,
                          which also involves acceptance of the terms of this
                          Privacy Policy. Any User that does not agree with any
                          provisions of the same has the option to discontinue
                          the Services provided by Atmed immediately.
                        </li>
                        <li>
                          <span class="item-no">3.1.2</span>An indicative list
                          of information that Atmed may require you to provide
                          to enable your use of the Services is provided in the
                          Schedule annexed to this Privacy Policy.
                        </li>
                        <li>
                          <span class="item-no">3.1.3</span>All the information
                          provided to Atmed by a User, including Personal
                          Information or any Sensitive Personal Data or
                          Information, is voluntary. You understand that Atmed
                          may use certain information of yours, which has been
                          designated as Personal Information or ‘Sensitive
                          Personal Data or Information’ under the SPI Rules, (a)
                          for the purpose of providing you the Services, (b) for
                          commercial purposes and in an aggregated or
                          non-personally identifiable form for research,
                          statistical analysis and business intelligence
                          purposes, (c) for sale or transfer of such research,
                          statistical or intelligence data in an aggregated or
                          non-personally identifiable form to third parties and
                          affiliates (d) for communication purpose so as to
                          provide You a better way of booking appointments and
                          for obtaining feedback in relation to the
                          Practitioners and their practice, (e) debugging
                          customer support related issues.. (f) for the purpose
                          of contacting you to complete any transaction if you
                          do not complete a transaction after having provided us
                          with your contact information in the course of
                          completing such steps that are designed for completion
                          of the transaction. Atmed also reserves the right to
                          use information provided by or about the End-User for
                          the following purposes:
                          <ul class="lower-rom-list">
                            <li>Publishing such information on the Website.</li>
                            <li>
                              Contacting End-Users for offering new products or
                              services.
                            </li>
                            <li>
                              Contacting End-Users for taking product and
                              Service feedback.
                            </li>
                            <li>
                              Analyzing software usage patterns for improving
                              product design and utility.
                            </li>
                            <li>
                              Analyzing anonymized practice information for
                              commercial use.
                            </li>
                          </ul>
                          <p>
                            If you have voluntarily provided your Personal
                            Information to Atmed for any of the purposes stated
                            above, you hereby consent to such collection and use
                            of such information by Atmed. However, Atmed shall
                            not contact You on Your telephone number(s) for any
                            purpose including those mentioned in this
                            sub-section 4.1(iii), if such telephone number is
                            registered with the Do Not Call registry (“DNC
                            Registry”) under the PDPA without your express,
                            clear and un-ambiguous written consent.
                          </p>
                        </li>
                        <li>
                          <span class="item-no">3.1.4</span>Collection, use and
                          disclosure of information which has been designated as
                          Personal Information or Sensitive Personal Data or
                          Information’ under the SPI Rules requires your express
                          consent. By affirming your assent to this Privacy
                          Policy, you provide your consent to such use,
                          collection and disclosure as required under applicable
                          law.
                        </li>
                        <li>
                          <span class="item-no">3.1.5</span>Atmed does not
                          control or endorse the content, messages or
                          information found in any Services and, therefore,
                          Atmed specifically disclaims any liability with regard
                          to the Services and any actions resulting from your
                          participation in any Services, and you agree that you
                          waive any claims against Atmed relating to same, and
                          to the extent such waiver may be ineffective, you
                          agree to release any claims against Atmed relating to
                          the same.
                        </li>
                        <li>
                          <span class="item-no">3.1.6</span>You are responsible
                          for maintaining the accuracy of the information you
                          submit to us, such as your contact information
                          provided as part of account registration. If your
                          personal information changes, you may correct, delete
                          inaccuracies, or amend information by making the
                          change on our member information page or by contacting
                          us through&nbsp;privacy@atmed.com. We will make good
                          faith efforts to make requested changes in our then
                          active databases as soon as reasonably practicable. If
                          you provide any information that is untrue,
                          inaccurate, out of date or incomplete (or becomes
                          untrue, inaccurate, out of date or incomplete), or
                          Atmed has reasonable grounds to suspect that the
                          information provided by you is untrue, inaccurate, out
                          of date or incomplete, Atmed may, at its sole
                          discretion, discontinue the provision of the Services
                          to you. There may be circumstances where Atmed will
                          not correct, delete or update your Personal Data,
                          including (a) where the Personal Data is opinion data
                          that is kept solely for evaluative purpose; and (b)
                          the Personal Data is in documents related to a
                          prosecution if all proceedings relating to the
                          prosecution have not been completed.
                        </li>
                        <li>
                          <span class="item-no">3.1.7</span>If you wish to
                          cancel your account or request that we no longer use
                          your information to provide you Services, contact us
                          through&nbsp; support@atmed.com . We will retain your
                          information for as long as your account with the
                          Services is active and as needed to provide you the
                          Services. We shall not retain such information for
                          longer than is required for the purposes for which the
                          information may lawfully be used or is otherwise
                          required under any other law for the time being in
                          force. After a period of time, your data may be
                          anonymized and aggregated, and then may be held by us
                          as long as necessary for us to provide our Services
                          effectively, but our use of the anonymized data will
                          be solely for analytic purposes. Please note that your
                          withdrawal of consent, or cancellation of account may
                          result in Atmed being unable to provide you with its
                          Services or to terminate any existing relationship
                          Atmed may have with you.
                        </li>
                        <li>
                          <span class="item-no">3.1.8</span>If you wish to
                          opt-out of receiving non-essential communications such
                          as promotional and marketing-related information
                          regarding the Services, please send us an email at
                          support@atmed.com .
                        </li>
                        <li>
                          <span class="item-no">3.1.9</span>Atmed may require
                          the User to pay with a credit card, wire transfer,
                          debit card or cheque for Services for which
                          subscription amount(s) is/are payable. Atmed will
                          collect such User’s credit card number and/or other
                          financial institution information such as bank account
                          numbers and will use that information for the billing
                          and payment processes, including but not limited to
                          the use and disclosure of such credit card number and
                          information to third parties as necessary to complete
                          such billing operation. Verification of credit
                          information, however, is accomplished solely by the
                          User through the authentication process. User’s
                          credit-card/debit card details are transacted upon
                          secure sites of approved payment gateways which are
                          digitally under encryption, thereby providing the
                          highest possible degree of care as per current
                          technology. However, Atmed provides you an option not
                          to save your payment details. User is advised,
                          however, that internet technology is not full proof
                          safe and User should exercise discretion on using the
                          same.
                        </li>
                        <li>
                          <span class="item-no">3.1.10</span>Due to the
                          communications standards on the Internet, when a User
                          or the End-User or anyone who visits the Website,
                          Atmed automatically receives the URL of the site from
                          which anyone visits. Atmed also receives the Internet
                          Protocol (IP) address of each User’s computer (or the
                          proxy server a User used to access the World Wide
                          Web), User’s computer operating system and type of web
                          browser the User is using, email patterns, as well as
                          the name of User’s ISP. This information is used to
                          analyze overall trends to help Atmed improve its
                          Service. The linkage between User’s IP address and
                          User’s personally identifiable information is not
                          shared with or disclosed to third parties.
                          Notwithstanding the above, Atmed may share and/or
                          disclose some of the aggregate findings (not the
                          specific data) in anonymized form (i.e.,
                          non-personally identifiable) with advertisers,
                          sponsors, investors, strategic partners, and others in
                          order to help grow its business.
                        </li>
                        <li>
                          <span class="item-no">3.1.11</span>The Website uses
                          temporary cookies to store certain (that is not
                          sensitive personal data or information) that is used
                          by Atmed and its service providers for the technical
                          administration of the Website, research and
                          development, and for User administration. In the
                          course of serving advertisements or optimizing
                          services to its Users, Atmed may allow authorized
                          third parties to place or recognize a unique cookie on
                          the User’s browser. The cookies however, do not store
                          any Personal Information of the User. You may adjust
                          your internet browser to disable cookies. If cookies
                          are disabled you may still use the Website, but the
                          Website may be limited in the use of some of the
                          features.
                        </li>
                        <li>
                          <span class="item-no">3.1.12</span>A User may have
                          limited access to the Website without creating an
                          account on the Website. Unregistered Users can make
                          appointments with the doctors by providing their name
                          and phone number. In order to have access to all the
                          features and benefits on our Website, a User must
                          first create an account on our Website. To create an
                          account, a User is required to provide the following
                          information, which such User recognizes and expressly
                          acknowledges is Personal Information allowing others,
                          including Atmed, to identify the User: name, User ID,
                          email address, country, ZIP/postal code, age, phone
                          number, password chosen by the User and valid
                          financial account information. Other information
                          requested on the registration page, including the
                          ability to receive promotional offers from Atmed, is
                          optional. Atmed may, in future, include other optional
                          requests for information from the User to help Atmed
                          to customize the Website to deliver personalized
                          information to the User.
                        </li>
                        <li>
                          <span class="item-no">3.1.13</span>This Privacy Policy
                          applies to Services that are owned and operated by
                          Atmed. Atmed does not exercise control over the sites
                          displayed as search results or links from within its
                          Services. These other sites may place their own
                          cookies or other files on the Users’ computer, collect
                          data or solicit personal information from the Users,
                          for which Atmed is not responsible or liable.
                          Accordingly, Atmed does not make any representations
                          concerning the privacy practices or policies of such
                          third parties or terms of use of such websites, nor
                          does Atmed guarantee the accuracy, integrity, or
                          quality of the information, data, text, software,
                          sound, photographs, graphics, videos, messages or
                          other materials available on such websites. The
                          inclusion or exclusion does not imply any endorsement
                          by Atmed of the website, the website's provider, or
                          the information on the website. If you decide to visit
                          a third party website linked to the Website, you do
                          this entirely at your own risk. Atmed encourages the
                          User to read the privacy policies of that website.
                        </li>
                        <li>
                          <span class="item-no">3.1.14</span>The Website may
                          enable User to communicate with other Users or to post
                          information to be accessed by others, whereupon other
                          Users may collect such data. Such Users, including any
                          moderators or administrators, are not authorized Atmed
                          representatives or agents, and their opinions or
                          statements do not necessarily reflect those of Atmed,
                          and they are not authorized to bind Atmed to any
                          contract. Atmed hereby expressly disclaims any
                          liability for any reliance or misuse of such
                          information that is made available by Users or
                          visitors in such a manner.
                        </li>
                        <li>
                          <span class="item-no">3.1.15</span>Atmed does not
                          collect information about the visitors of the Website
                          from other sources, such as public records or bodies,
                          or private organisations, save and except for the
                          purposes of registration of the Users (the collection,
                          use, storage and disclosure of which each End User
                          must agree to under the Terms of Use in order for
                          Atmed to effectively render the Services).
                        </li>
                        <li>
                          <span class="item-no">3.1.16</span>Atmed maintains a
                          strict "No-Spam" policy, which means that Atmed does
                          not intend to sell, rent or otherwise give your e-mail
                          address to a third party without your consent.
                        </li>
                        <li>
                          <span class="item-no">3.1.17</span>Atmed has
                          implemented best international market practices and
                          security policies, rules and technical measures to
                          protect the personal data that it has under its
                          control from unauthorised access, improper use or
                          disclosure, unauthorised modification and unlawful
                          destruction or accidental loss. However, for any data
                          loss or theft due to unauthorized access to the User’s
                          electronic devices through which the User avails the
                          Services, Atmed shall not be held liable for any loss
                          whatsoever incurred by the User.
                        </li>
                        <li>
                          <span class="item-no">3.1.18</span>Atmed implements
                          reasonable security practices and procedures and has a
                          comprehensive documented information security
                          programme and information security policies that
                          contain managerial, technical, operational and
                          physical security control measures that are
                          commensurate with respect to the information being
                          collected and the nature of Atmed’s business.
                        </li>
                        <li>
                          <span class="item-no">3.1.19</span>Atmed takes your
                          right to privacy very seriously and other than as
                          specifically stated in this Privacy Policy, will only
                          disclose your Personal Information in the event it is
                          required to do so by law, rule, regulation, law
                          enforcement agency, governmental official, legal
                          authority or similar requirements or when Atmed, in
                          its sole discretion, deems it necessary in order to
                          protect its rights or the rights of others, to prevent
                          harm to persons or property, to fight fraud and credit
                          risk, or to enforce or apply the Terms of Use.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <span class="item-no">3.2</span>PRACTITIONERS NOTE:
                      <p>This section applies to all Practitioners.</p>
                      <ul class="custom-list">
                        <li>
                          <span class="item-no">3.2.1</span>As part of the
                          registration as well as the application creation and
                          submission process that is available to Practitioners
                          on Atmed, certain information, including Personal
                          Information or Sensitive Personal Data or Information
                          is collected from the Practitioners.
                        </li>
                        <li>
                          <span class="item-no">3.2.2</span>All the statements
                          in this Privacy Policy apply to all Practitioners, and
                          all Practitioners are therefore required to read and
                          understand the privacy statements set out herein prior
                          to submitting any Personal Information or Sensitive
                          Personal Data or Information to Atmed, failing which
                          they are required to leave the Services, including the
                          Website immediately.
                        </li>
                        <li>
                          <span class="item-no">3.2.3</span>Practitioners’
                          personally identifiable information, which they choose
                          to provide to Atmed, is used to help the Practitioners
                          describe and identify themselves. This information is
                          exclusively owned by Atmed You will be the owner of
                          your information and you consent to Atmed collecting,
                          using, processing and/or disclosing this information
                          for the purposes hereinafter stated . Atmed may use
                          such information for commercial purposes and in an
                          aggregated or non-personally identifiable form for
                          research, statistical analysis and business
                          intelligence purposes, and may sell or otherwise
                          transfer such research, statistical or intelligence
                          data in an aggregated or non-personally identifiable
                          form to third parties and affiliates. Atmed also
                          reserves the right to use information provided by or
                          about the Practitioner for the following purposes:
                          <ul class="lower-rom-list">
                            <li>Publishing such information on the Website.</li>
                            <li>
                              Contacting Practitioners for offering new products
                              or services subject to the telephone number
                              registered with the DNC Registry.
                            </li>
                            <li>
                              Contacting Practitioners for taking product
                              feedback.
                            </li>
                            <li>
                              Analyzing software usage patterns for improving
                              product design and utility.
                            </li>
                            <li>
                              Analyzing anonymized practice information
                              including financial, and inventory information for
                              commercial use.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <span class="item-no">3.2.4</span>Atmed automatically
                          enables the listing of Practitioners’ information on
                          its Website for every ‘Doctor’ or ‘Clinic’ added to a
                          Practice using its software. The Practitioner
                          information listed on Website is displayed when
                          End-Users search for doctors on Website, and the
                          Practitioner information listed on Website is used by
                          End-Users to request for doctor appointments. Any
                          personally identifiable information of the
                          Practitioners listed on the Website is not generated
                          by Atmed and is provided to Atmed by Practitioners who
                          wish to enlist themselves on the Website, or is
                          collected by Atmed from the public domain. Atmed
                          displays such information on its Website on an as-is
                          basis making no representation or warranty on the
                          accuracy or completeness of the information. As such,
                          we strongly encourage Practitioners to check the
                          accuracy and completeness of their information from
                          time to time, and inform us immediately of any
                          discrepancies, changes or updates to such information.
                          Atmed will, however, take reasonable steps to ensure
                          the accuracy and completeness of this information.
                        </li>
                        <li>
                          <span class="item-no">3.2.5</span>Atmed may also
                          display information for Practitioners who have not
                          signed up or registered for the Services, provided
                          that the Practitioners have consented to Atmed
                          collecting, processing and/or disclosing their
                          information on the Website. Such Practitioners are
                          verified by Atmed or its associates, and Atmed makes
                          every effort to capture accurate information for such
                          Practitioners. However, Atmed does not undertake any
                          liability for any incorrect or incomplete information
                          appearing on the Website for such Practitioners.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <span class="item-no">3.3</span>END-USERS NOTE:
                      <p>This section applies to all End-Users.</p>
                      <ul class="custom-list">
                        <li>
                          <span class="item-no">3.3.1</span>As part of the
                          registration/application creation and submission
                          process that is available to End-Users on this
                          Website, certain information, including Personal
                          Information or Sensitive Personal Data or Information
                          is collected from the End-Users.
                        </li>
                        <li>
                          <span class="item-no">3.3.2</span>All the statements
                          in this Privacy Policy apply to all End-Users, and all
                          End-Users are therefore required to read and
                          understand the privacy statements set out herein prior
                          to submitting any Personal Information or Sensitive
                          Personal Data or Information to Atmed, failing which
                          they are required to leave the Atmed immediately.
                        </li>
                        <li>
                          <span class="item-no">3.3.3</span>If you have
                          inadvertently submitted any such information to Atmed
                          prior to reading theprivacy statements set out herein,
                          and you do not agree with the manner in which such
                          information is collected, processed, stored, used or
                          disclosed, then you may access, modify and delete such
                          information by using options provided on the Website.
                          In addition, you can, by sending an email to
                          privacy@atmed.com, inquire whether Atmed is in
                          possession of your personal data, and you may also
                          require Atmed to delete and destroy all such
                          information.
                        </li>
                        <li>
                          <span class="item-no">3.3.4</span>End-Users’
                          personally identifiable information, which they choose
                          to provide on the Website is used to help the
                          End-Users describe/identify themselves. Other
                          information that does not personally identify the
                          End-Users as an individual, is collected by Atmed from
                          End-Users (such as, patterns of utilization described
                          above) and is exclusively owned by Atmed. Atmed may
                          also use such information in an aggregated or
                          non-personally identifiable form for research,
                          statistical analysis and business intelligence
                          purposes, and may sell or otherwise transfer such
                          research, statistical or intelligence data in an
                          aggregated or non-personally identifiable form to
                          third parties and affiliates. In particular, Atmed
                          reserves with it the right to use anonymized End-User
                          demographics information and anonymized End-User
                          health information for the following purposes:
                          <ul class="lower-rom-list">
                            <li>
                              Analyzing software usage patterns for improving
                              product design and utility.
                            </li>
                            <li>
                              Analyzing such information for research and
                              development of new technologies.
                            </li>
                            <li>
                              Using analysis of such information in other
                              commercial product offerings of Atmed.
                            </li>
                            <li>
                              Sharing analysis of such information with third
                              parties for commercial use.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <span class="item-no">3.3.5</span>Atmed will
                          communicate with the End-Users through email, phone
                          and notices posted on the Website or through other
                          means available through the service, including text
                          and other forms of messaging. The End-Users can change
                          their e-mail and contact preferences at any time by
                          logging into their "Account" at www.atmed.com and
                          changing the account settings.
                        </li>
                        <li>
                          <span class="item-no">3.3.6</span>At times, Atmed
                          conducts a User survey to collect information about
                          End-Users' preferences. These surveys are optional and
                          if End-Users choose to respond, their responses will
                          be kept anonymous. Similarly, Atmed may offer contests
                          to qualifying End-Users in which we ask for contact
                          and demographic information such as name, email
                          address and mailing address. The demographic
                          information that Atmed collects in the registration
                          process and through surveys is used to help Atmed
                          improve its Services to meet the needs and preferences
                          of End-Users.
                        </li>
                        <li>
                          <span class="item-no">3.3.7</span>Atmed may keep
                          records of electronic communications and telephone
                          calls received andmade for making appointments or
                          other purposes for the purpose of administration of
                          Services, customer support, research and development
                          and for better listing of Practitioners.
                        </li>
                        <li>
                          <span class="item-no">3.3.8</span>All Atmed employees
                          and data processors, who have access to, and are
                          associated with the processing of sensitive personal
                          data or information, are obliged to respect the
                          confidentiality of every End-Users’ Personal
                          Information or Sensitive Personal Data and
                          Information. Atmed has put in place procedures and
                          technologies as per good industry practices and in
                          accordance with the applicable laws, to maintain
                          security of all personal data from the point of
                          collection to the point of destruction. Any
                          third-party data processor to which Atmed transfers
                          Personal Data shall have to agree to comply with those
                          procedures and policies, or put in place adequate
                          measures on their own.
                        </li>
                        <li>
                          <span class="item-no">3.3.9</span>Atmed may also
                          disclose or transfer End-Users’ personal and other
                          information provided by a User, to a third party as
                          part of reorganization or a sale of the assets of a
                          Atmed corporation division or company. Any third party
                          to which Atmed transfers or sells its assets to will
                          have the right to continue to use the personal and
                          other information that End-Users provide to us, in
                          accordance with the Terms of Use
                        </li>
                        <li>
                          <span class="item-no">3.3.10</span>To the extent
                          necessary to provide End-Users with the Services,
                          Atmed may provide their Personal Information to third
                          party contractors who work on behalf of or with Atmed
                          to provide End-Users with such Services, to help Atmed
                          communicate with End-Users or to maintain the Website.
                          Generally these contractors do not have any
                          independent right to share this information, however
                          certain contractors who provide services on the
                          Website, including the providers of online
                          communications services, may use and disclose the
                          personal information collected in connection with the
                          provision of these Services in accordance with their
                          own privacy policies. In such circumstances, you
                          consent to us disclosing your Personal Information to
                          contractors, solely for the intended purposes only.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <span class="item-no">3.4</span>CASUAL VISITORS NOTE:
                      <ul class="custom-list">
                        <li>
                          <span class="item-no">3.4.1</span>No sensitive
                          personal data or information is automatically
                          collected by Atmed from any casual visitors of this
                          website, who are merely perusing the Website.
                        </li>
                        <li>
                          <span class="item-no">3.4.2</span>Nevertheless,
                          certain provisions of this Privacy Policy are
                          applicable to even such casual visitors, and such
                          casual visitors are also required to read and
                          understand the privacy statements set out herein,
                          failing which they are required to leave this Website
                          immediately.
                        </li>
                        <li>
                          <span class="item-no">3.4.3</span>If you, as a casual
                          visitor, have inadvertently browsed any other page of
                          this Website prior to reading the privacy statements
                          set out herein, and you do not agree with the manner
                          in which such information is obtained, collected,
                          processed, stored, used, disclosed or retained, merely
                          quitting this browser application should ordinarily
                          clear all temporary cookies installed by Atmed. All
                          visitors, however, are encouraged to use the “clear
                          cookies” functionality of their browsers to ensure
                          such clearing / deletion, as Atmed cannot guarantee,
                          predict or provide for the behaviour of the equipment
                          of all the visitors of the Website.
                        </li>
                        <li>
                          <span class="item-no">3.4.4</span>You are not a casual
                          visitor if you have willingly submitted any personal
                          data or information to Atmed through any means,
                          including email, post or through the registration
                          process on the Website. All such visitors will be
                          deemed to be, and will be treated as, Users for the
                          purposes of this Privacy Policy, and in which case,
                          all the statements in this Privacy Policy apply to
                          such persons.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <h5>
                    <span class="item-no">4.</span>CONFIDENTIALITY AND SECURITY
                  </h5>
                  <ul class="custom-list">
                    <li>
                      <span class="item-no">4.1</span>Your Personal Information
                      is maintained by Atmed in electronic form on its
                      equipment, and on the equipment of its employees. Such
                      information may also be converted to physical form from
                      time to time. Atmed takes all necessary precautions to
                      protect your personal information both online and
                      off-line, and implements reasonable security practices and
                      measures including certain managerial, technical,
                      operational and physical security control measures that
                      are commensurate with respect to the information being
                      collected and the nature of Atmed’s business.
                    </li>
                    <li>
                      <span class="item-no">4.2</span>No administrator at Atmed
                      will have knowledge of your password. It is important for
                      you to protect against unauthorized access to your
                      password, your computer and your mobile phone. Be sure to
                      log off from the Website when finished. Atmed does not
                      undertake any liability for any unauthorised use of your
                      account and password. If you suspect any unauthorized use
                      of your account, you must immediately notify Atmed by
                      sending an email to support@atmed.com You shall be liable
                      to indemnify Atmed due to any loss suffered by it due to
                      such unauthorized use of your account and password.
                    </li>
                    <li>
                      <span class="item-no">4.3</span>Atmed makes all User
                      information accessible to its employees, agents or
                      partners and third parties only on a need-to-know basis,
                      and binds only its employees to strict confidentiality
                      obligations.
                    </li>
                    <li>
                      <span class="item-no">4.4</span>Part of the functionality
                      of Atmed is assisting the doctors to maintain and organise
                      such information. Atmed may, therefore, retain and submit
                      all such records to the appropriate authorities, or to
                      doctors who request access to such information.
                    </li>
                    <li>
                      <span class="item-no">4.5</span>Part of the functionality
                      of the Atmed is assisting the patients to access
                      information relating to them. Atmed may, therefore, retain
                      and submit all such records to the relevant patients, or
                      to their doctors.
                    </li>
                    <li>
                      <span class="item-no">4.6</span>Notwithstanding the above,
                      Atmed is not responsible for the confidentiality, security
                      or distribution of your Personal Information by our
                      partners and third parties outside the scope of our
                      agreement with such partners and third parties. Further,
                      Atmed shall not be responsible for any breach of security
                      or for any actions of any third parties or events that are
                      beyond the reasonable control of Atmed including but not
                      limited to, acts of government, computer hacking,
                      unauthorised access to computer data and storage device,
                      computer crashes, breach of security and encryption, poor
                      quality of Internet service or telephone service of the
                      User etc.
                    </li>
                  </ul>
                </li>
                <li>
                  <h5>
                    <span class="item-no">5.</span>CHANGE TO PRIVACY POLICY
                  </h5>
                  <p>
                    Atmed may update this Privacy Policy at any time, with or
                    without advance notice. In the event there are significant
                    changes in the way Atmed treats User’s personally
                    identifiable information, or in the Privacy Policy itself,
                    Atmed will display a notice on the Website or send Users an
                    email, as provided for above, so that you may review the
                    changed terms prior to continuing to use the Services. As
                    always, if you object to any of the changes to our terms,
                    and you no longer wish to use the Services, you may contact
                    support@atmed.com to deactivate your account. Unless stated
                    otherwise, Atmed’s current Privacy Policy applies to all
                    information that Atmed has about you and your account.
                  </p>
                  <p>
                    If a User uses the Services or accesses the Website after a
                    notice of changes has been sent to such User or published on
                    the Website, such User hereby provides his/her/its consent
                    to the changed terms.
                  </p>
                </li>
                <li>
                  <h5>
                    <span class="item-no">6.</span>CHILDREN'S AND MINOR'S
                    PRIVACY
                  </h5>
                  <p>
                    Atmed strongly encourages parents and guardians to supervise
                    the online activities of their minor children and consider
                    using parental control tools available from online services
                    and software manufacturers to help provide a child-friendly
                    online environment. These tools also can prevent minors from
                    disclosing their name, address, and other personally
                    identifiable information online without parental permission.
                    Although the Atmed Website and Services are not intended for
                    use by minors, Atmed respects the privacy of minors who may
                    inadvertently use the internet or the mobile application.
                  </p>
                </li>
                <li>
                  <h5>
                    <span class="item-no">7.</span>CONSENT TO THIS POLICY
                  </h5>
                  <p>
                    You acknowledge that this Privacy Policy is a part of the
                    Terms of Use of the Website and the other Services, and you
                    unconditionally agree that becoming a User of the Website
                    and its Services signifies your (i) assent to this Privacy
                    Policy, and (ii) consent to Atmed using, collecting,
                    processing and/or disclosing your Personal Information in
                    the manner and for the purposes set out in this Privacy
                    Policy. Your visit to the Website and use of the Services is
                    subject to this Privacy Policy and the Terms of Use.
                  </p>
                </li>
                <li>
                  <h5>
                    <span class="item-no">8.</span>ADDRESS FOR PRIVACY QUESTIONS
                  </h5>
                  <p>
                    Should you have questions about this Privacy Policy or
                    Atmed's information collection, use and disclosure
                    practices, you may contact, the Data Protection Officer
                    appointed by Atmed in accordance with the provisions of
                    PDPA. We will use reasonable efforts to respond promptly to
                    any requests, questions or concerns, which you may have
                    regarding our use of your personal information. If you have
                    any grievance with respect to our use of your information,
                    you may communicate such grievance to the Data Protection
                    Officer:
                    <br />
                    Name: Dr. Suresh Kumar
                    <br />
                    Atmed Healthcare Pvt Ltd
                    <br />
                    Thendral Apartments H45, F2,
                    <br />
                    Thiruvanmiyur,
                    <br />
                    Chennai - 600041
                    <br />
                    Phone: <a href="tel:98404 20007">98404 20007</a>
                    <br />
                    Email:{" "}
                    <a href="mailto:privacy@atmed.com">privacy@atmed.com</a>
                    <br />
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  );
}

export default Index;
