/* eslint-disable max-len */
/* eslint-disable camelcase */
import { protectedServiceInstance } from '../instance';
import {
  message
} from 'antd';

const apiKey = '7A3MXb5pTzE7zMuBRspN';

export const getAllHomeHealthcareServices = (queryString,pageSize,currentPage) => protectedServiceInstance.get(
  `/home/services/all?${queryString}&limit=${pageSize}&offset=${(currentPage-1)*pageSize}`,
  { params: { api_key: apiKey } },
  console.log('currentPage::',currentPage,'pageSize::',pageSize)
);

export const getAllHomeHealthcareServicesList = (queryString) => protectedServiceInstance.get(
  `/home/services/list?${queryString}`,
  { params: { api_key: apiKey } },
);
export const updateHomeHealthcareService = ({ payload, id }) => protectedServiceInstance.post(
  'home/service/update',
  payload,
  {
    params: { api_key: apiKey, id },
    headers: {
      'Content-Type': 'application/json',
    },
  },
).then((res) => {
  message.success(res.data.message);
  setTimeout(() => getAllHomeHealthcareServices(), 2000)
});
export const getSingleHomeHealthcareService = (queryString) => protectedServiceInstance.get(
  `home/service/show?${queryString}`,
  { params: { api_key: apiKey } },
);

export const getHomeHealthcareServiceAssets = (queryString) => protectedServiceInstance.get(
  `home/services/assets/all?${queryString}`,
  { params: { api_key: apiKey } },
);

export const addHomeHealthcareServiceAssets = ({ payload, id }) => protectedServiceInstance.post('home/service/asset/create', payload, {
  params: { api_key: apiKey, id },
  // headers: {
  //   'Content-Type': 'application/json',
  // },
}).then((res) => {
  message.success(res.data.message);
});

export const updateHomeHealthcareServiceAssets = ({ payload, id }) => protectedServiceInstance.post(
  'home/service/assets/update',
  payload,
  {
    params: { api_key: apiKey, id },
    headers: {
      'Content-Type': 'application/json',
    },
  },
).then((res) => {
  message.success(res.data.message);
});
export const deleteHomeHealthcareServiceAssets = ({ id }) => protectedServiceInstance.post(
  'home/service/asset/delete',
  {},
  {
    params: { api_key: apiKey, id },
    headers: {
      'Content-Type': 'application/json',
    },
  },
).then((res) => {
  message.success(res.data.message);
});

export const getAllHomeHealthcarePrograms = (queryString) => protectedServiceInstance.get(
  `/home/programs/all?${queryString}`,
  { params: { api_key: apiKey } },
);
export const updateHomeHealthcareProgram = ({ payload, id, service_id }) => protectedServiceInstance.post(
  'home/program/update',
  payload,
  {
    params: { api_key: apiKey, id, service_id },
    headers: {
      'Content-Type': 'application/json',
    },
  },
).then((res) => {
  message.success(res.data.message);
});
export const getSingleHomeHealthcareProgram = (queryString) => protectedServiceInstance.get(
  `home/program/show?${queryString}`,
  { params: { api_key: apiKey } },
);
export const addHomeHealthcareProgram = (serviceId, payload) => protectedServiceInstance.post(
  `home/program/create?service_id=${serviceId}`,
  payload,
  {
    params: { api_key: apiKey },
    headers: {
      'Content-Type': 'application/json',
    },
  },
);

export const getProgramCategories = (queryString) => protectedServiceInstance.get(
  `home/program/category/all?${queryString}`,
  { params: { api_key: apiKey } },
);


export const getFilterCategory = (queryString) => protectedServiceInstance.get(
  `home/program/category/filter?${queryString}`,
  { params: { api_key: apiKey } },
);