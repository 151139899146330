import React, { useState, useEffect } from "react";
import {
  Table,
  Typography,
  Menu,
  Dropdown,
  Checkbox,
  Button,
  Select,
  Tag,
  Row,
  Col,
} from "antd";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import AdvancedSearchForm from "../../../common/SearchForm";

const { Title } = Typography;
const { Option } = Select;

function Locations(props) {
  const [locations, setLocations] = useState([]);
  const [searchValues, setsearchValues] = useState({});
  const [searchOption, setSearchOption] = useState(false);

  const onSearch = (values) => {
    setsearchValues(values);
    if (Object.keys(values).length === 0 && values.constructor === Object) {
      setLocations(props.homeSupportedLocations);
      return;
    }
    const filtered = Object.values(values).filter((x) => x !== undefined);
    if (filtered.length === 0) {
      setLocations(props.homeSupportedLocations);
      return;
    }
    let locationsNew = [];
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues);
    locationsNew = queryString.map((val) =>
      props.homeSupportedLocations.filter(
        (value) =>
          newValues[val] &&
          JSON.stringify(value[val])
            .toUpperCase()
            .includes(newValues[val].toUpperCase())
      )
    );
    locationsNew = locationsNew
      .reduce((acc, val) => acc.concat(val), [])
      .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
    setLocations(locationsNew);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  useEffect(() => {
    props.getAllHomeCities();
    props.getAllHomeRegions();
    props.getAllHomeSupportedLocations();
  }, []);

  useEffect(() => {
    props.getAllHomeCities();
    props.getAllHomeRegions();
    props.getAllHomeSupportedLocations();
  }, [props.updateHomeSupportedCities]);

  useEffect(() => {
    props.getAllHomeCities();
    props.getAllHomeRegions();
    props.getAllHomeSupportedLocations();
  }, [props.updateHomeSupportedRegions]);

  useEffect(() => {
    setLocations(props.homeSupportedLocations);
    onSearch(searchValues);
  }, [props.homeSupportedLocations]);

  const columns = [
    {
      title: "City",
      key: "name",
      render: (_, columnData) => <div>{columnData.name}</div>,
      width: 200,
    },
    {
      title: "State",
      key: "state",
      render: (_, columnData) => <div>{columnData.state}</div>,
      width: 200,
    },
    {
      title: "Country",
      key: "country",
      render: (_, columnData) => <div>{columnData.country}</div>,
      width: 150,
    },
    {
      title: "Regions / Localities",
      key: "name",
      render: (_, columnData) => (
        <>
          {/* {columnData.regions.map((val) => <span key={val.id}>{`${val.name} `}</span>)} */}
          {columnData.regions.map((val) => (
            <Tag color="#e5f4fe">
              <span key={val.id} style={{ color: "#333333" }}>
                {val.name}
              </span>
            </Tag>
          ))}
        </>
      ),
      width: 250,
    },
    {
      title: "Select",
      key: "actions",
      render: (_, columnData) => (
        <Dropdown
          overlay={
            <Menu
              style={{ margin: "5px", height: "290px", overflow: "scroll" }}
            >
              {props.homeAllRegions &&
                props.homeAllRegions
                  .filter((val) => val.city_id === columnData.id)
                  .map((region, index) => (
                    <div style={{ margin: "auto" }}>
                      {index == 0 && (
                        <div>
                          <Checkbox
                            style={{ margin: "5px" }}
                            key="All"
                            value="0"
                            onChange={(e) => onChangeAll1(e.target.checked, columnData.id)}
                          >
                            Select All
                          </Checkbox>
                        </div>
                      )}

                      <div style={{ margin: "auto" }} key={region.id}>
                        <Checkbox
                          style={{ margin: "5px" }}
                          key={`region_` + region.id}
                          value={region.id}
                          className="region_checkbox"
                          onChange={(e) => onChange1(e, columnData.id)}
                          checked={region.supported}
                        >
                          {region.name}
                        </Checkbox>
                      </div>
                    </div>
                  ))}
            </Menu>
          }
          trigger={["click"]}
        >
          <Button className="table-btn">
            <span
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              Select Regions
            </span>
            <DownOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];

  function onChange1(e, cityId) {
    console.log("props.homeAllRegions", props.homeAllRegions);

    const obj = props.homeAllRegions
      .filter((val) => val.city_id === cityId)
      .reduce((acc, cur) => ({ ...acc, [`${cur.id}`]: cur.supported }), {});

    if (e.target.checked) {
      obj[e.target.value] = 1;
    } else {
      obj[e.target.value] = 0;
    }

    const payloadObj = {
      payload: { region: obj },
    };
    props.updateAllHomeSupportedRegions(payloadObj);
  }

  function onChangeAll1(isChecked, cityId) {
      let obj = props.homeAllRegions
        .filter((val) => val.city_id === cityId)
        .reduce((acc, cur) => ({ ...acc, [`${cur.id}`]: isChecked? 1:0 }), {});

      const payloadObj = {
        payload: { region: obj },
      };
      props.updateAllHomeSupportedRegions(payloadObj);
  }

  function onChange(e) {
    const obj = props.homeAllCities.reduce(
      (acc, cur) => ({ ...acc, [`${cur.id}`]: cur.supported }),
      {}
    );
    if (e.target.checked) {
      obj[e.target.value] = 1;
    } else {
      obj[e.target.value] = 0;
    }
    const payloadObj = {
      payload: { city: obj },
    };
    props.updateAllHomeSupportedCities(payloadObj);
  }
  const menu = (
    <Menu>
      {props.homeAllCities &&
        props.homeAllCities.map((city) => (
          <div style={{ margin: "auto" }} key={city.id}>
            {/* <Menu.Item key={city.id}> */}
            <Checkbox
              style={{ margin: "5px" }}
              key={city.id}
              value={city.id}
              onChange={(e) => onChange(e)}
              checked={city.supported}
            >
              {city.name}
            </Checkbox>
            {/* </Menu.Item> */}
            {/* <Menu.Divider /> */}
          </div>
        ))}
    </Menu>
  );

  return (
    <div className="delivery-location">
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title level={2} className="d-flex title">
            Supported Locations
          </Title>
          <span className="d-flex sub-title">
            City / Region location support for home healthcare services.
          </span>
        </Col>
        <Col className="gutter-row" span={5} offset={0}></Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-dropdown" span={4} offset={0}>
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button className="search-close">
              <span onClick={(e) => e.preventDefault()}>Select Cities</span>
              <DownOutlined />
            </Button>
          </Dropdown>
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Users"
          formItems={[
            {
              key: "name",
              label: "City Name",
            },
            {
              key: "regions",
              label: "Region/Locality",
            },
            {
              key: "state",
              label: "State Name",
            },
            {
              key: "country",
              label: "Select Country",
              Component: (
                <Select placeholder="Select Country" size="large">
                  <Option value="India">India</Option>
                  <Option value="United Kingdom">United Kingdom</Option>
                  <Option value="Australia">Australia</Option>
                  <Option value="United States of America">
                    United States of America
                  </Option>
                </Select>
              ),
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={locations}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="name"
      />
    </div>
  );
}

export default Locations;
