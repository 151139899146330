import React, { useEffect } from "react";
import _ from "lodash";
import { Drawer, Spin, Typography, Row, Col, Divider } from "antd";

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue ? (
        <Text className="darkFont">{value}</Text>
      ) : (
        <Text className="darkFont" type="secondary">
          Not Provided
        </Text>
      )}
    </div>
  </Paragraph>
);

const drawerTitle = (result) => {
  if (!result || _.isEmpty(result)) return undefined;
  return (
    <Row>
      <Col span={24}>
        <DescrtiptionItem value={result.module} />
        <div className="fw500">Session #{result.sid}</div>
      </Col>
    </Row>
  );
};

const DetailsDrawer = ({
  getData,
  data,
  config = [],
  title,
  visible,
  setVisible,
  showMessageIfEmpty,
}) => {
  useEffect(() => {
    if (visible) {
      getData();
    }
  }, [visible]);
  return (
    <Drawer
      title={drawerTitle(data)}
      placement="right"
      closable
      visible={visible}
      width={500}
      onClose={() => setVisible(false)}
    >
      {!data && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      )}
      {data && (
        <>
          <Row>
            <Col span={24} className="fw500 mb-10">
              <Text>SESSION SUMMARY</Text>
            </Col>
            <Col span={12}>
              <DescrtiptionItem title="Session ID" value={data.sid} />
            </Col>
            <Col span={12}>
              <DescrtiptionItem title="Booking ID" value={data.bid} />
            </Col>
            <Col span={12}>
              <DescrtiptionItem title="Service Type" value={data.module} />
            </Col>
            <Col span={12}>
              <DescrtiptionItem title="Session Status" value={data.status} />
            </Col>
            <Col span={12}>
              <DescrtiptionItem
                title="Session Date"
                value={data.session_date}
              />
            </Col>
            <Col span={12}>
              <DescrtiptionItem
                title="Payment Status"
                value={data.payment_status}
              />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24} className="fw500 mb-10">
              <Text>Delivery Information</Text>
            </Col>
            <Col span={24}>
              <DescrtiptionItem title="From Address" value={data.from_address} />
            </Col>
            <Col span={24}>
              <DescrtiptionItem title="To Address" value={data.address} />
            </Col>
            <Col span={24}>
              <DescrtiptionItem title="Distance In KM" value={data.distance_km} />
            </Col>
          </Row>

          <Divider />
          <Row>
            <Col span={24}>
              <DescrtiptionItem
                title="Payment Details"
                value={data.payment_status}
              />
              <Paragraph>
                <Text className="lightFont">Patient Name</Text>
                <div>
                  <Text className="darkFont">{data.patient_name}</Text>
                </div>
                <div>
                  <Text className="darkFont">
                    {data.patient_age}, {data.patient_gender}
                  </Text>
                </div>
              </Paragraph>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescrtiptionItem
                title="Program Name"
                value={data.program_name}
              />
            </Col>
            <Col span={24}>
              <DescrtiptionItem
                title="Program Duration"
                value={data.program_duration}
              />
            </Col>
            <Col span={24}>
              <DescrtiptionItem
                title="Caregiver Name"
                value={data.caregiver_name}
              />
            </Col>
            <Col span={12}>
              <DescrtiptionItem title="Started At" value={data.started_at} />
            </Col>
            <Col span={12}>
              <DescrtiptionItem title="Ended At" value={data.ended_at} />
            </Col>
          </Row>
        </>
      )}
    </Drawer>
  );
};
export default DetailsDrawer;
