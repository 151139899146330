import React, { useEffect } from 'react';

const RedirectToAppStore = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // patient app only not vendor app
    // const playStoreUrl = 'https://play.google.com/store/apps'
    const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.atmed.patient';
    // const appStoreUrl = 'https://www.apple.com/in/app-store/';
    const appStoreUrl = ' https://apps.apple.com/in/app/atmed-healthcare/id1526947995';
    // const webFallbackUrl = 'https://play.google.com/store/apps';
    const webFallbackUrl = 'https://play.google.com/store/apps/details?id=com.atmed.patient'

    if (/android/i.test(userAgent)) {
      window.location.href = playStoreUrl;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = appStoreUrl;
    } else {
      window.location.href = webFallbackUrl; // Redirect desktop users to a landing page
    }
  }, []);

  return (
    <div>
      Redirecting to the appropriate store...
    </div>
  );
};

export default RedirectToAppStore;
