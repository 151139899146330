/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import {
  Layout,
  Menu,
  Form,
  Dropdown,
  Image,
  Modal,
  notification,
  Drawer,
  Input,
  Col,
  Row,
  Button,
  Typography,
} from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, withRouter } from "react-router-dom";
import { noop } from "lodash";
import {
  UserOutlined,
  InfoCircleOutlined,
  CaretDownOutlined,
  BellOutlined,
} from "@ant-design/icons";
import UIfx from "uifx";
import alertAudio from "../assets/alert.mp3";
import footerIcon from "../assets/atmed_footer_logo.svg";
import profileMyprofileIcon from "../assets/CommonIcons/profile_myprofile.svg";
import supportCenterIcon from "../assets/CommonIcons/support_center.svg";
import changePasswordIcon from "../assets/CommonIcons/change_password.svg";
import logoutIcon from "../assets/CommonIcons/logout.svg";
import { get } from "lodash";
import { usePubNub } from "pubnub-react";
import {
  logOut,
  changePasswordAction,
  getProfileInfo,
  getNotifications,
  setNotificationsUnread
} from "../store/actions";
import logoWithText from "../assets/atmed_logo.svg";
import getRoute from "./variables/routePaths/equipmentAdmin";
import { routesToId } from "./variables/routes";
import styles from "./Layout.module.scss";
import moment from "moment";


const alertSoundUrl ="https://atmed-v.s3.ap-south-1.amazonaws.com/common/alert.mp3"
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const mapStateToProps = ({ loginReducer, userReducer }) => ({
  user: loginReducer.user,
  profileInfo: userReducer.profileInfo,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logOut,
      getProfileInfo,
      changePasswordAction,
      getNotifications,
      setNotificationsUnread
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )((props) => {
    const pubnub = usePubNub();
    const [collapsed, setCollapsed] = useState(true);
    const [showDrawer, setShowDrawer] = useState(false);
    const [component, setComponent] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [passwordInputsValidation, setPasswordInputsValidation] =
      useState("");

    const [profileDrawer, setProfileDrawer] = useState(false);

    const { Paragraph, Text } = Typography;
    const [form] = Form.useForm();
    const [message] = useState("");
    const [isNotificationsMenuOpen, setIsNotificationsMenuOpen] =
      useState(false);

    const [notificationList, setIsNotificationsList] = useState("");
    const [notificationCount, setNotificationsCount] = useState("");

    const handleBellClick = () => {
      props.setNotificationsUnread().then((response) => {
        setNotificationsCount(0);
      });
    };

    const alertSound = new UIfx(alertSoundUrl, {
      volume: 0.4,
      throttleMs: 100,
    });
    // alertSound.play();
    // console.log("Sound",  alertSound.play());
    let channels = [];
    const userInfo = JSON.parse(localStorage.getItem("userObj"));
    console.log("userInfo", userInfo);
    channels = Object.values(userInfo.channels || {});
    console.log("channels::", channels);

    useEffect(() => {
      
      if (userInfo.utype == "atmed") {

        props.getNotifications().then((response) => {
          setIsNotificationsList(response?.value?.data?.data);
          setNotificationsCount(response?.value?.data?.notification_unread_count);
        });
      }
      console.log("pubnub",message)

      pubnub.addListener({
        message: (messageEvent) => {
          if (
            messageEvent.message &&
            messageEvent.message !== {} &&
            messageEvent.message.title !== "" &&
            messageEvent.message.title !== undefined
          )
          console.log("checking sound")
           {

            alertSound.play();

            notification.open({
              key: 1,
              message: messageEvent.message.title,
              description: messageEvent.message.desc,
              placement: "bottomLeft",
              // duration: 0,
              icon: <InfoCircleOutlined style={{ color: "#108ee9" }} />,
            });

            if (userInfo.utype == "atmed") {

              props.getNotifications().then((response) => {
                setIsNotificationsList(response?.value?.data?.data);
                setNotificationsCount(response?.value?.data?.notification_unread_count);
              });
            }
            
          }

        },
      });
      pubnub.subscribe({ channels });
      return () => {
        pubnub.unsubscribe({ channels });
      };
    }, [message]);

    const onCollapse = (collapse) => {
      setCollapsed(!collapse);
    };
    const {
      location: { pathname },
      profileInfo,
    } = props;

    const toggleDrawer = () => {
      setShowDrawer(!showDrawer);
    };
    const selectedKey = routesToId[pathname];
    const baseType = `${userInfo.utype}_${userInfo.vtype}`;

    const localData = JSON.parse(localStorage.getItem("userObj")).profile;
    const userType = JSON.parse(localStorage.getItem("userObj")).utype;
    const asdn = profileInfo && profileInfo;

    const renderContent = () => {
      return (
        <div style={{ paddingTop: 12, paddingBottom: 30 }}>
          Are you sure you want to logout ?
        </div>
      );
    };

    const confirmLogout = () => {
      Modal.confirm({
        title: `Confirm`,
        icon: "",
        content: renderContent(),
        closable: true,
        okText: `Log Out`,
        onOk() {
          props.logOut();
        },
        cancelButtonProps: { style: { display: "none" } },
      });
    };
    const onChangePassword = () => {
      form
        .validateFields()
        .then(async (values) => {
          if (values.newPassword === values.confirmPassword) {
            setPasswordInputsValidation("");
            let payload = {
              user: {
                password: values.newPassword,
                password_confirmation: values.confirmPassword,
              },
            };
            await props.changePasswordAction(payload);
            // form.resetFields();
            // if (isEmpty(data)) {
            form.resetFields();
            setChangePassword(false);
            // }
          } else {
            setPasswordInputsValidation("Passwords did not match");
          }
        })
        .catch(noop);
    };

    const modalFooter = (
      <Button type="primary" onClick={onChangePassword}>
        UPDATE
      </Button>
    );

    const IconImg = (props) => (
      <img src={props} width="24" style={{ marginRight: 6 }} alt="Route" />
    );

    const showProfileDrawer = async () => {
      await props.getProfileInfo();
      setProfileDrawer(true);
    };

    const onCloseProfile = () => {
      setProfileDrawer(false);
    };

    const menu = (
      <Menu style={{  maxWidth: '30vw', marginLeft: 'auto', marginRight: '0px', maxHeight: '400px', overflow:'auto'  }}>
        {notificationList && notificationList.length > 0 ? (
          notificationList.map((notificationData) => (
            <Menu.Item key={notificationData.id}>
              <span className="text-sm ">
                {notificationData.description} - (
                {moment(notificationData.created_at).fromNow()})
              </span>
            </Menu.Item>
          ))
        ) : (
          <Menu.Item >
              <span className="text-sm ">No Notification Available</span>
          </Menu.Item>
        )}
        {notificationList && notificationList.length > 0 && (
          <Menu.Item>
            <Link to="/atmed/notifications">See all Notifications</Link>
          </Menu.Item>
        )}
      </Menu>
    );

    const headerMenu = (
      <Menu
        style={{
          width: "250px",
          padding: "10px",
          borderRadius: 6,
          marginRight: "25px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Menu.Item
          key="0"
          onClick={showProfileDrawer}
          icon={IconImg(profileMyprofileIcon)}
        >
          <span>My profile</span>
        </Menu.Item>
        {/* <Menu.Item key="1" icon={IconImg(supportCenterIcon)} >
        <span>Support Center</span>
      </Menu.Item> */}
        <Menu.Item
          key="2"
          onClick={() => setChangePassword(true)}
          icon={IconImg(changePasswordIcon)}
        >
          <span>Change Password</span>
        </Menu.Item>
        <Menu.Item key="3" onClick={confirmLogout} icon={IconImg(logoutIcon)}>
          {/* <Button style={{ width: '100%' }} type="primary" className={styles.profileButton} onClick={confirmLogout}>
          Logout
        </Button> */}
          <span>Logout</span>
        </Menu.Item>
      </Menu>
    );

    // eslint-disable-next-line no-nested-ternary
    const returnItems = (Routes) =>
      Routes?.map((menu) =>
        menu?.children?.length ? (
          <SubMenu key={`${menu.id}sub`} icon={menu?.logo} title={menu?.name}>
            {menu.children
              .filter((val) => val)
              .map((subMenu) => (
                <Menu.Item key={subMenu?.id}>
                  <Link to={`${subMenu?.path}`} title={subMenu?.name}>
                    {subMenu?.name}
                  </Link>
                </Menu.Item>
              ))}
          </SubMenu>
        ) : menu.showDrawer ? (
          <Menu.Item
            key={menu?.id}
            icon={menu?.logo}
            title={menu?.name}
            onClick={() => {
              setComponent(menu.component);
              toggleDrawer();
            }}
          >
            {menu?.name}
          </Menu.Item>
        ) : (
          <Menu.Item key={menu?.id} icon={menu?.logo}>
            <Link to={`${menu?.path}`} title={menu?.name}>
              {menu?.name}
            </Link>
          </Menu.Item>
        )
      );

    const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
      <Paragraph>
        {title && <Text className="lightFont">{`${title} `}</Text>}
        <div>
          {showValue ? (
            <Text className="darkFont">{value}</Text>
          ) : (
            <Text className="darkFont" type="secondary">
              -
            </Text>
          )}
        </div>
      </Paragraph>
    );
    const drawerTitle = (item) => {
      return (
        <Row>
          <Col span={4} className="table-fc-image">
            <Image width={50} src={item.pic_url} preview={false} />
          </Col>
          <Col span={16}>
            <Text
              style={{ position: "relative", top: "25%" }}
              className="darkFont"
            >
              {item.name}
            </Text>
          </Col>
        </Row>
      );
    };

    return (
      <>
        {profileInfo && (
          <Drawer
            title={drawerTitle(asdn)}
            width={500}
            // closable={false}
            onClose={onCloseProfile}
            visible={profileDrawer}
          >
            <DescrtiptionItem title="Phone Number" value={asdn.phone} />
            <DescrtiptionItem title="Email" value={asdn.email} />
            <DescrtiptionItem title="Gender" value={asdn.gender} />
            <DescrtiptionItem title="Blood Group" value={asdn.blood_group} />
            <DescrtiptionItem
              title="Date of Birth"
              value={
                asdn.dob === null ? "-" : moment(asdn.dob).format("DD-MM-YYYY")
              }
            />

            <DescrtiptionItem title="Address" value={asdn.address} />
            <DescrtiptionItem
              title="Alt. Phone Number"
              value={asdn.alt_phone}
            />
          </Drawer>
        )}
        <Modal
          title="Change Password"
          footer={modalFooter}
          visible={changePassword}
          onOk={onChangePassword}
          onCancel={() => setChangePassword(false)}
          width={400}
          className="common-modal-form"
        >
          <Form form={form} layout="vertical">
            <Row gutter={24}>
              <Col xs={24}>
                <Form.Item
                  name="newPassword"
                  label="New Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input the New Password!",
                    },
                    {
                      pattern: new RegExp(/^\S*$/),
                      message: "field does not accept white spaces",
                    },
                  ]}
                >
                  <Input.Password
                    type="password"
                    size="large"
                    placeholder="Enter New Password"
                  />
                </Form.Item>
                <Form.Item
                  name="confirmPassword"
                  label="Confirm Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input the Confirm Password!",
                    },
                    {
                      pattern: new RegExp(/^\S*$/),
                      message: "field does not accept white spaces",
                    },
                  ]}
                >
                  <Input.Password
                    type="password"
                    size="large"
                    placeholder="Enter Confirm Password"
                  />
                </Form.Item>
              </Col>
            </Row>
            <p style={{ color: "red" }}>{passwordInputsValidation}</p>
          </Form>
        </Modal>
        <Layout style={{ minHeight: "100vh" }}>
          <Drawer
            title=""
            placement="right"
            closable={false}
            onClose={toggleDrawer}
            visible={showDrawer}
            width={500}
            destroyOnClose
          >
            {component}
          </Drawer>
          <Layout className={styles.bodyLayout}>
            <Sider
              // collapsible
              // collapsed={collapsed}
              // onCollapse={() => onCollapse()}
              breakpoint="sm"
              collapsedWidth="0"
              className={styles.sider}
            >
              <img
                src={logoWithText}
                className={styles.headerLogo}
                alt="logo"
              />
              {/* <Menu theme="dark" mode="inline" selectedKeys={[`${selectedKey}`]} defaultOpenKeys={['1sub', '10sub']}> */}
              <Menu
                theme="dark"
                mode="inline"
                selectedKeys={[`${selectedKey}`]}
                defaultOpenKeys={baseType == "atmed_atmed" ? [] : ["1sub"]}
              >
                {returnItems(getRoute(baseType, userInfo.permission))}
              </Menu>
            </Sider>
            <Layout className={styles.headerContentContainer}>
              <Header className={styles.header}>
                {userType == 'atmed' &&

                  <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    visible={isNotificationsMenuOpen}
                    onVisibleChange={setIsNotificationsMenuOpen}
                    placement="bottomCenter"
                  >
                    <div
                      style={{
                        marginRight: "25px",
                      }}
                    >
                      <BellOutlined
                        className="w-5 h-5"
                        onClick={handleBellClick}
                      />
                      {/* Notification badge */}
                      {notificationCount && (
                        <span className="absolute top-0 right-0 inline-block w-3 h-3 transform translate-x-1 -translate-y-1 bg-red-600 border-2 border-white rounded-full dark:border-gray-800">
                          {notificationCount}
                        </span>
                      )}
                    </div>
                  </Dropdown>
                }
                <Dropdown overlay={headerMenu} placement="bottomCenter">
                  <div
                    style={{
                      marginRight: "25px",
                    }}
                  >
                    <UserOutlined />
                    <span style={{ marginLeft: "10px" }}>
                      {get(localData, "name")}
                    </span>{" "}
                    <CaretDownOutlined />
                  </div>
                </Dropdown>
              </Header>
              <Layout style={{ marginTop: 64 }}>
                <Content style={{ margin: 16 }}>{props.children}</Content>
                <Footer style={{ textAlign: "center" }}>
                  Copyright © 2020 ATMED HEALTHCARE PRIVATE LIMITED. All Rights
                  Reserved (v3.0.15)
                  <div>
                    <Image
                      style={{ width: "80px", height: "25px" }}
                      alt="footer_pic"
                      src={footerIcon}
                    />
                  </div>
                </Footer>
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </>
    );
  })
);
