import { useLocation } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { usePubNub } from "pubnub-react";
import { Col, Row } from "antd"; // Assuming you are using Ant Design for grid layout
import Map from "./Map";

const googleMapsApiKey = "AIzaSyAxjwom-B5LHIh_8MYyq61_lyCqCBohz6Y";

let destLatitude = "";
let destLongitude = "";
const TRack = (props) => {
  console.log("props", props);
  const location = useLocation();
  const pathname = location.pathname;
  const id = pathname.split('/').pop(); // Extract the last part of the path
  console.log("🚀 ~ TRack ~ id:", id)

  const [columnData, setColumn] = useState(null);
  console.log("columnData", columnData);
  const getAllHomeHealthcareSessions = async () => {
    try {
      const response = await axios.get(
        `https://api1.atmed.com/emergency-booking-track/${id}`
      );
      setColumn(response?.data?.data);
    } catch (error) {
      console.error("Error fetching home healthcare sessions:", error);
    }
  };
  useEffect(() => {
    if (id) {
      getAllHomeHealthcareSessions();
    }
  }, [id]);

  const pubnub = usePubNub();

  const places = [
    {
      latitude: Number(
        columnData &&
          (columnData?.from_latitude == ""
            ? columnData?.latitude
            : columnData?.from_latitude)
      ),
      longitude: Number(
        columnData &&
          (columnData?.from_longitude == ""
            ? columnData?.longitude
            : columnData?.from_longitude)
      ),
    },
    {
      latitude: Number(columnData && columnData?.centre_latitude),
      longitude: Number(columnData && columnData?.centre_longitude),
    },
  ];
  const {
    loadingElement,
    containerElement,
    mapElement,
    defaultCenter,
    defaultZoom,
  } = props;

  const destinationLocation = {
    lat: Number(columnData && columnData?.centre_latitude),
    lng: Number(columnData && columnData?.centre_longitude),
  };
  return (
    <div>
            {columnData ? (
        <div>
          <Map
            googleMapURL={
              "https://maps.googleapis.com/maps/api/js?key=" +
              googleMapsApiKey +
              "&v=3.exp&libraries=geometry,drawing,places"
            }
            markers={places}
            defaultCenter={{ lat: 13.0697753, lng: 80.2042857 }}
            loadingElement={
              loadingElement || <div style={{ height: `100%` }} />
            }
            containerElement={
              containerElement || <div style={{ height: "60vh" }} />
            }
            mapElement={mapElement || <div style={{ height: `100%` }} />}
            defaultZoom={defaultZoom || 11}
            location={location}
            zoomLevel={18}
            destinationLocation={destinationLocation}
          />
          <Col xs={24} md={24} lg={24}>
            <Row gutter={24} className="trackingboxrow">
              <Col xs={24} md={12} lg={7}>
                <div className="trackingbox">
                  <h2>Booking Details</h2>
                  <p className="lightFont">
                    <span className="first_sp">Booking ID</span>
                    <span className="darkFont">{columnData?.orderid}</span>
                  </p>
                  <p className="lightFont">
                    <span className="first_sp">Booking Date</span>
                    <span className="darkFont">{columnData?.booking_date}</span>
                  </p>
                  <p className="lightFont">
                    <span className="first_sp">Centre Name</span>
                    <span className="darkFont">{columnData?.centre_name}</span>
                  </p>
                  {/* <p className="lightFont">
                    <span className="first_sp">Payment Status</span>
                    <span className="darkFont">
                      {columnData?.payment_status
                        ? columnData.payment_status
                        : "-"}
                    </span>
                  </p> */}
                </div>
              </Col>
              <Col xs={24} md={12} lg={10}>
                <div className="trackingbox">
                  <h2>Patient Details</h2>

                  <p className="lightFont">
                    <span className="first_sp">Name </span>{" "}
                    <span className="darkFont">
                      {columnData?.patient_name
                        ? columnData?.patient_name
                        : "-"}
                    </span>
                  </p>
                  <p className="lightFont">
                    <span className="first_sp">Age</span>
                    <span className="darkFont">
                      {columnData?.patient_age ? columnData?.patient_age : "-"}
                    </span>
                  </p>
                  <p className="lightFont">
                    <span className="first_sp">Gender</span>
                    <span className="darkFont">
                      {columnData?.patient_gender
                        ? columnData?.patient_gender
                        : "-"}
                    </span>
                  </p>
                  <p className="lightFont">
                    <span className="first_sp">Address</span>
                    <span className="darkFont">
                      {columnData?.address
                        ? columnData?.address
                        : "-"}
                    </span>{" "}
                  </p>
                  <p className="lightFont">
                    <span className="first_sp">Phone</span>
                    <span className="darkFont">
                    {columnData?.reg_phone
                        ? columnData?.reg_phone
                        : ""}
                    </span>
                  </p>
                </div>
              </Col>
              <Col xs={24} md={12} lg={7}>
                <div className="trackingbox">
                  <h2>Executive Info</h2>
                  <p className="lightFont">
                    <span className="first_sp">Executive Name</span>
                    <span className="darkFont">{columnData?.delivery_name}</span>
                 
                  </p>
                  <p className="lightFont">
                    <span className="first_sp">Phone</span>
                    <span className="darkFont">{columnData?.delivery_phone}</span>
                  </p>
                
                </div>
              </Col>
            </Row>
          </Col>
        </div>
         ) : (
          " Please Wait"
        )}
    </div>
  );
};

export default TRack;
