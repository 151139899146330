import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Button,
  Typography,
  Select,
  Modal,
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Radio,
  message,
} from "antd";
import { SearchOutlined, SyncOutlined } from "@ant-design/icons";
import AdvancedSearchForm from "../../../common/SearchForm";
import PharmacyCompanyBranchInfoDrawer from "./components/CompanyBranchInfoDrawer";
import ActionsColumn from "./components/ActionsColumn";

const { Title, Link } = Typography;
const { Option } = Select;
const { TextArea } = Input;

function AppointmentClinic(props) {
  const afterGetAllOrders = useSelector(
    (state) => state.medicalReducer.vendorMedicalAppEmergencyAll
  );

  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [searchOption, setSearchOption] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [reasonComments, setReasonComments] = useState("");
  const [selectedOptions, setSelectedOptions] = useState("");
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [columnValue, setColumnValue] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const [form] = Form.useForm();

  const hideConfirmModal = () => {
    setConfirmModal(false);
  };
  const handleOk = () => {
    setConfirmModal(false);
    setColumnValue();
  };
  const handleConfirm = (data) => {
    props.confirmMedicalAppointmentEmergency(`id=${data.id}`);
    setConfirmModal(false);
    setColumnValue();
    props.getAllVendorMedAppointmentEmergency(undefined, 20, currentPage);
  };
  const onCancelOrder = () => {
    let id = columnValue.id;
    const payload = {
      emergency_booking: {
        cancel_reason: selectedOptions,
        cancel_notes: reasonComments,
      },
    };
    props.cancelMedicalAppointmentEmergency({ id, payload });
    props.getAllVendorMedAppointmentEmergency(undefined, 20, currentPage);
    setCancelModalVisible(false);
  };

  const renderData = useCallback(
    (currentPage) => {
      props.getAllVendorMedAppointmentEmergency(undefined, 20, currentPage);
    },
    [
      props.getAllVendorMedAppointmentEmergency,
      props.confirmStatus,
      props.cancelBookingStatus,
    ]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  const handleRefresh = () => {
    props.getAllVendorMedAppointmentEmergency(undefined, 20, currentPage);
    if (afterGetAllOrders && afterGetAllOrders) {
      message.success(afterGetAllOrders.message);
    }
  };

  const assignVendorMedicalDeliveryExecutive = async (payload) => {
    await props.assignVendorMedAppointmentEmergencyExecutive(payload);
    await props.getAllVendorMedAppointmentEmergency(undefined, 20, currentPage);
  };
  const columns = [
    {
      title: "Booking Details",
      key: "booking_id",
      dataIndex: "booking_id",
      width: "35%",
      render: (_, columnData) => (
        <Row className="name-details-row">
          <Col span={14} offset={1}>
            <div className="table-fc-name">{columnData.vendor_name}</div>
            <div className="table-fc-email">
              Booking ID: {columnData.orderid}
            </div>
            <div className="table-fc-email">
              Date: {columnData.booking_date}
            </div>
            <div className="view-details">
              <Link
                onClick={() => {
                  setDescriptionModal(true);
                  setCurrentColumn(columnData);
                }}
              >
                VIEW DETAILS
              </Link>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Patient Details",
      key: "patient_name",
      width: "20%",
      render: (_, columnData) => (
        <Row>
          <Col span={24}>{columnData.patient_name} </Col>
          <Col span={24} className="lightFont">
            {columnData.patient_age} / {columnData.patient_gender}
          </Col>
          <Col span={24} className="lightFont">
            {columnData.reg_phone}
          </Col>
          <Col span={24} className="lightFont">
            {columnData.address}
          </Col>
        </Row>
      ),
    },
    {
      title: "Executive Info",
      key: "medic_name",
      width: "25%",
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>{columnData.delivery_name} </Col>
          </Row>
          <Row>
            <Col span={24} className="lightFont">
              {columnData.delivery_phone}
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Status",
      key: "status_code",
      dataIndex: "status_code",
      width: "25%",
      render: (_, columnData) => (
        <Row>
          <Col span={24}>
            {columnData.status_code === 1 ? (
              <span className="booking-placed">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 2 ? (
              <span className="emergency-book-confirm">
                {columnData.status}
              </span>
            ) : null}
            {columnData.status_code === 3 ? (
              <span className="booking-inprogress">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 4 ? (
              <span className="booking-completed">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 5 ? (
              <span className="booking-cancelled">{columnData.status}</span>
            ) : null}
          </Col>
        </Row>
      ),
    },
    // {
    //   title: 'Action',
    //   key: 'status_code',
    //   dataIndex: 'status_code',
    //   width: '25%',
    //   render: (_, columnData) => (
    //     <Row>

    //       {columnData.confirm_button === 1 ? (
    //         <Col span={24} style={{ marginBottom: 20 }}>
    //           <Button onClick={() => showConfirmModal(columnData)} className='ant-btn-primary'>Confirm</Button>
    //         </Col>
    //       ) : null}

    //       {columnData.cancel_button === 1 ? (
    //         <Col span={24}>
    //           <Button onClick={() => {setCancelModalVisible(true); props.helpOptionVendormedical({'id':columnData.id}); setColumnValue(columnData); }} style={{ borderColor: '#0090FF', color: '#0090FF' }}>Cancel</Button>
    //         </Col>
    //       ) : null}
    //       {columnData.rating > 0 ? (
    //         <Col span={24}>
    //           <Rate value={columnData.rating} />
    //         </Col>
    //       ) : null}
    //       {columnData.cancel_info_button === 1 ? (
    //         <Col span={24}>
    //           <Link onClick={""}>MORE INFO</Link>
    //         </Col>
    //       ) : null}
    //     </Row>
    //   ),
    // }
    {
      title: "Action",
      key: "actions",
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          confirmMedicalAppointmentEmergency={handleConfirm}
          assignVendorMedicalDeliveryExecutive={
            assignVendorMedicalDeliveryExecutive
          }
          appoinmentMedicalUsers={props.appoinmentMedicalUsers}
          vendorMedicalAppointmentEmergencyUsers={
            props.vendorMedicalAppointmentEmergencyUsers
          }
          getAllVendorMedAppointmentEmergency={
            props.getAllVendorMedAppointmentEmergency
          }
          helpOptionVendormedical={props.helpOptionVendormedical}
          helpOptions={props.helpOptions}
          cancelMedicalAppointmentEmergency={
            props.cancelMedicalAppointmentEmergency
          }
        />
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAllVendorMedAppointmentEmergency(queryString, 20, currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  const confirmModalFooter = (
    <>
      <Button onClick={handleConfirm} type="primary">
        Confirm
      </Button>
    </>
  );

  return (
    <div>
      <Modal
        className="logs-modal"
        title="Confirm Booking"
        footer={confirmModalFooter}
        visible={confirmModal}
        onOk={handleOk}
        onCancel={hideConfirmModal}
      >
        <p>
          <span>Booking Id : </span>
          {columnValue && columnValue.id}
        </p>
      </Modal>
      {cancelModalVisible ? (
        <Modal
          className="cancel-order-modal"
          title={`Order ID: ${columnValue && columnValue.id} `}
          footer={false}
          visible={cancelModalVisible}
          onOk={() => setCancelModalVisible(false)}
          onCancel={() => setCancelModalVisible(false)}
          width={800}
        >
          <Form
            form={form}
            // initialValues={status}
            layout="vertical"
            className="cancel-order"
            name="canselOrder"
          >
            <p className="form-title required">Reason for cancellation</p>
            <Row>
              <Col span={12}>
                <Radio.Group
                  onChange={(e) => setSelectedOptions(e.target.value)}
                >
                  {props.helpOptions &&
                    props.helpOptions.abroad.map((options) => (
                      <>
                        <p>
                          <Radio value={options}>{options}</Radio>
                        </p>
                      </>
                    ))}
                </Radio.Group>
              </Col>
              <Col span={12}>
                <p className="lightFont required">Comments</p>
                <TextArea
                  onChange={(e) => setReasonComments(e.target.value)}
                  placeholder="Enter your comments"
                  rows={4}
                />
              </Col>
            </Row>
            <Form.Item className="button-item">
              <Button type="primary" htmlType="submit" onClick={onCancelOrder}>
                CANCEL ORDER
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      ) : (
        ""
      )}
      <PharmacyCompanyBranchInfoDrawer
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        All Appointments - Surgery Consultation
      </Title> */}
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Emergency Bookings
          </Title>
        </Col>
        <Col className="gutter-row section-heading" span={3} offset={0}>
          <Title
              level={4}
              className="d-flex title"
              style={{ marginTop: '10px' }}
            >
              Total: {props.results?.total_count}
            </Title>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={2} offset={0}>
          <Button
            shape="circle"
            icon={<SyncOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => handleRefresh()}
          />
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Surgery Consultation"
          formItems={[
            {
              key: "q[bid_cont]",
              label: "Booking ID",
            },
            {
              key: "q[patient_name_cont]",
              label: "Patient name",
            },
            {
              key: "q[medic_name_cont]",
              label: "Doctor name",
            },
            {
              key: "q[health_centre_name_cont]",
              label: "Centre name",
            },
            {
              key: "q[status_code_eq]",
              label: "Status",
              Component: (
                <Select placeholder="Select Booking status" size="large">
                  <Option value="1">Booking Placed</Option>
                  <Option value="2">Booking Confirmed</Option>
                  <Option value="3">In Progress</Option>
                  <Option value="4">Booking Completed</Option>
                  <Option value="5">Booking Cancelled</Option>
                </Select>
              ),
            },
            {
              key: "q[created_at_gteq]",
              label: "From Date",
              Component: <DatePicker size="large" />,
            },
            {
              key: "q[created_at_lteq]",
              label: "To Date",
              Component: <DatePicker size="large" />,
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={props.results ? props.results.data : null}
        pagination={{
          pageSize: 20,
          total: props.results?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default AppointmentClinic;
