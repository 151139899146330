import { protectedServiceInstance } from "./instance";
import { message } from "antd";
const apiKey = "7A3MXb5pTzE7zMuBRspN";

//Company Profile
export const getMedicalCompanyProfile = (queryString) =>
  protectedServiceInstance.get(`medical/company/profile?${queryString}`, {
    params: { api_key: apiKey },
  });

//Administration - Hospital Stuff
export const getAllAdmHospitalStaff = (queryString) =>
  protectedServiceInstance.get(`medical/users/all?${queryString}`, {
    params: { api_key: apiKey },
  });

export const addAdmHospitalStaff = (payload) =>
  protectedServiceInstance
    .post("medical/user/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const activateAdmHospitalStaff = ({ id, otp_digest }) =>
  protectedServiceInstance
    .post(
      "medical/user/activate",
      {},
      {
        params: { api_key: apiKey, id, otp_digest },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const resendActivateAdmHospitalStuffOTP = ({ id }) =>
  protectedServiceInstance
    .post(
      "medical/user/resend-otp",
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const updateAdmHospitalStaff = ({ payload, id }) =>
  protectedServiceInstance
    .post("medical/user/update", payload, {
      params:
        payload.user.password === undefined ? { id } : { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateAdmHospitalStaffProfile = ({ payload, id }) =>
  protectedServiceInstance
    .post("medical/user/update", payload, {
      params:
        payload.user.password === undefined ? { id } : { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateAdmHospitalPermission = ({ payload, id }) =>
  protectedServiceInstance
    .post("medical/user/permission", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getSingleAdmHospitalStaff = (queryString) =>
  protectedServiceInstance.get(`medical/user/show?id=${queryString}`, {
    params: { api_key: apiKey },
  });

export const getAdmHospitalStaffProfileInfo = (id) =>
  protectedServiceInstance.get("medical/user/profile", {
    params: { api_key: apiKey, id },
  });
export const setAdmHospitalStaffProfileInfo = ({ id, payload }) =>
  protectedServiceInstance
    .post("medical/user/profile/update", payload, {
      params: { api_key: apiKey, id },
    })
    .then((res) => {
      message.success(res.data.message);
    });

//ADM - Health Centres
export const getAllMedicalAdmHealthCentres = (queryString) =>
  protectedServiceInstance.get(`medical/branches/all?${queryString}`, {
    params: { api_key: apiKey },
  });

export const getMedicalAdmHealthCentreInfo = (queryString) =>
  protectedServiceInstance.get(`medical/branch/show?${queryString}`, {
    params: { api_key: apiKey },
  });

export const updateMedicalAdmHealthCentre = ({ id, payload }) =>
  protectedServiceInstance
    .post("medical/branch/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const addMedicalAdmHealthCentreAsset = ({ payload, health_centre_id }) =>
  protectedServiceInstance
    .post("medical/branch/emergency/photo/create", payload, {
      params: { api_key: apiKey, health_centre_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const deleteMedicalAdmHealthCentreAsset = ({ id, health_centre_id }) =>
  protectedServiceInstance
    .post(
      "medical/branch/emergency/photo/delete",
      {},
      {
        params: { api_key: apiKey, id, health_centre_id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

// Company Branches
export const getAllPharmacyCompanyBranches = (queryString) =>
  protectedServiceInstance.get(`pharmacy/branches/all?${queryString}`, {
    params: { api_key: apiKey },
  });

export const getPharmacyCompanyBranchInfo = (queryString) =>
  protectedServiceInstance.get(`pharmacy/branch/show?${queryString}`, {
    params: { api_key: apiKey },
  });

export const updatePharmacyCompanyBranch = ({ payload, id }) =>
  protectedServiceInstance
    .post("pharmacy/branch/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllPharmacyMedicineProducts = (queryString) =>
  protectedServiceInstance.get(`pharmacy/medicines/all?${queryString}`, {
    params: { api_key: apiKey },
  });

export const getAllMedicineProductsSearchAutocomplete = (queryString) =>
  protectedServiceInstance.get(
    `pharmacy/medicines/search-autocomplete?search_term=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const getPharmacyCatalogMedicineProductInfo = (id) =>
  protectedServiceInstance.get("pharmacy/medicines/show", {
    params: { api_key: apiKey, id },
  });

export const addPharmacyMedicineProduct = (payload) =>
  protectedServiceInstance
    .post("pharmacy/medicines/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updatePharmacyMedicineProduct = ({ payload, id }) =>
  protectedServiceInstance
    .post("pharmacy/medicines/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllPharmacyHealthProducts = (queryString) =>
  protectedServiceInstance.get(`pharmacy/products/all?${queryString}`, {
    params: { api_key: apiKey },
  });

export const getPharmacyHealthProductInfo = (id) =>
  protectedServiceInstance.get("pharmacy/products/show", {
    params: { api_key: apiKey, id },
  });

export const getAllPharmacyCategories = (params) =>
  protectedServiceInstance.get(`pharmacy/products/categories`, {
    params: { api_key: apiKey },
  });

export const addPharmacyHealthProducts = (payload) =>
  protectedServiceInstance
    .post("pharmacy/products/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updatePharmacyHealthProduct = ({ payload, id }) =>
  protectedServiceInstance
    .post("pharmacy/products/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

//Administrator - Log Management
export const getAllMedicalLog = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `logs/medical/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

//Surgery - Packages
export const getAllMedicalCatalogSurgeries = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `medical/surgeries/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllMedicalSurgeryAutoComplete = (queryString) =>
  protectedServiceInstance.get(
    `medical/surgeries/search-autocomplete?search_term=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const getMedicalCatalogSurgeryInfo = (id) =>
  protectedServiceInstance.get("medical/surgeries/show", {
    params: { api_key: apiKey, id },
  });

export const addMedicalCatalogSurgery = (payload) =>
  protectedServiceInstance
    .post("medical/surgeries/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateMedicalCatalogSurgery = ({ payload, id }) =>
  protectedServiceInstance
    .post("medical/surgeries/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllMedicalSurgeryMedics = (queryString) =>
  protectedServiceInstance.get(`medical/surgeries/members/all?${queryString}`, {
    params: { api_key: apiKey },
  });

export const addMedicalSurgerySpecialist = (payload) =>
  protectedServiceInstance
    .post("medical/surgeries/specialist/add", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteMedicalSurgerySpecialist = ({
  payload,
  id,
  surgery_package_id,
}) =>
  protectedServiceInstance
    .post("medical/surgeries/specialist/delete", payload, {
      params: { api_key: apiKey, id, surgery_package_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllMedicalSurgeryCentres = (queryString) =>
  protectedServiceInstance.get(`medical/surgeries/centres/all?${queryString}`, {
    params: { api_key: apiKey },
  });
export const addMedicalSurgeryCentres = (payload) =>
  protectedServiceInstance
    .post("medical/surgeries/centre/add", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const deleteMedicalSurgeryCentres = ({
  payload,
  id,
  surgery_package_id,
}) =>
  protectedServiceInstance
    .post("medical/surgeries/centre/delete", payload, {
      params: { api_key: apiKey, id, surgery_package_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

//Surgery - Insurance
export const getAllVendorMedSurgerylnsurance = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `medical/insurance/surgery/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const updateVendorMedSurgeryInsurance = ({ payload, id }) =>
  protectedServiceInstance
    .post("medical/insurance/surgery/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

//Surgery - Spot Finance
export const getAllVendorMedSurgerySpotFinance = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `medical/spot-finance/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const updateVendorMedSurgerySpotFinance = ({ payload, id }) =>
  protectedServiceInstance
    .post("medical/spot-finance/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

//Catalog - Consultant Doctors
export const getAllMedicalCatalogConsultantDoctors = (queryString) =>
  protectedServiceInstance.get(
    `medical/consultation/medics/all?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const updateMedicalCatalogConsultantDoctors = ({
  payload,
  id,
  medic_id,
  health_centre_id,
}) =>
  protectedServiceInstance
    .post("medical/consultation/medics/update", payload, {
      params: { api_key: apiKey, id, medic_id, health_centre_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

//Abroad - Package
export const getAllMedicalAbroadPackages = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `medical/abroad/surgeries/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllMedicalAbroadPackageAutoComplete = (queryString) =>
  protectedServiceInstance.get(
    `medical/abroad/surgeries/search-autocomplete?search_term=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllMedicalAbroadCentres = (queryString) =>
  protectedServiceInstance.get(`medical/abroad/surgeries/centres/all`, {
    params: { api_key: apiKey },
  });

export const getMedicalAbroadPackageInfo = (id) =>
  protectedServiceInstance.get("medical/abroad/surgeries/show", {
    params: { api_key: apiKey, id },
  });

export const addMedicalAbroadPackage = (payload) =>
  protectedServiceInstance
    .post("medical/abroad/surgeries/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateMedicalAbroadPackage = ({ payload, id }) =>
  protectedServiceInstance
    .post("medical/abroad/surgeries/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllMedicalAbroadPackageMedics = (queryString) =>
  protectedServiceInstance.get(
    `medical/abroad/surgeries/members/all?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const addMedicalAbroadPackageSpecialist = (payload) =>
  protectedServiceInstance
    .post("medical/abroad/surgeries/specialist/add", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteMedicalAbroadPackageSpecialist = ({
  payload,
  id,
  abroad_package_id,
}) =>
  protectedServiceInstance
    .post("medical/abroad/surgeries/specialist/delete", payload, {
      params: { api_key: apiKey, id, abroad_package_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const addMedicalAbroadStoryContent = ({
  payload,
  abroad_package_story_id,
  id,
}) =>
  protectedServiceInstance
    .post("medical/abroad/stories/content/create", payload, {
      params: { api_key: apiKey, abroad_package_story_id, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteMedicalAbroadStoryContent = ({
  id,
  abroad_package_story_id,
}) =>
  protectedServiceInstance
    .post(
      "medical/abroad/stories/content/delete",
      {},
      {
        params: { api_key: apiKey, id, abroad_package_story_id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

//Abroad - Insurance
export const getAllVendorMedAbroadInsurance = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `medical/insurance/abroad/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const updateVendorMedAbroadInsurance = ({ payload, id }) =>
  protectedServiceInstance
    .post("medical/insurance/abroad/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

//Appointment - Clinic
export const getAllVendorMedicalAppointmentClincOrders = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `medical/appointments/consultation/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );
export const getSingleVendorMedicalClinicOrder = (queryString) =>
  protectedServiceInstance.get(
    `medical/appointments/consultation/show?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

//Appointment - Surgery
export const getAllVendorMedicalAppointmentSurgeryOrders = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `medical/appointments/surgery/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );
export const getSingleVendorMedicalSurgeryOrder = (queryString) =>
  protectedServiceInstance.get(
    `medical/appointments/surgery/show?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );
export const vendorMedicalAppointmentConfirmSurgery = ({ id }) =>
  protectedServiceInstance
    .post(
      `medical/appointments/surgery/confirm`,
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      getAllVendorMedicalAppointmentSurgeryOrders(undefined, 20, 1);
      message.success(res.data.message);
    });
export const vendorMedicalAppointmentCancelSurgery = ({ id }) =>
  protectedServiceInstance
    .post(
      `medical/appointments/surgery/cancel`,
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });
export const vendorMedicalAppointmentStartSurgery = ({ id }) =>
  protectedServiceInstance
    .post(
      `medical/appointments/surgery/start`,
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      getAllVendorMedicalAppointmentSurgeryOrders(undefined, 20, 1);
      message.success(res.data.message);
    });
export const vendorMedicalAppointmentCompleteSurgery = ({ id }) =>
  protectedServiceInstance
    .post(
      `medical/appointments/surgery/complete`,
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      getAllVendorMedicalAppointmentSurgeryOrders(undefined, 20, 1);
      message.success(res.data.message);
    });
export const vendorMedicalAppointmentSurgeryDetailEdit = ({ id, payload }) =>
  protectedServiceInstance
    .post(`medical/appointments/surgery/details/edit`, payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      getAllVendorMedicalAppointmentSurgeryOrders(undefined, 20, 1);
      message.success(res.data.message);
    });

//Appointment - Medical Tourism
export const getAllVendorMedicalAppointmentTourism = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `medical/appointments/abroad/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );
export const getSingleVendorMedicalTourism = (queryString) =>
  protectedServiceInstance.get(
    `medical/appointments/abroad/show?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );
export const vendorMedicalAppointmentEmergencyUsers = (id) =>
  protectedServiceInstance.get(
    `medical/appointments/emergency/emergency-users?id=${id}`,
    {
      params: { api_key: apiKey },
    }
  );
export const confirmMedicalAppointmentTourism = (queryString) =>
  protectedServiceInstance
    .post(`medical/appointments/abroad/confirm?${queryString}`, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const cancelMedicalAppointmentTourism = ({ id, payload }) =>
  protectedServiceInstance
    .post(`medical/appointments/abroad/cancel?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const firstConsultationMedicalAppointmentTourism = ({ id, payload }) =>
  protectedServiceInstance
    .post(`medical/appointments/abroad/first-schedule/update`, payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const confirmFirstConsultationMedicalAppointmentTourism = ({ id }) =>
  protectedServiceInstance
    .post(
      `medical/appointments/abroad/first-schedule/confirm`,
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const completeFirstConsultationMedicalAppointmentTourism = ({ id }) =>
  protectedServiceInstance
    .post(
      `medical/appointments/abroad/first-schedule/complete`,
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });
export const confirmFinalConsultationMedicalAppointmentTourism = ({ id }) =>
  protectedServiceInstance
    .post(
      `medical/appointments/abroad/final-schedule/confirm`,
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });
export const completeFinalConsultationMedicalAppointmentTourism = ({ id }) =>
  protectedServiceInstance
    .post(
      `medical/appointments/abroad/final-schedule/complete`,
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const finalConsultationMedicalAppointmentTourism = ({ id, payload }) =>
  protectedServiceInstance
    .post(`medical/appointments/abroad/final-schedule/update`, payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const helpOptionVendormedical = ({ id, payload }) =>
  protectedServiceInstance.get("medical/help/options", payload, {
    params: { api_key: apiKey, id },
  });

//Appointment - Emergency
export const getAllVendorMedAppointmentEmergency = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `medical/appointments/emergency/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getSingleVendorMedAppointmentEmergency = (queryString) =>
  protectedServiceInstance.get(
    `medical/appointments/emergency/show?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

//Settlement - Surgery
export const getAllVendorMedSettlementSurgery = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `medical/settlements/surgery/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getVendorMedSettlementSurgeryInfo = (queryString) =>
  protectedServiceInstance.get(
    `medical/settlements/surgery/detail?id=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

//Settlement - Abroad
export const getAllVendorMedSettlementAbroad = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `medical/settlements/abroad/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getVendorMedSettlementAbroadInfo = (queryString) =>
  protectedServiceInstance.get(
    `medical/settlements/abroad/detail?id=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

//Feedback
export const getAllMedicalFeedbackClinic = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `medical/consultation/feedback/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllMedicalFeedbackSurgery = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `medical/surgery/feedback/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllMedicalFeedbackTourism = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `medical/abroad/feedback/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllMedicalFeedbackEmergency = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `medical/emergency/feedback/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

//Help and Support
export const getAllMedicalHelpSupport = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `medical/help/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const updateMedicalHelpSupport = ({ payload, id, medic_id }) =>
  protectedServiceInstance
    .post("medical/help/update", payload, {
      params: { api_key: apiKey, id, medic_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const completeMedicalAppointmentTourism = (queryString) =>
  protectedServiceInstance
    .post(`medical/appointments/abroad/complete?${queryString}`, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllMedicalAppoinmentAbroadDocuments = (queryString) =>
  protectedServiceInstance.get(
    `medical/appointments/abroad/documents/all?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const postMedicalAppoinmentAbroadPlan = ({ id, payload }) =>
  protectedServiceInstance
    .post(`medical/appointments/abroad/plan/create?id=${id}`, payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const updateMedicalAppoinmentAbroadPlan = ({
  id,
  abroadPlanId,
  payload,
}) =>
  protectedServiceInstance
    .post(
      `medical/appointments/abroad/plan/update?id=${id}&abroad_plan_id=${abroadPlanId}`,
      payload,
      {
        params: { api_key: apiKey },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });
export const deleteMedicalAppoinmentAbroadPlan = ({ id, abroadPlanId }) =>
  protectedServiceInstance
    .post(
      `medical/appointments/abroad/plan/delete?id=${id}&abroad_plan_id=${abroadPlanId}`,
      {
        params: { api_key: apiKey },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });
export const postMedicalAppoinmentAbroadDocuments = ({ id, payload }) =>
  protectedServiceInstance
    .post(`medical/appointments/abroad/documents/create?id=${id}`, payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
    export const vendorMedicaltourismPaymentOtp = (queryString) =>
      protectedServiceInstance
        .get(`medical/order/payment-otp?id=${queryString}`, {
          params: { api_key: apiKey },
        })
        .then((res) => {
          message.success(res.data.message);
        });
        export const vendorMedicalOrderPaymentVerifyOtp = (queryString) =>
          //console.log('id:', id, 'otp_digest', otp_digest, 'mode', mode_type)
          protectedServiceInstance
            .get(`medical/order/payment-verify-otp?${queryString}`, {
              params: { api_key: apiKey },
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              message.success(res.data.message);
            });
export const confirmMedicalAppointmentEmergency = (queryString) =>
  protectedServiceInstance
    .post(`medical/appointments/emergency/confirm?${queryString}`, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const cancelMedicalAppointmentEmergency = ({ id, payload }) =>
  protectedServiceInstance
    .post(`medical/appointments/emergency/cancel?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const assignVendorMedAppointmentEmergencyExecutive = ({
  id,
  payload,
  userId,
}) =>
  protectedServiceInstance
    .post("medical/appointments/emergency/assign-executive", payload, {
      params: { api_key: apiKey, id, user_id: userId },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateVendorMedicalAbroadStories = ({ payload, id }) =>
  protectedServiceInstance
    .post("medical/abroad/story/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const addVendorMedicalAbroadStories = (payload) =>
  protectedServiceInstance
    .post("medical/abroad/story/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteVendorMedicalAbroadStories = ({ id, abroad_package_id }) =>
  protectedServiceInstance
    .post(
      `medical/abroad/story/delete?id=${id}&abroad_package_id=${abroad_package_id}`,
      {
        params: { api_key: apiKey },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteVendorMedicalAppoinmentDocuments = ({
  id,
  abroad_document_id,
}) =>
  protectedServiceInstance
    .post(
      `medical/appointments/abroad/documents/delete?id=${id}&abroad_document_id=${abroad_document_id}`,
      {
        params: { api_key: apiKey },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });
