import React, { useState, useEffect, Component, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Typography,
  Form,
  Input,
  Select,
  Button,
  message,
  Row,
  Col,
  Spin,
  Modal,
  Divider,
  Space,
  InputNumber,
  AutoComplete,
} from "antd";
import moment from "moment";
import ListForm from "../Booking/listform";
import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import GoogleMapComponent from "../../../common/googlemap";
import GooglePlacesAutocomplete from "react-google-autocomplete";
const { Title } = Typography;
const { Option } = Select;
const today = new Date();
const maxDate = new Date(today);
maxDate.setDate(today.getDate() + 5, 0);

console.log(maxDate);

export const getNextFiveDays = () => {
  const currentDate = new DateObject();
  const nextFiveDays = [];

  for (let i = 0; i < 5; i++) {
    const nextDate = new DateObject().set({
      day: currentDate.day + i, // Adding i+1 to skip today
    });
    nextFiveDays.push(nextDate);
  }
  return nextFiveDays;
};

function BookForm(props) {
  console.log("Creating",props);
  const inputRef = useRef();
  const [sublist ,setSubList] = useState();
  const [subcatgeory,setSubCategory] = useState([]);
  const [allcategory, setAllCategories] = useState([]);
  const [code, setCountryCode] = useState([]);
  const [phoneCode, setPhoneCode] = useState("+91");
  const [duration1, setDuration] = useState();
  const [prognam, setprogname] = useState("");
  const [finalprogram, setFinalprogram] = useState([]);
  const [programcatgory, SetProgramcategory] = useState();
  const [programOptions, setProgramOptions] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [serviceListOption, setServiceListOption] = useState(false);
  const [addressOption, setAddressOption] = useState("");
  const [programListOption, setProgramListOption] = useState(false);
  const [staffListOption, setStaffListOption] = useState(false);
  const defaultDates = [
    new Date(today.getFullYear(), today.getMonth(), today.getDate() + 4),
    new Date(today.getFullYear(), today.getMonth(), today.getDate() + 3),
    new Date(today.getFullYear(), today.getMonth(), today.getDate() + 2),
    new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1),
    new Date(),
  ];

  const nextFiveDays = getNextFiveDays();

  const [selectedDates, setSelectedDates] = useState(nextFiveDays || []);
  console.log("before format", selectedDates);
  const [giver, setcaregiver] = useState("");
  const [addresssaved, setAddresssaved] = useState("");
  const [dob, setDob] = useState("");
  console.log("🚀 ~ BookForm ~ dob:", dob);
  const [user, setUser] = useState([{}]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [name1, setName] = useState("");
  const [service, setService] = useState(false);
  // State to hold user information
  const [phoneNumberList, setPhoneNumberList] = useState([]);
  const [searchPhoneNumber, setSearchPhoneNumber] = useState("");
  const [onClearCount, setOnClearCount] = useState(0);

  // ---------------------New Address------------------------------

  const [houseNumber, setHouseNumber] = useState("");
  const [landmark, setLandmark] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [otherAddressType, setOtherAddressType] = useState("");
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [longitude, setLong] = useState(null);
  const [latitude, setLat] = useState(null);
  const [isAddAddress, setIsAddAddress] = useState(false);
  const [addressType, setAddressType] = useState("");

  const handleAddressTypeChange = (value) => {
    setAddressType(value);
  };

  const handleSaveAddress = (values) => {
    let city = "";
    let country = "";
    let state = "";
    let locality = "";
    let zip = "";
    if (selectedPlace && selectedPlace?.address_components) {
      const addressComponents = selectedPlace?.address_components;
      addressComponents.forEach((component) => {
        if (component.types.includes("locality")) {
          city = component.long_name;
        }
        if (component.types.includes("sublocality")) {
          locality = component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          state = component.long_name;
        }
        if (component.types.includes("country")) {
          country = component.long_name;
        }
        if (component.types.includes("postal_town")) {
          city = component.long_name;
        }
        if (component.types.includes("postal_code")) {
          zip = component.long_name;
        }
      });
      const Newaddress = {
        category: addressType,
        location: selectedPlace?.formatted_address || "",
        house_no: houseNumber,
        locality: locality,
        city: city,
        state: state,
        zip: zip,
        landmark: landmark,
        country: country,
        latitude: latitude,
        longitude: longitude,
        phone_code: phoneCode,
        phone: mobileNumber || `${phoneCode} ${values?.phone}`,
        otherAddressType: otherAddressType,
      };
      return Newaddress;
    }
  };
  // Existing User details and address

  const handleOnSearch = (searchValue) => {
    setUser({});
    // add api here - send searchvalue into api and get the list in console
    props.getAllUser(searchValue).then((response) => {
      const phoneNumbers = response?.value?.data?.data.map((user) => ({
        phone: user.phone,
      }));
      if (phoneNumbers?.length == 0) {
        setSearchPhoneNumber(searchValue);
      } else {
        setSearchPhoneNumber("");
      }
      setPhoneNumberList(phoneNumbers);
    });
  };

  const handleOnSelect = (data) => {
    props.getAllUser(data).then((response) => {
      const findUser = response.value.data.data?.find(
        (each) => each.phone == data
      );

      setPhoneNumber(findUser?.phone);

      // Assuming the API response contains user data
      setUser(findUser);
      // setName(response?.value?.data?.data[0].name);
    });

    // setPhoneNumber
  };
  console.log("UNIQUE", user);
  useEffect(() => {
    const id = user?.id;
    props
      .saveAddress(id)
      .then((response) => {
        const filteredAddress = response?.value?.data?.data;
        setAddressOption(filteredAddress);
      })

      .catch((error) => {});
  }, [user.id]);

  const handleAddressSelect = (e) => {
    setAddresssaved(e);
    setIsAddAddress(false);
    setAddressType("");
    setMobileNumber("");
    setLandmark("");
    setHouseNumber("");
    setSelectedPlace("");
    setUser(user);
  };
  const handleAddressSaved = (address) => {
    onSubmit(address); // Call onSubmit and pass both form values and address
  };

  // vendor

  const userInfo = JSON.parse(localStorage.getItem("userObj"));
  console.log("userInfo", userInfo);

  // Dates and age
  const TodayDateFormated = moment()?.format("DD/MM/YYYY");
  console.log("TodayDateFormated", TodayDateFormated);
  const formattedDates = selectedDates?.map(
    (date) => date?.format("DD/MM/YYYY") || moment(date).format("DD/MM/YYYY")
  );
  console.log("formattedDates five days", selectedDates, formattedDates);

  const formattedDate = dob ? dob.format("DD/MM/YYYY") : "";
  console.log("Selected Date:", formattedDate);
  const calculateAge = (dob) => {
    const now = moment();
    const age = now.diff(dob, "years");
    return age;
  };
  const maxDate1 = new Date(
    today.getFullYear() - 15,
    today.getMonth(),
    today.getDate()
  );
  const handledates = (dates) => {
    setSelectedDates(dates);
  };

  // API CALLING
  const [currentPage, setCurrentPage] = useState(1);
  const [categorybased, setCategoryBased] = useState([]);
  useEffect(() => {
    props.getAllCountryCodes().then((response) => {
      setCountryCode(response?.value?.data?.data);
    });
    props.getAllEquipmentCategories().then((response) => {
      const allCategories = response?.value?.data?.data;
      setCategoryBased(allCategories);
    });
    props.getAllCitie().then((response) => {
      const allCities = response?.value?.data?.data;
      setCity(allCities);
    });
  }, []);


  const handleServiceChange = (value) => {
    const selected = categorybased.find((branch) => branch?.id === value);
    setAllCategories(selected);
    setSubCategory(selected?.sub_categories)
    // sub_categories[0].name
  };

  useEffect(() => {
    if (allcategory?.name && service) {
      const queryString = `category_eq=${allcategory?.name}`;
      props
        .getSearchEquipmentProducts(queryString)
        .then((response) => {
          const FinalProgarm = response?.value?.data?.data;
          const filtered = FinalProgarm.filter(
            (product) =>
              product.category === allcategory?.name && product.mode === service
          );
          console.log("Filtered",filtered)
          setFinalprogram(filtered);
        })
        .catch((error) => {
        });
    }
  }, [allcategory?.name,service]);

  // patient onchange

  const handleNameChange = (e) => {
    const { value } = e.target;
    setUser((prevValues) => ({
      ...prevValues,
      name: value,
    }));
  };
  const handleEmailChange = (e) => {
    const { value } = e.target;
    setUser((prevValues) => ({
      ...prevValues,
      email: value,
    }));
  };

  const handleDobChange = (date) => {
    // Handle date change and update the state
    setUser((prevValues) => ({
      ...prevValues,
      dob: date,
    }));
    setDob(date); // Update the dob state directly with the selected date
  };

  const handleGenderChange = (value) => {
    // Handle gender change and update the state
    setUser((prevValues) => ({
      ...prevValues,
      gender: value,
    }));
  };

  const [product, setProduct] = useState("");
  console.log("product", product)
  const [size, setSize] = useState([]);
  const [qty, setQty] = useState("");
  const [selectedsize, setSelectedSize] = useState("");
  const handleproduct = (value) => {
    const selected = finalprogram.find((branch) => branch?.id === value);
    setProduct(selected);
    setSize(selected?.size);
    setQty(selected?.max_qty);

  };

const handlesubcategory =(value) =>{
  setSubList(value);
}
 // rent days
 const [quantity, setQuantity] = useState(0);

 const increaseQuantity = () => {
   setQuantity((prevQuantity) => prevQuantity + 1);
 };

 const decreaseQuantity = () => {
   setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 0));
 };
  const calculateCollectionFees = (product) => {
    const { delivery_fee_local, delivery_calculation } = product;

    if (delivery_calculation === 'per_qty') {
        // Each quantity has the same fee
        return delivery_fee_local;
    } else if (delivery_calculation === 'group_qty') {
        // Total fee is the same regardless of quantity
        return delivery_fee_local;
    } else {
        // Handle unexpected delivery_calculation values
        // console.warn("Unexpected delivery_calculation value:", delivery_calculation);
        return 0;
    }
};
const collectionFees = calculateCollectionFees(product, quantity);


  const handleSizeChange = (value) => {
    setSelectedSize(value);
  };
  const handleRentalChange = (value) => {
    setService(value);
  };
 
  // MRP AND PRICE

  const MRP1 = product?.mrp * quantity;
  const discounts = product?.discount * quantity;
  const discount = product?.discount
  // const total_payable = MRP1 - discounts + collectionFees;
  const calculatepay = (product , quantity) => {
    console.log("product",product , quantity)
    const { delivery_calculation ,mrp ,discount } = product;
    if (delivery_calculation === 'per_qty') {
      console.log("i am converting")
        return  ((mrp *  quantity) + (collectionFees * quantity)) - (discount * quantity);
    } else if (delivery_calculation === 'group_qty') {
      console.log("i am regret")
      return (mrp *  quantity) + (collectionFees) - (discount * quantity);
    }
};
const total_payable = calculatepay(product ,quantity)
console.log(" total_payable:", total_payable)

  // validation region = 

  const [cities ,setCity] = useState([]);

  const getRegionsForSelectedCity = (cityName) => {
    const city = cities?.find(c => c.name === cityName);
    return city ? city?.regions : [];
  };

  const selectedregions = product?.delivery_local?.flatMap(cityName => getRegionsForSelectedCity(cityName));
  const regionNamesString = selectedregions?.map(region => region?.name.trim()).join(', ');

  console.log('regionNamesString',  regionNamesString);

  // SUBMIT

  const onSubmit = (values) => {
    const phoneNumber = `${phoneCode} ${values?.phone}`;
    const age = calculateAge(formattedDate || values?.dob);
    const address = handleSaveAddress(values);
    const Quantity = quantity > 0
    const isAddress =
      typeof address?.location === "string" && address?.location?.length;
    const isNewAddress =
      typeof values.address === "string" && values.address?.length;

    if (isAddress || isNewAddress) {
      const addressObj = addressOption?.find(
        (each) => each.location === values?.address
      );
      const selectedRegion = address?.locality || addressOption?.find((each) => each.location === values?.address)?.locality;

      console.log("selected region", selectedRegion);

      const normalizedRegionNames = regionNamesString.toLowerCase().split(',').map(name => name.trim());
      const normalizedSelectedRegion = selectedRegion?.toLowerCase().trim();
      
      const isRegionMatched = normalizedRegionNames.includes(normalizedSelectedRegion);

      console.log("checking", isRegionMatched)
      if (isRegionMatched) {
      if(Quantity){
        const payload = {
          equip_transaction: {
            patient_id: user.id ? user.id : "",
            payment_method: "COD",
            payment_id:"COD",
            payment_status: "Pending",
            total_payable: total_payable,
            total_savings: discounts,
            total_price: product?.price,
            total_discount: discounts,
            additional_discount: discounts,
            total_delivery_fee: collectionFees,
            vendor_country: "India",
            applied_coupon: "",
            patient_name: values?.name,
            patient_gender: values?.gender,
            reg_phone: phoneNumber,
            contact_phone: phoneNumber,
            address: address?.location ? address?.location : values?.address, // Include address if provided
            latitude: addressObj?.latitude
              ? addressObj.latitude
              : address.latitude,
            longitude: addressObj?.longitude
              ? addressObj.longitude
              : address.longitude,
            // delivery_fee:collectionFees
          },
          equip_orders: [
            {
              equip_vendor_id: userInfo?.permission?.equip_vendor_id,
              product_id: product?.id,
              vendor_name: userInfo?.profile?.company_name,
              mode: product?.mode,
              product_name: product?.name,
              qty: quantity,
              size: selectedsize,
              category: product?.category,
              sub_category: product?.sub_category,
              brand: product?.brand,
              product_info: product?.category,
              // delivery_info: "Delivery Info",
              // installation_info: "1 day",
              delivery_fee_local:collectionFees,
              delivery_fee_national: "0",
              delivery_fee_global: "0",
              delivery_fee_type: "local",
              mrp: product?.mrp,
              price: product?.price,
              discount: product?.discount,
            },
          ],
          address,
        };
        // Check if the user already exists based on the phone number
        const existingUser = user;

        // Only include patient details if the user does not already exist
        if (!existingUser?.phone) {
          payload.patient = {
            phone_code: phoneCode,
            phone: values?.phone,
            name: values?.name,
            email: values?.email,
            gender: values?.gender,
            dob: formattedDate,
          };
        }
        console.log("onSubmitpayload", payload);
        props.saveMedicalData(payload);
        setUser("");
        setOnClearCount((prev) => prev + 1);
        setAddresssaved("");
        setSelectedPlace(null);
        setSelectedDates([]);
        setAllCategories([]);
        setQuantity(0);
        setProduct("");

      }
      
      else{
      message.error("Please select Quantity / No of days")
      }
    }else{
      message.error("Service  is not available for this address");
    }
    } else {
      message.error("Address cannot be empty");
    }
  };

  // return fields

  const otherFieldInput =
    addressType === "Others"
      ? {
          key: "otherInput",
          name: "otherInput",
          label: "Others specify",
          width: 12,
          Component: (
            <Input
              placeholder="Specify Address Type"
              size="large"
              value={otherAddressType}
              onChange={(e) => setOtherAddressType(e.target.value)}
            />
          ),
        }
      : {};

  const NewAddressFields = isAddAddress
    ? [
        {
          key: "newAddress",
          name: "newAddress",
          width: 24, // Adjust the width according to your layout
          Component: (
            <Form.Item
              label="New Address"
              name="newAddress"
              rules={[{ required: true, message: "New address is required!" }]}
            >
              <div style={{ width: "100%", height: "40px" }}>
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyAxjwom-B5LHIh_8MYyq61_lyCqCBohz6Y"
                  onPlaceSelected={(place) => {
                    if (place && place.geometry && place.geometry.location) {
                      const lat = place.geometry.location.lat();
                      const lng = place.geometry.location.lng();
                      setLat(lat);
                      setLong(lng);
                      // console.log("Latitude:", lat, "Longitude:", lng);
                    } else {
                      console.error("Invalid place object:", place);
                    }
                    setSelectedPlace(place);
                  }}
                  options={{
                    types: ["establishment", "geocode"],
                    componentRestrictions: { country: "IN" },
                  }}
                  style={{ width: "100%", height: "40px" }}
                />
              </div>
              {/* {selectedPlace && (
                <div
                  style={{
                    width: "100%",
                    height: "300px",
                    marginTop: "30px",
                  }}
                >
                  <GoogleMapComponent
                    location={{ lat: latitude, lng: longitude }}
                  />
                </div>
              )} */}
            </Form.Item>
          ),
        },
        {
          key: "houseNumber",
          name: "houseNumber",
          label: "House No/Flat/Building No",
          width: 12,
          rules: [{ required: true, message: "House No is required!" }],
          Component: (
            <Input
              autoComplete="off"
              placeholder="Enter House No/Flat/Building No"
              size="large"
              value={houseNumber}
              onChange={(e) => setHouseNumber(e.target.value)}
            />
          ),
        },
        {
          key: selectedPlace ? "map" : null,
          name: "map",
          Component: selectedPlace && (
            <div
              style={{
                width: "100%",
                height: "300px",
                marginBottom: "30px",
              }}
            >
              <GoogleMapComponent
                location={{ lat: latitude, lng: longitude }}
              />
            </div>
          ),
        },
        {
          key: "landmark",
          name: "landmark",
          label: "Landmark",
          width: 12,
          rules: [{ required: true, message: "Landmark is required!" }],

          Component: (
            <Input
              autoComplete="off"
              placeholder="Enter Landmark"
              size="large"
              value={landmark}
              onChange={(e) => setLandmark(e.target.value)}
            />
          ),
        },
        {
          key: "mobileNumber",
          name: "mobileNumber",
          label: "Mobile Number",
          width: 12,
          rules: [
            { required: true, message: "Phone number is required!" },
            {
              pattern: /^[0-9]{10}$/,
              message: "Phone number must be 10 digits!",
            },
          ],
          Component: (
            <Input
              autoComplete="off"
              placeholder="Enter Mobile Number"
              size="large"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          ),
        },

        {
          key: "addressType",
          name: "addressType",
          label: "Address Type",
          width: 12,
          rules: [{ required: true, message: "AddressType is required!" }],
          Component: (
            // <>
            <Select
              placeholder="Select Address Type"
              size="large"
              onChange={handleAddressTypeChange}
              value={addressType} // Ensure addressType is initialized correctly
            >
              {addressOption?.find(
                (each) => each.category === "Home"
              ) ? null : (
                <Option value="Home">Home</Option>
              )}
              <Option value="Work">Work</Option>
              <Option value="Others">Others</Option>
            </Select>
            // {/* Additional field for specifying address type if "Others" is selected */}
            // {addressType === "Others" && (
            // <Form.Item label="Please specify">
            // <Input placeholder="Specify Address Type" size="large" value={otherAddressType} onChange={(e) => setOtherAddressType(e.target.value)} />
            // </Form.Item>
            // )}
            // </>
          ),
        },
        otherFieldInput,
      ]
    : [];

  return (
    <Spin spinning={props.Loading === true}>
      <Row gutter={[0, 16]}></Row>
      <ListForm
        selectedDates={selectedDates}
        onSubmit={onSubmit}
        user={user}
        allcategory={allcategory}
        addressOption={addressOption}
        discounts={discounts}
        MRP1={MRP1}
        product={product}
        formattedDates={formattedDates}
        total_payable={total_payable}
        addressType={addressType}
        quantity={quantity}
        finalprogram={finalprogram}
        categorybased={categorybased}
        service={service}
        collectionFees={collectionFees}
        formItems={[
          {
            heading: "Patient Details",
          },
          {
            key: "contact_phone",
            label: "Customer Phone Number",
            name: "phone",
            width: 12,
            rules: [{ required: true, message: "Phone number is required!" }],
            Component: (
              <Input.Group
                compact
                validateTrigger={["onBlur", "onChange"]}
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.phone !== currentValues.phone
                }
                style={{ width: "100%" }}
              >
                <Select
                  value={phoneCode}
                  size="large"
                  onSelect={(value) => setPhoneCode(value)}
                  style={{ width: "25%" }}
                >
                  {code?.map((program) => (
                    <Option key={program?.id} value={program?.id}>
                      {program?.phone_code}
                    </Option>
                  ))}
                  {/* Add more options as needed */}
                </Select>
                <Form.Item
                  name="phone"
                  rules={[
                    // { required: true, message: "Phone number is required!" },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Phone number must be 10 digits!",
                    },
                  ]}
                  noStyle
                >
                  <AutoComplete
                    className="autoCompletewidth"
                    size="large"
                    options={phoneNumberList?.map((phoneObj, index) => ({
                      value: phoneObj.phone,
                      label: phoneObj.phone,
                      key: index.toString(),
                    }))}
                    onSelect={handleOnSelect}
                    onSearch={handleOnSearch}
                    placeholder="Enter phone number"
                    style={{ width: "75%" }}
                  />
                </Form.Item>
              </Input.Group>
            ),
          },
          {
            key: "name",
            name: "name",
            label: "Name",
            width: 12,
            rules: [{ required: true, message: "User's name is required!" }],
            Component: (
              <Input
                size="large"
                value={name1}
                placeholder="Enter name"
                onChange={handleNameChange}
              />
            ),
          },
          {
            width: 12,
            label: "Date of Birth",
            key: "dob",
            rules: [
              { required: true, message: " Select Date of Birth is required!" },
            ],
            Component: (
              <DatePicker
                placeholder="Enter date of birth"
                value={dob}
                onChange={handleDobChange}
                maxDate={maxDate1}
                currentDate={new DateObject().set({
                  year: today.getFullYear() - 15,
                  format: "DD/MM/YYYY",
                })}
                className="ant-input ant-input-lg"
                style={{ width: "100%" }}
              />
            ),
          },
          {
            key: "gender",
            label: "Patient Gender",
            width: 12,
            rules: [{ required: true, message: "User's gender is required!" }],
            Component: (
              <Select
                placeholder="Select gender"
                size="large"
                name="gender"
                onChange={(e) => {
                  handleGenderChange(e);
                }}
              >
                <Option value={"Male"}>Male</Option>
                <Option value={"Female"}>Female</Option>
              </Select>
            ),
          },
          {
            key: "email",
            label: "Email ID",
            name: "email",
            width: 12,
            // rules: [{ required: true, message: "  User's email !" }],
            Component: (
              <Input
                size="large"
                onChange={handleEmailChange}
                placeholder="Enter email"
              />
            ),
          },
          // {
          //   key: "address",
          //   label: "Address",
          //   name: "address",
          //   width: 12,
          //   Component: <Input size="large"  placeholder="Enter Address" />,
          // },

          {
            heading: "Booking Details",
          },
          {
            key: "rental",
            label: "Service",
            width: 12,
            rules: [{ required: true, message: "User's service is required!" }],
            Component: (
              <Select showSearch size="large" onChange={handleRentalChange}>
                <Option value="rent">Rental</Option>
                <Option value="buy">Purchase</Option>
              </Select>
            ),
          },
          {
            key: "category",
            label: "Category",
            width: 12,
            rules: [
              { required: true, message: "User's Category is required!" },
            ],
            Component: (
              <Select
                showSearch
                placeholder="Select category"
                size="large"
                onChange={handleServiceChange}
              >
                {categorybased?.map((program) => (
                  <Option key={program?.id} value={program?.id}>
                    {program?.name}
                  </Option>
                ))}
              </Select>
            ),
          },
          {
            key: "subcatgeory",
            label: "Sub Catgeory ",
            width: 12,
            Component: (
              <Select
                showSearch
                placeholder="Select subcatgeory"
                size="large"
                onChange={handlesubcategory}
              >
                {subcatgeory?.map((product) => (
                  <Option key={product?.id} value={product?.name}>
                    {product?.name}
                  </Option>
                ))}
              </Select>
            ),
          },
          {
            key: "product",
            label: "Product ",
            width: 12,
            rules: [
              { required: true, message: "User's product is required! " },
            ],
            Component: (
              <Select
                showSearch
                placeholder="Select product"
                size="large"
                onChange={handleproduct}
              >
                {finalprogram?.map((product) => (
                  <Option key={product?.id} value={product?.id}>
                    {product?.name}
                  </Option>
                ))}
              </Select>
            ),
          },         
          {
            key: "size",
            label: "Size ",
            width: 12,
            rules: [{ required: true, message: "User's size is required! " }],
            Component: (
              <Select
                showSearch
                placeholder="Select size"
                size="large"
                onChange={handleSizeChange}
              >
                {size?.map((productSize) => (
                  <Option key={productSize} value={productSize}>
                    {productSize.toUpperCase()}
                  </Option>
                ))}
              </Select>
            ),
          },
            
          // {
          //   key: "dates",
          //   label: "Select Date",
          //   rules: [{ required: true, message: "Select Date's is required!" }],
          //   Component: (
          //     <DatePicker
          //       className="ant-input ant-input-lg"
          //       multiple
          //       value={selectedDates}
          //       onChange={handledates}
          //       style={{ width: "100%" }} // Adjust the width value as needed
          //       minDate={today}
          //       maxDate={maxDate}
          //       format="DD/MM/YYYY"
          //       plugins={[<DatePanel />]}
          //     />
          //   ),
          // },
          // {
          //   key: "PreferedTime",
          //   label: "Prefered Time",
          //   width: 12,
          //   rules: [{ required: true, message: " Time is required!" }],
          //   Component: (
          //     <Select
          //       size="large"
          //       placeholder="Select time"
          //       className="ant-input ant-input-lg"
          //     >
          //       {timeListOptions.map((option) => (
          //         <Option key={option} value={option}>
          //           {option}
          //         </Option>
          //       ))}{" "}
          //     </Select>
          //   ),
          // },
          {
            heading: "Address Details",
          },
          {
            key: "address",
            label: "Address",
            width: 12,
            // rules: [
            //   { required: !isAddAddress, message: "Address is required!" },
            // ],
            Component: (
              <Select
                placeholder="Select address"
                size="large "
                className="ant-select-lg"
                name="address"
                onChange={(e) => {
                  handleAddressSelect(e);
                }}
                value={addresssaved}
              >
                {addressOption &&
                  addressOption.map((val) => (
                    <Option key={val.id} value={val.location}>
                      {val.location}
                    </Option>
                  ))}
              </Select>
            ),
          },
          {
            name: "button",
            Component: (
              <Button
                onClick={() => {
                  setIsAddAddress((prev) => !prev);
                  setAddresssaved("");
                  setUser(user);
                }}
              >
                {isAddAddress ? "Close" : "Add New Address"}

                {/* {isAddAddress  ? "true" : "false"} */}
              </Button>
            ),
          },
          ...NewAddressFields,
          {
            key: "quantity",
            label: "No of Days / Quantity",
            width: 12,
            Component: (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button onClick={decreaseQuantity} disabled={quantity <= 0}>
                  -
                </Button>
                <InputNumber
                  max={qty}
                  min={0}
                  value={quantity}
                  onChange={setQuantity}
                  style={{ margin: "0 10px", width: "60px" }}
                />
                <Button
                  onClick={increaseQuantity}
                  disabled={quantity >= qty}
                >
                  +
                </Button>{" "}
              </div>
            ),
          }
        ]}
        onAddressSaved={handleAddressSaved} // Pass the handleAddressSaved function
        key={`${onClearCount}`}
        addressData={addresssaved}
      />
    </Spin>
  );
}
export default BookForm;
