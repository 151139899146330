import React, { useState, useEffect, useCallback } from "react";
import { Table, Button, Tag, Typography, Select, Image, Row, Col } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import LabDiagnosticsFormModal from "./components/LabDiagnosticsFormModal";
import ActionsColumn from "./components/ActionsColumn";
import OwnerColumn from "./components/OwnerColumn";
import AdvancedSearchForm from "../../../common/SearchForm";
import VendorDetailModal from "./components/VendorDetailModal";
import { DEFAULT_AGENCY_IMAGE } from "../../../common/awsBucket";
import LabBranchColumn from "./components/LabBranchColumn";

const { Title } = Typography;
const { Option } = Select;

function LabsDiagnostics(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onAddVendorEquipment = async (payload) => {
    const payloadObj = { lab_vendor: payload };
    await props.addAtmedVenderLabsDiagnostics(payloadObj);
    await props.getAllVendorLabsDiagnostics(undefined, 20, currentPage);
    hideModal();
  };
  const onUpdateVendorEquipment = async (payloadObj) => {
    await props.updateAtmedVendorLabsDiagnostics(payloadObj);
    await props.getAllVendorLabsDiagnostics(undefined, 20, currentPage);
  };
  const onAddVendorEquipmentOwner = async (payload) => {
    await props.addAtmedVendorLabsDiagnosticsOwner(payload);
    await props.getAllVendorLabsDiagnostics(undefined, 20, currentPage);
    hideModal();
  };
  // const onDeleteProfile = async (payloadObj) => {
  //   // console.log("on delete console", payloadObj);
  //   await props.updateAtmedVendorLabsDiagnostics(payloadObj)
  //   await props.getAllVendorLabsDiagnostics();
  //   // message.success('Deleted Profile Photo');
  // };
  const onUpdateVendorEquipmentOwner = async (payloadObj) => {
    await props.updateAtmedVendorLabsDiagnosticsOwner(payloadObj);
    await props.getAllVendorLabsDiagnostics(undefined, 20, currentPage);
  };
  const onAddBranch = async (payload) => {
    await props.addAtmedVenderLabsDiagnosticsBranch(payload);
    await props.getAllVendorLabsDiagnostics(undefined, 20, currentPage);
    hideModal();
  };

  const onUpdateBranch = async (payloadObj) => {
    console.log("onUpdateBranch");
    await props.updateAtmedVenderLabsDiagnosticsBranch(payloadObj);
    await props.getAllVendorLabsDiagnostics(undefined, 20, currentPage);
  };

  const renderData = useCallback(
    (currentPage) => {
      props.getAllVendorLabsDiagnostics(undefined, 20, currentPage);
      props.getAllCountry.call();
      props.getAllCountryCodes.call();
    },
    [
      props.getAllVendorLabsDiagnostics,
      // props.updateLabsBranch,
      // props.addLabsBranch,
    ]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  const columns = [
    {
      title: "Vendor",
      key: "name",
      dataIndex: "name",
      width: "28%",
      render: (_, columnData) => (
        // <>
        //   <ShowLogo imageURL={columnData.pic_url ? columnData.pic_url : DEFAULT_AGENCY_IMAGE} />
        //   <div style={{ display: 'inline-flex' }}>
        //     <UploadComponent props={props} id={columnData.id} columnData={columnData} DefaultImage={DEFAULT_AGENCY_IMAGE} />
        //     {columnData.pic_url && columnData.pic_url !== DEFAULT_AGENCY_IMAGE ?
        //       <DeleteLogo onDeleteProfile={onDeleteProfile} columnData={columnData} getAllVendorLabsDiagnostics={props.getAllVendorLabsDiagnostics} /> : null
        //     }
        //   </div>
        // </>
        <Row className="name-details-row">
          <Col span={4} className="table-fc-image">
            <Image
              src={
                columnData.pic_url ? columnData.pic_url : DEFAULT_AGENCY_IMAGE
              }
              //preview={false}
            />
          </Col>
          <Col offset={2}>
            <div className="table-fc-name">{columnData.name}</div>
            <div className="table-fc-email">{columnData.mode}</div>
            <div className="table-fc-status">
              {columnData.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </div>
            <div className="table-fc-status">
              {columnData.lab_service === true ? (
                <Tag color="#e5f4fe">
                  <span style={{ color: "#333333" }}>Lab</span>
                </Tag>
              ) : (
                ""
              )}
              {columnData.diag_service === true ? (
                <Tag color="#e5f4fe">
                  <span style={{ color: "#333333" }}>Diagnostic</span>
                </Tag>
              ) : (
                ""
              )}
            </div>
            <div className="view-details">
              <Link
                onClick={() => {
                  setDescriptionModal(true);
                  setCurrentColumn(columnData);
                }}
              >
                VIEW DETAILS
              </Link>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Owner",
      key: "owner",
      render: (_, columnData) => (
        <OwnerColumn
          columnData={columnData}
          addVendorEquipmentOwner={onAddVendorEquipmentOwner}
          countryCode={props.countryCode}
        />
      ),
    },
    {
      title: "View Orders",
      key: "actions",
      render: (_, columnData) => (
        <>
          <Link to={`/atmed/settlement/orders/labs-diagnostics?lab_vendor_id=${columnData.id}`}>
            LINK
          </Link>
        </>
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAllVendorLabsDiagnostics(queryString, 20, currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <div>
      <VendorDetailModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <LabDiagnosticsFormModal
        onSubmit={onAddVendorEquipment}
        allCountry={props.allCountry}
        countryCode={props.countryCode}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add a New Vendor"
        submitText="Add Vendor"
      />
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Labs & Diagnostics
          </Title>
        </Col>
        <Col className="gutter-row section-heading" span={3} offset={0}>
          <Title
              level={4}
              className="d-flex title"
              style={{ marginTop: '10px' }}
            >
              Total: {props.vendorEquipment?.total_count}
            </Title>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        {/* <Col className="gutter-row header-buttons" span={4} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span>ADD VENDOR</span>
          </Button>
        </Col> */}
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Labs & Diagnostics"
          formItems={[
            {
              key: "q[name_cont]",
              label: "Company Name",
            },
            {
              key: "q[status_eq]",
              label: "Vendor Status",
              Component: (
                <Select placeholder="Vendor Status" size="large">
                  <Option value={true}>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={props.vendorEquipment ? props.vendorEquipment.data : null}
        pagination={{
          pageSize: 20,
          total: props.vendorEquipment?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default LabsDiagnostics;
