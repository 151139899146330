import * as actionType from './actionType';
import * as bookingSettingsApi from '../api/bookingSettingsApi';

export const getAllBookingSettings = (params) => ({
  type: actionType.ATMED_GET_ALL_BOOKING_SETTINGS,
  payload: bookingSettingsApi.getAllBookingSettings(params),
});

export const updateBookingSettings = (params) => ({
  type: actionType.ATMED_UPDATE_BOOKING_SETTINGS,
  payload: bookingSettingsApi.updateBookingSettings(params),
});
